<template>    
        
    <v-container fluid class="white page__container--padding">

        <div class="d-flex align-items-center">            
            <v-card class="mx-auto rounded-lg elevation-4 ma-3 w-100" outlined :style="'border: 4px solid black; background-color:transparent;' ">
                <v-list-item-content>
                    <div class="text-general-module mb-0">
                        <v-icon color="black">mdi mdi-account-cash</v-icon>
                        ESTADO DE CUENTA DEL {{ opt == 'byGroup' ? 'GRUPO' : 'CLIENTE' }}
                    </div>
                </v-list-item-content>
            </v-card>
        </div>

        <!-- Menu -->
        <ButtonsrGroupCustomer :opt="opt"></ButtonsrGroupCustomer>        
        <!-- End Menu -->     

        <!-- Datos del cliente con grupo o sin grupo -->
        <data-customer class="mt-5" :dataClient="dataClient" :loader="loader"></data-customer>      

        <v-row>
            <v-col cols="12"> 
                <!-- Botonera -->
                <div class="buttons__container" v-if="opt !== 'byGroup'">       
                    <div class="buttons__colum buttons__colum-1 d-flex"> 
                        <div class="flex-fill">                           
                            <search-input 
                                v-model="search" 
                                :hideDetails="true" 
                                :placeholder="'Buscar documento'"
                                class="m-2">
                            </search-input>
                        </div>
                        
                        <div class="flex-fill">                            
                            <v-btn class="btn-multipago" @click="isOpenHistorical = true">
                                Histórico estado de cuenta
                            </v-btn>
                        </div>
                    </div>
                    
                    <div class="buttons__colum buttons__colum-2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                          class="btn-multipago button-green" 
                          v-bind="attrs" 
                          v-on="on" 
                          @click="isOpenPayment = true"
                        >
                          Anticipo c/ factura
                        </v-btn>
                      </template>
                      <span>
                        Si usted paga en esta opción, se generará factura por el anticipo. <br/>
                        En este apartado no se hacen pagos de facturas de materiales, 
                        ni pagos parciales, solo pagos por Anticipo.
                      </span>
                    </v-tooltip>
                    
                    </div>
                
                    <div class="buttons__colum buttons__colum-3">                   
                        <router-link :to="{ name: 'AccountStatus' }">
                            <v-btn class="btn-multipago">
                                Realizar pagos
                            </v-btn>
                        </router-link> 
                    </div>
                
                    <div class="buttons__colum buttons__colum-4">  
                        <div class="text-left mr-5">
                            <p class="mb-0"><b>Bono POP (c/IVA): ${{bonus.MONTO | formatCurrency}}</b></p>   
                            <p class="mb-0"><b>Saldo disponible: ${{bonus.DISPO | formatCurrency}}</b></p>   
                        </div>                 
                        <router-link :to="disableMultipayment ? '' : { name: 'AccountStatus', params: { disc: 'PSPO' } }">
                            <v-btn class="btn-multipago" :disabled="disableMultipayment">
                                Aplicar bono
                            </v-btn>
                        </router-link> 
                    </div>
                </div>
                <!-- Fin Botonera -->
                
                <title-header-table>
                    <span>Movimientos al {{dateCurrent}}</span>
                </title-header-table>         
                                        
                <v-data-table                        
                    :headers="dataClient?.CTEGPO>0 ? headers : headers.slice(2,13)"
                    :items="filteredItems"
                    :search="search"                       
                    class="elevation-2 table__desing-general table__movements--scroll"     
                    :items-per-page="filteredItems.length"
                    :mobile-breakpoint="null"
                    :loading="loader"
                    loading-text="Cargando... Por favor espere"
                    :footer-props="footerProps"
                    :options="dataTableOptions"> 
         
                    <template v-slot:[`header.TIPO`]="{ header }"> 
                        {{ header.text }}                       
                        <v-select
                            :items="documentsAll"
                            label="Todos los Documentos"
                            item-text="option"
                            item-value="value"
                            dense
                            solo
                            hide-details
                            v-model="selectedTIPO"
                        ></v-select>                        
                    </template>

                    <template v-slot:[`header.KUNNR`]="{ header }"> 
                        {{ header.text }}                       
                        <v-select
                            :items="customersAll"
                            label="Todos los Clientes"
                            item-text="option"
                            item-value="value"
                            dense
                            solo
                            hide-details
                            v-model="selectedKUNNR"
                        ></v-select>                      
                    </template>
                    
                    <template v-slot:[`header.STCD1`]="{ header }"> 
                        {{ header.text }}                       
                        <v-select
                            :items="rfcAll"
                            label="Todos los RFC"
                            item-text="option"
                            item-value="value"
                            dense
                            solo
                            hide-details
                            v-model="selectedSTCD1"
                        ></v-select>                      
                    </template>                    

                    <template v-slot:foot>
                        <tfoot>
                            <tr>
                                <td :colspan=" dataClient?.CTEGPO>0 ? 10 : 8 " class="text-right foot-sub-total">
                                    <b>Subtotales:</b>
                                </td>
                                <td class="foot-sub-total">
                                    <b>${{sumToExpire | formatCurrency}}</b>
                                </td>
                                <td class="foot-sub-total">
                                    <b style="color: red">${{sumExpired | formatCurrency}}</b>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="13" class="text-right foot-total">
                                    <b>Saldo total: ${{sumAmount | formatCurrency}}</b>
                                </td>
                            </tr>
                        </tfoot>
                    </template>

                    <template v-slot:item="{ item }">                        
                        <row-table-movements 
                            :invoice="item" 
                            :isGroup="parseInt(dataClient.CTEGPO)"
                            @actionButton="validateInsertOrDelete(item)">
                        </row-table-movements>
                    </template>                    
                </v-data-table>
                
                <title-header-table class="mt-5">
                    <span>Información de notas de crédito/Cargo</span>
                </title-header-table>

                <v-simple-table class="elevation-2 table__desing-general">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th width="30%">Concepto</th>
                                <th width="70%">Descripción del concepto</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="concepts.length > 0">
                                <tr v-for="concept, index in concepts" :key="index">
                                    <td>{{ concept.code }}</td>
                                    <td>{{ concept.description }}</td>                                
                                </tr>
                            </template>      
                            <template v-else>
                                <tr><td colspan="2"><i>Sin conceptos</i></td></tr>
                            </template>                      
                        </tbody>
                    </template>
                </v-simple-table>

                <v-row class="mt-4">
                    <v-col cols="12" md="6">                        
                        <p class="mb-0 py-3">Para efectos de la fórmula de PPDP, la fecha de pago que se registra en nuestro sistema es aquella en la que la factura queda saldada y compensada en su totalidad</p>                      
                    </v-col>
                    <v-col cols="12" md="6">                        
                        <dialog-glosary></dialog-glosary>
                    </v-col>
                </v-row>            
                
            </v-col>
        </v-row>

        <ConfidentialityNotice></ConfidentialityNotice> 

        <!-- Payment Card -->
        <v-dialog v-model="isOpenPayment" width="500" persistent>
            <bank-payment-card 
                :userChannel="user.VTWEG" 
                :customerCode="customerCode" 
                :invoice="0" 
                :typePay="2" 
                @close="isOpenPayment = false">
            </bank-payment-card>
        </v-dialog> 
        <!-- End Payment Card -->

        <!-- Histórico -->
        <v-dialog v-model="isOpenHistorical" width="90%">
            <div class="w-full bg-black d-flex justify-content-between align-center px-5">
                <span class="text-white d-flex align-center font-weight-bold">Historial estado de cuenta</span>
                <v-btn icon small style="bg-gray" @click="isOpenHistorical = false">
                <v-icon class="text-white font-size-8">mdi-close</v-icon>
                </v-btn>
            </div>
            <account-status-card :customerCode="customerCode" :customerGroup="opt === 'byCustomer' ? '' : 0"/>
        </v-dialog>
        <!-- Fin Histórico -->
        
    </v-container>

</template>

<script>
import { mapActions } from "vuex";
import DialogGlosary from './components/DialogGlosary'
import ButtonsrGroupCustomer from '@/components/ButtonsrGroupCustomer'
import ConfidentialityNotice from '@/components/ConfidentialityNotice'
import moment from 'moment'
import axios from "@/plugins/axios";
import DataCustomer from '@/components/DataCustomer.vue';
import RowTableMovements from './components/RowTableMovements';
import TitleHeaderTable from '@/components/tables/TitleHeaderTable';
import SearchInput from '@/components/SearchInput.vue';
import BankPaymentCard from '@/components/BankPaymentCard.vue';
import userAuthOrStorage from '@/mixins/userAuthOrStorage';
import AccountStatusCard from "@/components/AccountStatusCard.vue";

export default {
    name: 'AccountStatement',
    props: {
        opt:{
            type: String,
            default: 'byCustomer'
        }
    },
    components: {
        DialogGlosary, 
        ConfidentialityNotice, 
        ButtonsrGroupCustomer, 
        DataCustomer, 
        RowTableMovements, 
        TitleHeaderTable, 
        SearchInput,
        BankPaymentCard,
        AccountStatusCard
    },
    mixins: [userAuthOrStorage],
    data(){
        return{  
            loader: true,          
            dateCurrent: moment().format('D [de] MMMM [del] YYYY HH:mm:ss a'), 
            search: '',
            headers: [
                { text: 'N° de Cliente', value: 'KUNNR', align:"center", sortable: false },
                { text: 'RFC', value: 'STCD1', align:"center", sortable: false },
                { text: '# documento', value: 'BELNR', align:"center", sortable: false },
                { text: 'Orden de Compra', value: 'ORDCOM', align:"center", sortable: false },
                { text: 'Descargas', value: 'ver', align:"center", sortable: false },
                { text: 'Tipo de documento', value: 'TIPO', align:"center", sortable: false },
                { text: 'Fecha de factura', value: 'BUDAT', align:"center", sortable: false },
                { text: 'Fecha de vencimiento', value: 'FVENC', align:"center", sortable: false },
                { text: 'Días vencido', value: 'DVENC', align:"center", sortable: false },
                { text: 'Importe', value: 'DMBTR', align:"center", sortable: false },
                { text: 'Saldo sin vencer', value: 'por_vencer', align:"center", sortable: false },
                { text: 'Saldo Vencido', value: 'vencido', align:"center", sortable: false }
            ],
            items: [],
            dataClient: {},
            selectedKUNNR: "",
            selectedSTCD1: "",
            selectedTIPO: "",
            itemCSV:[],
            bonus: {
                'MONTO': 0,
                'DISPO': 0
            },
            disableMultipayment: true,
            customerCode: null,
            isOpenPayment: false,
            isOpenHistorical: false,
            sortBy: [], // Array de campos por los que se ordena
            sortDesc: [],
            dataTableOptions: {
                itemsPerPage: 15
            },
            footerProps: {
                itemsPerPageOptions: [5, 10, 15, { text: 'Todos', value: -1 }],
                itemsPerPageText: 'Mostrar en grupo de:',
                showCurrentPage: false,
                pageText: '{0} al {1} de {2}'           
            },  
        }        
    },
    mounted(){ 
        this.customerCode = this.validateCustomerCode();
        this.getDataClient();
        this.getCustomersAccounts(); 
        this.getBonus();
        this.generatePDF();
    },
    computed: {
        filteredItems() {       
            return this.items.filter(item => {
                const filterKUNNR = !this.selectedKUNNR || item.KUNNR === this.selectedKUNNR;
                const filterSTCD1 = !this.selectedSTCD1 || item.STCD1 === this.selectedSTCD1;
                const filterTIPO = !this.selectedTIPO || item.TIPO === this.selectedTIPO;

                return filterKUNNR && filterSTCD1 && filterTIPO;
            });
        },        
        sumExpired(){
            return this.items.reduce((total, item) => {        
                return (!item.inserted) ? total + parseFloat(item.vencido) : total;
            }, 0);
        },
        sumToExpire(){
            return this.items.reduce((total, item) => {
                return (!item.inserted) ? total + parseFloat(item.por_vencer) : total;
            }, 0);
        },
        sumAmount(){
            return this.items.reduce((total, item) => { 
                if (!item.inserted) {
                    let subTotal = 0;                    
                    if (item.DETFAC && item.DETFAC.length > 0) {                   
                        subTotal += item.DETFAC.reduce((totalDetFac, detail) => {
                            return totalDetFac + (parseFloat(detail.DMBTR) || 0);
                        }, 0);
                    }
                    return total + subTotal + (parseFloat(item.DMBTR) || 0);
                }
                return total;
            }, 0);
        },
        documentsAll() { 
            const documentos = this.items.map(item => ({'option': item.TIPO, 'value': item.TIPO}));       
            return [{ option: 'Todos los Documentos', value: ''}, ...documentos];
        },
        customersAll() {
            const documentos = this.items.filter(item => !item.inserted).map(item => ({'option': this.$options.filters.formatCustomer(item.KUNNR), 'value': item.KUNNR}));       
            return [{ option: 'Todos los Clientes', value: ''}, ...documentos];        
        },
        rfcAll() {   
            const documentos = this.items.filter(item => !item.inserted).map(item => ({'option': item.STCD1, 'value': item.STCD1}));       
            return [{ option: 'Todos los RFC', value: ''}, ...documentos];      
        },
        concepts(){
            const uniqueConcepts  = new Set();
            return this.items.flatMap(item => 
                (item.DETFAC && item.DETFAC.length > 0)
                    ? item.DETFAC
                        .filter(concept => concept.AUGRU !== '' && !uniqueConcepts.has(concept.AUGRU) && uniqueConcepts.add(concept.AUGRU))
                        .map(concept => ({
                            code: concept.AUGRU,
                            description: concept.DESCR
                        }))
                    : []
            );
        }              
    },
    watch:{
        dataClient(){
            this.generateExcel();
        },
        items(){
            this.generateExcel();
        }   
    },
    methods:{
        ...mapActions({
            sendToExcel: 'printer/sendToExcel',
            sendToPDF: 'printer/sendToPDF',
        }), 
        async getDataClient() {        
            try{
                let response = await axios.post("grupo-cliente/data-client", {
                    client: this.customerCode,
                    VKORG: this.user.VKORG,
                    VTWEG: this.user.VTWEG,
                    VKBUR: this.user.VKBUR,
                    P_OPCGPO: this.opt != 'byGroup' ? 0 : ''
                });

                this.dataClient = response.data.dataDetailClient;               
                this.loader = false;
            }catch(error){
                console.log(error);
                this.loader = false;
            }
        },
        async getCustomersAccounts(){
            try{
                let response = await axios.get("customers-account-statement", {
                    params:{
                        customer_code: this.customerCode,
                        P_VTWEG: this.user.VTWEG,
                        P_VKORG: this.user.VKORG,
                        P_OPCGPO: this.opt != 'byGroup' ? 0 : ''
                    }
                });

                this.items = this.reorderItemsData(response.data.data);
            }catch(error){
                console.log(error)
            }
        },
        async getBonus(){
            try{
                let response = await axios.get("customers-account-statement/get-bonus", {
                    params:{
                        customerCode: this.customerCode,
                        channel: this.user.VTWEG
                    }
                });

                const bonus = response.data.data;
                this.bonus = bonus.find(item => item.CODIGO === '262');
                this.disableMultipayment = this.bonus.DISPO === '0';
            }catch(error){
                console.log(error)
            }
        },
        validateInsertOrDelete(invoice){
            !invoice.isOpen 
                ? this.insertNewsObjects(invoice) 
                : this.deleteNewsObjects(invoice);
        },
        insertNewsObjects(invoice){
            invoice.DETFAC.forEach((item, index) => {
                item.inserted = true;
                item.idInvoice = invoice.BELNR; 
                this.items.splice(this.getPositionItem(invoice.BELNR) + index + 1, 0, item);
            });            
            this.setPropertyIsOpen(true, invoice.BELNR);    
        },
        deleteNewsObjects(invoice){ 
            this.items = this.items.filter(item => item.idInvoice !== invoice.BELNR);
            this.setPropertyIsOpen(false, invoice.BELNR); 
        }, 
        setPropertyIsOpen(isOpen, BELNR){   
            const position = this.getPositionItem(BELNR);         
            this.$set(this.items[position], 'isOpen', isOpen);   
        }, 
        getPositionItem(BELNR){
            return this.items.findIndex(item => item.BELNR === BELNR);
        },
        reorderNotApplied(items){
            const notApplied = items.filter(item => item.TIPO !== 'FACTURA');
            const applied = items.filter(item => item.TIPO === 'FACTURA');
            return [...notApplied, ...applied];
        },  
        reorderExpired(items){
            const expired = items.filter(item => item.ESTAT === 'VENCIDO');
            const withoutExpire = items.filter(item => item.ESTAT !== 'VENCIDO');
            return [...expired, ...withoutExpire];
        },
        reorderItemsData(items){
            items = this.reorderNotApplied(items);
            return this.reorderExpired(items);            
        }, 
        generatePDF(){
            this.sendToPDF({height: 1200});   
        },
        generateExcel(){
            this.itemCSV = []
            const maxCeldas = 11
            let objeto = {}
            
            for(let i = 1; i <= maxCeldas; i++){
                objeto['C' + i] = '';
            }
            
            const fila1 = { ...objeto }
            const fila2 = { ...objeto }
            const fila3 = { ...objeto }
            const fila4 = { ...objeto }
            const fila5 = { ...objeto }
            const fila6 = { ...objeto }
            const fila7 = { ...objeto }
            const fila8 = { ...objeto }
            const fila9 = { ...objeto }
            const fila10 = { ...objeto }
            const fila11 = { ...objeto }
            const fila12 = { ...objeto }

            fila1['C1'] = 'Datos del Cliente'

            fila2['C1'] = 'Nombre del Cliente'
            fila2['C2'] = 'RFC'
            fila2['C3'] = 'Número del Cliente'
            
            fila3['C1'] = this.dataClient.NAME1
            fila3['C2'] = this.dataClient.STCD1
            fila3['C3'] = this.dataClient.KUNNR

            fila5['C1'] = 'Cartera y Línea de crédito'

            fila6['C1'] = 'Límite de crédito'
            fila6['C2'] = 'Línea de crédito comprometida'
            fila6['C3'] = 'Línea de crédito disponible'
            fila6['C4'] = 'Plazo en días'
            fila6['C5'] = 'Saldo Vencido'
            fila6['C6'] = 'Saldo Total'
            fila6['C7'] = 'Notas de Crédito por aplicar'
            fila6['C8'] = 'Pagos pendientes por aplicar'

            fila7['C1'] = this.dataClient.KLIMK
            fila7['C2'] = `$${this.dataClient.SKFOR}`
            fila7['C3'] = `$${this.dataClient.CREDD}`
            fila7['C4'] = this.dataClient.VTEXT
            fila7['C5'] = `$${this.dataClient.SVENC}`
            fila7['C6'] = `$${this.dataClient.STOTA}`
            fila7['C7'] = `$${this.dataClient.DMBTRNC}`
            fila7['C8'] = `$${this.dataClient.DMBTRDZ}`

            fila9['C1'] = `Movimientos al ${this.dateCurrent}`

            fila10['C1'] = 'N° de Cliente'
            fila10['C2'] = 'RFC'
            fila10['C3'] = 'N° de documento'
            fila10['C4'] = 'Tipo de documento'
            fila10['C5'] = 'Fecha de documento'
            fila10['C6'] = 'Fecha de vencimiento'
            fila10['C7'] = 'Días vencido'
            fila10['C8'] = 'Pedido'
            fila10['C9'] = 'Monto'
            fila10['C10'] = 'Saldo sin vencer'
            fila10['C11'] = 'Saldo Vencido'

            this.itemCSV.push(fila1)
            this.itemCSV.push(fila2)
            this.itemCSV.push(fila3)
            this.itemCSV.push(fila4)
            this.itemCSV.push(fila5)
            this.itemCSV.push(fila6)
            this.itemCSV.push(fila7)
            this.itemCSV.push(fila8)
            this.itemCSV.push(fila9)
            this.itemCSV.push(fila10)

            let newItems = []
            for (let key in this.items) {                
                let item = this.items[key];
                newItems.push({
                    'C1': item.KUNNR,
                    'C2': item.STCD1,
                    'C3': item.BELNR,
                    'C4': item.TIPO,                    
                    'C5': item.BUDAT,                    
                    'C6': item.FVENC,                    
                    'C7': item.DVENC,                    
                    'C8': item.BSTNK,                    
                    'C9': `$${item.DMBTR}`,                    
                    'C10': `$${item.por_vencer}`,                    
                    'C11': `$${item.vencido}`,                    
                });
            }

            this.itemCSV = [...this.itemCSV, ...newItems]

            fila11['C9'] = 'Subtotales'
            fila11['C10'] = `$${this.sumToExpire}`
            fila11['C11'] = `$${this.sumExpired}`

            fila12['C10'] = 'Saldo total'
            fila12['C11'] = `$${this.sumAmount}`

            this.itemCSV.push(fila11)
            this.itemCSV.push(fila12)

            /**
             * GENERAR EXCEL DESDE PRINTER
             */
            this.sendToExcel({items: this.itemCSV})

        },        
    }
}
</script>

<style scoped> 
    .foot-total{
        background: transparent linear-gradient(180deg, var(--light-🌕-error-b00020) 0%, #580010 100%) 0% 0% no-repeat padding-box;
        border: 1px solid var(--gris-de-las-tablas);
        background: transparent linear-gradient(180deg, #B00020 0%, #580010 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #DDE1E6;
        opacity: 1;
        height: 28px !important;
        color: #FFF
    }
    .foot-sub-total{
        background: rgb(245,245,245);
        height: 30px !important;
    }  
    .btn-multipago{
        background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        color: #FFF !important;
        text-transform: inherit !important;        
        height: 25px !important;
        font-size: 12px !important;
        min-width: 210px !important;
        border-radius: 10px;
    }
    .buttons__container{
        margin-top: 20px;
        display: flex;
        flex-flow: row wrap;
    }  
    .buttons__colum{
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
        padding: 10px 20px;
    }
    .buttons__colum-1{
        flex: 2;
    }
    .buttons__colum-2{
        flex: 1;
    }
    .buttons__colum-3{
        flex: 1;
    }
    .buttons__colum-4{
        flex: 2;
    }
    .button-green{
        background: transparent linear-gradient(180deg,var(--success-hover-218838) 0,#11441c 100%) 0 0 no-repeat padding-box;
        background: transparent linear-gradient(180deg,#218838,#11441c) 0 0 no-repeat padding-box;
        background-color: transparent;
    }
    .table__movements--scroll ::v-deep table{
        max-height: 400px;
        overflow-y: auto;  
        display: block !important;
        width: 100%; 
    } 
    .table__movements--scroll ::v-deep table thead{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1;
    }
    .table__movements--scroll ::v-deep table tfoot{
        position: sticky;
        bottom: 0;
        z-index: 1;
    }    
    ::v-deep table tbody td{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        border: 1px solid var(--gris-de-las-tablas);
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DDE1E6;
        opacity: 1;
        height: auto !important;
        padding: 4px !important;
        width: 8%;
    }
    ::v-deep table thead th{
        font-weight: 500 !important;
        font-family: Roboto !important;
        background: #f3f3f3 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029;
        opacity: 1;   
        border-bottom: 2px solid #707070 !important;
        font-size: 14px !important;
        text-align: center !important;
        padding: 5px 8px !important;        
        height: auto !important;
    }   
    ::v-deep table thead .v-input__slot, ::v-deep table thead .v-input__control{
        min-height: auto !important;  
        background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box !important;
        opacity: 1;          
    }
    ::v-deep table  thead .v-input__slot .v-icon{
        color: #FFF !important;
    }
    ::v-deep table .v-input__slot .v-label, ::v-deep table .v-input__slot .v-select__selection {
        font-size: 11px !important;
        color: #FFF !important;
    }      
</style>