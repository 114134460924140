export const MIN_ORDER_AMOUNT = 5000;
export const MIN_ORDER_AMOUNT_DT = 2500;
export const NPO_MIN_PRODUCTS_IN_CART = 15;
export const NPO_MIN_INVOICED_LINES = 20;
export const NPO_MIN_CUSTOMER_TENURE_MONTHS = 6;
export const MIN_AMOUNT_PAYMENT_ADVANCE_DT = 5000;
export const MIN_AMOUNT_PAYMENT_ADVANCE_PR = 30000;

/** 
  Warning: The constants in this file are not fully replicated throughout
  the project. Before making any changes, please ensure that the values 
  are consistently updated in all relevant locations to avoid discrepancies
  and potential errors.
*/

/**
  Dictionary of constants

  NPO = New Products Order (In business terminology, NPO is referred to as PSNW), 
*/