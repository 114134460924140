<template>
  <v-dialog
    v-model="isModalOpen"
    :persistent="loading"
    width="550"
  >
    <v-card :loading="loading">
      <div class="d-flex justify-content-between align-center px-4 bg-black">
        <span class="text-white">Búsqueda de Productos</span>
        <v-btn
          color="white"
          icon
          small
          @click="isModalOpen = false"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <div class="pa-3">
        <p v-if="loading">Cargando...</p>
        <p v-if="products?.length === 0 && !loading">
          No se encontraron productos
        </p>
        <search-product-result-item
          v-for="product in products"
          :key="product.MATNR"
          :product="product"
          @select="onProductSelect"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import SearchProductResultItem from './SearchProductResultItem.vue';

  export default {
    name: 'SearchProductResultModal',
    components: {
      SearchProductResultItem,
    },
    props: {
      products: { type: Array },
      loading: { type: Boolean },
      value: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      isModalOpen: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
    methods: {
      onProductSelect(product) {
        this.$emit('select', product);
        this.isModalOpen = false;
      },
    },
  };
</script>

<style scoped>
  .shadow-in {
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  }
</style>
