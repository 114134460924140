<template>
  <div class="my-select">
    <v-menu v-model="showOptions" offset-y :disabled="disabled">
      <template v-slot:activator="{ on, attrs }">
        <v-sheet class="my-select-button d-flex align-center" v-bind="attrs" v-on="disabled ? {} : on"
          :class="{ 'disabled-select': disabled }" outlined @click="toggleOptions">
          <span>{{ selectedLabel }}</span>
          <v-icon right>mdi-chevron-down</v-icon>
        </v-sheet>
      </template>

      <v-list class="my-select-options">
        <v-list-item v-for="(option, index) in options" :key="index" @click="selectOption(option.value)">
          <v-list-item-title>{{ option.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "MySelect",
  props: {
    disabled: {
      type: Boolean,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showOptions: false,
    };
  },
  computed: {
    selectedLabel() {
      const selectedOption = this.options.find(
        (option) => option.value === this.value
      );
      return selectedOption ? selectedOption.label : this.placeholder;
    },
  },
  methods: {
    toggleOptions() {
      if (!this.disabled) {
        this.showOptions = !this.showOptions;
      }
    },
    selectOption(optionValue) {
      if (!this.disabled) {
        this.$emit("input", optionValue);
        this.showOptions = false;
      }
    },
  },
};
</script>

<style scoped>
.my-select {
  position: relative;
  width: 100%;
}

.my-select-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #ebebeb;
  cursor: pointer;
  min-width: 160px;
}

.disabled-select {
  background-color: #f5f5f5 !important;
  color: #a0a0a0;
  cursor: not-allowed !important;
  border: 1px solid #ddd;
}

.my-select-options {
  min-width: 160px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: white;
}

.v-list-item {
  justify-content: center;
  text-align: center;
}

.v-list-item:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
</style>
