import axios from 'axios';
import { cleanExpiredLocalStorage } from '@/utilities/cacheCleaner';
import { setupCache, buildWebStorage } from 'axios-cache-interceptor';

// Set config defaults when creating the instance
const axiosApi = axios.create({
  baseURL: process.env.VUE_APP_FILE_REPOSITORY_API,
  headers: {
    'Content-Type': 'application/json',
    'api-key': process.env.VUE_APP_FILE_REPOSITORY_API_TOKEN,
  },
});

const cacheKey = 'x-products-files-cache:';

const fileRepositoryApi = setupCache(axiosApi, {
  methods: ['get', 'post'], // Cache only get and post requests
  ttl: 3600000, // Time to expire data in cache, 60 minutes sets as default
  interpretHeader: false, // As backend returns no-cache, this is set to false, otherwise it will no cache the requests
  cachePredicate: {
    statusCheck: (status) => [200, 203].includes(status), // It will only cache request with 200 or 203 code responses
  },

  storage: buildWebStorage(localStorage, cacheKey),
});

fileRepositoryApi.interceptors.request.use(
  (config) => {
    // In api call set cachable: false to no cache requests
    if (config.cachable === false) {
      config.cache = false;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Run once immediately when the script loads
cleanExpiredLocalStorage(cacheKey);

export default fileRepositoryApi;
