<template>
    <div class="renglonaje-seller__container" id="toPrint">     
        <v-container white lighten-5 fluid class="card-rounded">
            <div class="d-flex align-items-center ml-5 mr-5">
                <v-card class="mx-auto rounded-lg elevation-4 ma-3 w-100" outlined :style="'border: 4px solid black; background-color:transparent;' ">
                    <v-list-item-content>
                        <div class="text-general-module mb-0">
                            <v-icon color="black">mdi mdi-clock-outline</v-icon>
                            <span class="ml-2">RENGLONAJE POR CLIENTE</span>
                        </div>                    
                    </v-list-item-content>               
                </v-card>                
            </div>

            <div class="ml-5 mr-5">
                <div class="row disabled-option">
                    <div class="col-12">
                        <div class="d-flex justify-content-center align-items-center options">
                            <span class="mr-3">Ver:</span> 
                            <div class="form-check mb-0 mr-3" style="min-height: 0">
                                <input class="form-check-input" type="radio" value="1" v-model="opt" id="r_trim">
                                <label class="form-check-label" for="r_trim">Trimestral</label>
                            </div>

                            <div class="form-check mb-0 mr-3" style="min-height: 0">
                                <input class="form-check-input" type="radio" value="2" v-model="opt" id="r_men">
                                <label class="form-check-label" for="r_men">Mensual</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row d-flex justify-content-center mt-2">
                    <div class="col-md-12">

                            <!-- Table-->
                            <v-data-table
                                        :headers="headers"
                                        :items="items"
                                        :footer-props="footerProps"
                                        :options="dataTableOptions"
                                        :mobile-breakpoint="0"
                                        :loading="isLoading"
                                        class="elevation-2">

                                    <template v-slot:header>
                                        <thead>
                                            <tr class="top-header">
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>                                                
                                                <th class="text-center" :colspan="opt == 1 ? 4 : 6">Facturados en el {{opt == "1" ? 'Trimestre' : 'Mes'}}</th>
                                                <th class="text-center" style="width: 250px">Variación en renglones</th>
                                                <th class="text-center" style="width: 250px" v-if="opt == 1">Variación en renglones</th>
                                            </tr>
                                        </thead>
                                    </template>

                                    <template v-slot:[`item.cod_nom`]="{ item }"> 
                                        <div class="text-left">
                                            <a href="#" class="customer--link" @click.prevent="sendHomeView(item.KUNNR)"><b>{{item.cod_nom}}</b></a>
                                        </div>   
                                    </template>

                                    <template v-slot:[`item.status`]="{ item }"> 
                                        <div class="d-flex table-row">
                                            <template v-if="item.KUNBAJA == 0">   
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }" v-if="item.KUNN30 == 1">                                                
                                                        <v-icon v-bind="attrs"
                                                            v-on="on"
                                                            class="color1 mr-2" >
                                                            mdi-account-plus
                                                        </v-icon>
                                                    </template>
                                                    <span>Cliente nuevo 1 mes</span>
                                                </v-tooltip>

                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }" v-if="item.KUNN90 == 1">                                                
                                                        <v-icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="color2 mr-2" >
                                                            mdi-account-plus
                                                        </v-icon>                                               
                                                    </template>
                                                    <span>Cliente nuevo 3 mes</span>
                                                </v-tooltip> 

                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }" v-if="item.KUNN180 == 1">                                                
                                                        <v-icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="color3 mr-2" >
                                                            mdi-account-plus
                                                        </v-icon>                                               
                                                    </template>
                                                    <span>Cliente nuevo 6 mes</span>
                                                </v-tooltip>
                                                            
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }" v-if="item.KUNIEL == 1">
                                                        <img v-bind="attrs"
                                                            v-on="on"
                                                            src="/img/cliente-con-iusa-en-linea.svg" alt="" class="mr-1" >                                              
                                                    </template>
                                                    <span>Cliente con IUSA en línea </span>
                                                </v-tooltip> 

                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }" v-if="item.KUNNIEL == 1">                                                
                                                        <v-icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="color4 mr-2" >
                                                            mdi-account-voice-off
                                                        </v-icon>                                               
                                                    </template>
                                                    <span>Cliente sin IUSA en línea</span>
                                                </v-tooltip>

                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }" v-if="item.KUNSC3M == 1">                                                
                                                        <v-icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            color="red" >
                                                            mdi-account-cancel
                                                        </v-icon>                                               
                                                    </template>
                                                    <span>Cliente con 3 meses sin comprar</span>
                                                </v-tooltip>

                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }" v-if="item.KUNNSA == 1">                                                
                                                        <v-icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="color5 mr-2" >
                                                            mdi-cancel
                                                        </v-icon> 
                                                    </template>
                                                    <span>Cliente bloqueado por crédito</span>
                                                </v-tooltip>
                                            </template>

                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }" v-if="item.KUNBAJA == 1">                                                
                                                    <img v-bind="attrs" v-on="on" src="/img/angel.svg" class="px-1" />
                                                </template>
                                                <span>Cliente dado de baja</span>
                                            </v-tooltip>
                                        </div>
                                    </template>
                                    
                                    <template v-slot:[`item.DIFREN`]="{ item }">                        
                                        <span :class="{'text-danger': item.DIFREN <= 0 }">{{item.DIFREN}}</span>                     
                                    </template>

                                    <template v-slot:[`item.DIFREN1`]="{ item }">                        
                                        <span :class="{'text-danger': item.DIFREN1 <= 0 }">{{item.DIFREN1}}</span>                     
                                    </template>

                                    <template v-slot:[`item.TOTREN1`]="{ item }">                        
                                        <span :class="{'text-success': item.TOTREN1 == valorMayor(item) && item.TOTREN1 > 0, 'text-danger': item.TOTREN1 == valorMenor(item)}">{{item.TOTREN1 | removeDecimals}}</span>                     
                                    </template>
                                    <template v-slot:[`item.TOTREN2`]="{ item }">                        
                                        <span :class="{'text-success': item.TOTREN2 == valorMayor(item) && item.TOTREN2 > 0, 'text-danger': item.TOTREN2 == valorMenor(item)}">{{item.TOTREN2 | removeDecimals}}</span>                     
                                    </template>
                                    <template v-slot:[`item.TOTREN3`]="{ item }">                        
                                        <span :class="{'text-success': item.TOTREN3 == valorMayor(item) && item.TOTREN3 > 0, 'text-danger': item.TOTREN3 == valorMenor(item)}">{{item.TOTREN3 | removeDecimals}}</span>                     
                                    </template>
                                    <template v-slot:[`item.TOTREN4`]="{ item }">                        
                                        <span :class="{'text-success': item.TOTREN4 == valorMayor(item) && item.TOTREN4 > 0, 'text-danger': item.TOTREN4 == valorMenor(item)}">{{item.TOTREN4 | removeDecimals}}</span>                     
                                    </template>
                                    
                                    <template v-slot:[`item.TOTREN5`]="{ item }" v-if="opt == 2">                        
                                        <span :class="{'text-success': item.TOTREN5 == valorMayor(item) && item.TOTREN5 > 0, 'text-danger': item.TOTREN5 == valorMenor(item)}">{{item.TOTREN5 | removeDecimals}}</span>                     
                                    </template>
                                    <template v-slot:[`item.TOTREN6`]="{ item }" v-if="opt == 2">                        
                                        <span :class="{'text-success': item.TOTREN6 == valorMayor(item) && item.TOTREN6 > 0, 'text-danger': item.TOTREN6 == valorMenor(item)}">{{item.TOTREN6 | removeDecimals}}</span>                     
                                    </template>
                                        
                            </v-data-table>
                            <!-- End Table -->

                    </div>
                </div>

                <div class="row d-flex text-left">
                    <div class="col-12">
                        <div class="cli">
                            <v-icon class="color1">
                                mdi-account-plus
                            </v-icon>
                            Clientes nuevos 1 mes
                        </div>
                        <div class="cli">
                            <v-icon class="color2">
                                mdi-account-plus
                            </v-icon>
                            Clientes nuevos a 3 mes
                        </div>
                        <div class="cli">
                            <v-icon class="color3">
                                mdi-account-plus
                            </v-icon>
                            Clientes nuevos a 6 mes
                        </div>
                    </div>                   
                    
                </div>

                <ConfidentialityNotice></ConfidentialityNotice>
            </div>
            
        </v-container>
    </div>
  
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ConfidentialityNotice from '@/components/ConfidentialityNotice'
import moment from 'moment'
import axios from "@/plugins/axios";
export default {
    components:{ConfidentialityNotice},
    data(){
        return{
            today: moment().format('D [de] MMMM h:mm a'), 
            today2: moment().format('YYYY/MM/DD'), 
            headers: [
                { text: 'id', value: 'id', align:"center" },
                { text: 'Zona', value: 'BZIRK', align:"center" },
                { text: 'Cliente', value: 'cod_nom', align:"center", class: 'table--customer__min' },
                { text: '', value: 'status', align:"left", sortable: false },
                { text: '', value: 'TOTREN4', align:"center" },
                { text: '', value: 'TOTREN3', align:"center" },
                { text: '', value: 'TOTREN2', align:"center" },
                { text: '', value: 'TOTREN1', align:"center" },
                { text: '', value: 'DIFREN', align:"center" }
            ], 
            items: [],
            itemCSV: [],
            dataTableOptions: {
                itemsPerPage: 24,
                pagination: {
                    enabled: true,
                    options: {
                        numeric: true,
                    },
                },
            },
            footerProps: {
                itemsPerPageOptions: [10, 14, 24, 34, { text: 'Todas', value: -1 }],
                itemsPerPageText: 'Mostrar en grupo de:',
                showCurrentPage: false,
                pageText: '{0} al {1} de {2}'           
            },
            breadcrumbsItems: [{
                text: 'IEL',
                disabled: false,
                href: '/',
            }],
            opt: null,
            itemsDates: [],
            isLoading: true
        }
    },
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
            user: 'auth/user'
        })     
    },
    watch:{
        opt(){
            this.getRenglonajeSerller()
            this.filteredHeaders()
            this.calculate()
        },
        items: {
            immediate: true,
            handler() {
                this.generarExcel()
            },
        },
    },
    mounted(){  
        this.cumplimiento = true
        this.opt = '2' 
        this.generarPDF()
    },
    methods:{
        ...mapActions({
            sendToExcel: 'printer/sendToExcel',
            sendToPDF: 'printer/sendToPDF',
        }),
        async getRenglonajeSerller(){
            try{ 
                const { route, params } = this.getRouteAndParams();
                this.isLoading = true
                this.items = []
                let response = await axios.get(route, {params})
                this.items = response.data.data
                this.isLoading = false
            }catch(error){
                console.log(error)
            }
        },
        calculate(){            
            const now = moment();
            this.itemsDates = []
            const limit = this.opt == 2 ? 6 : 4
            for (let i = 0; i < limit; i++) {
                const currentYear = now.year();

                if (this.opt === '1') {
                    const currentQuarter = Math.ceil(now.month() / 3);                    
                    this.itemsDates.push(`${currentQuarter}/${currentYear}`);
                    now.subtract(3, 'months');
                } else if (this.opt === '2') {
                    let currentMonth = now.format('M');
                    currentMonth = this.$options.filters.formatMonthText(currentMonth);
                    this.itemsDates.push(`${currentMonth}/${currentYear}`);
                    now.subtract(1, 'month');                    
                }
            }  
            
            if(this.opt == '1'){                            
                this.headers[4].text = this.itemsDates[3];
                this.headers[5].text = this.itemsDates[2];
                this.headers[6].text = this.itemsDates[1];
                this.headers[7].text = this.itemsDates[0];
                this.headers[8].text = `${this.itemsDates[0]} y ${this.itemsDates[1]}`;          
                this.headers[9].text = `${this.itemsDates[1]} y ${this.itemsDates[2]}`;
            } else if (this.opt == '2') {                              
                this.headers[4].text = this.itemsDates[5];
                this.headers[5].text = this.itemsDates[4];
                this.headers[6].text = this.itemsDates[3];
                this.headers[7].text = this.itemsDates[2];
                this.headers[8].text = this.itemsDates[1];
                this.headers[9].text = this.itemsDates[0];
                this.headers[10].text = `${this.itemsDates[0]} y ${this.itemsDates[1]}`;
            }
        },
        valorMayor(item){
            return Math.max(...[item.TOTREN1, item.TOTREN2, item.TOTREN3, item.TOTREN4])
        },
        valorMenor(item){
            return Math.min(...[item.TOTREN1, item.TOTREN2, item.TOTREN3, item.TOTREN4])
        },
        generarPDF(){
            let objectCSV = {
                'name': `renglonaje-${this.today2}`,
                'height': 1200,
                'disabledClass': 'disabled-option'
            }

            this.sendToPDF(objectCSV) 
        },
        generarExcel(){
            let newItems = []
            this.itemCSV = []

            for (let key in this.items) {                
                let item = this.items[key];

                if(this.opt == 2){
                    newItems.push({
                        'ID': parseInt(key) + 1,
                        'ZONA': item.BZIRK,
                        'CLIENTE': item.cod_nom,                        
                        [`Fact. ${this.opt == "1" ? 'Trim' : 'Mes'} ${this.headers[4].text}`]: this.$options.filters.formatCurrency(item.TOTREN6),
                        [`Fact. ${this.opt == "1" ? 'Trim' : 'Mes'} ${this.headers[5].text}`]: this.$options.filters.formatCurrency(item.TOTREN5),
                        [`Fact. ${this.opt == "1" ? 'Trim' : 'Mes'} ${this.headers[6].text}`]: this.$options.filters.formatCurrency(item.TOTREN4),
                        [`Fact. ${this.opt == "1" ? 'Trim' : 'Mes'} ${this.headers[7].text}`]: this.$options.filters.formatCurrency(item.TOTREN3),
                        [`Fact. ${this.opt == "1" ? 'Trim' : 'Mes'} ${this.headers[8].text}`]: this.$options.filters.formatCurrency(item.TOTREN2),
                        [`Fact. ${this.opt == "1" ? 'Trim' : 'Mes'} ${this.headers[9].text}`]: this.$options.filters.formatCurrency(item.TOTREN1),
                        [`Var. Reng. ${this.headers[10].text}`]: item.DIFREN
                    });
                }else{
                    newItems.push({
                        'ID': parseInt(key) + 1,
                        'ZONA': item.BZIRK,
                        'CLIENTE': item.cod_nom,                        
                        [`Fact. ${this.opt == "1" ? 'Trim' : 'Mes'} ${this.headers[4].text}`]: this.$options.filters.formatCurrency(item.TOTREN4),
                        [`Fact. ${this.opt == "1" ? 'Trim' : 'Mes'} ${this.headers[5].text}`]: this.$options.filters.formatCurrency(item.TOTREN3),
                        [`Fact. ${this.opt == "1" ? 'Trim' : 'Mes'} ${this.headers[6].text}`]: this.$options.filters.formatCurrency(item.TOTREN2),
                        [`Fact. ${this.opt == "1" ? 'Trim' : 'Mes'} ${this.headers[7].text}`]: this.$options.filters.formatCurrency(item.TOTREN1),
                        [`Var. Reng. ${this.headers[8].text}`]: item.DIFREN,
                        [`Var. Reng. ${this.headers[9].text}`]: item.DIFREN1
                    });
                }
            }

            this.itemCSV = newItems

            let objectCSV = {
                'name': `renglonaje-${this.today2}`,
                'items': this.itemCSV,
                'showLabels': true
            }

            this.sendToExcel(objectCSV)
        },
        filteredHeaders() {
            this.headers = []         
            if (this.opt == 2) {
                this.headers = [
                    { text: 'id', value: 'id', align:"center" },
                    { text: 'Zona', value: 'BZIRK', align:"center" },
                    { text: 'Cliente', value: 'cod_nom', align:"center", class: 'table--customer__min' },
                    { text: '', value: 'status', align:"left", sortable: false },                    
                    { text: '', value: 'TOTREN6', align:"center" },
                    { text: '', value: 'TOTREN5', align:"center" },
                    { text: '', value: 'TOTREN4', align:"center" },
                    { text: '', value: 'TOTREN3', align:"center" },
                    { text: '', value: 'TOTREN2', align:"center" },
                    { text: '', value: 'TOTREN1', align:"center" },
                    { text: '', value: 'DIFREN', align:"center" }
                ]
            }else{
                this.headers = [
                    { text: 'id', value: 'id', align:"center" },
                    { text: 'Zona', value: 'BZIRK', align:"center" },
                    { text: 'Cliente', value: 'cod_nom', align:"center", class: 'table--customer__min' },
                    { text: '', value: 'status', align:"left", sortable: false },                    
                    { text: '', value: 'TOTREN4', align:"center" },
                    { text: '', value: 'TOTREN3', align:"center" },
                    { text: '', value: 'TOTREN2', align:"center" },
                    { text: '', value: 'TOTREN1', align:"center" },
                    { text: '', value: 'DIFREN', align:"center" },
                    { text: '', value: 'DIFREN1', align:"center" }
                ]
            }
        },
        sendHomeView(code){
            code = parseInt(code);
            this.$setLocalStorageCustomer(code);
            this.$router.push({name: 'CustomerCoverDetails'});
        },
        getRouteAndParams(){
            const params = {
                opc: parseInt(this.opt),
                P_VTWEG: this.user.VTWEG
            };

            if (this.$hasAnyRole(['Manager', 'Submanager', 'IELManager', 'IELSubManager'])) {
                params.user_id = '0' + this.user.employee_number;
                return { route: '/renglonaje-manager', params };
            }

            if (this.$hasAnyRole(['Seller'])) {
                params.employee_code = this.user.employee_number;
                return { route: '/renglonaje-seller', params };
            }

            return { route: '', params };
        }       
    }
}
</script>

<style>
    .renglonaje-seller__container table thead tr{
        background: #f2f2f2 !important;        
    }
    
    .renglonaje-seller__container table tbody{
        border-top:3px solid  rgb(112, 112, 112); 
    }

    .renglonaje-seller__container table thead tr th{
        font-size: 14px !important;
        height: 30px !important;
        border-right: 1px solid #e0e0e0;        
    }

    .renglonaje-seller__container .v-data-table-header__icon{
        opacity: 1 !important;
    }

    /* Aplica estilos a las filas pares */
    .renglonaje-seller__container .v-data-table tbody tr:nth-child(even) {
        background-color: #f5f5f5;
    }

    .renglonaje-seller__container .v-data-table tbody tr td{
        border-bottom: 0 !important;
        height: 30px !important;
        max-width: 400px !important;
    }

    .renglonaje-seller__container .top-header th{
        border-bottom: 0 !important;        
    }

    .renglonaje-seller__container .options{
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
        padding: 8px 20px;
        font-size: 14px;
    }

    .renglonaje-seller__container .options span{        
        font-weight: bold;
    }

    .renglonaje-seller__container .cli{
        display: inline-block;
        margin-right: 20px;
    }

    .renglonaje-seller__container .color1{
        color: #5600e8 !important;
    }
    .renglonaje-seller__container .color2{
        color: #007bff !important;
    }
    .renglonaje-seller__container .color3{
        color: #f600ff !important;
    }    
    .renglonaje-seller__container .color4{
        color: #dc091e !important;
    } 
    .renglonaje-seller__container .color5{
        color: #b71c1c !important;
    } 
    
    .renglonaje-seller__container table .table--customer__min{
        min-width: 250px;
    }

    .renglonaje-seller__container table .customer--link{
        color: #000 !important
    }

    @media (max-width: 600px) {
        .renglonaje-seller__container .cli{
            display: block;
        }
    }
</style>