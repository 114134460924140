export default {
  methods: {
    /** Capitalizar palabras*/
    capitalize(string) {
      var words = string.split(" ");
      for (var i = 0; i < words.length; i++) {
        var word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return words.join(" ");
    },
    //Array a string eligiendo el separador
    implode(arr, separator = " ") {
      return arr.join(separator);
    },
    arrayPluck(arr, propertyName) {
      return arr.map(item => item[propertyName]);
    },
    arraySum(arr) {
      return arr.reduce((accum, current) => accum + Number(current), 0);
    },
    /**
     * 
     * @param {*} number 
     * @param {*} trunc 
     * @returns string
     */
    number_format(number = 0, trunc = true) {
      let result = trunc ? Math.trunc(number) : Number(number).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    sortByCol(params) {
      /* Ordering  
       params: {
           data:data,
           column:string,
           isString: boolean,
           orderAsc:boolean
         } */
      return params.data.sort((a, b) => {
        if (params.isString) {
          const compareString = params.orderAsc ?
            a[params.column].localeCompare(b[params.column]) :
            b[params.column].localeCompare(a[params.column]);
          return compareString !== 0 ? compareString : a[params.column] - b[params.column];

        } else {
          if (params.orderAsc) {
            if (Number(a[params.column]) < Number(b[params.column])) return -1;
            if (Number(a[params.column]) > Number(b[params.column])) return 1;
            return 0;
          } else {
            if (Number(a[params.column]) > Number(b[params.column])) return -1;
            if (Number(a[params.column]) < Number(b[params.column])) return 1;
            return 0;
          }
        }
      });
    },
    //Calculates the percentage of value 2 with respect to value 1
    calculatePercent(value1, value2) {
      if (value1 == 0 || value2 == 0) return 0;
      return value2 / value1 * 100
    },
  },
}

