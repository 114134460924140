<template>
  <v-dialog v-model="show" width="500" persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Enviar por Correo
      </v-card-title>

      <v-card-text class="pb-0"> 
        <v-form ref="form" class="mt-6">          
            <v-text-field 
              v-model="emailLocal"
              :rules="emailRules"
              label="Ingresar Email:"
            >{{ emailLocal }}</v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
            
      <v-card-actions>
        <v-btn color="error" text @click="show = false"> Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" :disabled="disabled" text @click="sendEmail">
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value:{
      type: Boolean,
      default: false
    },
    email:{
      type: String,
      default: ''
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      emailRules: [
        (v) => !!v || "El correo es obligatorio",
        (v) => /.+@.+\..+/.test(v) || "Ingrese un correo válido",
      ],
      emailLocal: this.email
    }
  },
  methods:{
    sendEmail(){
      if (this.$refs.form.validate()){
        this.$emit('send', this.emailLocal);
      }
    }
  },
  computed:{
    show:{
      set(newValue) {
        this.$emit('input', newValue);
      },
      get(){
        return this.value;
      }
    }
  },
  watch: {
    email(newEmail) {
      this.emailLocal = newEmail;
    }
  },
};
</script>

<style>
</style>