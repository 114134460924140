<template>
  <div class="btn-group d-flex flex-column">
    <v-btn
      class="action-btn"
      @click="cleanOrder"
    >
      <span class="mr-2 icoiusa-plus-circle"></span>
      Limpiar Pedido
    </v-btn>
    <v-btn
      class="action-btn"
      @click="goToBackOrdersView"
    >
      <span class="icoiusa-check-circle"></span>
      <span class="mr-2 icoiusa-group-circle"></span>
      Historial de Pedidos / BackOrder
    </v-btn>
    <v-btn
      v-if="doctype === 'PSIU' && !isBackorder"
      class="action-btn"
      @click="goToUploadFileView"
    >
      <span class="mr-2 icoiusa-cloud-upload"></span>
      Cargar Archivo
    </v-btn>
    <!-- <v-btn
      class="action-btn"
      @click="() => {}"
    >
      <span class="mr-2 icoiusa-group-circle"></span>
      Mis Pedidos
    </v-btn> -->
    <v-btn
      v-if="!isBackorder"
      class="action-btn yellow-button"
      @click="openPromotionsInNewTab"
    >
      <span class="mr-2 icoiusa-asterisk-circle-outline"></span>
      Promociones
    </v-btn>
    <v-btn
      v-if="!isBackorder"
      class="action-btn yellow-button"
      @click="goToProductsOnSaleView"
    >
      <span class="mr-2 icoiusa-tag"></span>
      Liquidaciones
    </v-btn>
    <!-- <v-btn
      v-if="!isBackorder"
      class="action-btn"
      @click="() => {}"
    >
      Aplicar facturas de anticipo
    </v-btn> -->
    <div v-if="!isBackorder" class="btn-spc-order-2">
      <shopping-card
        :showTitle="false"
        style="background-color: #67c3d0"
        class="w-full pt-0 mt-0"
      >
        <v-switch
          dense
          v-model="scheduleOrder"
          color="primary"
          class="d-inline-block pt-0 mt-0 pa-0 pb-0 mb-0"
          hide-details
        >
          <template v-slot:label>
            <span
              label-position="left"
              class="font-size-9 text-white font-weight-bold"
              >Pedido Programado</span
            >
          </template>
        </v-switch>
      </shopping-card>
      <v-menu
        v-if="scheduleOrder"
        ref="scheduleOrderMenu"
        v-model="scheduleOrderMenu"
        :close-on-content-click="false"
        :return-value.sync="scheduleOrderDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="scheduleOrderDate"
            label="Fecha programada"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="scheduleOrderDate"
          @input="closeScheduleOrderDatePicker"
          :min-date="scheduleOrderMinDate"
          is-required
        />
      </v-menu>
    </div>
  </div>
</template>

<script>
  import ShoppingCard from './ShoppingCard.vue';

  import Promotions from '@/mixins/promotionsSubmenu';
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';

  export default {
    name: 'ShoppingCartActionButtons',
    components: {
      ShoppingCard,
    },
    mixins: [Promotions],
    props: {
      doctype: { type: String },
      isBackorder: {type: Boolean}
    },
    data: () => {
      return {
        scheduleOrder: false,
        scheduleOrderMenu: false,
        scheduleOrderDate: moment().add(1, 'days').format('YYYY-MM-DD'),
        scheduleOrderMinDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      };
    },
    computed: {
      ...mapGetters('auth', ['user']),
      ...mapGetters('ordercart', ['orderId', 'itemsInCart']),
    },
    methods: {
      ...mapActions('ordercart', ['cleanMaterialOrder']),
      async cleanOrder() {
        try {
          await this.cleanMaterialOrder({ order_id: this.orderId });
          this.$toast.success('Se limpió el pedido');
        } catch (error) {
          this.$toast.error('No se pudo limpiar el pedido');
        }
      },
      goToBackOrdersView() {
        this.$router.push({ name: 'HistoryOrders' });
      },
      goToUploadFileView() {
        this.$router.push({ name: 'upload-file' });
      },
      async openPromotionsInNewTab() {
        await this.getPromotioPathButton();
      },
      goToProductsOnSaleView() {
        const routeName =
          this.user.VTWEG === 'PR'
            ? 'DiscontinuedMaterialPR'
            : 'DiscontinuedMaterial';

        this.$router.push({ name: routeName });
      },
      closeScheduleOrderDatePicker() {
        this.scheduleOrderMenu = false;
        const dateOffset = new Date().getTimezoneOffset() * 60000;
        this.scheduleOrderDate = new Date(this.scheduleOrderDate - dateOffset)
          .toISOString()
          .slice(0, 10);

        this.$emit('scheduleOrder', {
          scheduleOrder: this.scheduleOrder,
          scheduleOrderDate: this.scheduleOrderDate,
        });
      },
    },
    watch: {
      scheduleOrder: {
        handler(scheduleOrder) {
          if (scheduleOrder) {
            this.scheduleOrderDate = moment()
              .add(1, 'days')
              .format('YYYY-MM-DD');
          }

          this.$emit('scheduleOrder', {
            scheduleOrder: this.scheduleOrder,
            scheduleOrderDate: this.scheduleOrderDate,
          });
        },
        immediate: true,
      },
    },
  };
</script>

<style scoped>
  .action-btn {
    box-shadow: inset 0px 2px 6px #00000029, 0px 2px 6px #00000029;
    font-weight: 900;
    text-transform: capitalize;
  }

  .yellow-button {
    background-color: #f7dca6 !important;
  }

  .btn-group > *:not(:last-child) {
    margin-bottom: 8px;
  }
</style>
