<template>
  <v-container fluid class="white page__container--padding py-5">
    <div class="pb-5">
        <v-row>
          <v-col cols="12" md="8">
          <date-range-selector class="mt-5" @onClick="(data) => range = data" @onReset="resetRange" />
          </v-col>
          <v-col cols="12" md="4">
            <v-row class="mt-10" justify="end">
              <search-input v-model="search" class="search-input-width" />
            </v-row>
          </v-col>
        </v-row>

        <title-header-table>
          <span>Crédito - {{ (isRejectOrder) ? 'Pedidos Rechazados' : 'Pendientes por Autorizar' }}</span>
        </title-header-table>

        <v-data-table                        
        :headers="filteredHeaders"
        :items="sortedItems"
        :search="search"                  
        class="elevation-2 table__desing-general table__movements--scroll table__desing-border"  
        :mobile-breakpoint="null"
        :loading="loader"
        loading-text="Cargando... Por favor espere"
        :footer-props="footerProps"
        :options="dataTableOptions"> 

        <template v-slot:[`item.seller`]="{ item }">
            {{ item.USRNA }}
        </template>
        
        <template v-slot:[`item.KUNNR`]="{ item }">
            {{ item.KUNNR }} <br> {{ item.NAME1 }}
        </template>
        
        <template v-slot:[`item.credit`]="{ item }">
          <v-btn
              color="info"
              x-small
              dark
              @click="openAccountStatement(item)">
              <v-icon small>mdi-eye</v-icon>
          </v-btn>             

          <v-btn
              color="green-button"
              x-small
              class="mx-2"
              :disabled="disabledBtn"
              @click="buttonAuthorization(item);">
              <v-icon small>mdi-check-bold</v-icon>
          </v-btn> 
          
          <v-btn
              color="red-button"
              x-small
              :disabled="disabledBtn"
              @click="buttonReject(item)">
              <v-icon small>mdi-close-thick</v-icon>
          </v-btn> 
        </template>
      </v-data-table>
    </div>

    <ConfidentialityNotice></ConfidentialityNotice>

    <reason-reject 
      v-model="showReject"
      @send="(motive) => postAuthorization(0, motive)"/>
  </v-container>
</template>

<script>
import TitleHeaderTable from '@/components/tables/TitleHeaderTable.vue';
import ConfidentialityNotice from '@/components/ConfidentialityNotice';
import axios from "@/plugins/axios";
import moment from 'moment';
import { mapActions } from "vuex";
import ReasonReject from "@/components/modals/ReasonReject.vue";
import SearchInput from '@/components/SearchInput.vue';
import DateRangeSelector from "@/components/DateRangeSelector.vue";

export default {
  components: { TitleHeaderTable, ConfidentialityNotice, ReasonReject, DateRangeSelector, SearchInput },
  data(){
    return {
      disabledBtn: false,
      loader: true,
      search: '',
      isRejectOrder: false,
      showReject: false,
      numOrder: null,
      customerCode: null,
      range: {
        startDate: null,
        endDate: moment().format('YYYY/MM/DD'), 
      },
      headers: [
        { text: 'Fecha', value: 'ORDDA', align:"center", sortable: true },
        { text: 'Vendedor', value: 'seller', align:"center", sortable: false },
        { text: 'Sucursal', value: 'VKBUR', align:"center", sortable: false },
        { text: 'Cliente', value: 'KUNNR', align:"center", sortable: false },
        { text: 'Folio', value: 'PURCH', align:"center", sortable: false },
        { text: 'Pedido', value: 'VBELN', align:"center", sortable: false },
        { text: 'Estatus', value: 'ORDDE', align:"center", sortable: false },
        { text: 'Canal', value: 'VTWEG', align:"center", sortable: false },
        { text: 'Crédito', value: 'credit', align:"center", sortable: false }        
      ],
      items: [],
      dataTableOptions: {
          itemsPerPage: 15
      },
      footerProps: {
          itemsPerPageOptions: [5, 10, 15, { text: 'Todos', value: -1 }],
          itemsPerPageText: 'Mostrar en grupo de:',
          showCurrentPage: false,
          pageText: '{0} al {1} de {2}'           
      }, 
    }    
  },  
  mounted(){
    this.isRejectOrder = this.$route.name === 'RejectedOrders';
    this.getCreditOrder();
  },
  methods:{
    async getCreditOrder(){
      try{
        const response = await axios.get('/credit/pendings', {
            params:{ ORDST: this.isRejectOrder ? 5 : 2 }
        });
        this.items = response.data.data;
        this.loader = false;
      }catch(err){
        this.$toast.error('Ocurrió un error al cargar los pedidos');
      }
    },
    async postAuthorization(isAuthorized, motive = ''){
      this.disabledBtn = true;
      try{
        await this.setAuthorization({
          customer: this.customerCode,
          order: this.numOrder,
          isAuthorized,
          motive
        });
        
        this.items = [];
        this.getCreditOrder();
        this.$toast.success(`El pedido ${this.numOrder} fue ${isAuthorized ? 'autorizado' : 'rechazado'}`);
      }catch(err){
        this.$toast.error('Ocurrió un error. Por favor inténtelo de nuevo');
      }

      this.disabledBtn = false;
      this.showReject = false;
    },
    buttonReject(item){
      this.numOrder = item.VBELN;
      this.customerCode = item.KUNNR;
      this.showReject = true;
    },
    buttonAuthorization(item){
      this.numOrder = item.VBELN;
      this.customerCode = item.KUNNR;
      this.postAuthorization(1);      
    },
    openAccountStatement(customer){
        this.$store.commit('customerPendings/SET_CUSTOMER', customer);
        this.$router.push({ name: 'CreditAccountStatement' });
    },   
    resetRange(){
      this.range.startDate = null; 
      this.range.endDate = moment().format('YYYY/MM/DD');
    }, 
    ...mapActions("customerPendings", ['setAuthorization']),
  },  
  computed:{
    filteredHeaders() {
        const columns = ['VKBUR', 'VTWEG', 'credit'];
        if(this.isRejectOrder) return this.headers.filter(header => !columns.includes(header.value));

        return this.headers;
    },
    sortedItems() {
        return this.processedItems.slice().sort((a, b) => {
            const dateA = moment(a.ORDDA, "DD/MM/YYYY");
            const dateB = moment(b.ORDDA, "DD/MM/YYYY");
            return dateB - dateA;
        });
    },
    processedItems(){
      if(Object.keys(this.range).length === 0 || this.range.startDate == null) return this.items;

      return this.items.filter(item => {
        const itemDate = moment(item.ORDDA, 'DD-MM-YYYY');
        return itemDate.isSameOrAfter(this.range.startDate) && itemDate.isSameOrBefore(this.range.endDate)
      });
    }
  }
}
</script>

<style scoped>  
     
</style>