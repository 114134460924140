<template>
  <v-card
    min-height="300px"
    outlined
  >
    <div
      class="px-5 pb-5"
      rounded="0"
    >
      <div class="pt-4">
        <icon-title
          text="Instrucciones"
          title-class="text-h6 text-md-h6 ml-2"
        />
        <p class="text-left pt-2 mb-0">
          Estimad@ <span class="font-weight-black">{{ userName }}</span>
        </p>
        <p
          v-if="alerts.length"
          class="text-left"
        >
          {{ subtitle }}
        </p>
      </div>

      <v-row justify="space-between">
        <v-col cols="12">
          <div
            v-for="alert in alerts"
            :key="alert.id"
            class="d-flex align-center pb-2"
          >
            <v-icon :color="alert.checked ? 'green' : 'red darken-1'">
              {{ alert.checked ? 'mdi-check-circle' : 'mdi-close-circle' }}
            </v-icon>
            <p class="mb-0 ml-2">{{ alert.label }}</p>
          </div>
          <div
            v-if="!alerts.length"
            class="d-flex align-center pb-2"
          >
            <v-icon color="green"> mdi-check-circle </v-icon>
            <p class="mb-0 ml-2">
              El pedido cumple con los requisitos necesarios
            </p>
          </div>
          <ul
            v-if="
              alerts.find(
                (alert) =>
                  alert.id === 'VALID_CART_ITEMS_PSPO' ||
                  alert.id === 'VALID_CART_ITEMS_PSNW'
              )
            "
            class="pl-12"
          >
            <li
              v-for="invalidCartItem in invalidCartItems"
              :key="invalidCartItem.codigo_material"
            >
              {{ invalidCartItem.codigo_material }} -
              {{ invalidCartItem.nombre_material }}
            </li>
          </ul>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
  import IconTitle from '@/components/IconTitle.vue';
  import { mapGetters } from 'vuex';

  export default {
    name: 'ShoppingCartAlertsStep',
    components: { IconTitle },
    data: () => {
      return {
        titles: {
          PSDT: 'Nuevo Pedido',
          PSIU: 'Nuevo Pedido',
          PSNW: 'Nuevo Pedido de Productos Nuevos (PNC)',
        },
      };
    },
    computed: {
      ...mapGetters({
        user: 'auth/user',
        alerts: 'shoppingCartAlerts/cartAlerts',
        doctype: 'shoppingCartAlerts/doctype',
        isBackOrder: 'shoppingCartAlerts/isBackOrder',
        invalidCartItems: 'shoppingCartAlerts/invalidCartItems',
      }),
      isMobile() {
        return (
          this.$vuetify.breakpoint.name === 'xs' ||
          this.$vuetify.breakpoint.name === 'sm'
        );
      },
      subtitle() {
        if (this.isBackOrder) {
          return 'Tu pedido backorder tiene las siguientes observaciones:';
        } else {
          if (this.doctype === 'PSIU')
            return `Recuerda cumplir con los siguientes requisitos para procesar una compra:`;
          if (this.doctype === 'PSDT')
            return `Recuerda cumplir con los siguientes requisitos para procesar una compra:`;
          if (this.doctype === 'PSNW')
            return `Recuerda cumplir con los siguientes requisitos para procesar un pedido PNC:`;
          if (this.doctype === 'PSPO')
            return `Recuerda cumplir con los siguientes requisitos para procesar un pedido POP:`;
        }

        return '';
      },
      userName() {
        return this.user.name.trim();
      },
    },
  };
</script>

<style scoped></style>
