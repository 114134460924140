export default {
  namespaced: true,
  state: {    
    address: '',
    geolocation: {
        lat: '',
        lng: '',
        updatedAt: ''
    },
    activeTaxAdress: false,
    activeRecipient: false,
    showLoader: false,
    showEditMap: false,
    updateLocation: false,
    showBtnActions: false,
    lastGeolocation: null,
    customerCode: null,
    recipientsOptions: [],
    showSelectRecipients: false,
    selectedRecipient: null,
    showLoaderSelect: false
  },
  getters: {
    address: (state) => state.address,    
    geolocation: (state) => state.geolocation,    
    activeTaxAdress: (state) => state.activeTaxAdress,    
    activeRecipient: (state) => state.activeRecipient,    
    showLoader: (state) => state.showLoader,  
    showEditMap: (state) => state.showEditMap, 
    updateLocation: (state) => state.updateLocation,    
    showBtnActions: (state) => state.showBtnActions,      
    lastGeolocation: (state) => state.lastGeolocation,      
    customerCode: (state) => state.customerCode,      
    recipientsOptions: (state) => state.recipientsOptions,      
    showSelectRecipients: (state) => state.showSelectRecipients,      
    selectedRecipient: (state) => state.selectedRecipient,      
    showLoaderSelect: (state) => state.showLoaderSelect,      
  },
  mutations: {
    SET_ADDRESS(state, data) {
      state.address = data;
    },    
    SET_ACTIVE_TAX_ADDRESS(state, data) {
      state.activeTaxAdress = data;
    },
    SET_ACTIVE_RECIPIENT(state, data){
        state.activeRecipient = data;
    },
    SET_SHOW_LOADER(state, data){
        state.showLoader = data;
    },
    SET_SHOW_EDIT_MAP(state, data){
        state.showEditMap = data;
    },
    SET_UPDATE_LOCATION(state, data){
        state.updateLocation = data;
    },
    SET_GEOLOCATION(state, data){
        state.geolocation = data;
    },
    SET_SHOW_BTN_ACTIONS(state, data){
        state.showBtnActions = data;
    },
    SET_LAST_GEOLOCATION(state, data){
        state.lastGeolocation = data;
    },
    SET_CUSTOMER_CODE(state, data){
        state.customerCode = data;
    },
    SET_RECIPIENTS_OPTION(state, data){
        state.recipientsOptions = data;
    },    
    SET_SHOW_SELECT_RECIPIENTS(state, data){
        state.showSelectRecipients = data;
    },
    SET_SELECTED_RECIPIENT(state, data){
        state.selectedRecipient = data;
    },
    SET_SHOW_LOADER_SELECT(state, data){
        state.showLoaderSelect = data;
    }
  },
  actions: {
    actionActiveTaxAdress({ commit }){
        commit('SET_ACTIVE_TAX_ADDRESS', true);
        commit('SET_ACTIVE_RECIPIENT', false);
        commit('SET_SHOW_LOADER', true);        
    },
    actionActiveRecipient({ commit }){
        commit('SET_ACTIVE_RECIPIENT', true);
        commit('SET_ACTIVE_TAX_ADDRESS', false);
        commit('SET_SHOW_SELECT_RECIPIENTS', true);
        commit('SET_SHOW_LOADER_SELECT', true);
    },
    initMapLocation({ commit, state }){
        commit('SET_LAST_GEOLOCATION', state.geolocation);
        commit('SET_GEOLOCATION', { lat: '', lng: '', updatedAt: ''});
        commit('SET_SHOW_EDIT_MAP', false);
        commit('SET_SHOW_BTN_ACTIONS', true);
        commit('SET_SHOW_LOADER', true);
    },
    actionAcceptLocation({ commit }){
        commit('SET_SHOW_EDIT_MAP', true);
        commit('SET_SHOW_BTN_ACTIONS', false);
    },
    actionCancelLocation({ commit, state }){
        commit('SET_GEOLOCATION', state.lastGeolocation);
        commit('SET_SHOW_EDIT_MAP', true);
        commit('SET_SHOW_BTN_ACTIONS', false);
    },
    actionCloseModal({ commit }){
        commit('SET_SHOW_LOADER', false);
        commit('SET_SHOW_EDIT_MAP', false);
        commit('SET_UPDATE_LOCATION', false);
        commit('SET_SHOW_BTN_ACTIONS', false);
        commit('SET_LAST_GEOLOCATION', null);
        commit('SET_ACTIVE_TAX_ADDRESS', false);
        commit('SET_ACTIVE_RECIPIENT', false);
        commit('SET_RECIPIENTS_OPTION', []);
        commit('SET_SHOW_SELECT_RECIPIENTS', false);
        commit('SET_SELECTED_RECIPIENT', null);
    },
    actionSetRecipientSelected({ commit }, data){
        commit('SET_SELECTED_RECIPIENT', data);
        commit('SET_SHOW_LOADER', true);
        commit('SET_GEOLOCATION', { lat: '', lng: '', updatedAt: ''});
        commit('SET_SHOW_EDIT_MAP', false);
        commit('SET_UPDATE_LOCATION', false);
    }
  }
};