<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col>
          <div class="d-flex align-items-center mb-4">
            <v-card
              class="mx-auto rounded-lg elevation-4 ma-3 w-100"
              outlined
              :style="'border: 4px solid black; background-color:transparent;'"
            >
              <v-list-item-content>
                <div class="text-general-module mb-0">
                  <v-icon color="black">mdi mdi-sale</v-icon>
                  <span
                    >&nbsp; PRODUCTOS CON DESCUENTO DE LIQUIDACIÓN
                  </span
                  >
                </div>
              </v-list-item-content>
            </v-card>
          </div>
          <v-card
            cols="12"
            class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-7"
          >
          <v-row class="px-3 py-4">
            <v-col cols="12" sm="6" md="6" class="d-flex align-center">
              <v-btn
                class="button-green-round text-white font-weight-bold mr-3 mb-2"
                :loading="loadingExcel"
                :disabled="loadingExcel"
                @click="excelDownload()"
                :class="{
                  'v-btn--small': $vuetify.breakpoint.xs,
                }"
              >
                <span class="d-none d-sm-inline">Descargar Excel</span>
                <span class="d-sm-none">Excel</span>
                <v-icon>mdi-file-excel-outline</v-icon>
              </v-btn>

              <v-btn
                class="button-red-round text-white font-weight-bold mb-2"
                :loading="loadingEmail"
                :disabled="loadingEmail"
                @click="openDialogEmail()"
                :class="{
                  'v-btn--small': $vuetify.breakpoint.xs,
                }"
              >
                <span class="d-none d-sm-inline">Enviar por correo</span>
                <span class="d-sm-none">Correo</span>
                <v-icon>mdi-email-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              class="d-flex justify-end align-center"
            >
              <search-input
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                class="search-box"
                style="min-width: 200px; max-width: 100%"
              />
            </v-col>
          </v-row>
          <div class="px-3 pt-3">
            <v-data-table
              ref="dataTable"
              dense
              :headers="headers"
              :items-per-page="10"
              :items="filteredData"
              :loading="loading"
              item-key="order"
              :mobile-breakpoint="0"
              :hide-default-footer="!paginated"
              :disable-pagination="!paginated"
              :footer-props="{
                itemsPerPageText: 'Mostrar en grupo de',
                itemsPerPageAllText: 'Todos',
                pageText: '{0}-{1} de {2}',
              }"
              no-data-text="No hay datos disponibles"
              class="custom-table card-shadow rounded-0"
              loading-text="Cargando..."
              @update:options="onOptionsChange"
            >
              <template v-slot:[`item.material_code`]="{ item }">
                <div class="d-flex align-center" style="min-width: 120px">
                  <div style="min-width: 70px; min-height: 70px" class="mr-2">
                    <v-progress-circular
                      v-if="!item.material_image"
                      indeterminate
                      color="primary"
                    />
                    <v-img
                      v-else
                      :src="item.material_image"
                      height="70px"
                      width="70px"
                      class="cursor-pointer"
                      contain
                      @click="openProductImageGallery(item, i)"
                    />
                  </div>

                  <div class="d-flex flex-column ml-2">
                    <p
                      class="text-left font-size-8 mb-1"
                      style="max-width: 150px; word-wrap: break-word"
                    >
                      {{ item.material_code }} <br />
                      {{ item.description }}
                    </p>
                    <v-btn
                      icon
                      small
                      class="mb-1"
                      @click="openTechnicalSheets(item)"
                    >
                      <v-icon color="red red-darken-4">
                        mdi-folder-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  class="green-button text-white"
                  @click="openDialog(item)"
                >
                  <v-icon>mdi-cart-plus</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.units`]="{ item }">
                <v-text-field v-model="item.units"/>
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                ${{ formatAmount(item.amount) }}
              </template>
              <template v-slot:[`item.discount`]="{ item }">
                <div>{{ seePorcent(item.discount) }} %</div>
              </template>
            </v-data-table>
          </div>
          </v-card>
        </v-col>
      </v-row>
      <!-- /. Dialog -->
      <v-dialog v-model="dialog" width="700" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Agregar Material al Carrito
          </v-card-title>
          <v-card-text>
            <div class="col-md-12" v-if="hasError">
              <v-alert text dense outlined type="error">
                {{ warnerror }}
              </v-alert>
            </div>
            <v-row class="px-3 pt-3">
              <v-col md="3">
                <label>Imagen:</label>
                <v-img
                  :src="itemSelect.material_image"
                  v-if="itemSelect.material_image != ''"
                  width="100"
                  class="imageCarItem"
                />
              </v-col>
              <v-col md="3">
                <label>Material:</label>
                <v-text-field solo readonly v-model="m_code"/>
              </v-col>
              <v-col md="3">
                <div class="form-group">
                  <label>Empaque:</label>
                  <v-text-field solo readonly v-model="pack"/>
                </div>
              </v-col>
              <v-col md="3">
                <div class="form-group">
                  <label>Cant. Confirmada:</label>
                  <v-text-field solo readonly v-model="confirm_quantity"/>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error" text @click="closeItemDialog()"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="hasError"
              color="success"
              text
              @click="sendtoCart()"
            >
              Enviar a Carrito
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- /. Dialog -->
      <v-dialog v-model="dialog_email" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Enviar por Correo
          </v-card-title>
          <v-card-text>
            <h6 style="text-align: left; font-weight: 700" class="px-3 pt-3">
              Materiales Descontinuados
            </h6>
            <v-row>
              <v-col md="12">
                <div class="form-group">
                  <label>Ingresar Email:</label>
                  <v-text-field solo v-model="email_user"/>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error" text @click="closeEmailDialog()"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="sendEmail()"> Enviar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- PRODUCT IMAGE GALLERY -->
      <CoolLightBox
        v-if="productGalleryImages"
        :items="productGalleryImages"
        :index="productGalleryImageIndex"
        @close="closeProductImageGallery()"
      />

      <!-- PRODUCT PDF FILES MODAL -->
      <product-technical-sheets
        v-model="isProductTechnicalShetsDialogOpen"
        :product="product"
      />
    </template>
  </v-container>
</template>
<script>
import axios from "@/plugins/axios";
import { mapGetters, mapMutations, mapActions } from "vuex";
import moment from "moment";
import { getProductMainImage } from "@/repositories/ProductFiles";
import SearchInput from "@/components/SearchInput.vue";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import ProductTechnicalSheets from "../shopping_cart/componets/ProductTechnicalSheets.vue";

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
      cartIte: "ordercart/cart",
    }),

    filteredData() {
      if (!this.search || this.search.trim() === "") {
        return this.data;
      }

      const searchTerm = this.search.toLowerCase().trim();

      return this.data.filter((item) => {
        // Buscar en código de material
        if (
          item.material_code &&
          item.material_code.toString().toLowerCase().includes(searchTerm)
        ) {
          return true;
        }

        // Buscar en descripción
        if (
          item.description &&
          item.description.toString().toLowerCase().includes(searchTerm)
        ) {
          return true;
        }

        // Buscar en centro
        if (
          item.center &&
          item.center.toString().toLowerCase().includes(searchTerm)
        ) {
          return true;
        }

        return false;
      });
    },
    displayedItems() {
      let filteredItems = this.data;
      if (this.search && this.search.trim() !== "") {
        const searchTerm = this.search.toLowerCase();
        filteredItems = filteredItems.filter((item) => {
          return (
            (item.material_code &&
              item.material_code
                .toString()
                .toLowerCase()
                .includes(searchTerm)) ||
            (item.description &&
              item.description.toString().toLowerCase().includes(searchTerm)) ||
            (item.center &&
              item.center.toString().toLowerCase().includes(searchTerm))
          );
        });
      }

      if (this.options.sortBy.length > 0) {
        const sortBy = this.options.sortBy[0];
        const sortDesc = this.options.sortDesc[0];

        filteredItems.sort((a, b) => {
          let valueA = a[sortBy];
          let valueB = b[sortBy];

          if (valueA === null || valueA === undefined) valueA = "";
          if (valueB === null || valueB === undefined) valueB = "";
          if (typeof valueA === "string") valueA = valueA.toLowerCase();
          if (typeof valueB === "string") valueB = valueB.toLowerCase();

          if (valueA < valueB) return sortDesc ? 1 : -1;
          if (valueA > valueB) return sortDesc ? -1 : 1;
          return 0;
        });
      }

      return filteredItems;
    },
  },
  components: {
    SearchInput,
    CoolLightBox,
    ProductTechnicalSheets,
  },
  data: () => ({
    customer_code: null,
    id_order: null,
    data: [],
    loading: false,
    search: "",
    dialog: false,
    m_code: null,
    pack: null,
    quantity: null,
    confirm_quantity: null,
    units: null,
    warnerror: null,
    hasError: false,
    loadingExcel: false,
    loadingEmail: false,
    dialog_email: false,
    email_user: null,
    cartItems: null,
    itemSelect: {},
    itemsMaterial: [],
    totalMaterial: 0,
    headers: [
      { text: "Producto", align: "start", value: "material_code" },
      { text: "Centro", value: "center" },
      { text: "Existencia", sortable: false, value: "stock" },
      { text: "Importe", sortable: false, value: "amount" },
      { text: "Descuento ZK14", sortable: false, value: "discount" },
      { text: "U. Solicitadas", sortable: false, value: "units" },
      {
        text: "Agregar a Carrito",
        align: "center",
        sortable: false,
        value: "action",
      },
    ],
    today2: moment().format("YYYY/MM/DD"),
    itemCSV: [],
    type: null,
    paginated: true,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [""],
      sortDesc: [false],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
    },
    productGalleryImages: [],
    productGalleryImageIndex: null,
    isProductTechnicalShetsDialogOpen: false,
    product: null,
  }),
  watch: {
    data: {
      immediate: true,
      handler() {
        this.generarExcel();
      },
    },
    search: {
      handler() {
        this.$nextTick(() => {
          this.loadVisibleImages();
        });
      },
    },
  },
  mounted() {
    if (this.$hasAnyRole(["Customer", "CustomerPR"])) {
      this.customer_code = this.user.custumer_code;
      this.type = "CL";
    } else {
      const currentCustomerInfo =
        JSON.parse(localStorage.getItem("currentCustomerInfo")) || [];
      if (currentCustomerInfo.length < 1) {
        this.$router.push({ name: "Home" });
      }
      this.customerInfo_1 = currentCustomerInfo;
      this.customer_code = parseInt(currentCustomerInfo?.KUNNR, 10);
      this.type = "VE";
    }

    let self = this;
    self.getDiscontinuedMat();
    this.email_user = this.user.email;
    this.cartItems = this.cartIte;
    console.log(this.data);
    this.countMaxValues(this.data).then(() => {
      this.loadingTime = (Date.now() - this.timerStart) / 1000;
    });
    this.generarPDF();
    this.loadVisibleImages();
  },
  methods: {
    ...mapActions({
      sendToPDF: "printer/sendToPDF",
      sendToExcel: "printer/sendToExcel",
      getProductImages: "ordercart/getProductImages",
    }),
    ...mapMutations({
      setCard: "ordercart/SET_CART",
    }),
    generarPDF() {
      this.sendToPDF({ active: true });
    },
    generarExcel() {
      let newItems = [];
      this.itemCSV = [];

      for (let key in this.data) {
        let item = this.data[key];
        newItems.push({
          Código: item.material_code,
          Descripción: item.description,
          Centro: item.center,
          Existencia: item.stock,
          Importe: item.amount,
          "Descuento ZK14.": item.discount,
        });
      }
      this.itemCSV = newItems;

      let objectCSV = {
        name: `Mat.Desc.-${this.today2}`,
        items: this.itemCSV,
        showLabels: true,
      };

      this.sendToExcel(objectCSV);
    },
    getDiscontinuedMat() {
      this.loading = true;
      axios
        .post("get-discontinued-mat", {
          VTWEG: this.user.VTWEG,
        })
        .then((response) => {
          this.data = response.data.map((item) => ({
            ...item,
            material_image: "",
          }));
          this.loadVisibleImages();
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    seePorcent(value) {
      return parseFloat(value * 100).toFixed(2);
    },
    openDialog(item) {
      console.log(item);
      this.invoice = item.pay_complement;
      this.units = item.units;
      this.itemSelect = item;

      //this.id_order = itto em.id;

      this.materialInfoDT(this.units, item);
      this.getImge(item);
      this.dialog = true;
    },
    materialInfoDT(uni, item) {
      axios
        .post("getMaterialInfo", {
          code: item.material_code,
          units: uni,
          customer: this.customer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
        })
        .then((response) => {
          //itemsMaterial
          this.material_info = response.data;
          this.m_code = this.material_info.codigo_material;
          this.pack = this.material_info.empaque;
          this.quantity = this.material_info.u_pedidas;
          this.confirm_quantity = this.material_info.u_confirm;
        })
        .catch((error) => {
          console.log("Error:" + error.response.data[0]);
          this.hasError = true;
          this.warnerror = error.response.data.error_code;
        })
        .finally();
    },
    closeItemDialog() {
      this.hasError = false;
      (this.m_code = null),
        (this.pack = null),
        (this.quantity = null),
        (this.confirm_quantity = null),
        (this.dialog = false);
    },
    sendtoCart() {
      //let type = "CL";
      //let type = "VE"; // ENCASO DE PORTAL VENDEDOR ENVIAR ESTA VARIABLE
      axios
        .post("getOrderID", {
          customer: this.customer_code,
          user_id: this.user.id,
          type: this.type,
          doctype: "PSDT",
        })
        .then((response) => {
          this.order_id = response.data;

          console.log("getOrderID");
          console.log(this.order_id);
          this.getCartMaterials(this.order_id, this.m_code);
        })
        .catch((error) => {
          ///alert(error);
          console.log(error);
        })
        .finally();
    },

    getCartMaterials(order_id, m_code) {
      //////////////
      axios
        .post("get-cart-materials", {
          order_id: order_id,
          material_code: m_code,
        })
        .then((response) => {
          console.log("get-cart-materials");
          console.log(response.data);
          this.saveNewMaterialOrder(order_id, this.material_info);
        })
        .catch((error) => {
          ///alert(error);
          if (error.response.status == 404) {
            //alert(error.response.data.error_code);
            this.$swal({
              title: error.response.data.error_code,
              icon: "error",
              type: "error",
            }).then((result) => {
              console.log(result);
              // location.reload();
              this.hasError = false;
              (this.m_code = null),
                (this.pack = null),
                (this.quantity = null),
                (this.confirm_quantity = null),
                (this.dialog = false);
            });
          } else {
            //alert(error);
            console.log(error);
          }
        })
        .finally();
      /////////////
    },
    saveNewMaterialOrder(order_id, material_info) {
      axios
        .post("saveNewMaterialOrder", {
          order_id: order_id,
          item_cart: material_info,
        })
        .then((response) => {
          console.log(response);

          this.$swal({
            title: "Se agrego correctamente",
            icon: "success",
            type: "success",
          }).then((result) => {
            console.log(result);
            // location.reload();
            this.hasError = false;
            (this.m_code = null),
              (this.pack = null),
              (this.quantity = null),
              (this.confirm_quantity = null),
              (this.dialog = false);
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.dialogLoading = false));
    },
    excelDownload() {
      this.loadingExcel = true;

      axios
        .post(
          "export-material-desc-exc",
          {
            VTWEG: this.user.VTWEG,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          console.log(response.data);
          const current = new Date();
          const date = `${current.getDate()}/${
            current.getMonth() + 1
          }/${current.getFullYear()}`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "materiales_descontinuados" + "_" + date + ".xlsx"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.loadingExcel = false;
        })
        .catch((error) => {
          ///alert(error);
          console.log(error);
          this.loadingExcel = false;
        })
        .finally();
    },
    openDialogEmail() {
      this.dialog_email = true;
    },
    closeEmailDialog() {
      this.dialog_email = false;
    },
    sendEmail() {
      this.loading = true;
      axios
        .post("export-material-desc-exc-email", {
          VTWEG: this.user.VTWEG,
          email: this.email_user,
        })
        .then((response) => {
          console.log(response.data);
          this.$swal({
            title: response.data.message,
            icon: "success",
            type: "succes",
          }).then((result) => {
            //this.getRecordatoriosHistory();
            console.log(result);
            this.dialog_email = false;
            this.loading = false;
          });
        })
        .catch((error) => {
          ///alert(error);
          console.log(error);
          this.loading = false;
        })
        .finally();
    },

    async getImge(item) {
      const { image } = await getProductMainImage(item.material_code);

      if (image.path) {
        let index = this.data.findIndex(
          (element) => element.material_code == item.material_code
        );
        this.data[index].material_image = image.path;
      }
    },
    loadVisibleImages() {
      let displayedItems = [...this.data];

      if (this.search && this.search.trim() !== "") {
        const searchTerm = this.search.toLowerCase();
        displayedItems = displayedItems.filter((item) => {
          return (
            (item.material_code &&
              item.material_code
                .toString()
                .toLowerCase()
                .includes(searchTerm)) ||
            (item.description &&
              item.description.toString().toLowerCase().includes(searchTerm)) ||
            (item.center &&
              item.center.toString().toLowerCase().includes(searchTerm))
          );
        });
      }

      if (this.options.sortBy && this.options.sortBy.length > 0) {
        const sortBy = this.options.sortBy[0];
        const sortDesc = this.options.sortDesc[0];

        displayedItems.sort((a, b) => {
          let valueA = a[sortBy];
          let valueB = b[sortBy];

          if (valueA === null || valueA === undefined) valueA = "";
          if (valueB === null || valueB === undefined) valueB = "";
          if (typeof valueA === "string") valueA = valueA.toLowerCase();
          if (typeof valueB === "string") valueB = valueB.toLowerCase();
          if (valueA < valueB) return sortDesc ? 1 : -1;
          if (valueA > valueB) return sortDesc ? -1 : 1;
          return 0;
        });
      }

      if (displayedItems.length === 0) {
        return;
      }

      const startIndex = (this.options.page - 1) * this.options.itemsPerPage;
      const endIndex = startIndex + this.options.itemsPerPage;

      for (
        let i = startIndex;
        i < Math.min(endIndex, displayedItems.length);
        i++
      ) {
        const item = displayedItems[i];

        if (!item.material_image || item.material_image === "") {
          getProductMainImage(item.material_code)
            .then(({ image }) => {
              if (image && image.path) {
                console.log(
                  `Imagen encontrada para ${item.material_code}: ${image.path}`
                );

                const dataIndex = this.data.findIndex(
                  (d) => d.material_code === item.material_code
                );
                if (dataIndex !== -1) {
                  this.$set(this.data, dataIndex, {
                    ...this.data[dataIndex],
                    material_image: image.path,
                  });
                }
              } else {
                console.log(`No se encontró imagen para ${item.material_code}`);
              }
            })
            .catch((error) => {
              console.error(
                `Error cargando imagen para ${item.material_code}:`,
                error
              );
            });
        }
      }
    },

    async countMaxValues(array) {
      this.timerStart = Date.now();
      for (const item of array) {
        await this.countMaxValue(item).catch(() => {
          //Even when one request throws error we should not stop others
        });
      }
    },
    async countMaxValue(item) {
      await new Promise((resolve) => setTimeout(resolve, 50)).then(() => {
        let maxVal = Math.random() * 100;
        item.maxValue = maxVal < 20 ? null : maxVal;
        this.data.splice(item.id, 1, item);
      });
    },
    callMaterialFromPage(page, size, data) {
      let from = page * size;
      let to = from + size;
      console.log(data.slice(from, to));
      //let item = data.slice(from,to);
      for (const item of data.slice(from, to)) {
        console.log("item" + item.material_code);
        this.getImge(item);
      }
    },
    onOptionsChange(newOptions) {
      this.options = newOptions;
      this.$nextTick(() => {
        this.loadVisibleImages();
      });
    },
    async openProductImageGallery(item) {
      if (!item.images) {
        this.productGalleryImages = [
          {
            title: item.description || "Producto",
            description: item.material_code || "",
            src: item.material_image || "",
          },
        ];

        this.productGalleryImageIndex = 0;

        // Si tienes un método para obtener más imágenes
        try {
          const { images } = await this.getProductImages(item.codigo_material);
          if (images && images.length > 0) {
            this.productGalleryImages = images.map((img) => ({
              title: item.description || "Producto",
              description: item.codigo_material || "",
              src: img.path,
            }));
          }
        } catch (error) {
          console.error("Error loading product images:", error);
        }
      } else {
        this.productGalleryImages = item.images;
        this.productGalleryImageIndex = 0;
      }
    },

    closeProductImageGallery() {
      this.productGalleryImageIndex = null;
      this.productGalleryImages = [];
    },
    openTechnicalSheets(item) {
      this.product = {
        codigo_material: item.material_code,
        nombre_material: item.description,
      };
      this.isProductTechnicalShetsDialogOpen = true;
    },
    formatAmount(value) {
      if (!value) return "$0.00";
      return parseFloat(value).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>

<style scoped>
.v-data-table >>> .v-data-table-header {
  background-color: red;
}

.v-data-table >>> .v-data-table-header > tr > th {
  color: white !important;
}

.v-data-table >>> .v-data-table-header > tr > th {
  border-bottom: 2px solid rgb(112, 112, 112) !important;
}

.search-box {
  width: 100%;
  min-width: 200px;
  max-width: 300px;
}

@media (max-width: 600px) {
  .search-box {
    max-width: 100%;
  }
}
</style>