import axios from "@/plugins/axios";
export default {
  namespaced: true,
  state: {
    customerData: {},
    customer: JSON.parse(sessionStorage.getItem("creditCustomer")) || {},
  },

  getters: {
    customerData: (state) => state.customerData,
    customer: (state) => state.customer,
  },

  mutations: {
    SET_DATA_CUSTOMER(state, data) {
      state.customerData = data;
    },
    SET_CUSTOMER(state, customer) {
      state.customer = customer;
      sessionStorage.setItem("creditCustomer", JSON.stringify(customer));
    },
  },

  actions: {
    async getDataCustomer({ commit, state, rootState }) {
      commit("SET_DATA_CUSTOMER", {});
      const customer = state.customer;
      if (Object.keys(customer).length === 0) return false;

      try {
        let response = await axios.post("/grupo-cliente/data-client", {
          client: customer.KUNNR,
          VKORG: rootState.auth.user.VKORG,
          VTWEG: rootState.auth.user.VTWEG,
          VKBUR: rootState.auth.user.VKBUR,
          P_OPCGPO: 0,
        });

        commit("SET_DATA_CUSTOMER", response.data.dataDetailClient);
        return true;
      } catch (err) {
        return false;
      }
    },
    async setAuthorization({ rootState }, { customer, order, isAuthorized, motive }){
      try {
        let response = await axios.post("/credit/authorize", {
          employee_number: rootState.auth.user.employee_number,
          customer,
          order,
          isAuthorized,
          motive
        });
        
        return response.data.data;
      } catch (err) {
        return err;
      }
    }
  },  
};
