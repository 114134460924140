import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      permissions: "auth/permissions",
      roles: "auth/roles",
      channel: "auth/channel",
      channelLabel: "auth/channelLabel",
    }),
  },
  methods: {
    $can(permissionName) {
      return this.permissions.indexOf(permissionName) !== -1;
    },
    $hasRole(roleName) {
      return this.roles.indexOf(roleName) !== -1;
    },
    $hasAnyPermission(permissions) {
        return this.permissions.some(permission => permissions.includes(permission.name));
    },
    $hasAnyRole(roles) {
        return this.roles.some(role => roles.includes(role.name));
    },
    $hasAnyChannel(channels) {
      return channels ? channels.includes(this.channel) : false ;
    },
    $getLocalStorageDirector(){
      return JSON.parse(localStorage.getItem('currentDirectorCode'));
    },
    $getLocalStorageRegional(){
      return JSON.parse(localStorage.getItem('currentRegionalCode'));
    },
    $getLocalStorageManager(){
      return JSON.parse(localStorage.getItem('currentManagerCode')) ?? null;
    },
    $getLocalStorageSeller(){
      return JSON.parse(localStorage.getItem('currentSellerCode'));
    },
    $getLocalStorageCustomer(){
      return JSON.parse(localStorage.getItem('currentCustomerCode') ?? null);
    },
    $setLocalStorageDirector(employeeNumber){
      localStorage.setItem('currentDirectorCode', JSON.stringify(employeeNumber));
    },
    $setLocalStorageRegional(employeeNumber){
      localStorage.setItem('currentRegionalCode', JSON.stringify(employeeNumber));
    },
    $setLocalStorageManager(employeeNumber){
      localStorage.setItem('currentManagerCode', JSON.stringify(employeeNumber));
    },
    $setLocalStorageSeller(employeeNumber){
      localStorage.setItem('currentSellerCode', JSON.stringify(employeeNumber));
    },
    $setLocalStorageCustomer(employeeNumber){
      localStorage.setItem('currentCustomerCode', JSON.stringify(employeeNumber));
    },
    $removeLocalStorageCustomer(){
      localStorage.removeItem("currentCustomerCode");
    },
    $resetLocalStorage(){
      localStorage.removeItem("currentDirectorCode");
      localStorage.removeItem("currentRegionalCode");
      localStorage.removeItem("currentManagerCode");
      localStorage.removeItem("currentSellerCode");
      localStorage.removeItem("currentCustomerCode");
      // Iterar sobre todas las claves del localStorage para eliminar lo almacenado en renglonaje
      for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        // Verificar si la clave comienza con el prefijo 'cachedArray-'
        if (key.startsWith('cachedArray-')) {
            // Eliminar la entrada del localStorage
            localStorage.removeItem(key);
        }
      }
    }    
  },
};
