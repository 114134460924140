<template>
  <div
    class="w-full d-flex justify-space-between align-center rounded shadow-in m-1 p-1"
  >
    <div
      style="width: 70px"
      class="mr-1"
    >
      <v-progress-circular
        v-if="!src"
        indeterminate
        color="primary"
      />
      <v-img
        v-else
        :src="src"
        max-width="100"
        max-height="100"
      />
    </div>

    <div class="flex-grow-1 mx-2">
      <p class="text-left">{{ item.name }}</p>
      <div class="text-left">
        <span class="">SKU {{ item.sku }}</span>
      </div>
    </div>
    <div
      class="d-flex justify-center align-center"
      style="height: 20px"
    >
      <v-btn
        small
        class="button-green text-white font-weight-black py-5"
        rounded-md
        :value="item.materialCode"
        :checked="checked"
        @click="toggleCheckbox(src)"
      >
        <span>Agregar</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    name: 'ResultItem',
    props: ['item'],
    data: () => ({
      src: null,
      checked: false,
    }),
    mounted() {
      this.loadImage();
    },
    methods: {
      ...mapActions({
        getImageUrl: 'quotecart/getImageUrl',
      }),
      loadImage() {
        if (!this.item || this.item.materialCode == null) {
          return;
        }
        this.getImageUrl({ codigo_material: this.item.materialCode })
          .then((imagePath) => {
            this.src = imagePath;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      toggleCheckbox(urlimage = '') {
        this.checked = !this.checked;
        this.$emit('checkboxtoggle', {
          checked: this.checked,
          item: this.item,
          url: urlimage,
        });
      },
    },
  };
</script>

<style scoped>
  .shadow-in {
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  }
  .button-green {
    background: transparent
      linear-gradient(180deg, var(--success-hover-218838) 0%, #11441c 100%) 0%
      0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #218838 0%, #11441c 100%) 0%
      0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
    border: 1px solid #707070;
  }
</style>
