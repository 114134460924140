<template>
    <tr>
        <td :colspan="colspan" :rowspan="rowspan">
            <v-skeleton-loader type="table-tbody" />
        </td>
    </tr>
</template>
<script>
export default {
    name: "TableBodyLoader",
    props: {
        colspan: {
            type: Number,
            default: 15
        },
        rowspan: {
            type: Number,
            default: 0,
        },
    },
};
</script>
