<template>
  <div>
    <search-product-result-modal
      v-model="isSearchResultModalOpen"
      :products="searchResults"
      :loading="isLoadingSearchResults"
      @select="onProductSelect"
    />
    <shopping-card title="Búsqueda de Material">
      <v-form
        ref="form"
        @submit.prevent="onSubmit"
      >
        <v-row no-gutters>
          <v-col cols="12">
            <v-row>
              <v-col cols="7">
                <p class="mb-0 text-md-right">
                  Código de Material - SKU / Descripción
                </p>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="productSKU"
                  type="text"
                  :disabled="showProductName"
                  :rules="[(v) => !!v || 'Requerido']"
                  autofocus
                  dense
                  required
                  solo
                >
                  <template v-slot:append>
                    <v-btn
                      v-if="productSKU"
                      color="gray"
                      icon
                      @click="clearSKU"
                    >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="7">
                <p class="mb-0 text-md-right">Unidades</p>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="productQuantity"
                  type="number"
                  hide-details
                  dense
                  required
                  solo
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            v-if="showProductName"
            class="mt-7"
          >
            <div class="d-flex">
              <img
                :src="productImageURL"
                :alt="`Imagen de ${productName}`"
                style="width: 60px; height: 60px; object-fit: contain"
                class="mr-2"
              />
              <v-text-field
                :value="productName"
                label="Nombre"
                solo
                readonly
                hide-details
              />
            </div>
          </v-col>
          <v-col
            cols="12"
            md="8"
            style="margin-left: auto; margin-right: auto"
            class="mt-8"
          >
            <v-btn
              type="submit"
              :class="{'btn-pr': isPRChannel, 'btn-dt': isDTChannel}"
              :loading="isLoadingSearchResults"
              :disabled="disabled"
              block
              >Agregar</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </shopping-card>
  </div>
</template>

<script>
  import SearchProductResultModal from './SearchProductResultModal.vue';
  import ShoppingCard from './ShoppingCard.vue';

  import { mapGetters } from 'vuex';
  import axios from '@/plugins/axios';

  export default {
    name: 'ProductSearchCard',
    components: {
      ShoppingCard,
      SearchProductResultModal,
    },
    props: {
      disabled: { type: Boolean },
    },
    data: () => {
      return {
        productSKU: undefined,
        productName: undefined,
        productQuantity: undefined,
        productImageURL: undefined,
        showProductName: false,
        isSearchResultModalOpen: false,
        isLoadingSearchResults: false,
        searchResults: undefined,
      };
    },
    computed: {
      ...mapGetters('auth', ['channel']),
      isPRChannel(){return this.channel === 'PR'},
      isDTChannel(){return this.channel === 'DT'}
    },
    methods: {
      clearSKU() {
        this.productSKU = undefined;
        this.showProductName = false;
      },
      clearForm() {
        this.productSKU = undefined;
        this.productName = undefined;
        this.productQuantity = undefined;
        this.showProductName = false;

        this.$refs.form.resetValidation();
      },
      onProductSelect({ sku, name, imageURL }) {
        this.productSKU = sku;
        this.productName = name;
        this.productImageURL = imageURL;
        this.showProductName = true;
      },
      async onSubmit() {
        const valid = this.$refs.form.validate();
        if (!valid) return;

        // OPEN SEARCH RESULT MODAL IF SKU ARE NOT JUST NUMBERS
        const REGEX_NUMBERS_ONLY = '^[0-9]+$';
        if (this.productSKU.match(REGEX_NUMBERS_ONLY) == null) {
          this.isLoadingSearchResults = true;

          try {
            const response = await axios.post('/materialComplete', {
              name: this.productSKU,
            });

            this.searchResults = response.data;
            this.isSearchResultModalOpen = true;
          } catch (error) {
            this.$toast.error(
              'No se pudieron obtener los resultados de búsqueda'
            );
          } finally {
            this.isLoadingSearchResults = false;
          }

          return;
        }

        // UPDATE QUANTITY TO BE 1 IF 0
        if (!this.productQuantity || this.productQuantity <= 0) {
          this.productQuantity = 1;
        }

        this.$emit('submit', {
          sku: this.productSKU,
          quantity: this.productQuantity,
        });
      },
    },
  };
</script>

<style scoped>
  .btn-pr {
    color: white !important;
    background-color: #005eb0 !important;
  }
  .btn-dt {
    color: white !important;
    background-color: #c82333 !important;
  }
</style>
