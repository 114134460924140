<template>
    <div class="bg-white">
      <v-container fluid class="page__container--padding">
        <div class="container-sm mt-5"> 

          <!-- Button create -->
          <div class="d-flex justify-content-left mb-3">
            <v-btn @click="openModal = true" class="red-button">Crear Promoción</v-btn>   
          </div>    
          <!-- End Button create -->

          <!-- Table -->
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footerProps"
            :options="dataTableOptions"
            :mobile-breakpoint="null"
            :loading="isLoading"
            class="elevation-2 table__desing-general table__alternating-rows--grey"
          >
            <template v-slot:[`item.view`]="{ item }">
              <a :href="`${getBaseServer()}/storage/promotions/${item.promotion}`" target="_blank">
                <v-icon color="#124876">mdi-eye</v-icon>  
              </a> 
            </template>

            <template v-slot:[`item.trash`]="{ item }">
              <v-btn text @click="deletePromotion(item.id)">
                <v-icon color="red">mdi-trash-can</v-icon>  
              </v-btn> 
            </template>            
            
            <template v-slot:[`item.state`]="{ item }">
              <div class="d-flex align-items-center justify-content-center">
                <!-- checkbox -->
                <v-switch inset color="green" :input-value="!!item.state" @change="updatePromotions(item, !item.state, null)"/>
              </div>
            </template>
            
            <template v-slot:[`item.channel`]="{ item }">
              <div class="d-flex align-items-center justify-content-center">
                {{item.channel}}

                <!-- menu -->
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="black" icon v-bind="attrs" v-on="on" class="ml-2">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn @click="updatePromotions(item, null, validateChannel(item))">
                          <v-icon>mdi-cursor-move</v-icon>
                          Mover a {{ validateChannel(item) }}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!-- end menu -->
              </div>
            </template>
          </v-data-table>
          <!-- End Table -->
        </div>
        
        <confidentiality-notice class="my-3" />  

        <!-- Modal Create -->
        <create-promotion 
          v-model="openModal" 
          @success="getPromotions">
        </create-promotion> 
        <!-- End Modal Create -->
      </v-container>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import axios from "@/plugins/axios";
  import ConfidentialityNotice from '@/components/ConfidentialityNotice';
  import CreatePromotion from "./components/CreatePromotionModal.vue";
  const baseServer = process.env.VUE_APP_FILES_URL;

  export default {
    name: 'AdminPromotions',
    components: {ConfidentialityNotice, CreatePromotion},
    data() {
      return {
        headers: [
          { text: "Canal", value: "channel", align: "center" },
          { text: "Promoción", value: "promotion", align: "center" },
          { text: "Fecha", value: "created_at", align: "center" },
          { text: "Estado", value: "state", align: "center" },
          { text: "Visualizar", value: "view", align: "center" },
          { text: "Eliminar", value: "trash", align: "center" },
        ],
        items: [],      
        isLoading: true,
        footerProps: {
          itemsPerPageOptions: [10, 14, 24, 34, { text: "Todas", value: -1 }],
          itemsPerPageText: "Mostrar en grupo de:",
          showCurrentPage: false,
          pageText: "Desplegado {0} / {1}",
        },
        dataTableOptions: {
          itemsPerPage: 14,
          pagination: {
            enabled: true,
            options: {
              numeric: true,
            },
          },
        },
        openModal: false        
      };
    },
    mounted() {    
      this.getPromotions();
    },
    methods: {
      async getPromotions() {
        try{
          let response = await axios.get('/promotions');
          this.items = response.data.data;
          this.isLoading = false;
        }catch(error){
          this.$toast.error("Ocurrió un error, no se pudieron obtener las promociones.");
        }      
      },
      async deletePromotion(id) {
        try{
          await axios.delete(`/promotions/${id}`);          
          this.items = this.items.filter(item => item.id !== id);
          this.$toast.success("Se eliminó el registro correctamente.");
        }catch(error){
          this.$toast.error("Ocurrió un error, por favor vuelva a intentarlo.");
        } 
      },
      async updatePromotions(item, state = null, channel = null){         
        try{
          await axios.put(`/promotions/${item.id}`, {
            channel: channel ?? item.channel,
            state: state ?? item.state
          });
          this.getPromotions();  
          this.$toast.success("Se actualizó el registro correctamente.");
        }catch(error){
          this.$toast.error("Ocurrió un error, por favor vuelva a intentarlo.");
        } 
      },
      validateChannel(item){
        return item.channel === 'DT' ? 'PR' : 'DT';
      },
      getBaseServer() { 
        return baseServer; 
      }
    },
    computed: {
      ...mapGetters({
        user: "auth/user",
      })
    },
  };
  </script>

  <style scoped>
    .v-list-item{
      min-height: auto;
    }

    .v-list-item a{
      text-decoration: none;
      color: #454545;
      font-size: 14px;
    }
  </style>