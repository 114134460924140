import axios from '@/plugins/axios';

export const saveToCartOrderReminderPR = async (customerCode, customerId, orderConcept, cart, typeBack, type = 'CL') => {

  const { data } = await axios.post('/save-order-info-reminder-pr', {
    customer_id: customerCode,
    orden_compra: orderConcept,
    destiny: customerCode,
    cart: cart,
    user_id: customerId,
    type,
    type_back: typeBack,
  });

  return data;
}

export const saveToCartOrderReminderDT = async (customerCode, customerId, orderConcept, cart, typeBack, type = 'CL') => {

  const { data } = await axios.post('/saveOrderInfoReminder', {
    customer_id: customerCode,
    orden_compra: orderConcept,
    destiny: customerCode,
    cart: cart,
    user_id: customerId,
    type,
    type_back: typeBack,
  });

  return data;
}

export const generatePurchaseOrderId = async (orderType = 'normal', channel = '') => {
  const { data } = await axios.post("/generate-purchase-order-id", { orderType, channel });
  return data.purchaseOrder;
}