<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <a
        v-bind="attrs"
        v-on="on"
        v-if="invoice !== ''"
        @click.prevent="download"
      >
        <v-icon :color="color" size="22">mdi-file-download</v-icon>
      </a>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>
  
<script>
export default {
  props: {
    invoice: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  methods: {
    download() {
      this.$emit("download", this.invoice, this.type);
    },
  },
};
</script>
  