import moment from 'moment';

class Quoter {
  /**
   * Get the quarter identifier for the previous quarter
   * @returns {string} The identifier for the previous quarter (e.g. "202501" for Q1 of 2025)
   */
  static getPreviousQuarterCode() {
    const threeMonthsAgo = moment().subtract(3, 'months');
    const quarter = threeMonthsAgo.quarter();
    const year = threeMonthsAgo.year();

    return `${year}0${quarter}`;
  }
}

export const quoterInstance = new Quoter();
export default Quoter;
