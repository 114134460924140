<template>
    <div> 
      <month-end-table chnl="PR" class="mt-2" :regionalManagerId="`${regionalManager}`" />
      <month-end-table chnl="DT" class="mb-2" :regionalManagerId="`${regionalManager}`"/> 

        <v-simple-table class="my-t">
                <template v-slot:default>
                  <thead class="table-heading"> 
                    <tr class="custom-ligth-row ">   
                      <th colspan="7" class="text-center">
                        <div class="d-flex justify-center ">
                          <b class="pr-8 pl-8 text-dark " >Facturación neta por trimestre (Miles)</b>
                        </div> 
                      </th>  
                    </tr>
                    <tr> 
                      <th class="text-center text-dark" v-for="(item,index) in quarters" :key="index">
                        <b v-html="item.date"></b>
                      </th> 
                    </tr>
                  </thead> 
                  <tbody> 
                    <tr  v-if="!loadingOverview">
                      <td v-for="item in 6" :key="item">{{formatNumber(groupOverviewData[`FACNETA${item}`] / 1000,"$",0)}}</td>
                    </tr>
                    <tr v-else>
                        <td colspan="8">
                          <v-progress-linear indeterminate color="cyan" />
                        </td>
                      </tr>
                  </tbody>
                </template>
              </v-simple-table>
    </div> 
</template>
<script>
import axios from '@/plugins/axios'
import numbersFormats from '@/mixins/numbersFormats';
import service from '@/mixins/service';
import MonthEndTable from '@/views/diary/director/components/MonthEndTable.vue';

export default({
    name:'AverageOrders',
    components:{ 
      MonthEndTable
    },  
    mixins:[numbersFormats,service],
    props: {
      chnl: { 
        required:true,
        String
      },
      regionalManager:{
        required:true,
      }
    },
    data: () => ({
      groupOverviewData:[],
      loadingOverview:true,
      quarters:[],
  }),
  methods:{
    async groupOverview(){
      this.loadingOverview=true;
      var params = {
            channel :this.chnl
          }
          if (this.regionalManager) params.rgManager = this.regionalManager;
        try { 
          const response = await axios.get("/daily-director/group-overview", {
                params: params
              });
              
          this.groupOverviewData = response.data;
        } catch (error) {
          this.error = "No se pudo obtener la información";
          this.loadingOverview=false;
        } 
        this.loadingOverview=false;
    },
  },
  mounted(){
    this.groupOverview()
  },
  created(){
    this.quarters = this.calcularUltimosTrim(6).reverse()
  }
})
</script>
<style scoped src="../../../../../public/css/managercustom.css"></style>