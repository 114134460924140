<template>
  <div>
      <v-simple-table class="mt-4" dense>

            <template v-slot:default>
              <thead class="table-heading">
                <tr v-if="!loading && headerNetsales">
                  <th class="text-center boder-x-none"></th>
                  <th class="text-center boder-x-none text-dark"><b>Prom.&nbsp;Últ.&nbsp;6&nbsp;meses</b></th>
                  <th v-for="(item, index) in headerNetsales" :key="index" class="text-center boder-x-none text-dark"><b>{{ item }}</b></th> 
                </tr>
              </thead>
              <tbody class="custom-table-bordered">
                  <tr v-if="loading">
                      <td colspan="3">
                          <span class="text--disabled">Cargando información...</span>
                          <v-progress-linear :indeterminate="loading" color="teal" ></v-progress-linear>
                      </td>
                  </tr>
                  <tr v-for="(item, index) in bodyNetSales" :key="index">
                  <td class="table-heading">{{item.TITLE }}</td>                     
                  <td>
                      <b v-if="index=='FACNUEVOSPROD'">
                      <a class="text-decoration-underline text-dark" @click="showNetSales()">{{ numberformat(item.P6MES,false) }}%</a>
                      </b>
                      <b v-else-if="index=='PORCCTEPAG' || index=='FACGENN'">{{   numberformat(item.P6MES,false)}}%</b>
                      <b v-else-if="index=='FAMILIAS' || index=='RENGLONAJE'">{{  numberformat(item.P6MES)}}</b>
                      <b v-else>$&nbsp;{{numberformat(item.P6MES)}}</b>
                  </td>

                  <td :class="item.MAX== item.MES6M  && item.MAX != 0  ? 'text-success': (item.MIN == item.MES6M ? 'text-danger':'')">
                     <div  v-if="index== 'INGPAGADOS'" class="text-decoration-underline text-black" >
                        <a   @click="getCommissions(7)">{{ transformResponse(index,item.MES6M) }}  </a>  
                    </div>
                    <span v-else>
                     {{  transformResponse(index,item.MES6M)}}
                  </span>  
                   </td>
                   <td :class="item.MAX== item.MES5M && item.MAX != 0? 'text-success': (item.MIN == item.MES5M ? 'text-danger':'')">
                    <div  v-if="index== 'INGPAGADOS'" class="text-decoration-underline text-black" >
                          <a click="getCommissions(6)">  {{ transformResponse(index,item.MES5M) }}  </a>  
                    </div>
                    <span v-else>
                     {{ transformResponse(index,item.MES5M) }}
                  </span> 
                    </td>  
                    <td :class="item.MAX== item.MES4M && item.MAX != 0? 'text-success': (item.MIN == item.MES4M ? 'text-danger':'')">
                    <div  v-if="index== 'INGPAGADOS'" class="text-decoration-underline text-black" >
                        <a   @click="getCommissions(5)">  {{ transformResponse(index,item.MES4M) }}  </a>  
                    </div> 
                    <span v-else>
                      {{ transformResponse(index,item.MES4M) }}
                  </span>
                   </td> 
                   <td :class="item.MAX== item.MES3M && item.MAX != 0? 'text-success': (item.MIN == item.MES3M ? 'text-danger':'')">
                    <div  v-if="index== 'INGPAGADOS'" class="text-decoration-underline text-black" >
                        <a   @click="getCommissions(4)">  {{ transformResponse(index,item.MES3M) }}  </a>  
                    </div>
                    <span v-else>
                      {{ transformResponse(index,item.MES3M) }}
                  </span>
                   </td> 
                   <td :class="item.MAX== item.MES2M && item.MAX != 0  ? 'text-success': (item.MIN == item.MES2M ? 'text-danger':'')">
                    <div  v-if="index== 'INGPAGADOS'" class="text-decoration-underline text-black" >
                        <a   @click="getCommissions(3)">  {{ transformResponse(index,item.MES2M) }}  </a>  
                    </div> 
                    <span v-else>
                     {{ transformResponse(index,item.MES2M) }}  
                  </span>
                  </td> 
                  <td   :class="item.MAX== item.MESANT && item.MAX != 0  ? 'text-success': (item.MIN == item.MESANT ? 'text-danger':'')">
                    <div  v-if="index== 'INGPAGADOS'" class="text-decoration-underline  text-black" >
                      <a  @click="getCommissions(2)">{{ transformResponse(index,item.MESANT) }} </a>
                    </div> 
                    <span v-else>
                     {{ transformResponse(index,item.MESANT) }}
                    </span>
                   </td>  
                  <td>
                    <div  v-if="index== 'INGPAGADOS'" class="text-decoration-underline text-black" >
                        <a   @click="getCommissions(1)">  {{ transformResponse(index,item.MESACT) }}  </a>  
                    </div> 
                    <span v-else>
                      {{ transformResponse(index,item.MESACT)  }}
                    </span>
                    </td> 
                </tr> 
              </tbody>
            </template>
          </v-simple-table> 
          <v-dialog :persistent="commissionsLoader" v-model="commissionModal" width="auto">
        <v-card>
          <v-toolbar
            color="primary"
            dark dense class="pb-1 h-25"
          >Detalle de comisiones</v-toolbar>
            <v-progress-circular v-if="commissionsLoader" indeterminate color="primary" class="mt-1 mb-2"></v-progress-circular>
            <div v-else class=" pa-2 text-left">
              <v-simple-table>
              <tbody >  
                  <tr v-for="(item,index) in commissions.data" :key="index"> 
                    <td>{{  item.title  }}</td>
                    <td class="pa-1">$  {{ numberformat(item.value??0) }}</td>
                  </tr>
                  <tr>
                    <td>Total: </td>
                    <td class="pa-1">$ {{ numberformat(commissions.total??0) }}</td>
                  </tr>                   
          </tbody> 

          </v-simple-table>
          <div>
          <v-btn  class="justify-end"
              v-if="!commissionsLoader"
              @click="commissionModal = false"
            >Cerrar</v-btn>
            </div>
        </div>  
        </v-card> 
        </v-dialog>
  </div>
  </template>

<script>
import axios from "@/plugins/axios";
import moment from "moment"; 
export default ({
  name: 'NetSales',
  props:['seller'],
data () {
  return {  
       
      bodyNetSales:[],
      loading:true,
      headerNetsales:[],
      commissionModal:false,
      commissionsLoader:false,
      commissionHeader:[],
      commissions:{}
  }
}, 
watch:{
  billing: {
     deep: true
    },
  seller(){
    this.getData();
  }
},
methods: { 
  getPeriod(period){
  const today = moment();  
  const newDate = today.subtract(period-1, 'months');
  return newDate.format('YYYYMM')
  },
  getCommissions(period) {
    const newPeriod = this.getPeriod(period)
    this.commissionsLoader = true; 
    this.commissionModal=true 
    axios.post("/seller/home/sales-commissions", {
          seller_id: this.seller.PERNR,
          channel: this.seller.VTWEG,
          period:newPeriod
        })
        .then((response) => {
          if (response.data?.success==false) {
              console.log( response.data)
              this.commissionsLoader = false 
          } else {
              this.commissions = response.data; 
              this.commissionsLoader = false 
          }
        })
        .catch((e) => {
          console.log(e);
          this.commissionsLoader = false 
        }).finally (() => {
          this.commissionsLoader = false 
        });
  },
  transformResponse(index,value){
    switch (index) {
      case 'FACNUEVOSPROD' :
      case 'PORCCTEPAG' :
      case 'FACGENN':
       return this.numberformat(value,false)+"%"
      case 'PEDIDOS':
      case 'FACTURACION_NETA':
      case 'INGPAGADOS':
      case 'PRESUPUESTO':
      return "$"+this.numberformat(value,true)
      default: 
      return value; 
    }
     
  },
  async getData() {
this.loading = true;

try {
  const params = {
    seller_id: this.seller.PERNR,
    channel: this.seller.VTWEG,
  };

  const response = await axios.post("/seller/home/net-sales", params, {
    noCancelRequest: true,
    cachable: true,
  });

  if (response.data?.success === false) {
    console.log('Error en la respuesta de la API:', response.data);
  } else {
    const body = response.data[0].BODY;

    if (body && body.FACTURACION_NETA && body.FACTURACION_NETA.MESANT) {
      if (this.$hasAnyRole(['DirectorNoCommercial', 'SubDirectorPRNoCommercial', 'SubDirectorDTNoCommercial'])) {
        delete body.INGPAGADOS;
      }

      this.bodyNetSales = body;
      this.headerNetsales = response.data[0].HEADERS;
    }
    this.$emit('netSalesAmount', this.bodyNetSales['FACTURACION_NETA'].MESANT);
  }
} finally {
  this.loading = false;
}
},
  showNetSales(){
      this.$emit('showNetSales',5)
  },
  numberformat(number,round=true){ 
    let result = round? Math.trunc(number): Number(number).toFixed(2);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}, 

});
</script>