<template>
  <div>
    <!-- TABLE FOR DT CHANNEL -->
    <v-simple-table v-if="isDTChannel">
      <thead>
        <tr>
          <th>Producto / Descripción</th>
          <th v-if="!$hasAnyRole(['Customer', 'CustomerPR'])">Genética</th>
          <th>Cantidad</th>
          <th>Empaque</th>
          <th>Unidad de Medida de Venta</th>
          <th v-if="!$hasAnyRole(['Customer', 'CustomerPR'])">Existencia</th>
          <th v-if="!$hasAnyRole(['Customer', 'CustomerPR'])">
            Existencia CDPT
          </th>
          <th>Promedio Mensual de Compra*</th>
          <th>Días Solicitados de Inventario*</th>
          <th>Precio de Lista</th>
          <th>Precio con Descuento</th>
          <th>Importe</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="itemsInCart === 0">
          <td
            colspan="12"
            class="text-center"
          >
            No hay productos en el carrito
          </td>
        </tr>
        <tr
          v-for="(item, index) in cart"
          :key="index"
        >
          <td>
            <div class="d-flex">
              <div style="min-width: 75px; min-height: 75px; margin-right: 4px">
                <v-progress-circular
                  v-if="!productImages[item.codigo_material]"
                  color="primary"
                  indeterminate
                />
                <v-img
                  v-else
                  :src="productImages[item.codigo_material]"
                  max-height="75px"
                  max-width="75px"
                  class="cursor-pointer"
                  contain
                  @click="openProductImageGallery(item, index)"
                />
              </div>
              <div>
                <p
                  class="text-left text-no-wrap font-weight-bold text-decoration-underline"
                >
                  SKU {{ item.codigo_material }}
                </p>
                <p class="text-left mb-0">{{ item.nombre_material }}</p>
                <div class="d-flex align-center flex-wrap">
                  <v-btn
                    icon
                    @click="openProductDocumentsModal(index)"
                  >
                    <v-icon color="red red-darken-4">
                      mdi-folder-outline
                    </v-icon>
                  </v-btn>
                  <span class="text-left font-size-8 mr-2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :class="item.validacion"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-alert-circle
                        </v-icon>
                      </template>
                      <span>{{ item.stock_label }}</span>
                    </v-tooltip>
                  </span>
                  <special-discount-icon :discount="item" />
                </div>
              </div>
            </div>
          </td>
          <td v-if="!$hasAnyRole(['Customer', 'CustomerPR'])">
            <special-genetics-icons :genetics="item.material_type" />
          </td>
          <td>
            <v-text-field
              v-if="!item.backorder_id"
              v-model="item.u_pedidas"
              class="mt-0"
              dense
              single-line
              hide-details
              @keydown.enter.prevent="updateItemQuantity(item, item.u_pedidas)"
            />
            <p
              v-else
              class="mb-0 text-decoration-underline"
            >
              {{ parseInt(item.u_confirm) }}
            </p>
            <p
              v-if="item.recordatorios != 0"
              class="mb-0 mt-2"
              style="font-size: 12px"
            >
              {{ `BACKORDER ${item.recordatorios}` }}
            </p>
          </td>
          <td>
            <p class="mb-0">{{ item.empaque }}</p>
          </td>
          <td>
            <p class="mb-0">{{ item.unidad_medida }}</p>
          </td>
          <td v-if="!$hasAnyRole(['Customer', 'CustomerPR'])">
            <p class="mb-0">{{ formatToFixedCommas(item.existencia) }}</p>
          </td>
          <td v-if="!$hasAnyRole(['Customer', 'CustomerPR'])">
            <p class="mb-0">{{ formatToFixedCommas(item.existencia_cdpt) }}</p>
          </td>
          <td>
            <p class="mb-0">{{ item.PMCOM }}</p>
          </td>
          <td>
            <p class="mb-0">{{ item.DSINV }}</p>
          </td>
          <td>
            <p class="mb-0">{{ formatPrice(item.precio_lista) }}</p>
          </td>
          <td>
            <p
              v-if="item.recordatorios == 0"
              class="mb-0"
            >
              {{ formatPrice(item.precio_con_descuento) }}
            </p>
            <p
              v-else
              class="mb-0"
            >
              {{ formatPrice(item.importe_desciento) }}
            </p>
          </td>
          <td>
            <p class="mb-0">{{ formatPrice(item.importe_producto) }}</p>
          </td>
          <td>
            <v-btn
              icon
              @click="onDeleteItem(item)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <!-- TABLE FOR PR CHANNEL -->
    <v-simple-table v-if="isPRChannel">
      <thead>
        <tr>
          <th>Producto / Descripción</th>
          <th v-if="!$hasAnyRole(['Customer', 'CustomerPR'])">Genética</th>
          <th>Unidad de Medida</th>
          <th>Empaque</th>
          <th>U. Solicitadas</th>
          <th>U. Confirmadas</th>
          <th v-if="!$hasAnyRole(['Customer', 'CustomerPR'])">Existencia</th>
          <th v-if="!$hasAnyRole(['Customer', 'CustomerPR'])">
            Existencia CDPT
          </th>
          <th>Precio Unitario</th>
          <th>Precio con Descuento</th>
          <th>Importe</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="itemsInCart === 0">
          <td
            colspan="12"
            class="text-center"
          >
            No hay productos en el carrito
          </td>
        </tr>
        <tr
          v-for="(item, index) in cart"
          :key="index"
        >
          <td>
            <div class="d-flex">
              <div style="min-width: 75px; min-height: 75px; margin-right: 4px">
                <v-progress-circular
                  v-if="!productImages[item.codigo_material]"
                  color="primary"
                  indeterminate
                />
                <v-img
                  v-else
                  :src="productImages[item.codigo_material]"
                  max-height="75px"
                  max-width="75px"
                  class="cursor-pointer"
                  contain
                  @click="openProductImageGallery(item, index)"
                />
              </div>
              <div>
                <p
                  class="text-left text-no-wrap font-weight-bold text-decoration-underline"
                >
                  SKU {{ item.codigo_material }}
                </p>
                <p class="text-left mb-0">{{ item.nombre_material }}</p>
                <div class="d-flex align-center flex-wrap">
                  <v-btn
                    icon
                    @click="openProductDocumentsModal(index)"
                  >
                    <v-icon color="red red-darken-4">
                      mdi-folder-outline
                    </v-icon>
                  </v-btn>
                  <span class="text-left font-size-8">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :class="item.validacion"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-alert-circle
                        </v-icon>
                      </template>
                      <span>{{ item.stock_label }}</span>
                    </v-tooltip>
                  </span>
                  <special-discount-icon :discount="item" />
                </div>
              </div>
            </div>
          </td>
          <td v-if="!$hasAnyRole(['Customer', 'CustomerPR'])">
            <special-genetics-icons :genetics="item.material_type" />
          </td>
          <td>
            <p class="mb-0">{{ item.unidad_medida }}</p>
          </td>
          <td>
            <p class="mb-0">{{ item.empaque }}</p>
          </td>
          <td>
            <v-text-field
              v-if="!item.backorder_id"
              v-model="item.u_pedidas"
              class="mt-0"
              dense
              single-line
              hide-details
              @keydown.enter.prevent="updateItemQuantity(item, item.u_pedidas)"
            />
            <p
              v-else
              class="mb-0 text-decoration-underline"
            >
              {{ parseInt(item.u_confirm) }}
            </p>
            <p
              v-if="item.recordatorios != 0"
              class="mb-0 mt-2"
              style="font-size: 12px"
            >
              {{ `BACKORDER ${item.recordatorios}` }}
            </p>
          </td>
          <td>
            <p class="mb-0">{{ item.u_confirm }}</p>
          </td>
          <td v-if="!$hasAnyRole(['Customer', 'CustomerPR'])">
            <p class="mb-0">{{ formatToFixedCommas(item.existencia) }}</p>
          </td>
          <td v-if="!$hasAnyRole(['Customer', 'CustomerPR'])">
            <p class="mb-0">{{ formatToFixedCommas(item.existencia_cdpt) }}</p>
          </td>
          <td>
            <p class="mb-0">{{ formatPrice(item.precio_lista) }}</p>
          </td>
          <td>
            <p class="mb-0">{{ formatPrice(item.precio_con_descuento) }}</p>
          </td>
          <td>
            <p class="mb-0">{{ formatPrice(item.importe_producto) }}</p>
          </td>
          <td>
            <v-btn
              icon
              @click="onDeleteItem(item)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- DIALOGS, MODALS, OVERLAYS -->
    <CoolLightBox
      v-if="productGalleryImages"
      :items="productGalleryImages"
      :index="productGalleryImageIndex"
      @close="closeProductImageGallery()"
    />
    <product-documents-modal
      v-model="isProductDocumentsModalOpen"
      :product="currentProduct"
    />
  </div>
</template>

<script>
  import SpecialDiscountIcon from './SpecialDiscountIcon.vue';
  import SpecialGeneticsIcons from './SpecialGeneticsIcons.vue';
  import ProductDocumentsModal from './ProductDocumentsModal.vue';
  import CoolLightBox from 'vue-cool-lightbox';

  import { getProductMainImage } from '@/repositories/ProductFiles';
  import { formatPrice } from '@/utilities/formatPrice';

  import { mapActions, mapGetters, mapMutations } from 'vuex';

  export default {
    name: 'ShoppinCartTable',
    components: {
      SpecialDiscountIcon,
      SpecialGeneticsIcons,
      ProductDocumentsModal,
      CoolLightBox,
    },
    props: {
      customerCode: { type: Number },
      doctype: { type: String },
    },
    data: () => {
      return {
        productImages: {},
        productGalleryImages: [],
        productGalleryImageIndex: null,
        isProductDocumentsModalOpen: false,
      };
    },
    computed: {
      ...mapGetters('auth', ['channel']),
      ...mapGetters('ordercart', [
        'orderId',
        'cart',
        'itemsInCart',
        'currentProduct',
      ]),
      isPRChannel() {
        return this.channel === 'PR';
      },
      isDTChannel() {
        return this.channel === 'DT';
      },
    },
    methods: {
      ...mapActions('ordercart', [
        'updateMaterialQuantity',
        'deleteMaterialOrder',
        'getProductImages',
      ]),
      ...mapMutations('ordercart', ['SET_CURRENT_PRODUCT_INDEX']),
      async fetchProductImage(sku) {
        const { image } = await getProductMainImage(sku);
        this.$set(this.productImages, sku, image.path);
      },
      formatToFixedCommas(value) {
        if (isNaN(value) || value === null || value === undefined)
          return '0.00';

        let result = Number(value).toFixed(2);
        return result.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      formatPrice(value) {
        return formatPrice(value);
      },
      async updateItemQuantity(item, quantity) {
        try {
          await this.updateMaterialQuantity({
            custumer_code: this.customerCode,
            codigo_material: item.codigo_material,
            quantity,
            precio_con_descuento: item.precio_con_descuento,
            cart_i: this.cart,
            order_type: this.doctype,
          });
          this.$toast.success('Cantidad de producto actualizada');
        } catch (error) {
          this.$toast.warning(
            error?.response?.data?.error_code ||
              'No se pudo actualizar la cantidad del producto'
          );
          item.u_pedidas = parseInt(item.u_confirm);
        }
      },
      async onDeleteItem(item) {
        try {
          await this.deleteMaterialOrder({ order_id: this.orderId, item });
          this.$toast.success('Producto eliminado del carrito');
        } catch (error) {
          this.$toast.error('No se pudo eliminar el producto del carrito');
        }
      },
      async openProductImageGallery(product, index) {
        this.SET_CURRENT_PRODUCT_INDEX(index);

        if (product.images) {
          this.productGalleryImages = this.currentProduct.images;
          this.productGalleryImageIndex = 0;
          return;
        }

        this.productGalleryImages = [
          {
            title: 'Cargando...',
            description: 'Cargando...',
            src: product.image_url,
          },
        ];

        this.productGalleryImageIndex = 0;
        const images = await this.getProductImages(index);
        this.productGalleryImages = images;
      },
      closeProductImageGallery() {
        this.productGalleryImageIndex = null;
        this.productGalleryImages = [];
      },
      openProductDocumentsModal(index) {
        this.SET_CURRENT_PRODUCT_INDEX(index);
        this.isProductDocumentsModalOpen = true;
      },
    },
    watch: {
      cart: {
        handler(newCart) {
          newCart.forEach((item) => {
            if (!this.productImages[item.codigo_material]) {
              this.fetchProductImage(item.codigo_material);
            }
          });
        },
        immediate: true, // Fetch on component mount
        deep: true,
      },
    },
  };
</script>

<style scoped>
  thead {
    background-color: rgb(242, 242, 242);
    border-bottom: 2px solid rgb(112, 112, 112) !important;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029 !important;
  }

  thead > tr > th {
    font-size: 16px;
    font-weight: 900;
    text-align: center !important;
    white-space: wrap;
  }
</style>
