<template>
  <v-row :style="{ maxWidth: width }">
    <v-col cols="12" md="4">
      <input-date-picker 
				v-model="startDate" 
				:label="startDateLabel" 
				:max-date="maxDate"
				:min-date="minDate"
				:errors="startDateErrors"
			/>
    </v-col>
    <v-col cols="12" md="4">
      <input-date-picker
        v-model="endDate"
        :label="endDateLabel"
        :max-date="maxDate"
				:min-date="minDateBasedOnStartDate"
				:errors="endDateErrors"
      />
    </v-col>
    <v-col cols="12" md="3">
      <v-btn
        :loading="loading"
        :disabled="loading"
        depressed
        color="success"
        block
        @click="emitChanges"
        class="button-red-round text-white font-weight-bold"
      >
        {{ buttonLabel }} 
      </v-btn>      
      <v-btn
        v-if="startDate || endDate"
        text 
        class="m-1 p-2"
        @click="emitReset">
        <v-icon class="mr-1">mdi-close-circle</v-icon>
        Borrar Fechas
      </v-btn>      
    </v-col> 
  </v-row>
</template>

<script>
import InputDatePicker from "@/components/InputDatePicker.vue";

export default {
  name: "DateRangeSelector",

  props: {
    minDate: {
      type: Date,
      default: null,
    },

    maxDate: {
      type: Date,
      default: () => new Date(),
    },

    startDateLabel: {
      type: String,
      default: 'Fecha inicial',
    },

    endDateLabel: {
      type: String,
      default: 'Fecha final',
    },

    buttonLabel: {
      type: String,
      default: 'Ejecutar',
    },

		width: {
			type: String,
			default: '9000px'
		},

		loading: Boolean,
		endDateOptional: Boolean,
  },

  components: {
    InputDatePicker,
  },

  data() {
    return {
      startDate: null,
      endDate: null,

			endDateErrors: '',
			startDateErrors: '',
    };
  },

  methods: {
    emitChanges() {
			this.endDateErrors = '';
			this.startDateErrors = '';
			let errorInDates = false;

			if (this.startDate === null && this.endDate !== null) {
				this.startDateErrors = 'Introduce esta fecha';
				errorInDates = true;
			}
		
			if (!this.endDateOptional && this.endDate === null && this.startDate !== null ){
				this.endDateErrors = 'Introduce esta fecha';
				errorInDates = true;
			} 

			if (!errorInDates || (this.startDate === null && this.endDate === null)) {
				const dates = {
					startDate: this.startDate,
					endDate: this.endDate
				}
				
				this.$emit('onClick', dates);
			}
    },

    emitReset(){
      this.startDate = null;
      this.endDate = null;
      this.$emit('onReset')
    }
  },

	computed: {
		minDateBasedOnStartDate(){
			if(this.startDate === null)	return this.minDate;

			return new Date(this.startDate);
		}
	},

	watch: {
		startDate (newVal) {
			if(this.endDate !== null && new Date(newVal) > new Date(this.endDate)){
				this.endDate = null;
			}
		}
	}
};
</script>
