<template>
    <v-simple-table class="bordered-table">
        <template v-slot:default>
            <table-head>
                <template v-slot:thead>
                    <th class="text-center header pa-1">
                        <div class="text-black"> {{ columnTitle }} </div>
                    </th>
                    <th class="text-center header pa-1">
                        <div class="text-black"> Clientes</div>
                    </th>
                </template>
            </table-head>
            <tbody>
                <table-body-loader v-if="loading" />
                <template v-else v-for="(item, index) in items">
                    <tr :key="`customers-${index}`" v-if="index == 0" class=" font-weight-bold first-row">
                        <td>{{ item.name }}
                        </td>
                        <td v-for="(value, vIndex) in item.values" :key="vIndex" class="text-center">
                            <span v-if="vIndex != 0">
                                {{ formatFloat(value.metric_customers, 0) }} <br>
                                {{ formatFloat(calculatePercent(value.customers, value.metric_customers), 2) }}%
                                <br>
                            </span>
                            <span v-else>
                                {{ formatFloat(value.customers, 0) }}
                            </span>
                            <br>
                        </td>
                    </tr>
                    <tr v-else :key="`customers-${index}`">
                        <td>
                            <div class="font-weight-bold d-flex">
                                <span class="mr-8"> {{ index }} </span>
                                <a class="mr-8 text-black " :class="{ 'text-decoration-none': manager }" @click="optionSelected(item)">{{
                                    item.name.replaceAll(" ", "&nbsp;") }} </a>
                            </div>
                        </td>
                        <td v-for="(value, othindex) in item.values" :key="othindex">
                            <span v-if="othindex > 0">
                                {{ formatFloat(value.metric_customers, 0) }} <br>
                                {{ formatFloat(calculatePercent(value.customers, value.metric_customers), 2) }}%
                            </span>
                            <span v-else>
                                {{ formatFloat(value.customers, 0) }} <br>
                                {{ formatFloat(calculatePercent(items[0].values[0].customers, value.customers), 2)
                                }}%
                            </span>
                        </td>
                    </tr>
                </template>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import TableBodyLoader from '@/components/tables/TableBodyLoader.vue';
import TableHead from './TableHead.vue';

import numbersFormats from '@/mixins/numbersFormats';
import Helpers from '@/mixins/helpers';

export default {
    name: "CustomersTable",
    mixins: [numbersFormats, Helpers],
    components: { TableHead, TableBodyLoader },
    props: {
        items: {
            default: () => [{}]
        },
        loading: {
            default: true,
            type: Boolean
        },
        columnTitle: {
            type: String,
            default: 'Zona',
        },
        manager: {
            type: String,
            default: "",
        },
    },
    methods: {
        optionSelected(value) {
            this.$emit('optionSelected', value)
        }
    },
};
</script>
<style scoped>
.first-row td {
    background: #E0E0E0 0% 0% no-repeat padding-box;
    border: 1px solid #A6A3A3 !important;
}

.bordered-table td {
    border: 1px solid #DDE1E6;
}
</style>