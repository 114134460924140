<template>
    <div id="profile-director" class="menu--option background-grey lighten-5 d-flex align-items-center justify-content-center">       
        <div class="menu--card d-flex justify-content-center">
            <div class="card--container">
                <img src="img/propuesta-1-x2.svg" class="card--img mb-4" alt="">
                <h2 class="card--title card--title__bold">TE DA LA BIENVENIDA</h2>
                <p class="card--question">¿De qué canal te gustaría visualizar la información?</p>

                <div class="card--radio">
                    <input id="toggle-on" class="toggle toggle-left" name="toggle" value="DT" type="radio" v-model="VTWEG">
                    <label for="toggle-on" class="label-first card--label">{{ user.channels['DT'].toUpperCase() }} DT</label>

                    <input id="toggle-off" class="toggle toggle-right" name="toggle" value="PR" type="radio" v-model="VTWEG">
                    <label for="toggle-off" class="label-second card--label">{{ user.channels['PR'].toUpperCase() }} PR</label>
                </div>


                <button type="button" class="btn card--button card--button__color" @click="actualizarVTWEG()">CONTINUAR</button>
            </div> 
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    data(){
        return {
            VTWEG: 'DT'            
        }
    },
    computed: {
        ...mapGetters({
          authenticated: "auth/authenticated",
          user: "auth/user",
        })
    },
    methods:{
        ...mapActions({
            actualizarUsuario: 'auth/actualizarUsuario'
        }),
        actualizarVTWEG() { 
            this.actualizarUsuario(this.VTWEG).then(() => {  
                localStorage.setItem("channel", this.VTWEG);

                if(this.$hasAnyRole(["IELExpressSales"]) || this.$hasAnyRole(["IELBriefcase"])){
                    this.$router.push({ name: 'ResultsExpressSales' });
                }
                //tambien se reutliza para rol de gerente regional
               else if (this.$hasAnyRole(["RegionalManager", "IELRegManager"])) { 
                    this.$router.push({ name: 'DailyRegionalManager' });  
                }else{
                    this.$router.push({ name: 'Home' });
                }
            });
        },
    }
}
</script>

<style scoped>
    .menu--option{
        height: 650px;        
    }
    .menu--card{
        padding:40px;
        width:100%;
        max-width: 700px;
        background: #FFF;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        border-radius: 20px;
        opacity: 1;
    }

    .btn{
        width: 50%;
        font-size: 14px !important;
    }
    .card--title{
        margin-bottom: 40px;
    }
    .card--title__bold{
        font-weight: bold;
    }
    .card--container{
        width: 500px;
    }
    .card--question{
        font-size: 14px;
    }

    .card--button{
        margin-top: 40px;
        font-weight: bold;
        color: #FFF
    }

    .card--button__color{
        border: 1px solid #DC3545;
        background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #DC3545;
    }

    .card--img{
        width: 40%;
    }


    .card--radio {
        border: 2px solid #686868;
        overflow: hidden;
        width: 400px;
        border-radius: 6px;
        margin: 0 auto
    }

    .card--radio input[type="radio"].toggle {
        display: none;
    }

    .card--radio label {
        cursor: pointer;
        flex: 1;
        padding: 4px;
        text-align: center;
        position: relative !important;
        z-index: 1; /* Asegura que el contenido del botón esté sobre el fondo */
        color: #686868;
        width: 50%;
        margin: 0;
    }

    .card--radio label:hover {
        background: none;
    
    }

    .card--radio .label-first:after {
        background: #686868 !important;
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        transition: transform 200ms ease; 
        width: 100%;
        z-index: -1;
        transform: translateX(12%); 
    }

    .card--radio input[type="radio"].toggle-left:checked + label:after {
        transform: translateX(-90%); /* Muestra el fondo desplazándolo a la derecha */    
    }

    .card--radio input[type="radio"].toggle:checked + label {
        cursor: default;
        color: #FFF;
        transition: color 200ms;
    }


</style>