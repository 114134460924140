<template>
  <div>
    <!-- search -->
    <div class="cont-search my-4">
        <v-text-field style="max-width: 250px"
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            solo
            hide-details
           >
        </v-text-field>
    </div>
    <!-- end search -->   

    <v-data-table
        :headers="headers"
        :items="filteredItems"
        :footer-props="footerProps"
        :options="dataTableOptions"   
        :mobile-breakpoint="null"   
        :loading="isLoading"        
        class="elevation-2 table-renglonaje"                         
    >    
        <template v-slot:[`item.id`]="{ item }">
            {{ filteredItems.indexOf(item) + 1 }}
        </template>
        
        <template v-slot:[`item.BEZEI`]="{ item }">
            <a href="#" @click="$emit('getMaterials', item.MVGR4)">{{item.BEZEI}}</a>
        </template>

        <template v-slot:[`item.PORACU`]="{ item }">
            <span :class="{'text-danger': isNegative(item.PORACU)}">{{ item.PORACU | formatCurrency}}%</span>
        </template>

        <template v-slot:[`item.PORACU12`]="{ item }">
            {{ formatAmount(item.PORACU12) }}
        </template>
        
        <template v-slot:[`item.PROULT4`]="{ item }">
            {{ formatAmount(item.PROULT4) }}
        </template>

        <template v-for="index in 10" v-slot:[`item.col${index}`]="{ item }">
            {{ formatAmount(item['col' + index]) }}
        </template>


        <template v-slot:header v-if="items.length > 0">
            <tfoot>
                <tr>
                    <td></td>
                    <td>Total</td>
                    <td>{{calcularPromedio('PORACUP') | formatCurrency}}%</td>
                    <td>{{calcularPromedio('PORACU12P') | formatCurrency}}</td>
                    <td>${{calcularPromedio('PROULT4P') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col1') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col2') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col3') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col4') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col5') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col6') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col7') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col8') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col9') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col10') | formatCurrency}}</td>
                </tr>
            </tfoot>
        </template>    
    </v-data-table>

  </div>
</template>

<script>
import moment from 'moment'
import Service from "@/mixins/service.js"
export default {
    props: {
        items: {
            type: Object,
            required: true
        }, 
        option: {
            type: Number,
            default: 1
        }, 
        isLoading: {
            type: Boolean,
            default: true
        },
        selectedYear: {
            type: Number,
            default: moment().year()
        }
    },
    mixins: [Service],
    data(){
        return {
            itemsDates: [], 
            headers: [
                { text: 'id', value: 'id', align:"center" },
                { text: 'Familia', value: 'BEZEI', align:"left" },
                { text: 'Acumulado', value: 'PORACU', align:"center" },
                { text: 'Crecimiento acumulado 12 meses', value: 'PORACU12', align:"center" },
                { text: 'Prom. últ. 4 meses', value: 'PROULT4', align:"center" },                
                { text: '', value: 'col1', align:"center" },                
                { text: '', value: 'col2', align:"center" },                
                { text: '', value: 'col3', align:"center" },                
                { text: '', value: 'col4', align:"center" },                
                { text: '', value: 'col5', align:"center" },                
                { text: '', value: 'col6', align:"center" },                
                { text: '', value: 'col7', align:"center" },                
                { text: '', value: 'col8', align:"center" },                
                { text: '', value: 'col9', align:"center" },                
                { text: '', value: 'col10', align:"center" }  
            ], 
            footerProps: {
                itemsPerPageOptions: [10, 14, 24, 34, { text: 'Todas', value: -1 }],
                itemsPerPageText: 'Mostrar en grupo de:',
                showCurrentPage: false,
                pageText: 'Desplegado {0} / {1} de {2} Familias'           
            }, 
            dataTableOptions: {
                itemsPerPage: 24,
                pagination: {
                    enabled: true,
                    options: {
                        numeric: true,
                    },
                },
            },
            search: '',
            families: []
        }
    },
    mounted(){
        this.calculate()
    }, 
    computed:{
        filteredItems() {
            const searchLowerCase = this.search.toLowerCase();
            return this.families.filter(item =>
                Object.values(item).some(
                value =>
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchLowerCase)
                )
            );
        },
    },
    watch:{
        option(){
            this.fillFamilies()
        },
        items(){
            this.fillFamilies()
        },
        filteredItems(){
            this.generarExcel()
        },
        selectedYear(){
            this.calculate();
        }
    },
    methods:{
        calculate(){
            let currentMonth = moment().format('M');
            let year = this.selectedYear;
            this.itemsDates = this.calcularUltimosMeses(10, currentMonth, year)  
            
            this.headers[2].text = `Acum. ${year} vs ${year - 1}`;
            this.headers[5].text = this.itemsDates[0].date
            this.headers[6].text = this.itemsDates[1].date
            this.headers[7].text = this.itemsDates[2].date
            this.headers[8].text = this.itemsDates[3].date
            this.headers[9].text = this.itemsDates[4].date
            this.headers[10].text = this.itemsDates[5].date
            this.headers[11].text = this.itemsDates[6].date
            this.headers[12].text = this.itemsDates[7].date
            this.headers[13].text = this.itemsDates[8].date
            this.headers[14].text = this.itemsDates[9].date
        },
        calcularSuma(propiedad) {
            return this.items.reduce((acumulador, objeto) => acumulador + parseInt(objeto[propiedad]), 0);
        },
        calcularPromedio(propiedad) {
            const suma = this.calcularSuma(propiedad);
            return suma / this.filteredItems.length;
        },
        calcularSumaMes(tipo) {
            return this.filteredItems.reduce((acumulador, objeto) => acumulador + parseInt(objeto[tipo]), 0);
        },
        fillFamilies(){
            this.families = this.items.map(family => {
                return {
                    BEZEI: family.BEZEI,
                    MVGR4: family.MVGR4,
                    PORACU: this.option == 1 ? family.PORACUP : family.PORACUM,
                    PORACU12: this.option == 1 ? family.PORACU12P : family.PORACU12M,
                    PROULT4: this.option == 1 ? family.PROULT4P : family.PROULT4M,
                    col1: this.option == 1 ? family.DIVDET.DIVDET[0].PIEZAS : family.DIVDET.DIVDET[0].MONTO,
                    col2: this.option == 1 ? family.DIVDET.DIVDET[1].PIEZAS : family.DIVDET.DIVDET[1].MONTO,
                    col3: this.option == 1 ? family.DIVDET.DIVDET[2].PIEZAS : family.DIVDET.DIVDET[2].MONTO,
                    col4: this.option == 1 ? family.DIVDET.DIVDET[3].PIEZAS : family.DIVDET.DIVDET[3].MONTO,
                    col5: this.option == 1 ? family.DIVDET.DIVDET[4].PIEZAS : family.DIVDET.DIVDET[4].MONTO,
                    col6: this.option == 1 ? family.DIVDET.DIVDET[5].PIEZAS : family.DIVDET.DIVDET[5].MONTO,
                    col7: this.option == 1 ? family.DIVDET.DIVDET[6].PIEZAS : family.DIVDET.DIVDET[6].MONTO,
                    col8: this.option == 1 ? family.DIVDET.DIVDET[7].PIEZAS : family.DIVDET.DIVDET[7].MONTO,
                    col9: this.option == 1 ? family.DIVDET.DIVDET[8].PIEZAS : family.DIVDET.DIVDET[8].MONTO,
                    col10: this.option == 1 ? family.DIVDET.DIVDET[9].PIEZAS : family.DIVDET.DIVDET[9].MONTO
                }
            })
        },
        generarExcel(){
            let newItems = []
            this.itemCSV = []
            for (let key in this.filteredItems) {                
                let materiales = this.filteredItems[key];
                newItems.push({
                    [this.headers[0].text]: parseInt(key) + 1,
                    [this.headers[1].text]: materiales.BEZEI,
                    [this.headers[2].text]: materiales.PORACU,
                    [this.headers[3].text]: materiales.PORACU12,
                    [this.headers[4].text]: materiales.PROULT4,
                    [this.headers[5].text]: materiales.col1,
                    [this.headers[6].text]: materiales.col2,
                    [this.headers[7].text]: materiales.col3,
                    [this.headers[8].text]: materiales.col4,
                    [this.headers[9].text]: materiales.col5,
                    [this.headers[10].text]: materiales.col6,
                    [this.headers[11].text]: materiales.col7,
                    [this.headers[12].text]: materiales.col8,
                    [this.headers[13].text]: materiales.col9,
                    [this.headers[14].text]: materiales.col10
                });
            }

            const sumaObjeto = {
                    [this.headers[0].text]: '',
                    [this.headers[1].text]: 'TOTAL',
                    [this.headers[2].text]: this.calcularPromedio('PORACUP'),
                    [this.headers[3].text]: this.calcularPromedio('PORACU12'),
                    [this.headers[4].text]: this.calcularPromedio('PROULT4'),
                    [this.headers[5].text]: this.calcularSumaMes('col1'),
                    [this.headers[6].text]: this.calcularSumaMes('col2'),
                    [this.headers[7].text]: this.calcularSumaMes('col3'),
                    [this.headers[8].text]: this.calcularSumaMes('col4'),
                    [this.headers[9].text]: this.calcularSumaMes('col5'),
                    [this.headers[10].text]: this.calcularSumaMes('col6'),
                    [this.headers[11].text]: this.calcularSumaMes('col7'),
                    [this.headers[12].text]: this.calcularSumaMes('col8'),
                    [this.headers[13].text]: this.calcularSumaMes('col9'),
                    [this.headers[14].text]: this.calcularSumaMes('col10')
            };

            this.itemCSV = [...newItems, {...sumaObjeto}]
            this.$emit('sendItemCSV', this.itemCSV)
        },
        formatAmount(value) {
            return `${this.option === 2 ? '$' : ''}${this.$options.filters.removeDecimals(value)}`;
        },
        isNegative(number) {
            return number < 0;
        }
    },    
}
</script>

<style>

</style>