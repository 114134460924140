<template>
  <div v-if="cart.length != 0">
      <div class="text-ped  ml-2" style="text-align: left;">
          Detalle Compra {{doctype}}
      </div>
      <br>
      <v-row dense v-if="!dialogLoading">
        <v-col>
         <!--<table class="table">-->
          <v-simple-table class="table-profile ma-2">
              <thead>
                  <tr style="font-size:10px; background-color: rgb(238,238,238);">
                      <th colspan="2">Producto</th>
                      <th>Genetica</th>
                      <th>Cantidad</th>
                      <th>Empaque</th>
                      <th>Unidad de Medida</th>
                      <!--<th>Existencia</th>
                      <th>Existencia CDPT</th>-->
                      <!--<th>INNER</th>
                      <th>MASTER</th>-->
                      <th>Promedio Mensual de Compra</th>
                      <th>Días Solicitados de inventario</th>
                      <th>Precio Lista</th>
                      <th>Precio Descuento</th>
                      <th>Importe Total</th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(item, i) in cart" :key="i" style="font-size: 12px;">
                      <td >
                          <v-img :src="item.image_url" v-if="item.image_url != ''" width="100"
                              @click="seeImageDetail(item)" class="imageCarItem" />

                          <v-img src="img/img-default.svg" v-if="item.image_url == ''" width="100"
                              @click="seeImageDetail(item)" class="imageCarItem" />
                      </td>
                      <td style="text-align: start;">

                          <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                  <v-icon :class="item.validacion" v-bind="attrs" v-on="on">mdi-alert-circle
                                  </v-icon>
                              </template>
                              <span>{{ item.stock_label }}</span>
                          </v-tooltip>
                          <strong>{{ item.codigo_material }}</strong><br>
                          <strong>{{ item.nombre_material }}</strong><br>
                          <!--<span v-for="(item1, j) in item.array_doc" :key="j">
                              <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                      <a :href="item1.path" target="_blank">
                                          <v-icon v-bind="attrs" v-on="on" class="Parcial">mdi-folder</v-icon>
                                      </a>
                                  </template><span>{{ item1.name }}</span>
                              </v-tooltip>
                          </span>-->
                          <span>
                            <a @click="getFileDownload(item.codigo_material)" target="_blank">
                              <v-icon v-on="on" class="Parcial">mdi-folder</v-icon>
                            </a>
                          </span>
                          

                          <v-tooltip bottom v-if="item.PNC != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="success"
                                      style="font-size:10px;">PNC</v-chip>
                              </template><span>PNC</span>
                          </v-tooltip>

                         <!--<v-tooltip bottom v-if="item.DM != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="success" style="font-size:10px;">DM</v-chip>
                              </template><span>DM</span>
                          </v-tooltip>-->

                          <!--<v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary" @click="descuentosInfo(item)" style="font-size:10px;">DES
                                  </v-chip>
                              </template><span>Ver Descuentos</span>
                          </v-tooltip> -->

                          <v-tooltip bottom v-if="item.ZKVL != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary"
                                      style="font-size:10px;">ZKVL</v-chip>
                              </template><span>Des. Volumen= {{ seePorcent(item.ZKVL) }}%</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.ZKRF != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary"
                                      style="font-size:10px;">ZKRF</v-chip>
                              </template><span>Des. Rengl. Famila= {{ seePorcent(item.ZKRF) }}%</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.ZKRG != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary"
                                      style="font-size:10px;">ZKRG</v-chip>
                              </template><span>Des Rengl. Material={{ seePorcent(item.ZKRG) }}%</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.ZK14 != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary"
                                      style="font-size:10px;">ZK14</v-chip>
                              </template><span>Descto. Promocion={{ seePorcent(item.ZK14) }}%</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.ZK25 != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary"
                                      style="font-size:10px;">ZK25</v-chip>
                              </template><span>Descto. Cte /Mat.={{ seePorcent(item.ZK25) }}%</span>
                          </v-tooltip>
                          
                          <v-tooltip bottom v-if="item.ZK69 != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary"
                                      style="font-size:10px;">ZK69</v-chip>
                              </template><span>Descto. Cte/Gpo.Mat4. ={{ seePorcent(item.ZK69) }}%</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.ZK71 != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary"
                                      style="font-size:10px;">ZK71</v-chip>
                              </template><span>Dcto.OR.CA.GCTE.GMAT. ={{ seePorcent(item.ZK71) }}%</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.MVGR5_POR != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="error"
                                      style="font-size:10px;">{{ item.MVGR5 }}
                                  </v-chip>
                              </template><span>{{ item.MVGR5_POR }} %</span>
                          </v-tooltip>
                      </td>
                      <td style="width:15px;">
                          {{ item.material_type }}
                      </td>
                      <td style="width:90px;">
                          <v-text-field v-model="item.u_confirm"
                              @keydown.enter.prevent="getMaterialChange(item, item.u_confirm, i)">
                          </v-text-field>
                          <br/>
                          <strong v-if="item.recordatorios != 0" style="font-size: 12px">
                              BACKORDER {{ item.recordatorios}}
                          </strong>
                      </td>
                      <td>{{item.empaque}}</td>
                      <td style="width:15px;">
                          {{ item.unidad_medida }}
                      </td>
                      <!--<td> {{ format(item.existencia) }}</td>
                      <td> {{ format(item.existencia_cdpt) }}</td>-->
                      <!--<td>
                          <p class="pa-lg-2" style="font-size: 12px">

                              <v-icon slot="prepend" color="green" @click="materialChageMinus(item, item.INN, i)">
                                  mdi-minus
                              </v-icon>
                              <v-icon slot="append" color="red" @click="materialChagePlus(item, item.INN, i)">
                                  mdi-plus
                              </v-icon>
                              <br>
                              <button class="btn INN">{{ item.INN }}</button>
                              
                          </p>
                      </td>
                      <td v-if="item.MST != ''">
                          <p class="pa-lg-2" style="font-size: 12px">

                              <v-icon slot="prepend" color="green" @click="materialChageMinus(item, item.MST, i)">
                                  mdi-minus
                              </v-icon>
                              <v-icon slot="append" color="red" @click="materialChagePlus(item, item.MST, i)">
                                  mdi-plus
                              </v-icon>
                              <br>
                              <button class="btn MST">{{ item.MST }}</button>
                          </p>
                      </td>
                     <td v-if="item.MST == ''"></td>-->

                      <td style="width:15px;">
                          {{ item.PMCOM }}
                      </td>
                      <td style="width:15px;">
                          {{ item.DSINV }}
                      </td>
                      <td>
                          $ {{ item.precio_lista }}
                      </td>
                      <td>
                        $ {{parseFloat(item.precio_con_descuento).toFixed(2)}}
                          <!--<p class="pa-lg-2" style="font-size: 12px">
                            <table>
                            <tbody>
                              <tr>
                                
                                   <td v-if="item.array_packing.length > 0">
                                        <v-badge v-if="ifMasterExist(item.array_packing) == true" :content="totalSumMater(item.array_packing)"  :color="getColorTagMaster(item.array_packing)">$ {{ getPriceMaster(item.array_packing)}}
                                        </v-badge>
                                         <br>
                                          <v-badge class="mt-3" v-if="ifInnerExist(item.array_packing)" :content="totalSumInner(item.array_packing)" :color="getColorTagInner(item.array_packing)">$ {{ getPriceInner(item.array_packing)}}
                                          </v-badge>
                                      </td>
                                      <td v-else>
                                        <span>$ {{parseFloat(item.precio_con_descuento).toFixed(2)}}</span>
                                      </td>
                                  </tr>
                            </tbody>
                        </table>
                          </p>-->
                      </td>
                      <td>
                          <strong>$ {{
                                  formatPrice(item.importe_producto)
                          }} </strong>
                      </td>
                      <td>
                          <v-btn style="margin-left:10px;" dark rounded color="red darken-2" elevation="2" small
                              @click="deleteItem(i, item)">
                              <v-icon style="color: #fff !important">mdi-delete</v-icon>
                          </v-btn>
                      </td>
                  </tr>
              </tbody>
          <!--</table>-->
          </v-simple-table>
        </v-col>
      </v-row>

      <!-- Dialog loading -->
      <v-row justify="center">
          <v-dialog v-model="dialogCatalogo" hide-overlay width="600" transition="dialog-bottom-transition"
              justify="center">
              <v-card justify="center" v-if="itemSelect">
                  <div class="text-ped" style="text-align: center;">
                      Estadistica de Producto
                  </div>
                  <v-avatar class="ma-3" size="100" tile>
                      <v-img :src="itemSelect.image_url" v-if="itemSelect.image_url != ''" width="100"
                          class="imageCarItem" />
                      <v-img src="img/img-default.svg" v-if="itemSelect.image_url == ''" width="100"
                          class="imageCarItem" />

                  </v-avatar>
                  <v-card-text>
                      <strong>Material: {{ itemSelect.codigo_material }} -
                          {{ itemSelect.nombre_material }}</strong><br>
                      <table class="table">
                          <thead>
                              <tr>
                                  <th>Jul22</th>
                                  <th>Ago22</th>
                                  <th>Sep22</th>
                                  <th>Oct22</th>
                                  <th>Nov22</th>
                                  <th>Dic22</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>{{ product_statistics.MONT6 }}</td>
                                  <td>{{ product_statistics.MONT5 }}</td>
                                  <td>{{ product_statistics.MONT4 }}</td>
                                  <td>{{ product_statistics.MONT3 }}</td>
                                  <td>{{ product_statistics.MONT2 }}</td>
                                  <td>{{ product_statistics.MONT1 }}</td>
                              </tr>
                          </tbody>
                      </table>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                      <v-btn text @click="dialogCatalogo = false">Cerrar</v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
      </v-row>
      <!-- /. Dialog -->
      <!-- Dialog loading -->
      <v-row justify="center">
          <v-dialog v-model="dialogDescuentos" hide-overlay width="600" transition="dialog-bottom-transition"
              justify="center">
              <v-card justify="center" v-if="itemSelect">
                  <div class="text-ped" style="text-align: center;">
                      DESCUENTOS
                  </div>
                  <div class="col-12 row">
                      <div class="col-6">
                          <v-avatar class="ma-3" size="200" tile>
                              <v-img :src="itemSelect.image_url" v-if="itemSelect.image_url != ''" width="100"
                                  class="imageCarItem" />
                              <v-img src="img/img-default.svg" v-if="itemSelect.image_url == ''" width="100"
                                  class="imageCarItem" />
                          </v-avatar>
                      </div>
                      <div class="col-6">
                          <v-card-text>
                              <strong>{{ itemSelect.codigo_material }} -
                                  {{ itemSelect.nombre_material }}</strong><br>
                              <ul style="text-align: initial;">
                                  <li>
                                      ZKVL = <strong>{{ seePorcent(itemSelect.ZKVL) }} %</strong>
                                  </li>
                                  <li>
                                      ZKRF = <strong>{{ seePorcent(itemSelect.ZKRF) }} %</strong>
                                  </li>
                                  <li>
                                      ZKRG = <strong>{{ seePorcent(itemSelect.ZKRG) }} %</strong>
                                  </li>
                              </ul>
                          </v-card-text>
                      </div>
                  </div>
                  <v-card-actions class="justify-end">
                      <v-btn text @click="dialogDescuentos = false">Cerrar</v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
      </v-row>
      <!-- /. Dialog -->

  </div>
</template>
<script>
import axios from "@/plugins/axios";
import { getAllProductFiles, getProductMainImage } from "@/repositories/ProductFiles";
import { mapGetters } from "vuex";
export default {
name: "ShoppingCart",
components: {},
computed: {
  ...mapGetters({
    user: "auth/user",
  }),
},
props: {
  warnTotal: {
    type: Function,
    default: null,
  },
  capturedMaterialItem: {
    type: Object,
    default: null,
  },
  warnError: {
    type: Function,
    default: null,
  },
  warnDialogLoading: {
    type: Function,
    default: null,
  },
  warnCart: {
    type: Function,
    default: null,
  },
  capturedIdOrder: {
    type: Number,
    default: null,
  },
  catchErrorConnection:{
      type: Function,
      default: null,
    },
  capturedDocType: {
    type: String,
    default: null,
  },
},
data: () => ({
  cart: [],
  subtotal: 0,
  iva: 0,
  total: 0,
  total2: 0,
  dialogLoading: false,
  dialogCatalogo: false,
  itemSelect: {},
  product_statistics: {},
  dialogDescuentos: false,
  order_id: null,
  ImageUrl:null,
  errormsg: false,
  doctype: null,
}),
async mounted() {
  console.log("This cart"+this.cart);
},
methods: {
 ifInnerExist(item){
   
    var passing = false;
    if(item.length === 0){
     passing = false;
    }else{
      item.forEach(function(element) {
      if (element[1] == 'INN') {
          passing = true;
      }
  });
  console.log(passing);
  return passing;
    }
    

  },
  ifMasterExist(item){

    var passing = false;
    if(item.length == 0){
      passing = false;
    }else{
      item.forEach(function(element) {
      if (element[1] == 'MST') {
          passing = true;
      }
  });
  console.log(passing);
  return passing;
    } 
    
  },
  totalSumInner(item){
    let sum = 0;
    for (let index = 0, len = item.length; index < len; ++index) {
      const element = item[index];
      if(element[1] == "INN"){
        sum += element[5];
      }
       
   }
  return sum;
  },
  totalSumMater(item){
    let sum = 0;
    for (let index = 0, len = item.length; index < len; ++index) {
      const element = item[index];
      if(element[1] == "MST"){
        sum += element[5];
      }
       
   }
  return sum;
  },
  getColorTagInner(item){
    
    for (let index = 0, len = item.length; index < len; ++index) {
     const element = item[index];
     if(element[1] == "INN"){
      return element[1];
     }
   }
  },
  getColorTagMaster(item){
    
    for (let index = 0, len = item.length; index < len; ++index) {
     const element = item[index];
     if(element[1] == "MST"){
      return element[1];
     }
   }
  },
  getPriceInner(item){
    
    for (let index = 0, len = item.length; index < len; ++index) {
     const element = item[index];
     if(element[1] == "INN"){
      return element[4];
     }
   }
  },
  getPriceMaster(item){
    
    for (let index = 0, len = item.length; index < len; ++index) {
     const element = item[index];
     if(element[1] == "MST"){
      return element[4];
     }
   }
  },
  formatPrice(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  format(value) {
      let result = Number(value).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  seePorcent(value) {
    return parseFloat(value * 100).toFixed(2);
  },
  deleteItem(index, item) {
    this.cart.splice(index, 1);
    this.validacionCredicticia();
    this.warnCart(this.cart);
    this.deleteMaterialOrder(item);
  },
  validacionCredicticia() {
    this.subtotal = 0;
    //this.iva = 0;
    //this.total = 0;
    for (let index = 0; index < this.cart.length; index++) {
      const element = this.cart[index];
      var importe_producto = element["importe_producto"];
      this.subtotal += parseFloat(importe_producto);

    } //end for
    console.log("importe:"+this.subtotal);
    //this.iva = this.subtotal * 0.16;
    //this.total = this.subtotal + this.iva;
    //this.subtotal = this.subtotal.toFixed(2);
    //this.iva = this.iva.toFixed(2);
    //this.total = this.total.toFixed(2);
    this.warnTotal(this.cart);
  },
  materialChagePlus(item, x, i) {
    var cant = parseInt(item.u_confirm, 10) + x;
    this.getMaterialChange(item, cant, i);
  },
  materialChageMinus(item, x, i) {
    var cant = item.u_confirm - x;
    this.getMaterialChange(item, cant, i);
  },
  getMaterialChange(item, cant, i) {
    this.warnError(null);
    this.warnDialogLoading(true);
    this.dialogLoading = true;
    axios
      .post("get-material-info-pr", {
        code: item.codigo_material,
        units: cant,
        customer: this.user.custumer_code,
        VKORG: this.user.VKORG,
        VTWEG: this.user.VTWEG,
        VKBUR: this.user.VKBUR,
      })
      .then((response) => {
        this.cart[i] = response.data;
        
       this.validacionCredicticia();
       this.warnCart(this.cart);
       this.updateMaterialOrder(response.data);
       this.getImages();

      })
      .catch((error) => {
        console.log("Error:"+error.response.data[0]);
        this.warnError(error.response.data[0]);
      })
      .finally(
        () => (this.warnDialogLoading(false), (this.dialogLoading = false))
      );
  },
  seeImageDetail(item) {
    this.dialogCatalogo = true;
    this.itemSelect = item;
    this.product_statistics = {};
    axios
      .post("productStatistics", {
        customer: this.user.custumer_code,
        material_code: this.itemSelect.codigo_material,
      })
      .then((response) => {
        this.product_statistics = response.data;
      })
      .catch((error) => {
        this.warnError(error);
      })
      .finally(() => (this.dialogLoading = false));
  },
  descuentosInfo(item) {
    this.dialogDescuentos = true;
    this.itemSelect = item;
  },
  saveNewMaterialOrder(item) {
    axios
      .post("save-new-material-order-pr", {
        order_id: this.order_id,
        item_cart: item,
      })
      .then((response) => {
        console.log(response);
        this.getImge(item)
      })
      .catch((error) => {
        console.log(error);

      })
      .finally(() => (this.dialogLoading = false));
  },
  deleteMaterialOrder(item) {
    axios
      .post("delete-material-orde-pr", {
        order_id: this.order_id,
        item_cart: item,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => (this.dialogLoading = false));
  },
  updateMaterialOrder(item) {
    axios
      .post("update-material-order-pr", {
        order_id: this.order_id,
        item_cart: item,
        customer: this.user.custumer_code,
        VKORG: this.user.VKORG,
        VTWEG: this.user.VTWEG,
        VKBUR: this.user.VKBUR,
      })
      .then((response) => {
        console.log(response);
         this.getImge(item);
        //this.getImages();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => (this.dialogLoading = false));
  },
  getCartItems(id) {
    this.warnDialogLoading(true);
    axios
      .post("get-cart-items-pr", {
        id_order: id,
        customer: this.user.custumer_code,
        VKORG: this.user.VKORG,
        VTWEG: this.user.VTWEG,
        VKBUR: this.user.VKBUR,
        doctype: this.doctype,
      })
      .then((response) => {
        this.cart = response.data;
        
        this.validacionCredicticia();
        this.warnCart(this.cart);
        this.getImages();
        //this.errormsg = true;
        //this.catchErrorConnection(this.errormsg);
        
      })
      .catch((error) => {
        //alert(error);
           console.log(error);
          this.errormsg = true;
          this.catchErrorConnection(this.errormsg);
      })
      .finally(() => this.warnDialogLoading(false));
  },
  getImages() {
    this.cart.forEach((item, index) => {
      getProductMainImage(item.codigo_material).then(({ image }) => {
        this.cart[index].image_url = image.path;
      });
    });
  },
  async getImge(item) {
    const { image } = await getProductMainImage(item.codigo_material);
    
    let index = this.cart.findIndex(element => element.codigo_material == item.codigo_material);
    this.cart[index].image_url = image.path
  },

  async getFileDownload(materialCode) {
    const { files } = await getAllProductFiles(materialCode);

    if(files.length > 0){
      var fileURL = files[0].path
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'file.pdf');
      document.body.appendChild(fileLink);
      fileLink.click();
    }
    else {  
      this.warnError("El código ingresado no tiene archivos para descargar.");
    }
  },
},
watch: {
  capturedMaterialItem: function (val) {
    this.warnError(null);
    const exist = this.cart.some(
      (item) => item.codigo_material == val.codigo_material
    );
    if (exist) {
      this.warnError("El código ingresado ya está en el carrito.");
    } else {
      this.cart.unshift(val);
      this.validacionCredicticia();
      this.saveNewMaterialOrder(val);
      this.warnCart(this.cart);
    }
  },
  catchErrorConnection: function(val){
      this.errormsg = val;
  },
  capturedIdOrder: function (val) {
    this.order_id = val;
    this.$nextTick(function () {
      this.getCartItems(this.order_id);
    });
    
  },
  capturedDocType: function(val){
      this.doctype = val
    },
},
};
</script>
<style>
.Disponible {
color: #0c8a08;
}
</style>