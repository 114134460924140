<template>
  <v-container fluid fill-height style="background-color: #ddd">
    <v-row align="center" justify="center">
      <v-col col="12" md="8">
        <div v-if="hasError" class="alert alert-danger" role="alert">
          {{ message }}
        </div>

        <div v-if="messages.length > 0" class="alert alert-danger" role="alert">
          <div v-for="(m, index) in messages" :key="index">{{ m }}</div>
        </div>
            
        <v-card class="elevation-10 pa-5 pa-md-10 rounded-xl">
          <v-card-text class="mt-7">
            <iusa-logo lg/>
            <div>
              <h6 class="mt-5 mb-5">
                <v-icon color="black">mdi-key</v-icon>
                <strong> Volver a establecer contraseña </strong>
              </h6>
              
              <v-form v-if="!passwordUpdated" ref="formRef" @submit.prevent="submit" :disabled="updatingPassword">
                <v-row align="center" justify="center" class="text-left">
                  <v-col col="12" md="8">
                    <pasword-form v-model="passwordForm" hide-current-password :ref="inputsRef" />

                    <div class="d-flex justify-center">
                      <vue-recaptcha
                        v-if="!recaptchaInDevMode"
                        :sitekey="$googleSitekey"
                        @verify="onVerify"
                        @expired="onExpired"
                        class="g-recaptcha"
                      />
                    </div>

                    <v-btn
                      type="submit" 
                      color="#2fd410" 
                      class="text-capitalize white--text mt-4"
                      rounded
                      block 
                      :disabled="updatingPassword"
                    >
                      {{ updatingPassword ? "Actualizando..." : "Actualizar" }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <v-alert v-if="successMessage" type="success">
                <div>
                  {{ successMessage }}
                </div>
                <div class="mt-4">
                  <router-link to='/' class="font-weight-bold success--text">Ir a login.</router-link>
                </div>
              </v-alert>

              <help-banner class="mt-7"/>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { mapState, mapActions, mapMutations } from 'vuex';

import PaswordForm from '@/components/forms/PasswordForm';
import IusaLogo from '@/components/IusaLogo';
import HelpBanner from '@/components/HelpBanner';

export default {
  name: "RecoverPassword",

  components: {
    VueRecaptcha,
    PaswordForm,
    IusaLogo,
    HelpBanner
  },

  data() {
    return {
      token: '',
      tokenEmail: '',
    };
  },

  computed: {
    ...mapState('updatePassword', [
      'passwordForm',
      'updatingPassword',
      'passwordUpdated',
      'hasError',
      'message',
      'messages',
      'successMessage'
    ]),
  },

  created() {
    const { token, tokenM } = this.$route.query;

    if (!token || !tokenM)
      this.$router.replace('/');
    else {
      this.token = token;
      this.tokenEmail = tokenM;
    }
  },

  methods: {
    ...mapActions('updatePassword', ['passwordReset']),
    ...mapMutations('updatePassword', ['SET_VALID_FORM']),

    async validate() {
      if (this.$refs.formRef) {
        const isValid = await this.$refs.formRef.validate();
        return isValid;
      }
      return false;
    },

    async submit() {
      const isValid = await this.validate();
      if (!isValid) return;

      this.SET_VALID_FORM(true);
      this.passwordReset({ token: this.token, tokenM: this.tokenEmail });
    },
 },
};
</script>
