var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"550","min-width":"550","persistent":"","scrollable":""},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c('v-card',[_c('div',{staticClass:"w-full bg-black d-flex justify-content-between align-center px-4"},[_c('span',{staticClass:"text-white d-flex align-center font-weight-bold"},[_vm._v("Backorder")]),_c('v-btn',{staticStyle:{},attrs:{"icon":"","small":""},on:{"click":function($event){_vm.isModalOpen = false}}},[_c('v-icon',{staticClass:"text-white font-size-8"},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"w-9 bg-white d-flex flex-wrap"},[_c('h4',{staticClass:"w-full mt-4 font-weight-bold",staticStyle:{"color":"grey"}},[_vm._v(" CONFIRMAR BACKORDER ")]),_c('span',{staticClass:"solid_line"}),_c('span',{staticClass:"text-title-bk font-weight-bold mt-3 mb-5"},[_vm._v("Seleccione los backorders confirmados")]),_vm._l((_vm.cart),function(item,i){return _c('result-item-backorder',{key:i.codigo_material,staticClass:"mr-5 ml-5",attrs:{"item":{
          materialCode: item.codigo_material.slice(-6),
          name: item.nombre_material,
          sku: item.codigo_material.slice(-6),
          reminder: item.reminder_check,
          recordatorios: item.recordatorios,
          precio_lista: item.precio_lista,
        }}},[_c('div',{staticClass:"mr-1",staticStyle:{"width":"60px"}},[_c('img',{staticClass:"w-full",attrs:{"src":item.image_url,"alt":""}})])])})],2),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-card-actions',{staticClass:"justify-center"},[_c('v-spacer'),_c('v-btn',{staticClass:"button-backorder-text",on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v(" CANCELAR ")]),_c('v-btn',{staticClass:"button-backorder-text",attrs:{"loading":_vm.loading,"disabled":_vm.disabled || _vm.userIsBlockedByBackorders},on:{"click":function($event){return _vm.$emit('orderGenerateCredit')}}},[(_vm.disabled || _vm.userIsBlockedByBackorders)?_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Compra Bloqueada")]):_c('span',[_vm._v("CONFIRMAR PEDIDO")]),_c('span')])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }