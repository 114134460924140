<template>
  <div v-if="showComponent">
    <hr />
    <v-row>
      <v-col cols="12">
        <v-card-subtitle>
          <p>
            TOTAL DE LA FACTURACIÓN DEL TRIMESTRE ANTERIOR:
            <span class="font-weight-black">
              {{ formatPrice(rule?.TOTALM) }}
            </span>
          </p>

          <v-progress-linear
            :value="rulePercentage"
            color="#b00020"
            height="25"
            dark
          >
            <strong>{{ rulePercentage }} %</strong>
          </v-progress-linear>
          <v-row
            no-gutters
            class="mt-1"
          >
            <v-col
              cols="12"
              md="6"
              class="d-flex justify-center justify-md-start"
            >
              <div>
                <span class="text-xs text-center">
                  30% DEL MONTO MÁXIMO DE COMPRA DE CONDUCTOR:
                  <span class="font-weight-black">
                    {{ formatPrice(rule?.PORCENT) }}
                  </span>
                </span>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-flex flex-column"
            >
              <div class="text-center">
                <span class="text-xs">
                  MONTO RESTANTE DE COMPRA DE CONDUCTOR:
                  <span class="font-weight-black">
                    {{ formatPrice(rule?.SALDO) }}
                  </span>
                </span>
              </div>
            </v-col>
          </v-row>
        </v-card-subtitle>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { formatPrice } from '@/utilities/formatPrice';
  import Quoter from '@/core/models/quoter';

  import axios from '@/plugins/axios';

  export default {
    name: 'RestrictedMaterialPurchaseLimit',
    props: {
      doctype: { type: String },
      customerCode: { type: Number },
    },
    data: () => {
      return {
        rule: undefined,
      };
    },
    computed: {
      showComponent() {
        return ['PSDT', 'PSNW'].includes(this.doctype);
      },
      rulePercentage() {
        if (this.rule && this.rule.SALDO && this.rule.PORCENT) {
          const saldo = parseFloat(this.rule.SALDO);
          const porcent = parseFloat(this.rule.PORCENT);

          if (porcent === 0) return 0;

          return Math.abs(((saldo - porcent) / porcent) * 100).toFixed(0);
        }
        return 0;
      },
    },
    watch: {
      customerCode: {
        async handler(customerCode) {
          if (!customerCode) return;
          await this.getMaterialRule();
        },
        immediate: true,
      },
    },
    methods: {
      formatPrice(value) {
        return formatPrice(value);
      },
      async getMaterialRule() {
        const prevTrimesterTrim = Quoter.getPreviousQuarterCode();

        try {
          const { data } = await axios.post('/material-rules', {
            dateTrim: prevTrimesterTrim,
            customer: this.customerCode,
          });

          this.rule = data;
        } catch (error) {
          this.$toast.error('No se pudieron obtener las reglas de material');
        }
      },
    },
  };
</script>
