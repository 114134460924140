import axios from "@/plugins/axios";
import { getProductMainImage } from "@/repositories/ProductFiles";
import Vue from "vue";



export default {
  namespaced: true,
  state: {
    type: "CL", // Constante para IEL
    destinations: [],
    orderId: null,
    increase: 0,
    cart: [],
  },
  getters: {
    destinations: (state) => state.destinations,
    increase: (state) => state.increase,
    cart: (state) => state.cart,
    subtotal: (state) =>
    state.cart
      .reduce((a, b) => a + b.importe_producto, 0),
    //iva: (state) => state.iva,
    iva:  (state) =>
    state.cart
      .reduce((a, item) => {
        const iva = (item.MWST == 0 || item.MWST == 2 || item.MWST == 3 || item.MWST == 6) ?  (parseFloat(item.importe_producto) * 0):(parseFloat(item.importe_producto) * 0.16)
        return a + iva
      },0),
    //iva: (state, getters) => getters.subtotal * 0.16,
    total: (state, getters) => getters.subtotal + getters.iva,
    /*subtotal: (state) =>
      state.cart
        .map((item) => item.importe_producto)
        .reduce((a, b) => a + b, 0),
    iva: (state, getters) => getters.subtotal * 0.16,
    total: (state, getters) => getters.subtotal + getters.iva,*/
  },
  mutations: {
    UPDATE_STATE(state, payload) {
      state[payload.key] = payload.value;
    },
    SET_DESTINATIONS(state, data) {
      state.destinations = data;
    },
    SET_ORDER_ID(state, orderId) {
      state.orderId = orderId;
    },
    SET_CART(state, cart) {
      state.cart = cart;
    },
    ADD_ITEM(state, item) {
      state.cart.unshift(item);
    },
    DELETE_FROM_CART(state, code) {
      let index = state.cart.findIndex((item) => item.codigo_material == code);
      if (index >= 0) {
        state.cart.splice(index, 1);
        return true;
      }
      return false;
    },
    SET_IMAGE_ITEM(state, payload) {
      state.cart[payload.index].image_url = payload.path;
    },
    UPDATE_ITEM(state, payload) {
      let index = state.cart.findIndex(
         (item) => item.codigo_material == payload.codigo_material
       );
       Vue.set(state.cart, index,  payload);
     },
  },
  actions: {
    setOrderId({commit },orderId) {
      commit("SET_ORDER_ID", orderId);
    },
    async loadCustomerDestinations({ commit }, payload) {
      axios
        .post("destinyCustomer", {
          customer: payload.custumer_code,
          VKORG: payload.VKORG,
          VTWEG: payload.VTWEG,
          VKBUR: payload.VKBUR,
        })
        .then((response) => {
          commit("SET_DESTINATIONS", response.data);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }, 
    async loadQuoterOrder({ state, commit }, payload) {
      return axios
        .post("quoterOrder", {
          customer: payload.custumer_code,
          user_id: payload.user_id,
          type: state.type,
          nu_tender: payload.number_tender,
          channel: payload.VTWEG,
        })
        .then((response) => {
          commit("SET_ORDER_ID", response.data.order_id);
          this.quoter_type = response.data.quoter_type;
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async getMaterialInfo(_, payload) {
      return axios
        .post(`getMaterialInfoQuoter`, {
          code: payload.sku,
          units: payload.quantity,
          customer: payload.custumer_code,
          VKORG: payload.VKORG,
          VTWEG: payload.VTWEG,
          VKBUR: payload.VKBUR,
          doctype: payload.order_type,
        })
        .then((response) => response.data)
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async loadCartItems({ state, commit, dispatch }, payload) {
      console.log("Loading Cart Items into store");
      return axios
        .post("getCartQuoterItems", {
          id_order: payload?.order_id? payload.order_id:state.orderId,
          customer: payload.custumer_code,
          VKORG: payload.VKORG,
          VTWEG: payload.VTWEG,
          VKBUR: payload.VKBUR,
          doctype: payload.doctype,
          order_type: "PSDT"
        })
        .then((response) => {
          console.log("Cart Loaded", response.data);
          commit("SET_CART", response.data);
          dispatch("loadCartImages");
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async saveNewMaterialQuoter({ commit }, payload) {
      return axios
        .post(`saveNewMaterialQuoter`, {
          order_id: payload.order_id,
          item_cart: payload.item,
          percent: payload.quoter_type,
        })
        .then((response) => {
          commit("ADD_ITEM", payload.item);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async deleteMaterialOrder({ commit }, { order_id, item }) {
      return axios
        .post("deleteMaterialQuoter", {
          order_id: order_id,
          item_cart: item,
        })
        .then((response) => {
          console.log("Action finished:", response.data);
          console.log("item", item);
          commit("DELETE_FROM_CART", item.codigo_material);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    loadCartImages({ state, commit }) {
      state.cart.forEach((item, index) => {
        getProductMainImage(item.codigo_material).then(({ image }) => {
          commit('SET_IMAGE_ITEM', { index, path: image.path });
        });
      });
    },

    async getImageUrl(_, item) {
      const { image } = await getProductMainImage(item.codigo_material);
      return image.path || null;
    },

    async saveQuoterOrder({ state }, payload) {
      return axios
        .post("saveQuoterCart", {
          order_id: state.orderId,
          quoter_folio: payload.orderFolio,
          validation: payload.start_date,
          nu_tender: payload.nu_tender,
          quoter_type: payload.quoter_type,
          percent: state.increase,
          customer_customer: payload.customer_customer,
          customer_seller: payload.customer_seller,
          doctype: payload.orderType,
          destiny: payload?.destiny
        })
        .then((response) => response.data)
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async updateQuoterOrderIncrease({ state, commit, dispatch }, payload) {
      return axios
        .post("getCartQuoterIncrease", {
          id_order: state.orderId,
          customer: payload.customer_code,
          VKORG: payload.VKORG,
          VTWEG: payload.VTWEG,
          VKBUR: payload.VKBUR,
          increase: state.increase,
        })
        .then((response) => {
          commit("SET_CART", response.data);
          dispatch("loadCartImages");
          dispatch("updateMaterialQuoterIncrease", {
            customer_code: payload.customer_code,
            VKORG: payload.VKORG,
            VTWEG: payload.VTWEG,
            VKBUR: payload.VKBUR,
          });
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async updateMaterialQuoterIncrease({ state }, payload) {
      try {
        await axios.post("updateMaterialQuoterIncrease", {
          order_id: state.orderId,
          item_cart: state.cart,
          customer: payload.customer_customer,
          VKORG: payload.VKORG,
          VTWEG: payload.VTWEG,
          VKBUR: payload.VKBUR,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async createPDF({ state, rootState }, payload) {
      return axios
        .post(
          "quoterPdf",
          {
            order_id: state.orderId,
            customer: payload.customer_customer,
            VKORG: rootState.auth.user.VKORG,
            VTWEG: rootState.auth.user.VTWEG,
            VKBUR: rootState.auth.user.VKBUR,
            quoter_type: state.type,
            customer_customer: payload.customer_customer,
            customer_seller: payload.customer_seller,
          },
          { responseType: "blob" }
        )
        .then((response) => response.data)
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async generateOrder({ state, getters, rootState }, payload) {
      return axios
        .post(rootState.user.VTWEG == "PR" ? "createOrderPR" : "createOrder", {
          order_id: state.orderId,
          customer: payload.customer,
          user_id: rootState.auth.user.id,
          type: payload.type,
          destiny: payload.destiny,
          total: getters.total,
          doctype: payload.orderType,
        })
        .then((response) => response.data)
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async updateMaterialQuantity({ commit, dispatch, rootState }, payload) {
      return axios
        .post("getMaterialInfoQuoterDiscount", {
          code: payload.codigo_material,
          units: payload.quantity,
          customer: payload.custumer_code,
          VKORG: rootState.auth.user.VKORG,
          VTWEG: rootState.auth.user.VTWEG,
          VKBUR: rootState.auth.user.VKBUR,
          discount_price: payload.precio_con_descuento,
        })
        .then((response) => {
          const data = response.data;
          commit("UPDATE_ITEM", data);
          return dispatch("updateMaterialOrder", data);
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async updateMaterialOrder({ state, rootState }, item) {
      return axios
        .post("updateMaterialQuoter", {
          order_id: state.orderId,
          item_cart: item,
          customer: rootState.auth.user.custumer_code,
          VKORG: rootState.auth.user.VKORG,
          VTWEG: rootState.auth.user.VTWEG,
          VKBUR: rootState.auth.user.VKBUR,
        })
        .then((response) => response.data)
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },

    async turnOrderIntoQuoter({ commit }, payload) {
      return axios
        .post("turnOrderIntoQuoter", {
          customer: payload.custumer_code,
          order_id: payload.order_id,
          user_id: payload.user_id,
          cart: payload.cart,
          type: payload.type,
          doctype: payload.doctype,
          channel: payload.channel,
          total: payload.total,
        })
        .then((response) => {

          commit("SET_ORDER_ID", response.data);
          this.orderId = response.data.order_id;
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
  },
};
