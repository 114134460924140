<template>
  <div>
    <template v-if="loading">
      <v-skeleton-loader  elevation="2" type="table-heading, article, table-tfoot" ></v-skeleton-loader>
    </template>
    <v-simple-table v-else class=" my-table pb-0 mb-0" dense>
              <template v-slot:default>
                <tbody  class="custom-table-bordered"> 
                <tr>
                  <td class="custom-td-header">% Pagos en línea</td>
                  <td class="custom-td-header">Mes</td>
                  <td v-for="(date, index) in months" :key="index" class="custom-td-header">{{date.date}}</td> 
                </tr>
                <tr>
                  <td :rowspan="rowspanValue"><b class="text-black">
                    <v-tooltip bottom class="align-self-center">
                          <template v-slot:activator="{ on, attrs }">
                            <span  v-bind="attrs"  v-on="on">{{formatFloat(billing.PPAGL,2)}}%  </span>
                          </template>
                          <span>Montos de pago del mes: <b>{{formatNumber(billing.MONPAGPER,"$",0)}}</b> <br>
                                Montos de pago vía IEL: <b>{{formatNumber(billing.MONPAGIEL,"$",0)}}</b> </span>
                    </v-tooltip>
                  </b> 
                  </td>
                </tr>
                <tr v-for="(item, index) in billing" :key="index">
                  <td v-html="item.TITULO" class="custom-td-header"></td>
                  <td
                    v-for="(month, monthIndex) in monthKeys"
                    :key="month"
                    :class="setItemClass(index, item[month], item.MIN, item.MAX, monthIndex === billing.length)"
                  >
                    <template v-if="hasLink(index)">
                      <a @click="actionLink(index, months[monthIndex])">
                        {{ setFormattedValue(index, month, item) }}
                      </a>
                    </template>
                    <template v-else>
                      {{ setFormattedValue(index, month, item) }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="font-weight-bold custom-td-header " >
                    <v-progress-circular v-if="loadingAvgDelivery" indeterminate color="primary" /> Dias habiles tiempo de ciclo de venta 
                  </td>
                  <template v-if="!loadingAvgDelivery">
                      <template v-if="isTrim">
                      <td class="custom-td-header font-weight-bold" v-for="(item,index) in avgDelivery" :key="index">
                        Trim {{item.SPMON}}
                      </td>
                    </template>
                    <template v-else>
                      <td   v-for="(item,index) in avgDelivery" :key="index">{{ number_format(item.AVGORDERS_DAYS,false) }} </td>
                    </template>
                  </template>

                </tr> 
                <tr>
                </tr>
                <tr>
                  <td colspan="2">
                    <div class="d-inline-flex">
                      <v-checkbox class="ml-2" :disabled="loadingAvgDelivery" v-model="isTrim" dense>
                        <template v-slot:label>
                          <div class="text-black">Trimestral</div>
                        </template>
                      </v-checkbox>
                      <v-checkbox class="ml-2" :disabled="loadingAvgDelivery" v-model="monthly" dense>
                        <template v-slot:label>
                          <div class="text-black">Mensual</div>
                        </template>
                      </v-checkbox>
                    </div>
                  </td>
                  <template v-if="isTrim && !loadingAvgDelivery">
                  <td v-for="(item,index) in avgDelivery" :key="index">{{ number_format(item.AVGORDERS_DAYS,false) }}</td>
                  </template>
                </tr>
                </tbody>      
              </template>
    </v-simple-table>  

    <new-products 
    v-model="newProductsModal" 
    :period="newProductsPeriod" 
    :chnl="chnl"
    :regionalManager="regionalManager"
    />
    
    <customers-payment-i-e-l 
       :managerCode="0"
       :employeeChannel = "chnl" 
       v-model="showPaymentsViaIEL"
       :period = "ShowPaymentsViaIELPeriod" 
      :regionalManager="regionalManager"
       />

    </div>
</template>

<script>  
import axios from '@/plugins/axios'
import numbersFormats from '@/mixins/numbersFormats';
import dates from '@/mixins/dates'; 
import service from '@/mixins/service';
import NewProducts from '@/views/diary/director/modals/NewProducts.vue'
import CustomersPaymentIEL from '@/components/modals/manager/CustomersPaymentIEL.vue'

export default({
  name:'Orders',
  components:{ 
    NewProducts,
    CustomersPaymentIEL
},
mixins:[numbersFormats,service,dates],
props: {
    chnl: { 
      required:true,
      String
    },
    regionalManager:{
      required:true,
    }
  },
  computed: {
    rowspanValue() {
      let value;
      if (typeof this.$hasAnyRole === 'function' && this.$hasAnyRole(['DirectorNoCommercial', 'SubDirectorPRNoCommercial', 'SubDirectorDTNoCommercial'])) {
        value = '9';
      }
      else if (this.regionalManager) {
        value = '10';
      }
      else {
        value = '9';
      }
  
      return value;
    }
  },
  data: () => ({ 
  showModal: false, 
  monthly: false, 
  trim: true, 
  paymentHistory: false, 
  customerPerWeek:false,
  loading:true,
  billing:[],
  months:[],
  isTrim:true,
  avgDelivery:[],
  loadingAvgDelivery:false,
  newProductsModal:false,
  newProductsPeriod:"", 
  showPaymentsViaIEL:false,
  ShowPaymentsViaIELPeriod:"",
  monthsIndex: ['Actual', 'Anterior', '2 Meses', '3 Meses', '4 Meses', '5 Meses', '6 Meses'],
    monthKeys: ['MES6M','MES5M','MES4M','MES3M','MES2M','MESANT','MESACT'], // Claves de meses
    percentageKeys: ['PMESACT', 'PMESANT', 'PMES2M', 'PMES3M', 'PMES4M', 'PMES5M', 'PMES6M'], // Claves de porcentajes

}),
methods:{
// Devuelve el formato del valor con o sin porcentaje
  setFormattedValue(index, monthKey, item) {
    const value = item[monthKey];
    const percentageKey = this.getPercentageKey(monthKey);
    const percentage = item[percentageKey];
    return this.setFormatItem(index, value, percentage);
  },
// Devuelve la clave del porcentaje correspondiente a un mes
  getPercentageKey(monthKey) {
    const index = this.monthKeys.indexOf(monthKey);
    return this.percentageKeys[index] || null;
  },
hasLink(index){
  return index == 5;
},
actionLink(index,period){ 
  switch (index) {
    case 5 : this.showNewProducts(period); break; 
    case 8 : this.showCustomersViaIEl(period); break; 
  }
  },
  showCustomersViaIEl(date){ 
    this.ShowPaymentsViaIELPeriod = this.periodFormat(date); 
    this.showPaymentsViaIEL =true;
  },
  showNewProducts(period){ 
    this.newProductsPeriod = this.periodFormat(period);
    this.newProductsModal = true;
  },
  setItemClass(index,value,min,max,isCurrent=false){
    switch (true) {
      case index < 4 || index > 8:
        if (value == max && value!=0 && !isCurrent) {
            return 'text-success font-weight-bold';
        }
        if (value ==min && !isCurrent) {
          return 'text-danger font-weight-bold'
        }
      break;
      case index == 5:
        return 'text-secondary text-decoration-underline';
      case index == 6:
        return 'text-secondary';
    }
    return '' 
  },
  async getBilling() {
this.loading = true;

try {
  var params = {
    channel: this.chnl,
  };
  if (this.regionalManager) params.rgManager = this.regionalManager;
  const response = await axios.get("/daily-director/director-billing", {
    params: params
  });

  if (this.$hasAnyRole(['DirectorNoCommercial', 'SubDirectorPRNoCommercial', 'SubDirectorDTNoCommercial'])) {
    this.billing = [...response.data.BILLING.filter(item => item.TITULO !== "Ingresos totales pagados")];
  } else {
    this.billing = response.data.BILLING;
  }

} catch (error) {
  this.error = "No se pudo obtener la información";
} finally {
  this.loading = false;
}
},
async avgDdaysForDelivery(){
    this.loadingAvgDelivery=true;
      try { 
        var params = {
          channel: this.chnl,
          isQuarter:this.isTrim,
        }
        if (this.regionalManager) params.rgManager = this.regionalManager;
        const response = await axios.post("/daily-director/avg-bdays-for-delivery",params,{
          cachable:true
        });
        this.avgDelivery = response.data;
      } catch (error) {
        this.error = "No se pudo obtener la información";
        this.loadingAvgDelivery=false;
      } 
      this.loadingAvgDelivery=false; 
    },
setFormatItem(index,item){
  switch (index) {
    case 0: case 1: case 2:  case 8:
      return this.formatNumber(item,"$",0);
    case 7:
      return `${this.formatFloat(item,2)}%` 
    case 5: case 6:
      return `${this.formatFloat(item,2)}%`;
    default:
      return item 
  }
},
  closeModal() {
    this.showModal = false;
  }, 
},
mounted(){
  this.getBilling();
  this.avgDdaysForDelivery();
},
created(){
  this.months = this.calcularUltimosMeses(7).reverse(); 
}, 
watch: {
  billing: {
  deep: true
},
  isTrim(value) {
    this.avgDdaysForDelivery();
  this.monthly = value ? false : true;
  },
  monthly(value) {
    this.isTrim = value ? false : true; 
  },
},


})
</script>