<template>
  <div>
    <v-simple-table class="mt-0 my-table" dense>
      <template v-slot:default>
        <thead class="table-heading">
          <tr>
            <th v-for="(item, index) in headers" :key="index"
              class="text-center text-dark custom-border-right pb-3 pt-2 pl-1 pr-1">
              <template v-if="item.col == 'CUSTOMERS'" >
                <div class="d-flex flex-column h-75">
                    <div class="d-flex justify-center mt-auto" style="width: 254px;">
                    <b v-html="item.title.replaceAll(' ', '&nbsp;')"></b>
                  </div>
                  <div class="d-flex justify-space-between small justify-center mt-auto">
                      <span class="w-25">  {{ months[0].short_name }} / {{ months[1].short_name }}</span>
                      <span class="w-25"> <b>Prom 3</b> </span>
                      <span class="w-25"> Dif </span>
                      <span class="w-25">{{ months[2]?.short_name }}</span>
                    </div>
                </div>
              </template>
              <div v-else class="d-flex justify-center flex-column pb-3">
                <b v-html="item.title.replaceAll(' ', '&nbsp;')"></b>
                <sort-by-button v-if="item.col" :data="managerList" :column="item.col" :isString="true"
                  @sortedData="managerList = $event" />
              </div>

            </th>
          </tr>
        </thead>
        <tbody class="custom-table-bordered" v-if="!loading">
          <tr class="text-body-2" v-for="(manager,index) in managerList" :key="index">
            <td>
              <div class="d-flex d-inline-block">
                <a href="#" @click="getCustomersLocation(manager.CODGER)">
                  <v-img class="img-fluid mt-n1" src="/img/icono_maps.svg" width="55px"></v-img>
                </a>
                <div class="align-self-center mr-2 ml-2 " @click="gotoManager(manager.CODGER)">
                  <a class="text-decoration-underline text-black"
                    v-html="capitalize( `${manager?.NAME1}`).replaceAll(' ','&nbsp;')" />&nbsp;({{manager?.BZIRK}})
                  <br>
                  <div class="text-muted text-caption text-left ">
                    {{ manager.CODGER }}
                  </div>
                </div>
              </div>
            </td>
            <td><b class="small">{{manager?.CTESIS}}/ {{manager?.CTEACT}} <br>
                {{manager?.CTENU1}}/{{manager?.CTENU3}}/{{manager?.CTENU6}} </b></td>
            <td class="pl-1 pr-1"> 
                  <div class="d-flex justify-space-between small justify-center">
                    <span class="w-25"> {{number_format(manager.CTEACM)}}&nbsp;/&nbsp;{{number_format(manager.CTEACMA)}}</span>
                    <span class="w-25">  {{ number_format(manager.AVERAGE3) }}</span>
                    <span class="w-25"> {{ number_format(manager.CTEAC2M - manager.AVERAGE3)}} </span>
                    <span class="w-25">  {{number_format(manager.CTEAC2M)}}</span>
                  </div> 
            </td>
            <td>{{number_format(manager.PCTEPED,false)}}%</td>
            <td>${{number_format(manager.PREMES)}} </td>
            <td>${{number_format(manager.PEDMES,1)}}</td>
            <td>${{number_format(manager.FACMES,false)}}</td>
            <td>{{number_format(manager.FACVSPRE,false)}}%</td>
            <td>${{number_format(manager.PEDDANT,1)}}</td>
            <td style="border-width: 0px;"> <a @click="showCumulativeGrowthModal(manager.CODGER)"
                class="text-decoration-underline text-black">{{number_format(manager.CREACUFAC - 100,false)}}%</a></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="9">
              <v-progress-linear value="15" indeterminate />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <cumulative-growth-modal 
    title="Detalle de crecimiento constante de Gerencia" 
    v-model="commulativeGrowth"
    :period="filter.period" 
    :employee="managerSelected" 
    :role="filter.groupBy" 
    :employeeChannel="chnl" 
    />

    <payment-i-e-l-detail :managerCode="managerSelected" :employeeChannel="chnl" v-model="showPaymentsViaIEL"
      :period="period" />

    <customer-locations-map v-model="openMap" :type="typeCustomerLocation" :customers="customersLocation">
    </customer-locations-map>
  </div>
</template>
<script>  
import axios from '@/plugins/axios.js';
import MixinService from '@/mixins/service'
import MixinHelpers from '@/mixins/helpers';
import MixinDates from '@/mixins/dates';
import CumulativeGrowthModal from '@/views/diary/director/modals/CumulativeGrowthModal.vue';
import PaymentIELDetail from '@/components/modals/manager/PaymentIELDetail.vue'; 
import SortByButton from '@/components/SortByButton.vue';
import CustomerLocationsMap from '@/components/modals/customers_locations_map/CustomerLocationsMap.vue';

import moment from 'moment';
import { mapGetters } from "vuex";

export default({
    name:'ManagerList',
    mixins:[MixinService,MixinHelpers,MixinDates],
    props: { 
      chnl: { 
        required:true,
        String
      },
      regionalManager:{
        required:true,
      },
      filter:{
        required:true
      }
    },
    components:{
        CumulativeGrowthModal,
        PaymentIELDetail,
        SortByButton,
        CustomerLocationsMap
    },  
    data: () => ({
        valid: true, 
        showModal: false, 
        showPopulationInput: false,
        populationInput: "", 
        paymentHistory: false,
        customerSales: false,
        customersBuyWeekly: false,
        paymentInfo: false, 
        populationRules: [
        (v) => !!v || "Este campo es requerido",
        (v) =>
            (v && v.length <= 10) || "Este campo debe ser menor a 10 caracteres",
        ],
        loading:true,
        managerList:[],
        isOrderGrowth:false,
        isBillingGrowth:true,
        months:[], 
        period:[],   
        growthType:'',
        commulativeGrowth:false, 
        managerSelected:0,
        showPaymentsViaIEL:false,
        isRegional:false,  
        today: moment().format('YYYY-MM-DD'),
        customersLocation: [],
        typeCustomerLocation: 'byRegionalManager',
        openMap:false,
        group:"Gerencia Regional",
        currentMonth:'',       
  }),
    methods:{
      async getCustomersLocation(employeeNumber){
        this.setUserType();
        try{
          this.openMap = true;
          let response = await axios.get('/geolocation-map/coordinates-customers',{
            params:{
              inicio: this.today,
              fin: this.today,
              P_VTWEG: this.user.VTWEG,
              P_VKORG: this.user.VKORG,
              employee_number: employeeNumber,
              type: this.typeCustomerLocation
            }
          });
          this.customersLocation = response.data;
        }catch(error){
          this.openMap = false;
          console.log(error);
        }
      },
      actionMonth(index,col){
        switch (index) {
          case 2: this.showcustomersBuyWeekly(col); break;
          case 3: 
            this.period = this.periodFormat(this.months[col]); 
            this.showPaymentsViaIEL=true; 
          break; 
        }
      },
    showInfo(type) {
      this.showModal = true;
      switch (type) { 
        case 2: 
          this.paymentInfo = true;  
          break; 
      }
    },
    showCumulativeGrowthModal(manager)
      { 
          this.managerSelected=manager; 
          this.commulativeGrowth = true; 
      },
    async loadManagers(allManagers = false)
    {  
      this.managerList = [];
      this.loading=true;
      var params = { 
        'channel': this.chnl,
        'period':this.filter.period,
      } 
      if (this.regionalManager){//Los gerentes regionales se incluyen en params
        params.rgManager = this.regionalManager;
      }else { 
          if (allManagers) { //listar gerentes o subgerentes, se debe enviar como id de gerente, letra G
            params.rgManager = 'G'
          }
      }//todos los gerentes regionales, solo enviar el canal y periodo 
        try { 
          const response = await axios.get("/daily-director/managers", {
                params: params
              },
              {cachable:true}
            );
          this.managerList = response.data;
        } catch (error) {
          console.error(error)
            this.loading=false;
        } 
        this.loading=false;
    }, 
    sendManagerHome(code) {
      this.$setLocalStorageManager(code);
      this.$router.push({ name: "ManagerHome" });
    },
    sendRegionalManagerHome(code) {
      this.$setLocalStorageRegional(code);
      this.$router.push({ name: "DailyRegionalManager" });
    },
    sendSubDirectorHome(code) {
      this.$setLocalStorageRegional(code);
      this.$router.push({ name: "DailySubDirector" });
    },
    getnavRole(){
      switch (this.$route.meta.role) {
        case "Director":   
        case "IELDirector": 
        case "DirectorNoCommercial":   
          return "director";
        case "IELCommercialPR":   
        case "IELCommercialDT":   
        case "Subdirector":
        case "SubDirectorPRNoCommercial":
        case "SubDirectorDTNoCommercial":
          return "subDirector"  
        case "Regional": 
        case "RegionalManager":  
        case "IELRegManager": 
          return "regionalManager"; 
      } 
    },
    setUserType(){ 
      const role = this.getnavRole();
      switch (role) {
        case "director": 
        case "subDirector":  
          if (this.group == 'SubGerentes' || this.group == 'Gerentes'){
            this.typeCustomerLocation = 'byManager';
          }else{
            this.typeCustomerLocation = 'byRegionalManager';
          } 
          break;
        case "regionalManager":   
        this.typeCustomerLocation = 'byManager'; 
        break;
      }
    },
    gotoManager(managerCode){ 
      const role = this.getnavRole();
      switch (role) {
        case "director": 
        case "subDirector":  
          if (this.filter.groupBy == 3 || this.filter.groupBy == 2){
            this.sendManagerHome(managerCode)
          }else{
            this.sendRegionalManagerHome(managerCode)
          }
        break;
        case "regionalManager":   
        this.sendManagerHome(managerCode);  
        break; 
      } 
      },
    },
    computed:{ 
      headers() {
        return [
          { title: `${this.filter?.headerTitle}`, col: 'NAME1' },
          { title: `# de clientes <br> sistema / Activos  <br> Clientes nuevos <br> 1/3/6 meses` , col: '' },
          { title: `# de Clientes con compra` , col: 'CUSTOMERS' },
          { title: '% de clientes  <br> con pedidos', col: 'PCTEPED' },
          { title: `Presupuesto <br>de ${this.months[0].full_name}`, col: 'PREMES' },
          { title: `Pedidos de <br>${this.months[0].full_name}`, col: 'PEDMES' },
          { title: `Facturación <br>de ${this.months[0].full_name}`, col: 'FACMES' },
          { title: `Avance de <br> facturación <br> VS <br>ppto de ${this.months[0].full_name}`, col: 'FACVSPRE' },
          { title: 'Pedidos del <br>día anterior', col: 'PEDDANT' },
          { title: `Crec. Acum. <br>${this.months[1].date}`, col: 'CREACUFAC' },
        ];
      },
      ...mapGetters({ 
             user: "auth/user",
         }),
      getMonths(){ 
        var months = this.months;   
        return this.implode(this.arrayPluck(months.slice(0, 2),'short_name'),'/') 
      }, 
    },
    created(){
      this.months = this.calcularUltimosMeses(13);
      if (this.filter?.groupBy && this.filter?.period) {
        const groupBy = this.filter.groupBy;
        const allManagers = groupBy == 3 || groupBy == 2; //SubGerentes,Gerentes
       // this.currentMonth = this.months[0].full_name;
        this.loadManagers(allManagers);
      } 
    }, 
    watch:{
      openMap(){
        if(!this.openMap){
          this.customersLocation = [];
        }
      },
    filter(value){
      const groupBy = value.groupBy;
      const allManagers = groupBy == 3 || groupBy == 2; //SubGerentes,Gerentes
      this.currentMonth = this.removeTimeFromDate(this.filter.period,'Ym','MMMM'); 
      this.loadManagers(allManagers); 
    }
  },
})
</script>