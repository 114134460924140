<template>
  <v-container fluid class="white page__container--padding py-5">   

    <div class="d-flex align-center gap-2">
      <back-btn :to="{ name: 'PendingAuthorization' }" />
      <div class="outlined-card p-1">
        <icon-title text="ESTADO DE CUENTA DEL CLIENTE" icon="mdi-account-cash"/>
      </div>      
    </div>

    <!-- Datos del cliente -->
    <data-customer class="mt-5" :dataClient="customerData" :loader="loader"></data-customer>      
    
    <!-- Botonera -->  
    <v-row no-gutters class="mt-5">
      <v-col sm="12" md="6" lg="3" class="card-shadow d-flex pa-1">
        <search-input 
          v-model="search" 
          :hideDetails="true" 
          :placeholder="'Buscar documento'"
          class="m-2 w-100" />        
      </v-col>

      <v-col sm="12" md="6" lg="3" class="card-shadow d-flex justify-center align-items-center pa-2">
        <v-btn class="red-button rounded-pill button--small" @click="isOpenHistorical = true">
          Histórico estado de cuenta
        </v-btn>
      </v-col>

      <v-col sm="12" md="6" lg="3" class="card-shadow d-flex justify-center align-items-center pa-2">
        <v-btn :disabled="Object.keys(infoCustomer).length === 0" class="red-button rounded-pill button--small">
          <span @click="openModalEmail" class="mr-1">Enviar por correo</span>
          <v-icon small>mdi-email-outline</v-icon>          
        </v-btn> 
      </v-col>

      <v-col sm="12" md="6" lg="3" class="card-shadow">
        <div class="pt-2 mb-0">
          Total del pedido: 
          <span v-if="loadingOrders"><b>${{ totalOrdersAmount  | formatCurrency }}</b></span>
          <v-progress-circular v-else indeterminate color="primary"/>
        </div>    
        <div class="d-flex justify-center align-items-center pa-2 gap-2">          
          <v-btn :disabled="disabledBtn" class="green-button" @click="buttonAuthorization(true)">
            <v-icon small class="mr-1">mdi-check-bold</v-icon> Autorizar
          </v-btn>
          <v-btn :disabled="disabledBtn" class="red-button" @click="showReject = true">
            <v-icon small class="mr-1">mdi-close-thick</v-icon> Rechazar
          </v-btn>         
        </div>
      </v-col>          
    </v-row>  
    <!-- Fin Botonera --> 
    
    <title-header-table>
        <span>Movimientos al {{dateCurrent}}</span>
    </title-header-table>         
                            
    <v-data-table                        
        :headers="headers"
        :items="filteredItems"
        :search="search"                       
        class="elevation-2 table__desing-general table__desing-border table__movements--scroll"     
        :items-per-page="filteredItems.length"
        :mobile-breakpoint="null"
        :loading="loader"
        loading-text="Cargando... Por favor espere"
        :footer-props="footerProps"
        :options="dataTableOptions"> 

        <template v-slot:[`header.TIPO`]="{ header }"> 
            {{ header.text }}                       
            <v-select
              :items="allDocuments"
              label="Todos los Documentos"
              dense
              solo
              hide-details
              v-model="selectedTIPO" />                        
        </template>

        <template v-slot:foot>
            <tfoot>
                <tr>
                    <td :colspan="8" class="text-right foot-sub-total">
                        <b>Subtotales:</b>
                    </td>
                    <td class="foot-sub-total">
                        <b>${{sumToExpire | formatCurrency}}</b>
                    </td>
                    <td class="foot-sub-total">
                        <b style="color: red">${{sumExpired | formatCurrency}}</b>
                    </td>
                </tr>
                <tr>
                    <td colspan="13" class="text-right foot-total">
                        <b>Saldo total: ${{sumAmount | formatCurrency}}</b>
                    </td>
                </tr>
            </tfoot>
        </template>

        <template v-slot:item="{ item }">                        
            <row-table-credits 
                :invoice="item"
                @actionButton="validateInsertOrDelete(item)">
            </row-table-credits>
        </template>                    
    </v-data-table>      

    <ConfidentialityNotice />

    <!-- Send Email -->
    <send-invoice-by-email 
      v-model="showEmail" 
      :email="infoCustomer.SMTP_ADDR"
      :disabled="disabledSendEmail"
      @send="sendEmail"/>
    <!-- End Send Email -->

    <!-- Options Rejects -->
    <reason-reject 
      v-model="showReject"
      @send="(motive) => buttonAuthorization(false, motive)"/>
    <!-- End Options Rejects -->

    <!-- Histórico -->
    <v-dialog v-model="isOpenHistorical" width="90%">
      <modal-bar title="Historial estado de cuenta" @on-close="isOpenHistorical = false"/>
      <account-status-card :customerCode="customer.KUNNR" :customerGroup="0"/>
    </v-dialog>
    <!-- Fin Histórico -->
  </v-container>
</template>

<script>
import ConfidentialityNotice from "@/components/ConfidentialityNotice";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { jsPDF } from "jspdf";
import axios from "@/plugins/axios";
import SendInvoiceByEmail from "@/components/modals/SendInvoiceByEmail.vue";
import SearchInput from '@/components/SearchInput.vue';
import ReasonReject from "@/components/modals/ReasonReject.vue";
import DataCustomer from '@/components/DataCustomer.vue';
import TitleHeaderTable from '@/components/tables/TitleHeaderTable';
import AccountStatusCard from "@/components/AccountStatusCard.vue";
import RowTableCredits from "./components/RowTableCredits.vue";
import IconTitle from '@/components/IconTitle.vue';
import BackBtn from '@/components/BackBtn.vue';
import ModalBar from "@/components/modals/ModalBar.vue";

export default {
  components: { 
    ConfidentialityNotice, 
    SendInvoiceByEmail, 
    SearchInput, 
    ReasonReject,
    DataCustomer, 
    TitleHeaderTable, 
    AccountStatusCard, 
    RowTableCredits,
    IconTitle,
    BackBtn,
    ModalBar
  },
  data() {
    return {
      disabledBtn: false,
      showReject: false,
      infoCustomer: {},
      loader: true,
      showEmail: false,
      pdfFormData: null,
      search: '',
      disabledSendEmail: false,
      dateCurrent: moment().format('D [de] MMMM [del] YYYY HH:mm:ss a'), 
      isOpenHistorical: false,
      selectedTIPO: "",
      range: {
        startDate: null,
        endDate: moment().format('YYYY/MM/DD'), 
      },
      headers: [        
        { text: '# documento', value: 'BELNR', align:"center", sortable: false },
        { text: 'Orden de Compra', value: 'ORDCOM', align:"center", sortable: false },
        { text: 'Descargas', value: 'ver', align:"center", sortable: false },
        { text: 'Tipo de documento', value: 'TIPO', align:"center", sortable: false },
        { text: 'Fecha de factura', value: 'BUDAT', align:"center", sortable: false },
        { text: 'Fecha de vencimiento', value: 'FVENC', align:"center", sortable: false },
        { text: 'Días vencido', value: 'DVENC', align:"center", sortable: false },
        { text: 'Importe', value: 'DMBTR', align:"center", sortable: false },
        { text: 'Saldo sin vencer', value: 'por_vencer', align:"center", sortable: false },
        { text: 'Saldo Vencido', value: 'vencido', align:"center", sortable: false }
      ],
      items: [],
      dataTableOptions: {
        itemsPerPage: 15,
      },
      footerProps: {
        itemsPerPageOptions: [5, 10, 15, { text: "Todos", value: -1 }],
        itemsPerPageText: "Mostrar en grupo de:",
        showCurrentPage: false,
        pageText: "{0} al {1} de {2}",
      },
      statusOrders: [],
      loadingOrders: false
    };
  },
  mounted() {
    this.getDataCustomer().then((response) => {
      if (!response) {
        this.$router.push({ name: "PendingAuthorization" });
      }
      this.getInfoCustomer();  
      this.getCustomersAccounts(); 
      this.getOrders();
    });    
  },
  computed: {
    filteredItems() {       
        return this.items.filter(item => !this.selectedTIPO || item.TIPO === this.selectedTIPO );
    },        
    sumExpired(){
        return this.items.reduce((total, item) => {        
            return (!item.inserted) ? total + parseFloat(item.vencido) : total;
        }, 0);
    },
    sumToExpire(){
        return this.items.reduce((total, item) => {
            return (!item.inserted) ? total + parseFloat(item.por_vencer) : total;
        }, 0);
    },
    sumAmount(){
        return this.items.reduce((total, item) => { 
            if (!item.inserted) {
                let subTotal = 0;                    
                if (item.DETFAC && item.DETFAC.length > 0) {                   
                    subTotal += item.DETFAC.reduce((totalDetFac, detail) => {
                        return totalDetFac + (parseFloat(detail.DMBTR) || 0);
                    }, 0);
                }
                return total + subTotal + (parseFloat(item.DMBTR) || 0);
            }
            return total;
        }, 0);
    },
    allDocuments() { 
        const documentos = this.items.map(item => ({text: item.TIPO, value: item.TIPO}));       
        return [{ text: 'Todos los Documentos', value: ''}, ...documentos];
    },    
    totalOrdersAmount(){
      return this.statusOrders.reduce((acc, order) => acc + parseFloat(order.KZWI1), 0);
    },
    ...mapGetters({
      user: "auth/user",
      customerData: "customerPendings/customerData",
      customer: "customerPendings/customer",
    }),
  },
  methods: {    
    async getCustomersAccounts(){
        try{
            let response = await axios.get("/customers-account-statement", {
                params:{
                    customer_code: this.customerData.KUNNR,
                    P_VTWEG: this.user.VTWEG,
                    P_VKORG: this.user.VKORG,
                    P_OPCGPO: 0
                }
            });

            this.items = this.reorderItemsData(response.data.data);
            this.loader = false;
        }catch(error){
            console.log(error)
        }
    },
    async getInfoCustomer() {      
      try {
        const response = await axios.post("/infoCustomer", {
          customer: this.customerData.KUNNR,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
        });
        this.infoCustomer = response.data;
      } catch (error) {
        console.log(error)
      }
    },
    validateInsertOrDelete(invoice){
        !invoice.isOpen 
            ? this.insertNewsObjects(invoice) 
            : this.deleteNewsObjects(invoice);
    },
    insertNewsObjects(invoice){
        invoice.DETFAC.forEach((item, index) => {
            item.inserted = true;
            item.idInvoice = invoice.BELNR; 
            this.items.splice(this.getPositionItem(invoice.BELNR) + index + 1, 0, item);
        });            
        this.setPropertyIsOpen(true, invoice.BELNR);    
    },
    deleteNewsObjects(invoice){ 
        this.items = this.items.filter(item => item.idInvoice !== invoice.BELNR);
        this.setPropertyIsOpen(false, invoice.BELNR); 
    }, 
    setPropertyIsOpen(isOpen, BELNR){   
        const position = this.getPositionItem(BELNR);         
        this.$set(this.items[position], 'isOpen', isOpen);   
    }, 
    getPositionItem(BELNR){
        return this.items.findIndex(item => item.BELNR === BELNR);
    },
    reorderNotApplied(items){
        const notApplied = items.filter(item => item.TIPO !== 'FACTURA');
        const applied = items.filter(item => item.TIPO === 'FACTURA');
        return [...notApplied, ...applied];
    },  
    reorderExpired(items){
        const expired = items.filter(item => item.ESTAT === 'VENCIDO');
        const withoutExpire = items.filter(item => item.ESTAT !== 'VENCIDO');
        return [...expired, ...withoutExpire];
    },
    reorderItemsData(items){
        items = this.reorderNotApplied(items);
        return this.reorderExpired(items);            
    }, 
    formatCurrency(value){
      return this.$options.filters.formatCurrency(value);
    },
    buildPDF() {      
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });

      var img = new Image();
      var footer = new Image();

      img.src = "/img/header.png";
      footer.src = "/img/footer.png";

      doc.addImage(img, "png", 0.5, 0.5, 7.5, 0.8);
      doc.addImage(footer, "png", 0.5, 9.8, 7.5, 0.6);        
      doc.setFontSize(10);

      /**
       * Table Data Customer
       */
       doc.autoTable({
        styles: {
          fillColor: [255, 255, 255],
        },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        columns: [
          { header: "", dataKey: "title" },
          { header: "", dataKey: "content" },
        ],
        body: [
          { title: "Cliente:", content: this.infoCustomer.KUNNR },
          { title: "Nombre:", content: this.infoCustomer.NAME1 },
          { title: "RFC:", content: this.infoCustomer.STCD1 },
          { title: "Email:", content: this.infoCustomer.SMTP_ADDR },
          { title: "Teléfono:", content: this.infoCustomer.TELF1 },
          { title: "Rango Fecha Solicitud:", content: `${this.range.startDate ? moment(this.range.startDate).format('DD/MM/YYYY') : '~'} / ${moment(this.range.endDate).format('DD/MM/YYYY')}`},
        ],
        columnStyles: {
          title: { fontStyle: "bold" },
        },
        margin: { left: 0.5, top: 1.5 },
      });

      /**
       * Table Items
       */
       doc.autoTable({
        styles: { halign: "center", fillColor: [255, 255, 255] },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        tableLineColor: [169, 169, 169],
        tableLineWidth: 0.01,
        theme: "grid",
        columns: [
          { header: "Total", dataKey: 'DMBTR' },
          { header: "Por Vencer Mes Actual", dataKey: 'toExpire' },
          { header: "A 30 días", dataKey: 'to30days' },
          { header: "A 60 días", dataKey: 'to60days' },
          { header: "A 90 días", dataKey: 'to90days' },
          { header: "A 120 días", dataKey: 'to120days' },
        ],
        body: [{
            DMBTR: this.formatCurrency(this.sumTotal),
            toExpire: this.formatCurrency(this.sumToExpire),
            to30days: this.formatCurrency(this.sumLast30Days),
            to60days: this.formatCurrency(this.sumLast60Days),
            to90days: this.formatCurrency(this.sumLast90Days),
            to120days: this.formatCurrency(this.sumLast120Days),
        }],
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineColor: [169, 169, 169],
          lineWidth: 0.01,
        },
        bodyStyles: {
          lineColor: [169, 169, 169],
          lineWidth: 0.01,
        },
        margin: { left: 0.5, top: 1.5 },
      }); 

      /**
       * Table General
       */      
      doc.autoTable({
        styles: { halign: "left" },
        alternateRowStyles: { fillColor: [211, 211, 211] },
        tableLineColor: [211, 211, 211],
        tableLineWidth: 0.01,
        theme: "grid",
        columns: [
          { header: "Documento", dataKey: "BELNR" },
          { header: "Fecha Contable", dataKey: "BUDAT" },
          { header: "Fecha Vencimiento", dataKey: "FVENC" },
          { header: "Total", dataKey: "DMBTR" },
        ],
        body: this.formatTotal(),
        headStyles: {
          fillColor: [211, 211, 211],
          textColor: [0, 0, 0],
          lineColor: [211, 211, 211],
          lineWidth: 0.01,
        },
        bodyStyles: {
          lineColor: [211, 211, 211],
          lineWidth: 0.01,
          fillColor: [211, 211, 211],
        },
        margin: { left: 0.5, top: 1.5, bottom: 0.5 },
      });

      return doc;
    },
    formatTotal(){
      return this.items.map(item => ({
        ...item, DMBTR: `$${this.formatCurrency(item.DMBTR)}`
      }));
    },
    openModalEmail(){      
      const doc = this.buildPDF();
      const pdfBlob = doc.output("blob");      
      const formData = new FormData();

      formData.append("file", pdfBlob, "Estado_de_cuenta.pdf"); 
      
      this.pdfFormData = formData;      
      this.showEmail = true;
    },
    async sendEmail(email){   
      this.disabledSendEmail = true;  

      try{
        this.pdfFormData.append("email", email);
        await axios.post("/send-email-credit-pdf", this.pdfFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.$toast.success('El correo se envió con éxito');        
      }catch(err){
        this.$toast.error('Ocurrió un error al enviar el correo. Por favor vuelva a intentarlo');
      }

      this.disabledSendEmail = false;
      this.showEmail = false;
    },
    async buttonAuthorization(isAuthorized, motive = ''){
      this.disabledBtn = true;
      try{
        await this.setAuthorization({
          customer: this.customerData.KUNNR,
          order: this.customer.VBELN,
          isAuthorized: Number(isAuthorized),
          motive
        });
        
        this.$toast.success(`El pedido ${this.customer.VBELN} fue ${isAuthorized ? 'autorizado' : 'rechazado'}`);
        this.$router.push({ name: "PendingAuthorization" });
      }catch(err){
        this.$toast.error('Ocurrió un error. Por favor inténtelo de nuevo');
      }
      this.disabledBtn = false;
    },    
    closeAllItems(){
      this.items.forEach(item => {
        if (item.DETFAC && item.DETFAC.length > 0) {
          item.isOpen = false;
          item.inserted = false;
          this.deleteNewsObjects(item);
        }
      });
    },
    async getOrders(){
      try{
          const response = await axios.get("/credit/getStatus", {
              params:{
                  P_KUNNR: this.customer.KUNNR,
                  P_VTWEG: this.customer.VTWEG,
                  P_PEDIDO: this.customer.VBELN,
              }
          });
          this.statusOrders = response.data.data;
          this.loadingOrders = true;
      }catch(error){
          this.$toast.error('Ocurrió un error al consultar el total del pedido');
      }
    },
    ...mapActions("customerPendings", ['getDataCustomer', 'setAuthorization']),
  }  
};
</script>

<style scoped>
  .foot-total{
      background: transparent linear-gradient(180deg, var(--light-🌕-error-b00020) 0%, #580010 100%) 0% 0% no-repeat padding-box;
      border: 1px solid var(--gris-de-las-tablas);
      background: transparent linear-gradient(180deg, #B00020 0%, #580010 100%) 0% 0% no-repeat padding-box;
      border: 1px solid #DDE1E6;
      opacity: 1;
      height: 28px !important;
      color: #FFF
  }
  .foot-sub-total{
      background: rgb(245,245,245);
      height: 30px !important;
  }        
  ::v-deep table thead .v-input__slot, ::v-deep table thead .v-input__control{
      min-height: auto !important;  
      background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box !important;
      opacity: 1;          
  }
  ::v-deep table  thead .v-input__slot .v-icon{
      color: #FFF !important;
  }
  ::v-deep table .v-input__slot .v-label, ::v-deep table .v-input__slot .v-select__selection {
      font-size: 11px !important;
      color: #FFF !important;
  }    
</style>