<template>
  <div>
    <template v-if="loading"> 
         <v-skeleton-loader
           class="mx-auto" 
           type="card"
         ></v-skeleton-loader> 
     </template>
      <div v-else class="border-red" style="box-shadow: inset 0px 3px 6px #F50A0A29, 0px 3px 6px #FA0F0F4F; border-radius: 20px;">
         
          <v-card  class=" rounded-xl pb-2  pt-5 " elevation="0">
            <v-row class="pt-7 mb-3">
              <v-col cols="2" sm="4" class="text-center">
                <v-icon color="gray" class="mr-2" size="40"
                  >mdi-car</v-icon>
              </v-col>
              <v-col cols="2" sm="4" class="text-center">
                <router-link :to="{name:'PlaneadorManager'}">
                  <v-icon color="blue" class="mr-2" size="40"
                    >mdi-calendar-check-outline</v-icon>
                </router-link>
              </v-col>
              <v-col cols="2" sm="4" class="text-center">
                <a href="#" @click="openMapasLocation">
                  <v-img
                    class="img-fluid mt-n1"
                    src="/img/icono_maps.svg"
                    width="55px"
                  ></v-img>
                </a>
              </v-col>
              <v-col cols="12" class="text-body-2">
                <p class="text-center ">
                 {{this.managerInfo.NAME1}} <br />
                 {{this.managerInfo.PERNR}} <br />
                 <span v-if="this.managerInfo.ZONA">({{this.managerInfo.ZONA}}) </span>
                 
                  <data-update-step-launcher v-if="$hasAnyRole(['Manager', 'Submanager'])" />
                </p>
                <change-password-control v-if="$hasAnyRole(['Manager', 'Submanager'])"
                  class="mb-4 px-2"
                />
                <div class="text-left pl-6">{{number_format(managerInfo.NHAB)}} H</div>
                <div class="text-right mt-n5 pr-4">
                  ${{number_format(managerInfo?.HABI)}} $&nbsp;/&nbsp;H
                </div>
              </v-col>
              <v-col cols="12" class="text-body-2 mt-n4">
                 <div class="d-flex justify-center mr-2 ml-2">
                   <sales-policy-file-downloader class="mx-2" policy-type="delivery" />
                   <sales-policy-file-downloader class="mx-2" policy-type="sellCredit" />
                 </div>
               </v-col>
            </v-row>
          </v-card>
        </div>
        <customer-locations-map 
          v-model="openMap"
          :type="typeCustomerLocation"
          :customers="customersLocation">
        </customer-locations-map>
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex"; 
import MixinHelpers from '@/mixins/helpers';
import MixinGlobal from '@/mixins/global';
import moment from "moment";
import CustomerLocationsMap from '@/components/modals/customers_locations_map/CustomerLocationsMap.vue';
import ChangePasswordControl from "@/components/ChangePasswordControl.vue";
import DataUpdateStepLauncher from "@/template/partials/stepsAfterLogin/DataUpdateStepLauncher.vue";
import SalesPolicyFileDownloader from '@/components/policies/SalesPolicyFileDownload.vue'

export default({
  name:'ManagerCard', 

  mixins: [MixinHelpers,MixinGlobal],

  components: { CustomerLocationsMap, ChangePasswordControl, DataUpdateStepLauncher, SalesPolicyFileDownloader },

    data: () => ({
      loading: true,
      managerInfo: [],
      manager:[],
      customersLocation: [],
      today: moment().format('YYYY-MM-DD'),
      openMap: false,
      typeCustomerLocation: 'byManager'
    }),     
    mounted(){
      /** Aqui se define el id del manager y el canal al que pertenece */ 
      let manager = this.$getLocalStorageManager();
      if (manager) {
        this.manager.employee_number =manager,
        this.manager.VTWEG = this.user.VTWEG 
      }else{
        this.manager= this.user 
      } 
      this.getManagerInfo()
    }, 
    computed: { 
    ...mapGetters({
      user: "auth/user",
    }),

  },
    methods:{
      getManagerInfo(){
        this.loading=true;
        axios.post('/manager/home/general-info',{
          manager: Number (this.manager.employee_number), 
          channel:this.manager.VTWEG
        }).then((res) => { 
          this.managerInfo = res.data; 
          this.managerInfo.VTWEG=this.manager.VTWEG
          this.$emit('managerLoaded',res.data)
          this.loading = false;
      })
      .catch((err) => { 
            console.log(err);
            this.loading = false;
          }) ; 
      },
      openMapasLocation(){
        this.getCustomersLocation();
      },
      async getCustomersLocation(){
        try{
          this.openMap = true;
          let employeeNumber = this.$getLocalStorageManager() || this.user.employee_number;
          let response = await axios.get('/geolocation-map/coordinates-customers',{
            params:{
              inicio: this.today,
              fin: this.today,
              P_VTWEG: this.user.VTWEG,
              P_VKORG: this.user.VKORG,
              employee_number: employeeNumber,
              type: this.typeCustomerLocation
            }
          });
          this.customersLocation = response.data;
        }catch(error){
          this.openMap = false;
          console.log(error);
        }
      }
    },
    watch:{
      openMap(){
        if(!this.openMap){
          this.customersLocation = [];
        }
      }
    }
});
</script>