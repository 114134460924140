<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-text class="pa-0 space-card">
          <br />
          <v-icon
            class="mt-20"
            size="128"
            style="align-self: center; color: #ffd68a"
          >
            mdi-alert-circle-outline
          </v-icon>
          <div class="mt-5">
            <span
              class="font-weight-bold"
              style="color: black; font-size: 16px"
              >No cierre ni actualice la página hasta que se termine el
              proceso</span
            >
            <br />
            <span class="mb-5">
              Si cierra o actuliza tiene el riesgo que el pedido se
              duplique</span
            >
          </div>
          <br />
          <div class="mb-10 mt-10">
            <v-row
              align-content="center"
              class="fill-height"
              justify="center"
            >
              <v-progress-linear
                style="width: 55%"
                color="#a82222"
                indeterminate
                rounded
                height="20"
              ></v-progress-linear>
            </v-row>
          </div>
          <br />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    name: 'GenerateOrderLoadingModal',
    props: {
      value: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      showDialog: {
        set(newVal) {
          this.$emit('input', newVal);
        },
        get() {
          return this.value;
        },
      },
    },
  };
</script>
<style>
  .space-card {
    background-color: white;
    margin-top: 2px;
    margin-left: 3px;
    margin-bottom: 2px;
    overflow: hidden;
    align-content: left;
  }
</style>
