<template>
    <location-maps-record 
        @acceptLocation="acceptLocation"
        @getCoordinatesRecipient="getCoordinatesRecipient">
    </location-maps-record>
</template>

<script>
import LocationMapsRecord from './components/LocationMapsRecord.vue';
import { mapGetters, mapMutations } from "vuex";
import axios from "@/plugins/axios";
import moment from "moment";

export default {
    components: { LocationMapsRecord },

    methods:{
        async getRecipientsMerchandise(){            
            try{
                const response = await axios.get('customer-merchandise-recipient', {
                params: {
                    customer: this.customerCode,
                    VKORG: this.user.VKORG,
                    VTWEG: this.user.VTWEG
                }
                });

                this.SET_RECIPIENTS_OPTION(response.data.data); 
            }catch(error){
                this.$toast.error('Error al obtener los destinatarios de mercancía.')
            }
            
            this.SET_SHOW_LOADER_SELECT(false); 
        },

        async getCoordinatesRecipient(){
            try{
                const response = await axios.get(`customer-merchandise-recipient/${this.selectedRecipient}`);
                const location = response.data.data;

                if(location != null){
                    this.SET_GEOLOCATION({
                        lat: location.latitude,
                        lng: location.longitude,
                        updatedAt: location.updated_at
                    });
                    this.SET_UPDATE_LOCATION(true);
                }

                this.SET_SHOW_EDIT_MAP(true);
            }catch(error){
                this.$toast.error('Error al consultar el mapa, por favor inténtelo de nuevo.')
            }
        },

        async storeCoordinatesRecipient(){  
            try{
                await axios.post('customer-merchandise-recipient', {          
                    customer: this.customerCode,
                    latitude: this.geolocation.lat,
                    longitude: this.geolocation.lng,
                    id_kunnr: this.selectedRecipient                  
                });   
                
                this.SET_GEOLOCATION({
                    lat: this.geolocation.lat,
                    lng: this.geolocation.lng,
                    updatedAt: moment()
                });
                                
                this.SET_UPDATE_LOCATION(true); 

                this.$toast.success('Ubicación registrada con éxito.');
            }catch(error){         
                this.$toast.error('Error al registrar la ubicación. Inténtelo nuevamente.');
            }  
        },

        async updateCoordinatesRecipient(){
            try{
                await axios.put('customer-merchandise-recipient', {
                    customer: this.customerCode,
                    latitude: this.geolocation.lat,
                    longitude: this.geolocation.lng,
                    id_kunnr: this.selectedRecipient
                });

                this.SET_GEOLOCATION({
                    lat: this.geolocation.lat,
                    lng: this.geolocation.lng,
                    updatedAt: moment()
                });

                this.$toast.success('Ubicación actualizada con éxito.');
            }catch(error){    
                this.$toast.error('Error al actualizar la ubicación. Inténtelo nuevamente.');
            }  
        },

        acceptLocation(){            
            this.updateLocation ? this.updateCoordinatesRecipient() : this.storeCoordinatesRecipient();            
        },

        ...mapMutations('coordinatesRecords', [
            'SET_UPDATE_LOCATION', 
            'SET_SHOW_EDIT_MAP', 
            'SET_GEOLOCATION', 
            'SET_RECIPIENTS_OPTION', 
            'SET_SHOW_LOADER_SELECT'
        ])
    },

    computed:{
        ...mapGetters('auth', ['user']), 
        ...mapGetters('coordinatesRecords', ['activeRecipient', 'geolocation', 'updateLocation', 'customerCode', 'selectedRecipient']), 
    },
    
    watch:{
        activeRecipient(newVal){
            if(newVal){
                this.SET_GEOLOCATION({ lat: '', lng: '', updatedAt: ''});
                this.getRecipientsMerchandise();  
            }          
        },
    },  
}
</script>

<style>

</style>