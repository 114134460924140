<template>
  <div v-if="authenticated && user.VTWEG != null">
    <v-toolbar
      :class="breadcrumColor"
      class="breadcrumbroles--path"
      dense
      elevation="5"
    >
      <div
        style="width: 100%"
        class="d-md-flex align-items-center justify-content-between"
      >
        <!-- Menú de escritorio -->
        <div class="container-breadcrumb-desk">
          <v-breadcrumbs
            class="custom-breadcrumb"
            divider=">"
            dark
            :items="breadcrumbsItems"
          >
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                v-if="item.nameTooltip == null"
                :href="item.href"
                @click="saveCodeStorage(item.number_code, item.typeStorage)"
                :disabled="item.disabled"
              >
                <span
                  :class="
                    (!item.disabled ? 'yellow--text' : ' text-white ') +
                    ' small'
                  "
                >
                  {{ item.text }} {{ getDateBreadcrumb(item) }}</span
                >
              </v-breadcrumbs-item>

              <v-breadcrumbs-item
                v-else
                :href="item.href"
                @click="
                  saveCodeStorage(item.number_code, item.typeStorage);
                  handleClick(item);
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span :class="(!item.disabled ? 'yellow--text' : ' text-white ') + ' small'" v-bind="attrs" v-on="on">
                      {{ item.nameTooltip }} {{ getDateBreadcrumb(item) }}
                    </span>
                  </template>
                  {{ item.text }}
                </v-tooltip>
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </div>

        <!-- Menú para móviles -->
        <div style="position: relative" class="container-breadcrumb-mobile">
          <v-breadcrumbs
            class="d-flex justify-content-center"
            dark
            :items="lastBreadcrumb"
          >
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item>
                <v-breadcrumbs-item
                  :href="item.href"
                  :disabled="item.disabled"
                  @click="saveCodeStorage(item.number_code, item.typeStorage)"
                >
                  <span
                    :class="
                      (!item.disabled ? 'yellow--text' : ' text-white ') +
                      ' small'
                    "
                  >
                    {{ item.text }} {{ getDateBreadcrumb(item) }}
                  </span>
                </v-breadcrumbs-item>

                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                      color="white"
                      size="24"
                    >
                      mdi mdi-menu-down
                    </v-icon>
                  </template>

                  <v-list class="navBar__dropdown">
                    <v-list-item-title
                      class="subMenu--title"
                      @click="$router.push(submenu.href)"
                      :class="{
                        [validateColor()]: !submenu.disabled,
                        pointer: !submenu.disabled,
                      }"
                      v-for="(submenu, index) in breadcrumbsWithoutLast"
                      :key="index"
                    >
                      &lt; {{ submenu.text }} &gt;
                    </v-list-item-title>
                  </v-list>
                </v-menu>
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </div>

        <!-- Selector de módulos -->
        <SelectMenuBreadcrumb></SelectMenuBreadcrumb>
      </div>
    </v-toolbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectMenuBreadcrumb from "./SelectMenuBreadcrumb.vue";
import moment from "moment";
import axios from "@/plugins/axios";

export default {
  name: "BreadcrumbRoles",
  components: { SelectMenuBreadcrumb },
  data() {
    return {
      breadcrumbsItems: [],
    };
  },
  methods: {
    handleRouteChange() {
      this.breadcrumbsItems = [
        {
          text: 'IUSA',
          disabled: true,
          href: '#',
        },
      ];

      this.validateValuesSend();
      this.getPath();
    },
    validateValuesSend() {
      const role = this.$route.meta.role;
      const userEmployeeNumber = '0' + this.user.employee_number;

      if (role) {
        let currentCode = null;

        switch (role) {
          case 'Director':
            currentCode = this.$getLocalStorageDirector();
            this.P_USER = currentCode ? currentCode : userEmployeeNumber;
            break;
          case 'Regional':
            currentCode = this.$getLocalStorageRegional();
            this.P_USER = currentCode ? currentCode : userEmployeeNumber;
            break;
          case 'Manager':
            currentCode = this.$getLocalStorageManager();
            this.P_USER = currentCode ? currentCode : userEmployeeNumber;
            break;
          case 'Seller':
            currentCode = this.$getLocalStorageSeller();
            this.P_USER = currentCode ? currentCode : userEmployeeNumber;
            break;
          case 'Customer':
            currentCode = this.$getLocalStorageCustomer();
            this.P_USER = currentCode ? currentCode : this.user.KUNNR;
            break;
          case 'ExpressSales':
            this.P_USER = userEmployeeNumber;
            break;
        }
      }
    },
    async getPath() {
      try {
        let response = await axios.get('/path-life-line', {
          params: {
            P_USER: this.P_USER,
          },
        });

        let data = response.data.data;
        this.generateBreadCrumb(data);
        this.validateRoles();

      } catch ($e) {
        console.log($e);
      }
    },
    generateBreadCrumb(data) {      
      const breadcrumbRoles = {
        director: {
          name: data.NOMDIR,
          code: data.CODDIR,
          tooltip: this.getNameChannel(),
          href: this.$router.resolve({ name: 'DailyDirector' }).href,
          condition: this.validateCodeNull(data.CODDIR) && !this.$hasAnyRole('IELExpressSales'),
          typeStorage: ''
        },
        subDirector: {
          name: data.NOMSUBDIR,
          code: data.CODSUBDIR,
          tooltip: 'Subdirector',
          href: this.$router.resolve({ name: 'DailyDirector' }).href,
          condition: this.validateCodeNull(data.CODSUBDIR) && !this.$hasAnyRole('IELExpressSales'),
          typeStorage: 'Director'
        },
        regionalManager: {
          name: this.$hasAnyRole(['IELRegManager']) ? this.user.name : data.NOMGERR,
          code: this.$hasAnyRole(['IELRegManager']) ? this.employee_number : data.CODGERR,
          tooltip: 'GTE Regional',
          href: this.$router.resolve({ name: 'DailyRegionalManager' }).href,
          condition: this.validateCodeNull(data.CODGERR) && !this.$hasAnyRole('IELExpressSales'),
          typeStorage: 'Regional'
        },
        manager: {
          name: data.NOMGER,
          code: data.CODGER,
          tooltip: 'Gerente',
          href: this.$router.resolve({ name: 'ManagerHome' }).href,
          condition: this.validateCodeNull(data.CODGER) && this.user.VTWEG == 'DT' && !this.$hasAnyRole('IELExpressSales'),
          typeStorage: 'Manager'
        },
        subManager: {
          name: data.NOMSGER,
          code: data.CODSGER,
          tooltip: 'Subgerente',
          href: this.$router.resolve({ name: 'ManagerHome' }).href,
          condition: this.validateCodeNull(data.CODSGER) && this.user.VTWEG == 'PR' && !this.$hasAnyRole('IELExpressSales'),
          typeStorage: 'Manager'
        },
        seller: {
          name: data.NAME1,
          code: data.PERNR,
          href: this.$router.resolve({ name: 'SellerResults' }).href,
          condition: this.validateCodeNull(data.PERNR) && !this.$hasAnyRole('IELExpressSales'),
          typeStorage: 'Seller'
        },
        expressSales: {
          name: this.user.name,
          code: this.user.employee_number,
          href: this.$router.resolve({ name: 'ResultsExpressSales' }).href,
          condition: this.validateCodeNull(this.user.employee_number) && this.$hasAnyRole('IELExpressSales'),
          typeStorage: ''
        },
        customer: {
          name: data.SNAME,
          code: data.KUNNR,
          href: '/caratula',
          condition: this.validateCodeNull(data.KUNNR) && this.$route.meta.role !== 'ExpressSales',
          typeStorage: ''
        }
      };

      this.addBreadCrumbItems(breadcrumbRoles);
    },
    addBreadCrumbItems(breadcrumbRoles) {
      for (const [role, { name, code, tooltip, href, condition, typeStorage }] of Object.entries(breadcrumbRoles)) {
        if (condition) {
          this.breadcrumbsItems.push({
            role: role,
            text: name,
            number_code: code,
            disabled: true,
            href: href || '#',
            nameTooltip: tooltip || null,
            typeStorage: typeStorage,
          });
        }
      }
    },
    formatDate() {
      const today = moment();
      const formatDate = today.format('DD [de] MMMM [de] YYYY');
      return `(${today.date() > 1 ? '1 al ' : ''}${formatDate})`;
    },
    validateRoles() {
      const disableSeller = ['seller', 'customer'];
      const disableSubManager = ['subManager'];
      const disableManager = ['manager'];
      const disableRegionalManager = ['regionalManager'];
      const disableSubDirector = ['subDirector'];
      const disableDirector = ['director'];
      const disableExpressSales = ['expressSales'];

      const roleMappings = [
        {
          roles: ['Director', 'IELDirector', 'DirectorNoCommercial'],
          disable: [
            ...disableSeller,
            ...disableSubManager,
            ...disableManager,
            ...disableRegionalManager,
            ...disableSubDirector,
            ...disableDirector,
          ],
        },
        {
          roles: ['Subdirector', 'IELCommercialDT', 'IELCommercialPR', 'SubDirectorPRNoCommercial', 'SubDirectorDTNoCommercial'],
          disable: [
            ...disableSeller,
            ...disableSubManager,
            ...disableManager,
            ...disableRegionalManager,
            ...disableSubDirector,
          ],
        },
        {
          roles: ['RegionalManager', 'IELRegManager'],
          disable: [
            ...disableSeller,
            ...disableSubManager,
            ...disableManager,
            ...disableRegionalManager,
          ],
        },
        {
          roles: ['Manager', 'IELManager'],
          disable: [...disableSeller, ...disableManager],
        },
        {
          roles: ['Submanager', 'IELManager'],
          disable: [...disableSeller, ...disableSubManager],
        },
        {
          roles: ['Seller'],
          disable: disableSeller,
        },
        {
          roles: ['IELExpressSales'],
          disable: disableExpressSales,
        }
      ];

      roleMappings.forEach(({ roles, disable }) => {
        if (this.$hasAnyRole(roles)) {
          this.disableRoles(disable);
        }
      });
    },
    disableRoles(roleNames) {
      roleNames.forEach((role) => {
        let item = this.breadcrumbsItems.find((item) => item.role === role);
        if (item) {
          item.disabled = false;
        }
      });
    },
    formatName(name) {
      if (name != null) {
        return name
          .split(' ')
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(' ');
      }
      return;
    },
    validateColor() {
      return this.user.VTWEG == 'PR' ? 'text-blue-two' : 'text-red-two';
    },
    handleClick(item) {
      if (item.disabled) {
        event.preventDefault();
      }
    },
    getIndex(item) {
      return this.breadcrumbsItems.indexOf(item) + 1;
    },
    getDateBreadcrumb(item) {
      return this.getIndex(item) === this.breadcrumbsItems.length ? this.formatDate() : '';
    },
    validateCodeNull(code) {
      return code !== null && code !== '' && code != undefined;
    },
    saveCodeStorage(code, typeStorage) {
      if(typeStorage == 'Director' && !this.$hasAnyRole(['Director'])){
        this.$setLocalStorageDirector(code);
      } else if (typeStorage == 'Regional' && !this.$hasAnyRole(['RegionalManager', 'IELRegManager'])) {
        this.$setLocalStorageRegional(code);
      } else if (typeStorage == 'Seller' && !this.$hasAnyRole(['Seller'])) {
        this.$setLocalStorageSeller(code);
      } else if (typeStorage == 'Manager' && !this.$hasAnyRole(['Manager'])) {
        this.$setLocalStorageManager(code);
      }
    },
    getNameChannel() {
      const { channels, VTWEG } = this.user;
      return this.$options.filters.capitalizeFirstLetter(channels[VTWEG]);
    }
  },
  created() {
    this.handleRouteChange();
    this.$router.afterEach(() => this.handleRouteChange());
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    breadcrumColor() {
      const colorMap = {
        DT: 'color-red-two',
        PR: 'color-blue-two',
        default: 'color-red-two',
      };

      const canal = this.user.VTWEG;
      const classColor = colorMap[canal] || colorMap['default'];

      return classColor;
    },
    lastBreadcrumb() {
      // Devuelve solo el último elemento del array
      return [this.breadcrumbsItems[this.breadcrumbsItems.length - 1]];
    },
    breadcrumbsWithoutLast() {
      // Devuelve todos los elementos excepto el último
      return this.breadcrumbsItems.slice(0, -1);
    },
  },
  watch: {
    "user.VTWEG": function () {
      this.handleRouteChange();
    },
  },
};
</script>
<style>
/* estilos sin scope necesarios*/
.breadcrumbroles--path.v-toolbar,
.breadcrumbroles--path .v-toolbar__content {
  height: auto !important;
}

.breadcrumbroles--path .v-breadcrumbs {
  padding: 0 !important;
}

.breadcrumbroles--path .text-white {
  cursor: default;
}

@media (max-width: 768px) {
  .breadcrumbroles--path.v-toolbar {
    padding: 8px;
  }

  .breadcrumbroles--path .v-input__control {
    min-height: 28px !important;
  }
}
</style>
<style scoped>
.color-blue-one {
  background: #0d6efd !important;
}
.color-blue-two {
  background: #124876 !important;
}
.text-blue-two {
  color: #124876 !important;
  font-weight: 600;
}

.color-red-one {
  background: #fe0106 !important;
}
.color-red-two {
  background: #af001f !important;
}
.text-red-two {
  color: #af001f !important;
  font-weight: 600;
}

.subMenu--title {
  border-bottom: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 0;
}

.subMenu--title:last-child {
  border-bottom: inherit;
}

.navBar__dropdown {
  padding: 0 10px !important;
}

.container-breadcrumb-mobile {
  display: none;
}

.pointer {
  cursor: pointer;
}
@media (max-width: 1100px) {
  .breadcrumbroles--path .container-breadcrumb-desk {
    display: none;
  }
  .breadcrumbroles--path .container-breadcrumb-mobile {
    display: block !important;
  }
  .v-menu__content {
    max-width: inherit;
    width: 100%;
    text-align: left;
    left: 0;
  }
}
</style>