<template>
    <div class="bg-white">
        <v-container fluid class="page__container--padding">
            <search-customer-seller :show-goback="false" />
            <div class="d-flex my-4">
                <div class="w-auto d-inline-flex align-center mr-4">
                    Indicadores: <custom-select :disabled="loadingCustomerStats" v-model="filters.metric"
                        :options="metrics" />
                </div>
                <div class="w-auto d-inline-flex align-center mr-4">
                    Canal: <custom-select :disabled="loadingCustomerStats" v-model="filters.channel"
                        :options="channels" />
                </div>
                <div class="w-auto d-inline-flex align-center mr-4" v-if="filters.territory">
                    Territorio: <custom-select :disabled="loadingCustomerStats" v-model="filters.territory"
                        :options="territories" />
                </div>
                <div class="w-auto d-inline-flex align-center mr-4" v-if="filters.manager">
                    G.Estatal: <custom-select :disabled="loadingCustomerStats" v-model="filters.manager"
                        :options="managers" />
                </div>
            </div>
            <h3>{{ metricSelcted.label }}</h3>
            <billing-table @optionSelected="updateFilters" :loading="loadingCustomerStats" :data="customerStats"
                :column-title="columnTitle" v-if="showBillingTable" :manager="filters.manager" />
            <customers-table v-else :items="customerStats" :loading="loadingCustomerStats"
                @optionSelected="updateFilters" :column-title="columnTitle" :manager="filters.manager" />

            <alert-prominent v-if="error" indeterminate :message="error" :disabled-button="loadingCustomerStats" />
            <div v-if="lastUpdate" class="text-caption text-left font-weight-bold mt-4">
                Última actualización {{ lastUpdate }}
            </div>
        </v-container>
    </div>
</template>
<script>

import SearchCustomerSeller from '@/components/SearchCustomerSeller.vue';
import CustomSelect from '@/components/CustomSelect.vue';
import CustomersTable from './components/CustomersTable.vue';
import BillingTable from './components/BillingTable.vue';
import AlertProminent from '@/components/AlertProminent.vue';

import numbersFormats from '@/mixins/numbersFormats';
import axios from '@/plugins/axios';

export default {
    name: "ProjectManager",
    components: { SearchCustomerSeller, CustomSelect, CustomersTable, BillingTable, AlertProminent },
    mixins: [numbersFormats],
    data: () => ({
        filters: {
            metric: "G",
            channel: 'PR',
            territory: null,
            manager: null
        },
        optionSelected: [],
        showBillingTable: false,
        metrics: [{
            value: 'G',
            label: "Geolocalización"
        },
        { value: "F", label: "Fotos" },
        { value: "DC", label: "Datos de contacto" },
        { value: "FI", label: "Facturación IEL" },
        { value: "FC", label: "Facturación cliente" },
        { value: "CPI", label: "Clientes con pagos IEL" },
        { value: "PII", label: "Pagos IEL importe" },
        ],
        loadingTerritories: false,
        channels: [
            { value: 'DT', label: "Mostradores" },
            { value: 'PR', label: "Distribuidores" },
        ],
        territories: [],
        managers: [],
        customerStats: [],
        loadingCustomerStats: true,
        columnTitle: "Territorios",
        error: null,
        lastUpdate: null,
    }),
    mounted() {
        this.getCustomerStats()
    },
    methods: {
        updateFilters(value) {
            const selection = value.structure;
            this.filters.territory = this.fillNumberToNDigits(selection.territorial_director_id, 8);
            this.filters.manager = this.fillNumberToNDigits(selection.state_manager_id, 8);
        },
        async getCustomerStats() {
            try {
                this.loadingCustomerStats = true;
                const response = await axios.post("/project-manager/customer-stats", this.filters);
                this.customerStats = response.data.data;
                this.lastUpdate = response.data.lastUpdate;
            } catch (error) {
                this.error = error.response.data.message;
            }
            this.loadingCustomerStats = false;
        },
        async loadTerritories() {
            this.error = "";
            try {
                this.loadingTerritories = true;
                const response = await axios.post("/project-manager/territories", this.filters);
                this.territories = response.data;
            } catch (error) {
                this.error = error.response.data.message;
            }
            this.loadingTerritories = false;
        },
        async loadManagers() {
            try {
                const response = await axios.post("/project-manager/managers", this.filters);
                this.managers = response.data;
            } catch (error) {
                this.error = error.response.data.message;
            }
        }
    },
    watch: {

        'filters.metric': function (metric) {
            this.getCustomerStats();
            this.showBillingTable = ['PII', 'FI'].includes(metric);
        },
        'filters.channel': function () {
            this.filters.manager = null;
            this.filters.territory = null;
            this.getCustomerStats();
        },
        'filters.territory': function (value) {
            this.filters.manager = null;
            if (value) {
                this.loadTerritories();
                this.getCustomerStats();
                this.columnTitle = "Gerentes estatales";
            }
        },
        'filters.manager': function (value) {
            if (value) {
                this.loadManagers();
                this.getCustomerStats();
                this.columnTitle = "Zonas";
            }

        }
    },
    computed: {
        metricSelcted() {
            return this.metrics.find(metric => metric.value === this.filters.metric);
        }
    }
};
</script>