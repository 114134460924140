<template>
  <location-maps-record @acceptLocation="acceptLocation"/>
</template>

<script>
import LocationMapsRecord from './components/LocationMapsRecord.vue';
import { mapGetters, mapMutations } from "vuex";
import axios from "@/plugins/axios";
import moment from "moment";

export default {
    components: { LocationMapsRecord },

    methods:{
        async getLocationCustomer() {
            try{
                const response = await axios.get(`cover/location-customer/${this.customerCode}`);        
                const location = response.data.data;   
                
                if(location != null){
                    this.SET_GEOLOCATION({
                        lat: location.latitude,
                        lng: location.longitude,
                        updatedAt: location.updated_at
                    });                
                    this.SET_UPDATE_LOCATION(true);
                }   

                this.SET_SHOW_EDIT_MAP(true);
            }catch(error){
                this.$toast.error('Error al obtener la ubicación del mapa. Por favor inténtelo nuevamente.');
            }
        },

        async storeLocationCustomer(){  
            try{
                await axios.post('cover/location-customer', {          
                    customer: this.customerCode,
                    latitude: this.geolocation.lat,
                    longitude: this.geolocation.lng                  
                });   
                
                this.SET_GEOLOCATION({
                    lat: this.geolocation.lat,
                    lng: this.geolocation.lng,
                    updatedAt: moment()
                });
                                
                this.SET_UPDATE_LOCATION(true); 

                this.$toast.success('Ubicación registrada con éxito.');
            }catch(error){         
                this.$toast.error('Error al registrar la ubicación. Inténtelo nuevamente.');
            }  
        },

        async updateLocationCustomer(){
            try{
                await axios.put('cover/location-customer', {
                    customer: this.customerCode,
                    latitude: this.geolocation.lat,
                    longitude: this.geolocation.lng
                });

                this.SET_GEOLOCATION({
                    lat: this.geolocation.lat,
                    lng: this.geolocation.lng,
                    updatedAt: moment()
                });

                this.$toast.success('Ubicación actualizada con éxito.');
            }catch(error){    
                this.$toast.error('Error al actualizar la ubicación. Inténtelo nuevamente.');
            }  
        },

        acceptLocation(){            
            this.updateLocation ? this.updateLocationCustomer() : this.storeLocationCustomer();            
        },
        
        ...mapMutations('coordinatesRecords', ['SET_UPDATE_LOCATION', 'SET_SHOW_EDIT_MAP', 'SET_GEOLOCATION'])
    },

    computed:{
        ...mapGetters('coordinatesRecords', ['activeTaxAdress', 'geolocation', 'updateLocation', 'customerCode']), 
    },
    
    watch:{
        activeTaxAdress(newVal){
            if(newVal){
                this.SET_GEOLOCATION({ lat: '', lng: '', updatedAt: ''});
                this.getLocationCustomer();  
            }          
        },
    },    
}
</script>

<style>

</style>