<template>
    <div id="container-invoicing-manager">      
      <v-container fluid class="white lighten-5" style="padding-left: 3%;padding-right: 3%;">
  
          <!-- formulario bsuqueda -->
          <div class="cont-shadow  bloq-height mt-5">
            <div class="d-flex mt-0 mb-0 align-items-center">
              <div class="d-flex mb-0 align-items-center">
                <img src="img/arrow-u-left-top.svg">
                <label class="ml-4 mr-2">Zona:</label>
                <v-text-field dense solo small hide-details="auto" class="text-ped pt-0  mb-0" v-model="searchForm.zona" :disabled="!!searchForm.cliente"
                  required type="text">
                  <template v-slot:append>
                    <v-btn v-if="searchForm.zona" icon @click="clearInput">
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>             
                <label class="ml-4 mr-2">Cliente:</label>    
                <v-text-field dense solo small hide-details="auto" class="text-ped pt-0  mb-0" v-model="searchForm.cliente" :disabled="!!searchForm.zona"
                  required type="text">
                  <template v-slot:append>
                    <v-btn v-if="searchForm.cliente" icon @click="clearInput">
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>                   
                <div class="ml-4">
                  <v-btn  class="btn-danger btn-custom rounded-pill" block>Consultar</v-btn>
                </div>
            </div>
            <div class="ml-auto text-right" >
                  <div style="display: flex;">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                           <span  v-bind="attrs" v-on="on"  class="mb-0 mr-3"><a href="#"><img src="img/book-edit.svg"></a></span>
                        </template><span>Directorio</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                           <span  v-bind="attrs" v-on="on"  class="mb-0"><a href="#"><img src="img/notebook.svg"></a></span>
                        </template><span>Agenda</span>
                    </v-tooltip>
                 </div>
              </div>
          </div>   
          <!-- end formulario busqueda -->
      </div>
          <!-- tabla principal -->
          <v-progress-linear :active="loadingOrderDeliveryTime" :indeterminate="loadingOrderDeliveryTime" top primary></v-progress-linear>
          <v-data-table   
              :headers="headerManager"
              :items="dataDirector"
              :footer-props="footerProps"
              :options="dataTableOptions"  
              :sort-desc="sortDesc"    
              :mobile-breakpoint="null"   
              :loading="isLoading"        
              class="elevation-2 table-renglonaje  tabla-zebra"
              hide-default-footer
              > 
              <template v-slot:[`header.total_orders`]="{ item }"> <span>{{item}}Pedidos de {{monthName}} (mes en curso)</span></template>
  
               <template v-slot:[`item.zone_code`]="{ item }">
                  <div class="d-flex align-items-csenter justify-content-start">
                      <div class="ml-4 text-left">
                          <div style="min-width: 200px">
                              <span v-if="getProfile">{{capitalize(user.name)}} </span>
                              <span v-else>{{channelLabel}} </span>
                              <span>{{item.num_population}} h {{item.avergae_population | formatCurrency}} $/h</span>
                          </div>
                      </div>
  
                  </div>
              </template>
  
              <template v-slot:[`item.total_orders`]="{ item }">
                  ${{item.total_orders | formatCurrency}}
              </template>
  
              <template v-slot:[`item.backlog`]="{ item }">
                  ${{item.backlog | formatCurrency}}
              </template>
  
              <template v-slot:[`item.m_gross_billed`]="{ item }">
                  ${{item.m_gross_billed | formatCurrency}}
              </template>
  
              <template v-slot:[`item.credit`]="{ item }">
                  ${{item.credit | formatCurrency}}
              </template>
  
              <template v-slot:[`item.net_billed`]="{ item }">
                  ${{item.net_billed | formatCurrency}}
              </template>
          
          </v-data-table>
          <!-- end tabla principal -->
  
          <!-- formulario filtro -->
           <div class="cont-shadow bloq-height">
            <div class="row items-center">
              <div class="col-md-6 col-12 col-lg-6">
                  <div class="d-flex align-items-center " >   
                      <label class="filter-text">Agrupar por:</label>  
                      <v-select :items="filteredData" item-text="name" item-value="value" v-model="grupo" @change="getBillingData()" hide-details small dense solo
                          placeholder="Perfil" class="ml-2">
                      </v-select>
                      <label class="filter-text ml-2">Periodo:</label>
                      <v-select :items="months" item-text="mes" item-value="numero" v-model="periodoMes"  @change="getBillingData()" hide-details small dense solo
                          placeholder="Mes" class="ml-2"></v-select>
                      <v-select :items="this.litsYears" item-text="mes" v-model="selectedYear"  @change="getBillingData()" hide-details small dense solo placeholder="Año"
                          class="ml-2"></v-select>
                      </div>
                  </div>
              </div> 
            </div>
  
          <!-- end formulario filtro -->
          <!-- tabla listado de asesores -->
          <v-progress-linear :active="loadingOrderDeliveryTime" :indeterminate="loadingOrderDeliveryTime" top primary></v-progress-linear>
          <v-data-table   
              :headers="headerAsesores"
              :items="dataManager"
              :footer-props="footerProps"
              :options="dataTableOptions"  
              :sort-desc="sortDesc"    
              :mobile-breakpoint="null"   
              :loading="isLoading"        
              class="elevation-2 table-renglonaje"> 
              <template v-slot:[`header.total_orders`]="{ item }">{{item}}Pedidos de {{monthName}} (mes en curso)</template>
              <template v-slot:[`header.service_level`]="{ item }">
                <div v-if="grupo == 'G' || grupo == 'A'">{{item}}Promedio de días de atraso</div>
                <div v-else>{{item}}Nivel de servicio de lo facturado</div>
              </template>
  
               <template v-slot:[`item.id_zone`]="{ item }">

                    <div v-if="item.type == 'G' || item.type == 'A'">
                     <div class="d-flex align-items-center justify-content-start">
                       <div class="ml-1" style="min-width: 13px">
                           {{item.id}}
                       </div>
     
                      <div class="ml-0">
                          <div style="min-width:80px">
                              <a style="text-decoration: underline;font-weight: bold;" @click="sendSellerHome(item.code)">{{ item.code }}</a>
                          </div>
                          <div style="min-width: 80px">
                              <img src="img/car-front-fill-gray.svg" />
                          </div>
                      </div>
  
                      <div class="ml-1 p-1">
                          <div :class="['circle', getColor(item.trafficlight)]"></div>
                          </div>
  
                          <div class="ml-2 text-left">
                          <div style="min-width: 220px">
                              <span>{{capitalize(item.name)}}</span>
                          </div>
                          <div>
                              <p class="mb-0"><b>{{item.text_zone}}</b></p>
                          </div>
                      </div>
  
                      <div class="ml-auto text-right mb-5">
                          <div style="min-width: 60px">
                             <p class="mb-0"><b>{{item.num_population}} h</b></p>
                             <p class="mb-0"><b>{{item.avergae_population | formatCurrency}} $/h</b></p>
                          </div>
                      </div>
                     </div>
                    </div>
                    <div v-else>
                        <div class="d-flex align-items-center justify-content-start">
                        <div class="ml-2 text-left" style="min-width: 220px">
                              <span style="cursor: pointer; text-decoration: underline" @click="sendManagerHome(item.code)">{{item.name}} ({{item.text_zone}})</span>
                      </div>
                      </div>
                    </div>
              </template>
  
              <template v-slot:[`item.total_orders`]="{ item }">
                  ${{item.total_orders | formatCurrency}}
              </template>
  
              <template v-slot:[`item.backlog`]="{ item }">
                  ${{item.backlog | formatCurrency}}
              </template>
  
              <template v-slot:[`item.m_gross_billed`]="{ item }">
                  ${{item.m_gross_billed | formatCurrency}}
              </template>
  
              <template v-slot:[`item.credit`]="{ item }">
                  ${{item.credit | formatCurrency}}
              </template>
  
              <template v-slot:[`item.net_billed`]="{ item }">
                  ${{item.net_billed | formatCurrency}}
              </template>

              <template v-slot:[`item.service_level`]="{ item }">
                <div v-if="grupo == 'G' || grupo == 'A'">{{item.days_deadline}}</div>
                <div v-else>{{item.service_level}}</div>
              </template>
  
              <template v-slot:[`item.pendidng_apli`]="{ item }">
                  <div v-if="item.applications == '' || item.type == 'Manager'">
                      {{item.pendidng_apli}}
                  </div>
                  <div v-else>
                    <v-menu v-model="menus[item.code]"  open-on-hover  :close-on-content-click="false" location="end" :key="item.code">
                      <template v-slot:activator="{  on, attrs  }">
                          <a v-bind="attrs" v-on="on" class="text-application">{{item.pendidng_apli}}</a>
                      </template>
                      <BillingAplications :applications="item" :pending="item.pendidng_apli" :index="item.code" :app_one="item.total_app" ></BillingAplications>
                      
                   </v-menu>
                  </div>
              </template>
          
          </v-data-table>
          <!-- end tabla listado de asesores -->
  
          <div class="row mt-4">
              <div class="col">
                  <ConfidentialityNotice></ConfidentialityNotice>
              </div>
          </div>
  
      </v-container>
    </div>
  </template>
    
<script>
import axios from "@/plugins/axios";
import { mapGetters, mapActions } from "vuex";
import Service from "@/mixins/service.js"
import BillingAplications from "./components/BillingAplications.vue"; 
import ConfidentialityNotice from '@/components/ConfidentialityNotice';
import moment from 'moment';
import helpers from "@/mixins/service.js"

export default {
    components:{ ConfidentialityNotice,BillingAplications},
    mixins: [Service,helpers],
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name === 'SignIn') {
                vm.activeLoader();
            }
        });
    },
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
            user: 'auth/user'            
        }),
        filteredData(){
            return  this.agrupar.filter((item) => {
               if (this.getProfile == true) 
                 return  item.value != 'G' && item.value != 'D'
                 return  item.value != 'A'
            });
        },
        getProfile(){
            if(this.$hasAnyRole(['RegionalManager','IELRegManager'])){
                return true;
            }
           return false;
        },
    },
    data(){
        return{
            headerAsesores:[
                { text: 'Zona', value: 'id_zone', align:"center"},
                { text: 'Pedidos', value: 'total_orders', align:"center", sortable: false },
                { text: 'Backlog*', value: 'backlog', align:"center" },
                { text: 'Equivalencia de backlog en días', value: 'equiv_backlog', align:"center" },
                { text: 'Facturado bruto del mes', value: 'm_gross_billed', align:"center" },
                { text: 'Creditos del mes', value: 'credit', align:"center" },
                { text: 'Facturado neto del mes', value: 'net_billed', align:"center" },
                { text: 'Nivel de servicio de lo facturado', value: 'service_level', align:"center" },
                { text: 'Aplicaciones pendientes (con más de 48 hrs.)', value: 'pendidng_apli', align:"center" },
                { text: 'Pedidos bloqueados', value: 'block_orders', align:"center" }
            ], 
            footerProps: {
              itemsPerPageOptions: [10, 14, 24, 34, { text: 'Todas', value: -1 }],
              itemsPerPageText: 'Mostrar en grupo de:',
              showCurrentPage: false,
              pageText: 'Desplegado {0} / {1} de {2}'           
            }, 
            dataTableOptions: {
                itemsPerPage: 24,
                pagination: {
                    enabled: true,
                    options: {
                        numeric: true,
                    },
                },
            },
            headerManager:[
                { text: 'Gerencia', value: 'zone_code', align:"center", sortable: false },
                { text: 'Pedidos de', value: 'total_orders', align:"center", sortable: false },
                { text: 'Backlog*', value: 'backlog', align:"center",  sortable: false},
                { text: 'Equivalencia de backlog en días', value: 'equiv_backlog', align:"center", sortable: false  },
                { text: 'Facturado bruto del mes', value: 'm_gross_billed', align:"center", sortable: false  },
                { text: 'Creditos del mes', value: 'credit', align:"center", sortable: false  },
                { text: 'Facturado neto del mes', value: 'net_billed', align:"center", sortable: false  },
                { text: 'Nivel de servicio de lo facturado', value: 'service_level', align:"center", sortable: false  },
                { text: 'Aplicaciones pendientes (con más de 48 hrs.)', value: 'pendidng_apli', align:"center", sortable: false  },
                { text: 'Pedidos bloqueados', value: 'block_orders', align:"center",sortable: false  }
            ],
            sortDesc: false, 
            months: [
                { mes: 'Enero', numero: '01' },
                { mes: 'Febrero', numero: '02' },
                { mes: 'Marzo', numero: '03' },
                { mes: 'Abril', numero: '04' },
                { mes: 'Mayo', numero: '05' },
                { mes: 'Junio', numero: '06' },
                { mes: 'Julio', numero: '07' },
                { mes: 'Agosto', numero: '08' },
                { mes: 'Septiembre', numero: '09' },
                { mes: 'Octubre', numero: 10 },
                { mes: 'Noviembre', numero: 11 },
                { mes: 'Diciembre', numero: 12 },
            ],  
            agrupar:[
              {name:'Gerente Regional', value:'D'},
              {name:'Gerente', value:'R'},
              {name:'Zona', value:'G'},
              {name:'Zona', value:'A'},
            ],
            grupo: 'D',
            periodoMes: '01',
            dataDirector:[],
            dataManager:[],
            dataSeller:[],
            monthName: null,
            isLoading: false,
            loadingOrderDeliveryTime:true,
            month_year: null,
            selectedYear: new Date().getFullYear(),
            litsYears:[],
            menus:[],
            searchForm: {
            zona: null,
            cliente: null,
            },
            today2: moment().format('YYYY/MM/DD'), 
            itemCSV:[],
            profile:null,
        }
    },
    watch: {
        dataManager: {
        immediate: true,
        handler() {
            this.generarExcel()
        },
      },
      dataSeller: {
        immediate: true,
        handler() {
            this.generarExcel()
        },
      },
   },
    mounted(){
        this.grupo = this.getProfile == true ? 'R':"D";
        this.generarPDF();
        this.litsYears = this.generarListaDeAnios(8);
        this.formattedDate();
        this.getBillingData();
        
        
    },
    methods:{
        ...mapActions({
            sendToPDF: 'printer/sendToPDF',
            sendToExcel: 'printer/sendToExcel',
            activeLoader: 'loader/activeLoader'
        }),
        generarPDF() {
            this.sendToPDF({ active: true });
        },
        generarExcel() {
            let newItems = [];
            this.itemCSV = [];

            if(this.grupo == 'D'){

            for (let key in this.dataManager) {
                let item = this.dataManager[key];
                newItems.push({
                    'Zona': item.id_zone,
                    'Nombre': item.name,
                    'Pedidos': item.total_orders,
                    'Backlog': item.backlog,
                    'Equivalencia de backlog en día': item.equiv_backlog,
                    'Facturado bruto del mes.': item.m_gross_billed,
                    'Creditos del mes': item.credit,
                    'Facturado neto del mes': item.net_billed,
                    'Promedio de días de atraso': item.days_deadline,
                    'Aplicaciones pendientes (con más de 48 hrs.)': item.pendidng_apli,
                    'Pedidos bloqueados': item.block_orders,
                });

            }
        }else{

            for (let key in this.dataSeller) {
                let item = this.dataSeller[key];
                newItems.push({
                    'Zona': item.id_zone,
                    'Nombre': item.name,
                    'Pedidos': item.total_orders,
                    'Backlog': item.backlog,
                    'Equivalencia de backlog en día': item.equiv_backlog,
                    'Facturado bruto del mes.': item.m_gross_billed,
                    'Creditos del mes': item.credit,
                    'Facturado neto del mes': item.net_billed,
                    'Promedio de días de atraso': item.days_deadline,
                    'Aplicaciones pendientes (con más de 48 hrs.)': item.pendidng_apli,
                    'Pedidos bloqueados': item.block_orders,
                });

            }

        }
            this.itemCSV = newItems;
            let objectCSV = {
                'name': `Fact.Dir-${this.today2}`,
                'items': this.itemCSV,
                'showLabels': true
            }

            this.sendToExcel(objectCSV)
        },
        async getBillingData(){
        this.loadingOrderDeliveryTime = true;
        const params = {
            idUser: this.getProfile == true ? "0" + this.user.employee_number:"",
            month_year: this.selectedYear + "-" + this.periodoMes,
            channel: this.user.VTWEG,
            filter: this.grupo,
        };

        try {
         const response = await axios.post("/billingDirectorData", params);
           this.dataManager = response.data.listManager;
           this.dataDirector = response.data.dataDirector;
           this.selectedMonth(this.periodoMes);
           this.loadingOrderDeliveryTime = false;
      
        } catch (error) {
         this.error = "No se pudo obtener las facturas";
        }

        },
        clearInput() {
         this.searchForm.zona = "";  
         this.searchForm.cliente = "";    
        },
        getColor(dato){
          const color = {
            0: 'grey',
            1: 'red',
            2: 'yellow',
            3: 'green'
          }

          return color[dato]
        },
        selectedMonth(month){
           const monthString = Intl.DateTimeFormat('es-MX', { month: 'long' }).format(new Date(month));
           this.monthName = monthString ? monthString[0].toUpperCase() + monthString.slice(1) : "";
        },
        sendManagerHome(code){
            this.$setLocalStorageManager(code);
            this.$router.push({ name: 'ManagerHome' });
        },
        sendSellerHome(code){
            this.$setLocalStorageSeller(code);
            this.$router.push({ name: 'SellerResults' });
        },
        formattedDate() {
          const locale = "es-MX";
          const date = new Date(); 
          this.periodoMes = (date.getMonth() + 1);
          this.monthName = date.toLocaleString(locale, { month: "long" });
          if(this.periodoMes <= 9)
          this.periodoMes = '0'+this.periodoMes;
          },
    },
}
</script>
    
<style scoped src="../../assets/css/managerbilling.css"></style>
