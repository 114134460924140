export default {
  namespaced: true,

  state: {
    showModal: false,
    steps: [],
    currentStep: 1,
  },

  getters: {
    showModal: (state) => state.showModal,
    hasSteps: (state) => state.steps.length > 0,
    currentStepVal: (state) => state.currentStep,
    steps: (state) => {
      return state.steps.map((step, index) => {
        return {
          ...step,
          value: index + 1,
          visited: index === 0 ? true : step.visited || false,
        };
      });
    },
  },

  mutations: {
    SET_SHOW_MODAL(state, showValue) {
      state.showModal = showValue;
    },

    ADD_STEP(state, step) {
      const isStepInList =
        state.steps.findIndex((s) => s.key === step.key) >= 0;

      if (!isStepInList) {
        state.steps.push(step);
      }
    },

    REMOVE_STEP(state, stepKey) {
      const stepIndex = state.steps.findIndex((s) => s.key === stepKey);

      if (stepIndex >= 0) {
        state.steps.splice(stepIndex, 1);
        if (state.steps.length > 0) {
          if (state.currentStep > state.steps.length) {
            state.currentStep = state.steps.length;
          }
        } else {
          state.showModal = false;
        }
      }
    },

    CHANGE_STEP(state, stepValue) {
      state.currentStep = stepValue;
    },

    CLEAN_STEPS(state) {
      state.steps = [];
      state.currentStep = 1;
    },
  },

  actions: {
    async loadUpdateDataStep({ commit, rootGetters }) {
      if (rootGetters['updateData/mustUpdateData']) {
        commit('ADD_STEP', {
          key: 'updateData',
          text: 'Actualización de datos de contacto',
          doneRequired: true,
        });
      }
      commit('REMOVE_STEP', 'notifications');
      commit('REMOVE_STEP', 'reminders');
      commit('REMOVE_STEP', 'updatePassword');
      commit('REMOVE_STEP', 'shoppingCartAlerts');
    },
    async loadRemindersStep({ commit, rootGetters }) {
      if (rootGetters['reminders/hasAvailableReminders']) {
        commit('ADD_STEP', {
          key: 'reminders',
          text: 'Backorders',
          doneRequired: false,
        });
      }
      commit('REMOVE_STEP', 'notifications');
      commit('REMOVE_STEP', 'updateData');
      commit('REMOVE_STEP', 'updatePassword');
      commit('REMOVE_STEP', 'shoppingCartAlerts');
    },
    async loadUpdatePasswordStep({ commit, rootGetters }) {
      if (rootGetters['updatePassword/wantUpdatePassword']) {
        commit('ADD_STEP', {
          key: 'updatePassword',
          text: 'Actualización de contraseña',
          doneRequired: true,
        });
      }
      commit('REMOVE_STEP', 'notifications');
      commit('REMOVE_STEP', 'updateData');
      commit('REMOVE_STEP', 'reminders');
      commit('REMOVE_STEP', 'shoppingCartAlerts');
    },
    loadShoppingCartAlertsStep(
      { commit, dispatch },
      { doctype, isBackOrder, isConvert }
    ) {
      let text = 'Alertas de Carrito';

      if (isBackOrder) {
        text = 'Pedido Backorder';
      } else {
        switch (doctype) {
          case 'PSIU':
          case 'PSDT':
            text = 'Nuevo Pedido';
            break;
          case 'PSNW':
            text = 'Pedido de Productos Nuevos (PNC)';
            break;
          case 'PSPO':
            text = 'Pedido de Artículos Promocionales (POP)';
            break;
        }

        text = isConvert ? `Convertir a ${text}` : text;
      }

      dispatch('shoppingCartAlerts/setShoppingCartAlertsDoctype', doctype, {
        root: true,
      });
      dispatch(
        'shoppingCartAlerts/setShoppingCartAlertsIsBackOrder',
        isBackOrder,
        { root: true }
      );

      commit('REMOVE_STEP', 'notifications');
      commit('REMOVE_STEP', 'updateData');
      commit('REMOVE_STEP', 'reminders');
      commit('REMOVE_STEP', 'updatePassword');
      commit('REMOVE_STEP', 'shoppingCartAlerts');

      commit('ADD_STEP', {
        key: 'shoppingCartAlerts',
        text,
        doneRequired: false,
      });
    },
    async loadModalSteps({ commit, dispatch, rootGetters }) {
      const userId = rootGetters['auth/userCode'];

      if (userId) {
        await dispatch('buildSteps');
      } else {
        commit('CLEAN_STEPS');
        dispatch('reminders/cleanReminders', null, { root: true });
      }
    },

    changeCurrentStepValue({ commit }, newStepValue) {
      commit('CHANGE_STEP', newStepValue);
    },

    async buildSteps({ dispatch }) {
      await dispatch('loadSteps');
      await dispatch('syncSteps');
    },

    syncSteps({ commit, rootGetters }) {
      if (rootGetters['userNotification/hasNotifications']) {
        commit('ADD_STEP', {
          key: 'notifications',
          text: 'Actualización IEL',
          doneRequired: false,
        });
      } else {
        commit('REMOVE_STEP', 'notifications');
      }
      if (rootGetters['updateData/mustUpdateData']) {
        commit('ADD_STEP', {
          key: 'updateData',
          text: 'Actualización de datos de contacto',
          doneRequired: true,
        });
      } else {
        commit('REMOVE_STEP', 'updateData');
      }
      if (rootGetters['reminders/hasAvailableReminders']) {
        commit('ADD_STEP', {
          key: 'reminders',
          text: 'Backorders',
          doneRequired: false,
        });
      } else {
        commit('REMOVE_STEP', 'reminders');
      }
      if (rootGetters['updatePassword/wantUpdatePassword']) {
        commit('ADD_STEP', {
          key: 'updatePassword',
          text: 'Actualización de contraseña',
          doneRequired: true,
        });
      } else {
        commit('REMOVE_STEP', 'updatePassword');
      }
    },

    addManualStep({ commit }, step) {
      commit('ADD_STEP', {
        key: step.key,
        text: step.text,
        doneRequired: step.doneRequired || false,
      });
      commit('REMOVE_STEP', 'notifications');
      commit('REMOVE_STEP', 'reminders');
      commit('REMOVE_STEP', 'updateData');
      commit('REMOVE_STEP', 'updatePassword');
      commit('REMOVE_STEP', 'shoppingCartAlerts');
    },

    async loadSteps({ dispatch }) {
      await dispatch('reminders/loadReminders', null, { root: true });
      await dispatch('userNotification/checkUserNotification', null, {
        root: true,
      });
      await dispatch('updateData/checkSetNewUserInfo', null, { root: true });
      await dispatch('updatePassword/checkMustChangePassword', true, {
        root: true,
      });
    },

    findStepByKey({ getters }, key) {
      return getters.steps.find((s) => s.key === key) || null;
    },

    removeStep({ commit, state }, key) {
      commit('REMOVE_STEP', key);

      if (state.steps.length > 0) {
        if (state.currentStep > state.steps.length) {
          commit('CHANGE_STEP', state.steps.length);
        }
      } else {
        // if no more steps, close modal
        commit('SET_SHOW_MODAL', false);
      }
    },

    updateStepStatus({ state }, step) {
      const index = state.steps.findIndex((s) => s.key === step.key);

      if (index >= 0) {
        state.steps.splice(index, 1, step);
      }
    },

    updateModalState({ commit }, state) {
      commit('SET_SHOW_MODAL', state);
    },
  },
};
