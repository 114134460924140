<template>
  <div class="container-fluid">
    <CardTitle title="BENEFICIOS FINANCIEROS" icon="chart-box-outline">
      <template v-slot:content>
        <v-card  class="mx-auto elevation-5 " outlined>
        <div>   
          <v-progress-linear v-if="loading" :indeterminate="loading" color="cyan"  ></v-progress-linear>
          <div v-if="!loading"  class="elevation-5">
            <div class="card-body text-lg-center">
              
              <v-simple-table  dense >
                <template v-slot:default>
                  <thead>
                    <tr class=" text-black custom-body-hs font-size-x" > 
                      <td class="box-shadow" v-for="(item,index) in headers_bono_ind" :key="index" :style="index<1?'width:42%':''">{{ item.text }}</td> 
                    </tr>
                  </thead>
                  <tbody >
                    <tr v-for="(item, index) in bonus" :key="index" class="no-border custom-body-hs font-size-x">
                      <td class="text-left pl-1 pr-0 pt-0 pb-0">
                        <span class="ml-sm-10">
                          <router-link  v-if="isCashActive(item.CODIGO)"  :to="{ name: 'AccountStatus' }"  class="text-decoration-none text-black text-decoration-underline" >
                            {{ formatTitle(item.TITULO) }}
                          </router-link>
                          <span v-else>
                            {{ formatTitle(item.TITULO) }}
                          </span>
                        </span>
                        <v-progress-circular v-if="loadingCashRegister" indeterminate color="primary" />
                        <v-tooltip v-else-if="showCashRegisterLabel(item)" top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" :color="cashRegisterColor[index]">mdi-cash-register</v-icon>
                          </template>
                          <span style="white-space: pre-wrap;">{{ showMessage(item.CODIGO, index) }}</span>
                        </v-tooltip>
                      </td>
                      <td v-for="period in [4, 3, 2, 1]" :key="period" >
                        <register-cash
                        :value="Number (item[`PERIODO${period}`])"
                        :cashRegisterColor="cashRegisterColorItem(period)" 
                        :showValue="showValue(period)"
                        :showCashRegister="showCashRegister(period,item)"
                        :showPendingText="period==1 && !isPR"
                        :showActionLink="showActionLink(item,period)"
                        @actionLink="showTable(period,isPrePayment(item.CODIGO) ? 1 : 2)"
                         />
                      </td>
                    </tr>
                    <tr class=" font-size-x tex" v-if="!isPR">
                      <td class="text-left">
                        <span class="ml-sm-10 font-weight-bold">Nota: Los montos de los bonos son por el periodo que se obtuvieron </span>
                      </td> 
                      <td class="font-weight-bold pl-1 pt-0 pb-0 pr-0 qb-border-top-left" >
                        <v-progress-circular v-if="loadingIelBonus" indeterminate  color="primary"  ></v-progress-circular>
                        ${{ number_format(ielBonus?.PERIODO4 || 0,true) }}
                      </td>  
                      <td class="qb-border-top">${{ number_format(ielBonus?.PERIODO3 || 0,true) }}</td>
                      <td class="qb-border-top">${{ number_format(ielBonus?.PERIODO2 || 0,true) }}</td>
                      <td class="qb-border-top-right">
                        <span v-if="isPR"> ${{ number_format(ielBonus.PERIODO1,true) }}&nbsp; </span>
                        <span v-else class="font-weight-bold"> Por pagos en línea </span> 
                      </td> 
                    </tr>
                   
                  </tbody>
                </template>
              </v-simple-table>    
            </div>
          </div>
        </div>
      </v-card> 
      </template>
    </CardTitle> 
    <v-dialog v-model="modalPaymentDetails" :persistent="loading" width="auto">
      <payment-bonus-details-table :downloadble="false" :paymentDetailsHeader="paymentDetailsHeader"  :loading="loadingPaymentDetails" :paymentDetails="paymentDetails" :title="paymentTypeTitle" v-on:closeModal="closePaymentModal" />
    </v-dialog>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
import  helpers from '@/mixins/helpers.js';
import CardTitle from '@/components/CardTitle.vue'
import PaymentBonusDetailsTable  from "../indicators_payment/components/PaymentBonusDetailsTable.vue";
import RegisterCash from '@/components/RegisterCash.vue'
import moment from "moment";
export default {
  name:"QuarterlyBonus",
  mixins:[helpers],
  components: {
    PaymentBonusDetailsTable,
    CardTitle,
    RegisterCash
  },
  props: {
    generalInfoCustomer:{},
    }, 
    watch:{
      generalInfoCustomer(){
        let self = this;
        self.getBonus();
        if (this.generalInfoCustomer.VTWEG!='PR') {
          self.getIelBonus();
        }else{
          this.isPR=true;
        } 
        this.loadCashRegister();
      }
    },
    data() {
      return {
        ielBonus:{},
        isPR:false,
        loadingIelBonus:false,
        bonus: [],
        paymentDetails:[],
        headers_bono_ind: [],
        paymentTypeTitle:"",
        loading:true,
        modalPaymentDetails:false,
        loadingPaymentDetails:false,
        paymentDetailsHeader:[
          { text: "DOCTO PAGO", align: "center", value: "BELNR" }, 
          { text: "FE PAGO", align: "center", value: "BUDAT" },
          { text: "MONEDA", align: "center", value: "WAERS" },
          { text: "IMPORTE", align: "right", value: "IMPXIVA" },  
          { text: "FACTURA", align: "center", value: "REBZG" }, 
          { text: "IMPORTE NC DPP", align: "right", value: "IMPTENC" }, 
          { text: "TIPO DE PAGO", align: "center", value: "INDDPP" }, 
        ],
        cashRegister:[],
        loadingCashRegister:true,
        cashRegisterColor:[],
        validDateForPOPBonus:[],
      };
    },
    computed: {
      ...mapGetters({
        user: "auth/user",
      }),
    },
    mounted(){
      this.validDateForPOPBonus = moment().subtract(1, 'year').month(8);
    },
    methods: {
      showCashRegisterLabel(item){
        return this.isPR ? !this.isPrePayment(item.CODIGO) : true;
      },
      showValue(period){
        this.isPR ? true : period !== 1 ;
      },
      bonusCanBeApplied(date){
        //Aplica siempre y cuando sea despues de octubre del año pasado
          const lastquarter = moment(`${date}`,'MMM/YYYY');
          const lastYearSeptember = this.validDateForPOPBonus;
          return lastquarter.isAfter(lastYearSeptember);
      },
      cashRegisterColorItem(period){
       return  this.bonusCanBeApplied(this.getMonthNamesByPeriod(period)[0]) 
                ? 'green' : 'gray';
      },
      isPrePayment(paymentCode){
        return paymentCode == 201;
      },
      showActionLink(item,period){
       return !this.isPoPBonus(item['CODIGO']) && this.showCashRegister(period,item)  && !this.isPR
      },
      isPoPBonus(code){
      return  code  == 262;
      }, 
      showCashRegister(period,item) {
        if (this.isPR) return !this.isPrePayment(item.CODIGO);
        return period !== 1;
      },
      formatTitle(title) {
        return title.replace(/ /g, '\u00a0'); // Unicode for &nbsp;
      }, 
      findCashRegisterCode(code){
        return  this.cashRegister.find(item => item.CODIGO === code) || null;
      },
      isCashActive(code){
        if (this.isPR && this.isPrePayment(code)) {
          return false;
        }
        const item = this.findCashRegisterCode(code); 
        return item?.DISPO > 0;
      },
      showMessage(code, index) { 
      const item =  this.findCashRegisterCode(code);
      let amount = 0;
      let available = 0; 
        if (item) {
          if (item.DISPO > 0)  this.cashRegisterColor[index] = 'green'; 
          amount = item.MONTO; 
          available = item.DISPO; 
        }
      return `Total de bono ganado (importe c/IVA) :$ ${this.number_format(amount,false)}
        Total Acumulado disponible (importe c/IVA) :$ ${this.number_format(available)}`;
    },
    async loadCashRegister(){
        try{
            this.loadingCashRegister = true;
            const response = await axios.post('/cover/bonus/cash-register',
                {
                  customerCode: this.generalInfoCustomer.KUNNR,
                  channel: this.generalInfoCustomer.VTWEG,
                })    
            this.cashRegister = response.data
            this.loadingCashRegister = false;
        }catch(error){
            this.loadingCashRegister = false; 
            console.error(error);
        }
      },
      isCashPayment(paymentCode){ 
        return paymentCode == 273  ? true : false; 
      },  
      getIelBonus(){
        this.loadingIelBonus=true;
        const params = {
          customer_id: this.generalInfoCustomer.KUNNR, 
        }   
        axios.post("/cover/IELBonus",params)
          .then((response) => {
            this.ielBonus =  response.data;
            this.loadingIelBonus=false;
          },{
            noCancelRequest:true,
            cachable:true,
          })
          .catch((e) => { 
            console.error(e);
            this.loadingIelBonus=false;
          });
      },
      showTable(period,type){
        this.loadingPaymentDetails=true;
        this.modalPaymentDetails=true;
        this.paymentDetails=[];
        const per = this.trims[period-1][period]; 
        const year = 20+per.slice(-2)
        const trim =  this.getPeriodInString(per.slice(0,-3))  
        const params = {
            customer_code: this.generalInfoCustomer.KUNNR,
            trim:trim,
            year:year
        }  
        this.paymentTypeTitle="Bonos del Trimestre   " +  per ;
        axios.post("/indicatorsPayment/details",params)
          .then((response) => {
            var items = ( response.data.items == 1) ?  [response.data.data] : response.data.data;
            //Indica 1=Pago de Contad, 2=Pronto Pago
            this.paymentDetails = (type ==1)?  items.filter(item => item.INDDPP == 2):items.filter(item => item.INDDPP == 1);
            this.loadingPaymentDetails=false;  
          })
          .catch((e) => { 
            console.error(e);
            this.loadingPaymentDetails=false;
          });
      },
      closePaymentModal(){
        this.modalPaymentDetails=false;
      },
      getTotalByMonth(items){  
        var months = []; 
        for (let i =0; i < items.length ; i++) { 
            if (items[i].length == 1) {
              months.push(parseFloat(items[i][0].IMPXIVA));
            }else {
              let sum = 0;
              for (let j = 0; j < items[i].length;j++) {
                sum = sum + parseFloat(items[i][j].IMPXIVA) ;
                  }
              months.push(sum) //parsed to int ;
            } 
          }  
        return months; 
      },
      getMonthNamesByPeriod(period){
        var currentTrim = (this.trims[period-1][period]).split("/") 
        var months = currentTrim[0].split("-"); 
        var year ="20"+currentTrim[1];
        for (let i = 0; i < months.length; i++) { 
          months[i] = months[i]+"/"+year ;
          }  
      return months;
      },  
      getPeriodInString(period) {
        switch (period) {
          case 'Ene-Feb-Mar':  period = 1; break; 
          case 'Abr-May-Jun':  period = 2; break; 
          case 'Jul-Ago-Sep':  period = 3; break;  
          default: period = 4; break;
        }
        return period;
      },
      getBonus() {
        this.loading=true;
        axios
          .post("indicatorsBonos", {
            client_code: this.generalInfoCustomer.KUNNR,
            channel: this.generalInfoCustomer.VTWEG, 
          },{
            noCancelRequest:true,
            cachable:true,
          })
          .then((response) => {
            var headerBonus = response.data.header;
            var bonus  = response.data.data;
            this.bonus = bonus.filter(function (item){ 
              if (item.TITULO != 'Beneficios Adicionales al precio de distribuidor') { 
                return item;
              }  
            })  
            this.headers_bono_ind = [
          {
            text: "Concepto",
            align: "start",
            value: "TITULO",
            class: "border-bottom: none;",
            sortable: false,
          },
        // { text: '', value: 'data-table-expand' },
          { text: headerBonus[0].PERIODO4, sortable: false, value: "PERIODO1" },
          { text: headerBonus[0].PERIODO3, sortable: false, value: "PERIODO2" },
          { text: headerBonus[0].PERIODO2, sortable: false, value: "PERIODO3" },
          { text: headerBonus[0].PERIODO1, sortable: false, value: "PERIODO4" },
        ]
        this.trims = [
                      {1: headerBonus[0].PERIODO1},
                      {2: headerBonus[0].PERIODO2},
                      {3: headerBonus[0].PERIODO3},
                      {4: headerBonus[0].PERIODO4},
                  ]
                  this.loading=false;
          })
          .catch((e) => {
            
          this.loading=false;
            console.log(e);
          });
      }
    },
};
</script>

<style src="@/assets/css/customerHome.css" scoped></style> 
