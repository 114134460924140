
<template>
    <div class="outter-div">
    <span  v-for="(item, i) in validateGenetic" :key="i.id" style="display:inline-block;" >
      <v-tooltip
      bottom
      v-if="item.id == genetics"
      >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
         <img 
              :src="require(`@/assets/img/icons_orders/${genetics}.png`)"
              height="30px"
              width="30px"
              alt=""
              class="mr-2 ml-2 mt-2"
            />
          </span>
        </template>
        <span>{{item.description}}</span>
      </v-tooltip>
    </span>
    </div>
  </template>
  <script>
  export default {
    name: "SpecialGeneticsIcons",
    props: {
        genetics: {
        type: String,
        required: true
      },
    },
    data: () => ({
    validateGenetic: [
      { id: "N", description: "Producto Nuevo en Catálogo"},
      { id: "D", description: "Producto Descontinuado"},
      { id: "O", description: "Producto Obsoleto" },
      { id: "A", description: "Producto Alta Rotación" },
      { id: "B", description: "Producto Media Rotación" },
      { id: "C", description: "Producto Bajo Pedido" },
    ],
  }),
  };
  </script>
  <style scoped>
  .outter-div{
    display: inline-block;
  }
   div+span+span:after {  /* last span element's pseudo element */
     display: block;
   }
  </style>
  