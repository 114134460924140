<template>
  <div>
    <!-- Title -->
    <h5 class="font-weight-bold text-left mb-4">{{ activeTaxAdress ? "Domicilio fiscal" : "Destinatario de Mercancia"}}:</h5>
    
    <div class="row d-flex align-items-center">
      <div class="col-12 col-md-8 text-left"> 
        <!-- Text Addrees -->
        <h3 v-if="activeTaxAdress" style="font-size: 15px">{{ address }}</h3>

        <!-- Select Reception Merchandise -->
         <div class="d-flex align-items-center">
            <v-progress-circular
              v-if="showLoaderSelect"
              indeterminate
              color="primary"
              class="mr-2"
            ></v-progress-circular>
            <v-select
              v-if="showSelectRecipients"
              :items="recipientsOptions"
              item-text="ADDRESS"
              item-value="KUNNR"
              v-model="localSelectedRecipient"
              dense
              solo
              hide-details
              label="Seleccione ubicación"
              :disabled="showBtnActions"
            ></v-select>
         </div>        
        <!-- End Select Reception Merchandise -->
      </div>

      <!-- Buttons Register and Update -->
      <div class="col-12 col-md-4 text-center">
         <v-btn
          v-if="showEditMap && !$hasAnyRole(['Customer', 'CustomerPR'])"
          class="text-capitalize custom-red-button font-size-x w-100"
          style="max-width: 350px"
          color="error"
          dark
          small
          rounded
          @click="setMapLocation()"
        >
          {{ updateLocation ? "Actualizar" : "Registrar" }} Coordenadas
        </v-btn> 
        <!-- End Buttons Register and Update -->
      </div>
    </div>

    <div class="py-5" v-if="showLoader">
      <!-- Loader -->
       <div v-if="!showEditMap && geolocation.lat == '' && geolocation.lng == ''">
        <v-progress-circular
          indeterminate
          color="primary"
          class="mr-2"
        ></v-progress-circular>
        <small class="text-secondary">Cargando mapa</small>
      </div>

      <!-- Map -->
      <div v-else-if="geolocation.lat != '' && geolocation.lng != ''">       
        <p class="text-left"><b>Latitud: </b> {{ geolocation.lat }} <b>Longitud: </b> {{ geolocation.lng }}</p>  
        <GmapMap
          :center="geolocation"
          :zoom="16"
          style="width: 100%; height: 280px"
        >
          <GmapMarker
            :position="geolocation"
            :draggable="!showEditMap"
            @dragend="draggableMarker"
          >
            <GmapInfoWindow :options="{ maxWidth: 200 }" :opened="!showEditMap">
              <p>Puede arrastrar el marcador a la posición correcta</p>
            </GmapInfoWindow>
          </GmapMarker>
        </GmapMap>
        <!-- End Map -->        
      </div>

      <!-- Message -->
      <div v-else class="text-center">
        <small class="text-secondary">No se ha registrado una ubicación</small>
      </div>
    </div>

    <!-- Date -->
    <div class="text-left">
      <span v-if="geolocation.updatedAt != ''" >Última actualización {{ formatDate(geolocation.updatedAt) }}</span>
    </div>
    <div v-if="showBtnActions" class="d-flex justify-content-center">
      
      <!-- Buttons Accept and Cancel -->
      <v-btn
        v-if="geolocation.lat != '' && geolocation.lng != ''"
        @click="acceptLocation()"
        color="success"
        class="ma-2 white--text"
        style="
          border-width: 2px;
          background: #4caf50 0% 0% no-repeat padding-box;
          border: 2px solid #05741f !important;
        "
      >
        Confirmar
        <v-icon right dark> mdi-check-bold </v-icon>
      </v-btn>
      
      <v-btn
        @click="cancelLocation()"
        color="red"
        class="ma-2 white--text"
        style="
          border-width: 2px;
          background: #e20028 0% 0% no-repeat padding-box;
          border: 2px solid #890707 !important;
        "
      >
        Cancelar
        <v-icon right dark> mdi-close </v-icon>
      </v-btn>
      <!-- End Buttons Accept and Cancel -->
      
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default { 
    methods:{
        async setMapLocation(){
            this.initMapLocation();

            const permissionsGranted = await this.validateBrowserGeolocation();
            if (!permissionsGranted) {
                this.$swal({
                text: "Antes de registrar coordenadas tienes que activar tu geolocalización",
                icon: "warning",
                confirmButtonColor: "#ec0707",
                });
            }

            navigator.geolocation.getCurrentPosition((position) => {
                this.geolocation.lat = position.coords.latitude;
                this.geolocation.lng = position.coords.longitude;      
            });
        }, 

        async validateBrowserGeolocation() {
            if ("permissions" in navigator && "geolocation" in navigator) {
                const permissionStatus = await navigator.permissions.query({ name: "geolocation" }); 
                return permissionStatus.state === "granted";
            } 
            return false;      
        },

        draggableMarker(event) {
            this.geolocation.lat = event.latLng.lat();
            this.geolocation.lng = event.latLng.lng();  
        },

        formatDate(value) {
            return moment(value).format("DD-MMMM-YYYY");
        },

        cancelLocation(){
            this.actionCancelLocation();
        },

        acceptLocation(){          
            this.actionAcceptLocation();  
            this.$emit('acceptLocation');
        },

        ...mapActions('coordinatesRecords', [
          'initMapLocation', 
          'actionCancelLocation', 
          'actionAcceptLocation', 
          'actionSetRecipientSelected'
        ]),
    },

    computed:{
        ...mapGetters('coordinatesRecords', [
            'address', 
            'activeTaxAdress', 
            'activeRecipient',
            'showEditMap',
            'updateLocation',
            'showLoader',
            'geolocation',
            'showBtnActions',
            'showSelectRecipients',
            'recipientsOptions',
            'selectedRecipient',
            'showLoaderSelect'
        ]),

        localSelectedRecipient:{
          get(){
            return this.selectedRecipient;
          },
          set(value){
            this.actionSetRecipientSelected(value);
            this.$emit('getCoordinatesRecipient');
          }
        }
    },
};
</script>

<style>
</style>