<template>
  <v-dialog v-model="show" width="500" persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Rechazar Pedido
      </v-card-title>

      <v-card-text class="pb-0"> 
        <v-form class="mt-6">     
          <v-radio-group v-model="selectedOption" label="Motivo de la cancelación">
            <v-radio v-for="(option, index) in options" :key="index" :label="option" :value="option"/>
          </v-radio-group>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
            
      <v-card-actions>
        <v-btn color="error" text @click="cancel"> Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" :disabled="!selectedOption" text @click="$emit('send', selectedOption)">
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
        selectedOption: null,
        options: [
          "A1 Por Tener Cartera Vencida",
          "A2 Por Tener saldos comerciales pendientes por aclarar",
          "A3 Riesgo Por exceder su Límite de crédito",
          "A4 Por Solicitud Ventas Error al Facturar",
        ],
    }
  },
  methods:{
    cancel(){
      this.show = false
      this.selectedOption = null;
    }
  },
  computed:{
    show:{
      set(newValue) {
        this.$emit('input', newValue);
      },
      get(){
        return this.value
      }
    }
  }
};
</script>

<style>
</style>