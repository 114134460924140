<template>
  <div
    class="btn-container rounded-xl pa-1 cursor-pointer"
    @click="reloadWithoutCache()"
  >
    <div class="btn-caption">Recargar sin caché</div>
    <v-icon size="35px" class="btn-icon">mdi-refresh-circle</v-icon>
  </div>
</template>

<script>
export default {
  name: 'ReloadWithoutCacheBtn',

  methods: {
    reloadWithoutCache() {
      location.reload();
    },
  },
};
</script>

<style scoped>
.btn-container {
  /* Without justify-content, button with increase transition will not work  */
  display: flex;
  justify-content: end;
  align-items: center;

  position: fixed;
  width: 40px;
  bottom: 20px;
  right: 20px;

  z-index: 100;
  overflow: hidden;

  transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

@media screen and (max-width: 600px) {
  .btn-container {
    bottom: 5px;
    right: 5px;
  }
}

.btn-caption {
  visibility: hidden;
  opacity: 0;
  white-space: nowrap;
  color: white;

  transition: opacity 0.4s ease-in-out;
}

.btn-icon {
  color: rgba(255, 0, 0, 0.6) !important;
  transition: color 0.4s ease-in-out;
}

.btn-container:hover {
  background-color: #424242;
  width: 185px;
}

.btn-container:hover .btn-caption {
  visibility: visible;
  opacity: 1;
}

.btn-container:hover .btn-icon {
  color: rgba(255, 0, 0, 0.8) !important;
}
</style>
