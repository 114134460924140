<template>
  <v-dialog
    v-model="showModal"
    persistent
    transition="dialog-bottom-transition"
    :max-width="900"
  >
    <v-card min-height="150px">
      <v-toolbar :dark="true" dense>
        <v-toolbar-title>Ubicación</v-toolbar-title>
        <v-spacer />
        <v-btn class="right" @click="showModal = false" icon>
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-5">
        <overlay :show="loading" type="circular" text="Cargando..." />

        <v-expand-transition>
          <v-card
            v-if="noDestinationRegistered && !loading"
            outlined
            rounded
            height="100px"
            class="d-flex align-center justify-center"
          >
            <v-card-text> No se ha registrado una ubicación </v-card-text>
          </v-card>
        </v-expand-transition>

        <v-expand-transition>
          <single-map-marker
            v-if="!noDestinationRegistered && !loading"
            :lat="position.lat"
            :lng="position.lng"
          />
        </v-expand-transition>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@/plugins/axios';
import SingleMapMarker from '@/components/SingleMapMarker';
import Overlay from '@/components/Overlay';

export default {
  name: 'DestinationMapModal',

  props: {
    value: Boolean,

    destinationId: {
      type: String,
      default: null,
    },
  },

  components: { SingleMapMarker, Overlay },

  data() {
    return {
      position: {
        lat: 0,
        lng: 0,
      },

      loading: false,
      noDestinationRegistered: false,
    };
  },

  methods: {
    async fetchDestination() {
      if (this.showModal && this.destinationId) {
        try {
          this.loading = true;
          this.setDataValues();

          const { data } = await axios.get(
            `/customer-merchandise-recipient/${this.destinationId}`
          );

          const destination = data.data;

          if (destination) {
            this.setDataValues(destination.latitude, destination.longitude);
          } else {
            this.setDataValues(0, 0, true);
          }
        } catch (error) {
          this.showModal = false;
          this.$toast.error('No se pudo obtener la ubicación');
        }

        this.loading = false;
      }
    },

    setDataValues(lat = 0, lng = 0, noDestinationRegistered = false) {
      this.position.lat = lat;
      this.position.lng = lng;
      this.noDestinationRegistered = noDestinationRegistered;
    },
  },

  computed: {
    showModal: {
      set(newValue) {
        this.$emit('input', newValue);
      },
      get() {
        return this.value;
      },
    },
  },

  watch: {
    showModal() {
      this.fetchDestination();
    },
  },
};
</script>
