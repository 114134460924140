<template>
    <div> 
        <v-row class="custom-row-billing">
              <v-col>
                <div class="d-flex justify-start ">
              <div class=" mt-sm-2">
              <v-icon color="#000" class="mr-1 ">mdi-calendar-month</v-icon>
              <span>Periodo</span>
              </div>
              <div class="mx-5  custom-select-billing-year" style="width: 150px">
                <custom-select :options="years" :placeholder="'Año'"
                  v-model="periodYear" :disabled="loadingBilling"></custom-select>
              </div>
               <div  class="mx-5  custom-select-billing-month"  style="width: 150px">
                <custom-select :disabled="loadingBilling" :options="periodYear ==new Date().getFullYear()? months.slice(0,new Date().getMonth()+1).reverse():months"
                  v-model="periodMonth" ></custom-select>
              </div> 
              <div class="custom-chk mt-sm-n5 mt-md-n3">
              <v-checkbox v-model="showMoreTrim" color="info">
                <template v-slot:label>
                  <div style="color:#047DFF">
                    Mostrar más Trimestres/Meses
                    </div>
                </template>
              </v-checkbox>
              </div>
            </div>  
              </v-col>
        </v-row>                      
        <v-simple-table class="my-table w-full">
              <template v-slot:default>
                <thead class="table-heading">
                    <tr class="custom-table-bordered"> 
                    <th rowspan="2" class="text-center text-dark">Resumen<br />(días hábiles) </th>
                    <template  v-if="!showMoreTrim">
                    <th rowspan="2" class="text-center text-dark boder-x-none">#&nbsp;de&nbsp;Clientes<br> Sistema&nbsp;/&nbsp;Activos<br>
                      Clientes&nbsp;Nuevos<br>1&nbsp;/&nbsp;3&nbsp;/&nbsp;6&nbsp;Meses</th>
                    <th rowspan="2" class="text-center text-dark boder-x-none">Meses&nbsp;en<br>Zona</th>
                    <th v-if="!userHasRestrictedRole" rowspan="2" class="text-center text-dark boder-x-none">Ingresos Promedio<br />Últimos <br />6&nbsp;Meses</th>
                    <th v-if="!userHasRestrictedRole" rowspan="2" class="text-center text-dark boder-x-none">Ingresos Promedio<br />Últimos <br />2&nbsp;Meses </th>
                    <th rowspan="2" class="text-center text-dark boder-l-none">(VEP&nbsp;+&nbsp;PFD) <br />VEP</th>
                    <th colspan="4"  style="border-width: 0px; border:0px" class=" text-center">
                        <b class="text-dark"> Crecimientos constantes facturación neta</b>
                      </th>  
                     </template>
                    <th  style="border-width: 3px;" :colspan="showMoreTrim?8:4">
                      <div class="d-flex justify-space-around align-center">
                      <div class="mx-5 ma-1" style="min-width: 230px;max-width:500px">
                        <CustomSelectRed :disabled="loader_infoTrim" :options="[
                          { value: 1, label: 'Pedidos por día hábil' },
                          { value: 2, label: 'Fact neta por día hábil' },
                          { value: 3, label: 'Tiempo de entrega de pedidos' }  
                          ]" :placeholder="'Pedidos por día hábil'"
                          v-model="period"></CustomSelectRed>
                      </div>
                      <v-checkbox v-model="showTrim" label="Trimestral" color="info" :disabled="loader_infoTrim" small solo dense></v-checkbox>
                      <v-checkbox v-model="showMonth" label="Mensual" color="info" :disabled="loader_infoTrim" small dense solo></v-checkbox>
                      </div>
                      
                    </th> 
                  </tr>
                  <tr >
                    <template v-if="!showMoreTrim">
                    <th   class="text-center text-dark  boder-x-none">Acum  <br /> {{ constantGrowthDateHeaders.period1.year() }}  &nbsp;vs&nbsp;{{ constantGrowthDateHeaders.period2.year()}}</th>
                    <th class="text-center text-dark  boder-r-none">Acum <br />  {{ constantGrowthDateHeaders.period1.year() }} vs {{ constantGrowthDateHeaders.period2.year() + 1 }}   </th>
                    <th class="text-center text-dark  boder-r-none">Últimos <br />3&nbsp;Meses</th>
                    <th class="text-center text-dark " style="border-width: 0px 3px 0px 0px;">Último&nbsp;Mes vs&nbsp;3 <br />
                      Anteriores</th> 
                    </template> 
                    <th v-for="(item, index) in headerOrdersPerDay.slice(maxSliceTrim)" :key="item.i" class="text-center text-dark boder-r-none">
                      <!--
                      Trim&nbsp;1/23
                      <br />VPP&nbsp;+&nbsp;PFP&nbsp;&nbsp;&nbsp;<br />(63&nbsp;de&nbsp;64)
                    -->
                    {{ item.date }}
                    <p class="mb-0">VEP + PFD</p> 
                    <p class="mb-0" v-if="index == (showMoreTrim ? 7 : 3)">({{item.diaHabilActual}} de {{item.diaHabil}})</p>
                    <p class="mb-0" v-else>({{item.diaHabil}})</p>
                    </th> 
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!loadingBilling">
                    <td >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                         <a @click="showInfo(4)"><v-icon  v-bind="attrs"
                            v-on="on" color="#B71C1C">mdi-chart-pie</v-icon> 
                          </a> 
                        </template>
                        <span>Mostrar participación/ Divisiones</span>
                      </v-tooltip>  
                    </td>
                    <template  v-if="!showMoreTrim"> 
                      <td>
                        <b>{{ billing.NTCS }} / {{ billing.NTCA }}</b> <br />
                        {{ billing.NTCN1 }} / {{ billing.NTCN3 }} / {{ billing.NTCN6 }}
                      </td> 
                      <td>{{ billing.MESZON }}</td>
                      <td v-if="!userHasRestrictedRole"><b>${{ numberformat(billing.ING6MES,true) }}</b></td>
                      <td v-if="!userHasRestrictedRole"><b>${{ numberformat(billing.ING2MES,true) }}</b></td>
                      <td>
                        <a v-if="!loadVEP" @click="showInfo(2)">
                          <span class="text-danger text-decoration-underline">
                            {{formatoSinDecimales(historicos == '' ? 0 : historicos[0].porcentVEPPFD)}}%

                          </span>
                          <br />
                          <span class="text-danger text-decoration-underline">
                            {{formatoSinDecimales(historicos == '' ? 0 : historicos[0].porcentVEP)}}%
                          </span>
                        </a>
                        <v-progress-circular v-else
                            indeterminate
                            color="primary"
                          ></v-progress-circular> 
                      </td> 
                      <td class="text-decoration-underline"> 
                          <a @click="getConstantGrowth(1)" :class="billing.PORCACU-100 < 0 ? 'text-danger':'text-dark'">
                          {{numberformat(billing.PORCACU-100)}}%
                        </a> 
                      </td>
                      <td class="text-decoration-underline"> 
                          <a @click="getConstantGrowth(2)" :class="billing.ACUMANT-100 < 0 ? 'text-danger':'text-dark'">
                          {{numberformat(billing.ACUMANT-100)}}%
                        </a> 
                      </td>
                      <td class="text-decoration-underline"> 
                        <a @click="getConstantGrowth(3)" :class="billing.ACU3MES-100 < 0 ? 'text-danger':'text-dark'">
                        {{numberformat(billing.ACU3MES-100)}}%
                        </a> </td>
                      <td class="text-decoration-underline"> 
                        <a @click="getConstantGrowth(4)" :class="billing.PORFNUM-100 < 0 ? 'text-danger':'text-dark'">
                        {{numberformat(billing.PORFNUM-100)}}%
                        </a>
                      </td>
                    </template>
                    <td  v-if="loader_infoTrim" colspan="8"  class=" justify-content-center align-items-center"> 
                          <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                        </td> 
                    <template>  
                      <template v-if="period == 1 && !loader_infoTrim">
                        <td v-for="(item, index) in pedidosTrimOrMonth.slice(maxSliceTrim)" :key="index">                          
                          <a @click="showInfo(1); getVbUsuarios(index)">
                            <b class="text-decoration-underline">${{ promedio(item.monto, index == (showMoreTrim ? 7 : 3) ? headerOrdersPerDay[showMoreTrim ? index : 4 + index].diaHabilActual : headerOrdersPerDay[showMoreTrim ? index : 4 + index].diaHabil) }}</b>
                          </a><br>                       <b class="text-decoration-underline small">{{ formatoSinDecimales(item.porcentaje)}}%</b>                         
                        </td>  
                      </template>   

                      <template v-if="period == 2 && !loader_infoTrim">
                        <td v-for="(item, index) in facturasTrimOrMonth.slice(maxSliceTrim)" :key="index">
                          <a @click="showInfo(1); getVbUsuarios(index)">
                            <b class="text-decoration-underline">${{ promedio(item.monto, index == (showMoreTrim ? 7 : 3) ? headerOrdersPerDay[showMoreTrim ? index : 4 + index].diaHabilActual : headerOrdersPerDay[showMoreTrim ? index : 4 + index].diaHabil) }}</b>
                          </a><br>
                          <b class="text-decoration-underline small">{{ formatoSinDecimales(item.porcentaje)}}%</b>
                        </td>
                      </template>
                      <template v-if="period == 3 && !loader_infoTrim">
                        <td v-for="(item, index) in entregaTrimOrMonth.slice(maxSliceTrim)" :key="index">                          
                          <b>{{ formatoSinDecimales(item.diasDiferencia) }}</b>
                        </td>
                      </template>     
                    </template>            
                    
                    <!-- InfoFactTrim -->
                    
                   <!--
                    <td>
                    <b class="red--text text-decoration-underline">$43,106</b><br />
                      <b class="red--text text-decoration-underline small">40.10%</b>
                    </td>
                    <td>
                      <b class="text-decoration-underline">$45,610</b><br />
                      <b class="text-decoration-underline small">40.84%</b>
                    </td>
                    <td>
                       <b class="green--text text-decoration-underline">$61,259</b><br />
                        <b class="green--text text-decoration-underline small">43.75%</b>
                    </td>
                  -->
                  </tr>
                  <tr v-else>
                    <td colspan="15">
                      <span class="text--disabled">Cargando información</span>
                      <v-progress-linear
                        :indeterminate="loadingBilling"
                        color="teal"
                      ></v-progress-linear>
                    </td>
                  </tr>
                </tbody>
              </template>
        </v-simple-table> 
        <v-dialog v-model="showConstantGrowth" :max-width="800" persistent>
        <!-- 500 para table 1 |  350 a table 2 | 800 table 3 -->
        <v-card class="pa-3"> 
                <ConstantGrowth :modal="showConstantGrowth"   @closeModal="closeModal()" :seller="seller" :option="constantGrowthOperation" :year="constantGrowthDate.format('YYYY')" :month="constantGrowthDate.format('M')" > </ConstantGrowth> 
            </v-card>                    
        </v-dialog>  
      <v-dialog v-model="showModal" :max-width="showOrdersPerDay ? 500 : (paymentHistory ? 350 : 900)" :persistent="loadingBilling">
        <!-- 500 para table 1 |  350 a table 2 | 800 table 3 -->
        <v-card class="pa-0 pa-sm-2 pa-md-3">
            <OrdersPerDay v-if="showOrdersPerDay" :customersActive="customersActive"> </OrdersPerDay> 
            <PaymentHistory :seller="seller" v-if="paymentHistory" :historicos="historicos"> </PaymentHistory>
            <PaymentInfo v-if="paymentInfo"> </PaymentInfo>
            <Graph :charTitle="'Detalle de zona '+cardSeller.zona" v-if="showGraph1" :charData="billingByArea" :load="loadingBillingByArea" @closeModal="closeModal()" @periodChanged="loadSalesPerArea"> </Graph>
             <div  class="text-right ma-5 pb-1" >
            <v-btn small outlined color="success" @click="closeModal()">
            Cerrar
        </v-btn>
        </div>
        </v-card>
    </v-dialog>
        
    </div>

</template>

<script>

import MixinService from '@/mixins/service';
import moment from 'moment'
import axios from '@/plugins/axios'
import CustomSelect from "../components/CustomSelect.vue";
import CustomSelectRed from "../components/CustomSelectRed.vue";
import OrdersPerDay from "../includes/modals/OrdersPerDay.vue";
import PaymentHistory from "../includes/modals/PaymentHistory.vue";
import PaymentInfo from "../includes/modals/PaymentInfo.vue";
import Graph from "../includes/modals/Graph.vue";
import ConstantGrowth from '../includes/modals/ConstantGrowth.vue';

export default({ 
    name:"Billing",
    props: ['seller','CancelToken','cardSeller'],
    mixins:[MixinService],
    components:{CustomSelect,CustomSelectRed,OrdersPerDay,PaymentHistory,PaymentInfo,Graph,ConstantGrowth},
    data(){
      return {         
        loadingBilling:true,
        periodYear:new Date().getFullYear(),
        months: [
            { value:1, label: 'Enero' },{ value:2, label: 'Febrero'},
            { value:3, label: 'Marzo' },{ value:4, label: 'Abril'},
            { value:5, label: 'Mayo' },{ value:6, label: 'Junio'},
            { value:7, label: 'Julio' },{ value:8, label: 'Agosto'},
            { value:9, label: 'Septiembre' },{ value:10, label: 'Octubre'},
            { value:11, label: 'Noviembre' },{ value:12, label: 'Diciembre'},
        ],
        periodMonth:new Date().getMonth()+1,
        showMoreTrim: false, 
        loader_infoTrim: true,
        period:'1',
        showTrim:true,
        showMonth:false, 
        headerOrdersPerDay:[],  
        maxSliceTrim: -4, 
        loadVEP: true,
        showMoreBillingQuarters:false, 
        billing:[],
        showOrdersPerDay: false, 
        showModal: false,  
        paymentHistory: false,
        paymentInfo: false, 
        showGraph1:false,
        customersActive: {},
        loadingBillingByArea:true,
        billingByArea:[],
        constantGrowthDate: moment(),   
        constantGrowthOperation:1,
        showConstantGrowth:false,
      }
    },
    watch: {
        showMoreBillingQuarters(){ 
            this.getBillingQuarters() 
        }, 
        showTrim(value){
            if(value){
                this.showMonth = false; 
                this.calcularUltimos4Trim()
                this.getInfoFactTrim()
            }else {
                if(!this.showMonth){
                this.showMonth = true;
                }
            }
        },
        showMonth(value){
            if(value){
                this.showTrim = false;  
                this.calcularUltimos4Meses()
                this.getInfoFactTrim()
            }else {
            if(!this.showTrim){
                    this.showTrim = true;
                }
            }
        }, 
        period(){
            this.showTrim = true
            this.showMonth = false
            this.calcularUltimos4Trim()
            this.getInfoFactTrim()
        },
        showMoreTrim(value){ 
            this.maxSliceTrim = value ? -8 : -4
        },
        async seller(){ 
          try{
            await Promise.all([
              this.getBilling(),
              this.calcularUltimos4Trim(),
              this.getInfoFactTrim(),
              this.getHistorico()
            ]);
            this.$emit('desactiveLoader');
          }catch(error){
            this.$emit('desactiveLoader');
          }
        },
        periodYear(value){ 
            this.getBilling(); 
            this.period = 1
            this.showTrim = true
            this.showMonth = false
            if (value == new Date().getFullYear()) {
                let currentMonth =  new Date().getMonth()+1;
                this.periodMonth =currentMonth; 
                }
            this.calcularUltimos4Trim()
            this.getInfoFactTrim()
            this.getHistorico()
        },
        periodMonth(){
            this.getBilling(); 
            this.period = 1
            this.showTrim = true
            this.showMonth = false
            this.calcularUltimos4Trim()
            this.getInfoFactTrim()
            this.getHistorico()
        },
    },
 
    computed:{
    userHasRestrictedRole() {
     return this.$hasAnyRole(['DirectorNoCommercial', 'SubDirectorPRNoCommercial', 'SubDirectorDTNoCommercial']);
    },
    years(){ 
        let currentYear = new Date().getFullYear();
        let years = [];
        for (let index = 0; index < 3; index++) {
            years.push({value:(currentYear-index) , label:(currentYear-index)})
        }
        return years;
    }, 
    constantGrowthDateHeaders(){
      var date = moment({ year: this.periodYear, month: this.periodMonth -1}) 
     return {
        period1:date.clone().subtract(1, 'months'),
        period2:date.clone().subtract(2, 'Year')
      }
    }
    },
    methods:{
    loadSalesPerArea(per){
      let year = per.slice(0,4);
      let trim = per.slice(5,6);  
      this.loadingBillingByArea =true;
      axios
          .post("/seller/home/sales-by-div", {
            seller_id: this.seller.PERNR,
            trim: trim,
            year: year,
            channel:  this.seller.VTWEG
          },{
            cancelToken: this.CancelToken.token
          })
          .then((response) => {
            if (response.data?.success==false) {
                console.log( response.data)
            } else {
                if (!response.data.DIVISION) {
                  this.billingByArea = []; 
                }else {
                  this.billingByArea = response.data.DIVISION; 
                }
                
            } 
            this.loadingBilling =false;
            this.loadingBillingByArea =false;
          })
          .catch((e) => {
            console.log(e);
            this.loadingBilling =false;
            this.loadingBillingByArea =false;
          }); 
    },
    closeModal(){
      this.showOrdersPerDay = false;
      this.showManagerPaymentHistory=false;
      this.paymentHistory = false;
      this.paymentInfo = false;
      this.showGraph1=false; 
      this.showModal = false;
      this.showConstantGrowth=false;
    }, 
    showInfo(type) {
      this.showModal = true; 
      switch (type) {
        case 1:
          this.showOrdersPerDay = true;
          this.paymentHistory = false;
          this.paymentInfo = false;
          this.showGraph1=false;
          this.showManagerPaymentHistory=false;
          break;
        case 2:
          this.showOrdersPerDay = false;
          this.paymentHistory = true;
          this.paymentInfo = false;
          this.showGraph1=false;
          this.showManagerPaymentHistory=false;
          break; 
        case 3:
          this.showOrdersPerDay = false;
          this.paymentHistory = false;
          this.paymentInfo = true;
          this.showGraph1=false;
          this.showManagerPaymentHistory=false;
          break; 
        case 4:
          this.showOrdersPerDay = false;
          this.paymentHistory = false;
          this.paymentInfo = false;
          this.showGraph1=true;
          this.showManagerPaymentHistory=false;
          break;
          case 5:
          this.showOrdersPerDay = false;
          this.paymentHistory = false;
          this.paymentInfo = false;
          this.showGraph1=false;
          this.showManagerPaymentHistory=true;
          break;

           
        default:
          this.showOrdersPerDay = false;
          this.paymentHistory = false;
          this.paymentInfo = false;
          this.showGraph1=false;
          this.showManagerPaymentHistory=false;
          break;
      }
    }, 
    getConstantGrowth(operation){ 
      var date = moment({ year: this.periodYear, month: this.periodMonth -1}).subtract(1, 'months')
      this.constantGrowthDate = date
      this.constantGrowthOperation = operation;  
      this.showConstantGrowth=true;
    },
    numberformat(number=0,round=false){
      let result = round?Math.trunc(number):Number(number).toFixed(2); 
     return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    getMonthShortName(monthNo) {
      const date = new Date();
      date.setMonth(monthNo - 1);
     const month =  date.toLocaleString('default', { month: 'short' }) 
      return month.charAt(0).toUpperCase() + month.slice(1);
    },
    getBilling(){
      this.loadingBilling =true;
      axios
          .post("/seller/home/billing", {
            seller_id: this.seller.PERNR,
            month: this.periodMonth,
            year: this.periodYear,
             channel:  this.seller.VTWEG
          },{
            noCancelRequest:true,
            cachable:true,
          })
          .then((response) => {
            if (response.data?.success==false) {
                console.log( response.data)
            } else {
                this.billing = response.data;  
            } 
            this.loadingBilling =false;
          })
          .catch((e) => {
            console.log(e);
          }).finally (() => {
            this.loadingBilling =false;
           
          });
    },
    calcularUltimos4Trim(){
      this.showMonth = false;
      const fechaInicio = moment({ year: this.periodYear, month: this.periodMonth - 1 });
      
      this.headerOrdersPerDay=[]
      for (let i = 0; i < 8; i++) {
        const trimestreInicio = fechaInicio.clone().subtract(i * 3, 'months');
        const trimestreNum = Math.floor(trimestreInicio.month() / 3) + 1;
        const trimestreAno = trimestreInicio.year();
        

        // Inicializar un contador para la cantidad de días hábiles
        let cantidadDiasHabiles = 0;
        let cantidadDiaHabilActual = 0

        // Calcular el mes de inicio y fin del trimestre
        const mesInicio = (trimestreNum - 1) * 3 + 1;
        const mesFin = trimestreNum * 3;

        // Obtener la fecha actual
        const fechaActual = new Date();
        // Obtener el mes y año actual
        const mesActual = fechaActual.getMonth() + 1;  // Sumar 1 porque los meses van de 0 a 11
        const anoActual = fechaActual.getFullYear();

        // Recorrer los meses del trimestre
        for (let mes = mesInicio; mes <= mesFin; mes++) {
          cantidadDiasHabiles += this.calcularDiasHabilesMes(trimestreAno, mes)
          if (trimestreAno < anoActual || (trimestreAno === anoActual && mes <= mesActual)){
            cantidadDiaHabilActual += this.calcularDiasPasadosEnMes(trimestreAno, mes)
          }
        } 
        this.headerOrdersPerDay.unshift({
            date: `${trimestreNum}/${trimestreAno}`, 
            trimOrMonth: trimestreNum, 
            year: trimestreAno, 
            diaHabil: cantidadDiasHabiles, 
            diaHabilActual: cantidadDiaHabilActual
        });        
      }        
    },
    calcularUltimos4Meses() {
      this.showTrim = false;      
      const fechaInicio = moment({ year: this.periodYear, month: this.periodMonth - 1 });
      
      this.headerOrdersPerDay=[]
      for (let i = 0; i < 8; i++) {
        const mesInicio = fechaInicio.clone().subtract(i, 'months');
        const mesNum = mesInicio.month() + 1;
        const mesAno = mesInicio.year();
        
        this.headerOrdersPerDay.unshift({ date: `${mesNum}/${mesAno}`, trimOrMonth: mesNum, year: mesAno, diaHabil: this.calcularDiasHabilesMes(mesAno, mesNum), diaHabilActual: this.calcularDiasPasadosEnMes(mesAno, mesNum)});
      }
    },
    calcularDiasPasadosEnMes(year, month) {
      const fechaActual = new Date();
      const mesActual = fechaActual.getMonth() + 1; // El mes en JavaScript es 0-indexado
      if (year === fechaActual.getFullYear() && month === mesActual) {
        // Si el año y el mes son iguales al actual, calcular hasta la fecha actual
       // const primerDiaDelMes = new Date(year, month - 1, 1); // mes - 1 porque los meses son 0-indexados
        const contador = Array.from({ length: fechaActual.getDate() }, (_, i) => i + 1) // Crear un array de días del 1 al día actual
          .filter((dia) => this.esDiaLaboral(new Date(year, month - 1, dia))) // Filtrar los días laborales
          .length;
        return contador;
      }else{
        return this.calcularDiasHabilesMes(year, month)
      }
    },
    calcularDiasHabilesMes(year, month) {
      const primerDiaDelMes = new Date(year, month - 1, 1); // mes - 1 porque los meses son 0-indexados
      const ultimoDiaDelMes = new Date(year, month, 0); // 0 como día en el mes anterior para obtener el último día del mes actual
      let contador = 0;

      for (let dia = primerDiaDelMes; dia <= ultimoDiaDelMes; dia.setDate(dia.getDate() + 1)) {
        if (this.esDiaLaboral(dia)) {
          contador++;
        }
      }

      return contador;
    },
    esDiaLaboral(fecha) {
      const diaSemana = fecha.getDay(); // 0 = Domingo, 1 = Lunes, ..., 6 = Sábado
      return diaSemana >= 1 && diaSemana <= 5; // Días laborales (Lunes a Viernes)
    }, 
    async getVbUsuarios(index){
      let type = ''
      let trim = ''
      let month = ''
      let year =  this.headerOrdersPerDay[index].year
      if(this.showTrim == true){
        type = 2
        trim = this.headerOrdersPerDay[index].trimOrMonth
      }else{
        type = 1
        month = this.headerOrdersPerDay[index].trimOrMonth
      }

      let response = await axios.get('/seller/home/vbUsuarios', {
        params:{
          employee_number: Number (this.seller.PERNR),
          type: type,
          trim: trim,
          month: month,
          year: year
        },
        noCancelRequest:true,
            cachable:true,
      })

      this.customersActive = response.data.data
    },
    async getHistorico(){      
        this.loadVEP = true

        try{
          let response = await axios.get('/seller/home/historico-veppfd',{
            params:{
              employee_number: Number(this.seller.PERNR),
              P_VTWEG:  this.seller.VTWEG,
              number_month: this.periodMonth,
              number_year: this.periodYear
            },
            noCancelRequest:true,
            cachable:true,
          })

          this.historicos = response.data.data   
          this.loadVEP = false         
        }catch(error){
          this.loadVEP = false           
        }
    },
    formatoSinDecimales(numeroDecimal){
      return numeroDecimal.toString().split('.')[0];      
    }, 
    promedio(monto, dias, index){
        console.log(index)
      const division = dias == 0 ? 0 : monto / dias;
      const formato = this.$options.filters.formatCurrency(division);
      return this.formatoSinDecimales(formato)
    },
    async getInfoFactTrim(){
      this.loader_infoTrim = true      
      let response = await axios.get('/seller/home/dia-habil', {
        params:{
          employee_number:  Number (this.seller.PERNR),
          P_VTWEG: this.seller.VTWEG,
          trim: (this.showTrim) ? moment().month(this.periodMonth - 1).quarter() : '',
          month: (!this.showTrim) ? this.periodMonth : '',
          year: this.periodYear,
          opt: this.period, 
        },
        noCancelRequest:true,
            cachable:true,
      })

      // this.bodyOrdersPerDay = response.data.data
      this.pedidosTrimOrMonth = []
      this.facturasTrimOrMonth = []
      this.entregaTrimOrMonth = []
      if(this.period == 1){
        this.pedidosTrimOrMonth = response.data.data.reverse()
      }else if(this.period == 2){
        this.facturasTrimOrMonth = response.data.data.reverse()
      }else if(this.period == 3){
        this.entregaTrimOrMonth = response.data.data.reverse()
      }
      this.loader_infoTrim = false
    }, 
    }
})
</script>