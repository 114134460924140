<template>
  <div class="d-flex flex-sm-row flex-wrap">
    <div class="pa-2 pl-10 d-flex flex-wrap">
      <div class="mt-2 mr-1 "> Agrupar por :</div>
      <div class="mt-2" style="width: 180px;">
        <custom-gray-select :disabled="disabled" style="width: 100%;" 
        v-model="group" 
        :options="groupByOptions"  
        label="label"
        />
      </div>
    </div>
 
    <div class="pa-2 pl-10 d-flex flex-wrap">
      <div class="mt-2 mr-1 ">Periodo:</div>
      <div class="mt-2">
        <custom-gray-select :disabled="disabled" 
        v-model="period"
        :options="periodOptions"
         />
      </div>
    </div>
 
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomGraySelect from "@/components/CustomGraySelect.vue";
import MixinService from '@/mixins/service'
import MixinHelpers from '@/mixins/helpers';
import MixinDates from '@/mixins/dates';

export default {
  name: "GroupByMenu",
  props :{
      chnl:{
        default:'PR'
      },
      disabled:{
        default:false
      }
  },
  components: {
    CustomGraySelect,
  },
  data: () => ({
    periodOptions: [], 
    months: [], 
    period: [], 
    groupByOptions:[],
    selOptions: { 
      rgManager : { label:'Director territorial', value:1} ,
      manager: {label:'Gerente estatal',value:2},
      zone: {label:'Zona',value:3},
    },
    group:[],
    groupByConfig:[],
  }),
  mixins: [MixinService, MixinHelpers, MixinDates],
  watch: {
    group(newValue,oldValue){
      if (newValue != oldValue && oldValue.value) { 
        this.changeFilter()
      }
    },
    period(){ 
        this.changeFilter();     
    }
  },
  methods: {
    changeFilter() { 
      const data = {
        groupBy: this.group.value,
        period:  this.period.value,
        headerTitle : this.group.label
      } 
      this.$emit('change', data)
    },
    setGroupByOptions() {
        const role = this.getnavRole(); 
        const groupByConfig = this.getGroupByConfig() 
        this.groupByOptions = groupByConfig[`${role}${this.chnl}`]; 
        this.group = this.groupByOptions[0];
      },  
    setPeriodOptions() {
      var periods = [];
      this.months.forEach(element => {
        periods.push({
          label:element.date,
          value:`${element.year}${element.month.toString().padStart(2,'0')}`
        });
      }); 
      this.periodOptions = periods;
      this.period = periods[0];
      },  
    
    getnavRole(){
      switch (this.$route.meta.role) {  
        case "Director":   
        case "IELDirector":    
        case "DirectorNoCommercial": 
        case "IELCommercialPR":   
        case "IELCommercialDT":   
        case "director":
          return "director";
        case "Regional": 
        case "RegionalManager":  
        case "IELRegManager": 
          return "rgManager"; 
      } 
    },
    getGroupByConfig()//Opciones dependiendo del rol
    {
    return  {
        directorPR:[this.selOptions.rgManager,this.selOptions.manager,this.selOptions.zone], 
        directorDT:[this.selOptions.rgManager ,this.selOptions.manager,this.selOptions.zone],
        rgManagerPR:[this.selOptions.manager,this.selOptions.zone],
        rgManagerDT:[this.selOptions.manager,this.selOptions.zone,],
      };
    }
  },
  created() {
    this.months = this.calcularUltimosMeses(13);
    this.setGroupByOptions() //
    this.setPeriodOptions();
  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    optionPeriods() {
     
      return this.arrayPluck(this.months, 'date');
    },


  }

};
</script>
