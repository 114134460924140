<template>
  <v-container white lighten-5 fluid class="card-rounded renglonaje-director">

    <div class="d-flex align-items-center"> 
        <v-card class="mx-auto rounded-lg elevation-4 ma-3 w-100" outlined style="border: 4px solid black; background-color:transparent; position: relative; padding: 0 40px">
                <v-list-item-content>
                    <div class="text-general-module mb-0">
                        <v-icon color="black">mdi mdi-clock-outline</v-icon>
                        <span class="ml-2">RENGLONAJE</span>
                        <img src="img/logo_sd.png" class="ml-auto" style="max-width: 1.6em; height: auto; position: absolute; right:20px; top: 0; bottom: 0; margin: auto auto" />
                    </div>                    
                </v-list-item-content>               
            </v-card>

        <MenuPrint 
            :itemCSV="itemCSV"
            :nameCSV="`renglonaje-director-${today2}`"
            @generarDocumento="generarDocumento">
        </MenuPrint>
    </div>         
    
    <div class="filter--container">
        <div class="container py-0">
        <div class="row">
        <!-- bloque familias -->
        <div class="col-sm-12 col-md-6 col-xl-3">
            <div class="d-flex align-items-center filter--bloq">
                <b>Familias:</b>
                <v-select
                :items="getSelectOptions"
                item-text="text"
                item-value="value"
                dense
                solo
                hide-details
                class="ml-2"
                v-model="selectedBEZEI"
                @change="changeFamily"              
                ></v-select>
            </div>
        </div>        
        <!-- end bloque familias -->
        
        <!-- bloque mostrar info -->
        <div class="col-sm-12 col-md-6 col-xl-3">
            <div class="d-flex align-items-center filter--bloq" v-if="!generarPDF">
                <b>Mostrar información por:</b>
                <div class="form-check ml-3">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" :value="1" v-model="option">
                    <label class="form-check-label" for="flexRadioDefault1">
                        Piezas
                    </label>
                </div>
                <div class="form-check ml-3">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" :value="2" v-model="option">
                    <label class="form-check-label" for="flexRadioDefault2">
                        Montos
                    </label>
                </div>
            </div>
        </div>
        <!-- end bloque mostrar info -->

        <!-- bloque ver-->
        <div class="col-sm-12 col-md-6 col-xl-3">
            <div class="d-flex align-items-center filter--bloq" v-if="!generarPDF">
                <b>Ver:</b>
                <div class="form-check ml-3">
                    <input class="form-check-input" type="radio" name="verFamilias" id="familiasTodos" :value="null" v-model="optNuevos">
                    <label class="form-check-label" for="familiasTodos">
                        Todos
                    </label>
                </div>
                <div class="form-check ml-3">
                    <input class="form-check-input" type="radio" name="verFamilias" id="familiasNuevos" :value="1" v-model="optNuevos">
                    <label class="form-check-label" for="familiasNuevos">
                        Solo nuevos
                    </label>
                </div>
                <div class="form-check ml-3">
                    <input class="form-check-input" type="radio" name="verFamilias" id="familiasSinNuevos" :value="0" v-model="optNuevos">
                    <label class="form-check-label" for="familiasSinNuevos">
                        Sin nuevos
                    </label>
                </div>
            </div>
        </div>
        <!-- end bloque ver -->

        <!-- bloque periodo-->
        <div class="col-sm-12 col-md-6 col-xl-3">
            <div class="d-flex align-items-center filter--bloq">
                <b>Periodo:</b>
                <v-select
                    v-model="selectedYear"
                    :items="years"               
                    dense
                    solo
                    hide-details
                    class="ml-2 filter--select__red"
                ></v-select>
            </div>
        </div>
        <!-- end bloque periodo -->
        </div>
        </div>
    </div>

    <div class="row mt-1">
        <div class="col">
            <MenuDivisiones 
                :loader="isLoading" 
                :activeDIVI="selectedDIVI" 
                :divisiones="divisiones"
                @filterDIVIs="changeDIVI">
            </MenuDivisiones> 
        </div>
    </div>

    <div class="cont-shadow bloq-height mt-4">
        
            <div class="d-flex align-items-center">
                
                <!-- consultar -->
                <div class="d-flex align-items-center">
                    <b>Código:</b>
                    <v-text-field   
                        v-model="MATNR"                      
                        dense
                        solo
                        hide-details
                        class="ml-2"
                        @keyup="filtrarInput"
                    ></v-text-field>
                </div>
                <!-- end consultar -->
            
                <!-- btn consultar -->
                <div class="d-flex align-items-start ml-2">
                    <router-link to="">
                        <v-btn class="btn-red rounded-pill" @click="sendCode()">
                            Consultar 
                        </v-btn>
                    </router-link>
                </div>
                <!-- end btn consultar -->              

                <div class="ml-auto">
                    <!-- btn ver productos -->                    
                    
                    <v-btn class="btn-white">
                        <vue-json-to-csv :show-labels="true" :json-data="masVendidos" :csv-title="'Top500'" class="d-flex align-items-center">                                            
                            <span class="mr-2">Top 500 + vendidos</span>
                            <v-icon style="color: green">
                                mdi-file-excel
                            </v-icon>            
                        </vue-json-to-csv> 
                    </v-btn>
                                    
                    <!-- end btn ver productos -->
                </div>
            </div>
            
        
    </div>

    <TableFamiliesDirector v-show="!showFamilies" :items="filteredFamilies" :option="option" :isLoading="isLoading" :selectedYear="selectedYear" @sendItemCSV="sendItemCSV"  @getMaterials="(data) => {MVGR = data; MATNR = null; getMaterials()}"></TableFamiliesDirector>
    <TableMaterialsDirector v-show="showFamilies" :items="filteredMaterials" :option="option" :isLoading="isLoading" @sendItemCSV="sendItemCSV"></TableMaterialsDirector>

  </v-container>
</template>

<script>
/* eslint-disable */
import MenuDivisiones from './components/MenuDivisiones'
import MenuPrint from '@/components/MenuPrint'
import TableFamiliesDirector from './components/TableFamiliesDirector'
import TableMaterialsDirector from './components/TableMaterialsDirector'
import moment from 'moment'
import axios from "@/plugins/axios"
import html2pdf from 'html2pdf.js'
import VueJsonToCsv from 'vue-json-to-csv'
import { mapGetters } from "vuex";
export default {
    components: {MenuPrint, TableFamiliesDirector, MenuDivisiones, TableMaterialsDirector, VueJsonToCsv},
    data(){
        return{
            years: [], 
            selectedYear: moment().year(),      
            today2: moment().format('YYYY/MM/DD'),    
            familiesAll: [],
            families: [],
            materials: [],
            isLoading: true,
            option: 1,
            divisiones:   '',
            selectedBEZEI: null,
            selectedDIVI: 'todos',
            showFamilies: false,
            generarPDF: false,
            itemCSV: [],
            MVGR: null,
            MATNR: null,
            optNuevos: null,
            masVendidos: []
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        getSelectOptions() {
            const allOption = { text: 'Todas', value: null }; // O el valor que prefieras
            const options = this.families.map(family => ({ text: family.BEZEI, value: family.BEZEI }));
            return [allOption, ...options];
        },
        filteredFamilies(){
            return this.families.filter(family => {
                // Aplicar ambos filtros
                const filterByBEZEI = this.selectedBEZEI == null || family.BEZEI == this.selectedBEZEI;
                const filterByDIVI = this.selectedDIVI == 'todos' || family.DIVI == this.selectedDIVI;
                const filterByMATNUE = this.optNuevos === null || parseInt(family.MATNUE) === this.optNuevos

                // Devolver verdadero solo si ambas condiciones se cumplen
                return filterByBEZEI && filterByDIVI && filterByMATNUE
            });   
        },
        filteredMaterials(){
            return this.materials.filter(material =>  this.optNuevos === null || parseInt(material.MATNUE) == this.optNuevos)
        } 
    },
    mounted(){
        const currentYear = new Date().getFullYear();
        for (let year = currentYear; year >= currentYear - 10; year--) {
            this.years.push(year);
        }
        this.getFamilies()  
        this.getSold()   
    }, 
    watch:{
        families(){
            this.setDIVIs()
        },
        selectedYear(){ 
            this.getFamilies()
            if(this.MVGR != null){
                this.getMaterials()
            }
        }
    },
    methods:{
        async getFamilies(){
            this.families = []
            this.isLoading = true;
            let response = await axios.get('renglonaje-director/familias', {
                params: {
                    'P_YEAR': this.selectedYear,
                    'P_VTWEG': this.user.VTWEG
                }
            })

            if(response.data.data[0] != null){
                this.families = response.data.data
            }
            this.isLoading = false;
        },
        async getMaterials(){
            this.isLoading = true
            this.materials = []
            this.showFamilies = true;            
            let response = await axios.get('renglonaje-director/materiales', {
                params: {
                    'P_YEAR': this.selectedYear,
                    'P_MVGR4': this.MVGR,
                    'P_MATNR': this.MATNR,
                    'P_VTWEG': this.user.VTWEG
                }
            })

            if(response.data.data.length > 0){
                this.materials = response.data.data                
            }
            this.isLoading = false;
        },
        async getSold(){ 
            let month =  moment().format('MM');          
            let response = await axios.get('renglonaje-director/vendidos', {
                params: {
                    'P_SPMON': `${this.selectedYear}${month}`,
                    'P_VTWEG': this.user.VTWEG
                }
            })

            if(response.data.data.length > 0){
                let data = response.data.data  
                
                this.masVendidos = data.map(function(value){
                    return {
                        'Código': value.MATNR,
                        'Descripción': value.ARKTX,
                        'Prom. Ult. 4 Meses Pzas': value.TOTFAC,
                        'Prom. Ult. 4 Meses Monto': value.TOTMON
                    }
                })
            }
        },
        generarDocumento(){
            this.generarPDF = true
            var element = document.querySelector('html');
            var opt = {
                margin:       1,
                filename:     'renglonaje.pdf',
                image:        { type: 'jpeg', quality: 0.98 },
                html2canvas:  { scale: 2 },
                jsPDF: { unit: 'px', format: [1800,1600], orientation: 'landscape' }
            };
            
            let elem = this
            html2pdf(element, opt).then(function () {
                elem.generarPDF = false
            });
                 
        },
        setDIVIs(){
            // Utilizamos Set para obtener los valores únicos de la propiedad DIVI
            const uniqueDIVIs = [...new Set(this.families.map(item => item.DIVI))];            
            this.divisiones = uniqueDIVIs;
        },
        changeDIVI(data){
            // (this.MVGR != null)
            //     this.MATNR = null
            this.MVGR = null
            this.selectedDIVI = data
            this.selectedBEZEI = null
            this.showFamilies = false
        },
        changeFamily(){   
            // (this.MVGR != null)
            //     this.MATNR = null
            this.MVGR = null
            this.selectedDIVI = 'todos'
            this.showFamilies = false
        },
        sendItemCSV(data){
            this.itemCSV = data
        },
        sendCode(){
            (this.MATNR != null)
                this.MVGR = null
            this.getMaterials()
        },
        filtrarInput() {
            this.MATNR = this.MATNR.replace(/\D/g, '');
        }
    }
}
</script>

<style>
    /* select white */
    .renglonaje-director{
        padding-left: 3% !important;
        padding-right: 3% !important;
    }
    .renglonaje-director .filter--container{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
        padding: 10px 20px;
        font-size: 16px;
        /* display: flex;
        justify-content: center; */
    }

    .renglonaje-director .v-input__slot, .renglonaje-director .v-input__control{
        min-height: auto !important;      
    }    

    .renglonaje-director .v-input__slot .v-label, .renglonaje-director .v-input__slot .v-select__selection {
        font-size: 14px !important;
    }
    /* end select white */

    /* select red */
    .renglonaje-director .filter--select__red .v-input__slot , .renglonaje-director .filter--select__red .v-input__control {
        background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box !important;    
        max-width: 120px;
    }

    .renglonaje-director .filter--select__red .v-input__slot .v-icon{
        color: #FFF !important;
    }

    .renglonaje-director .filter--select__red .v-input__slot .v-label, .renglonaje-director .filter--select__red .v-input__slot .v-select__selection {        
        color: #FFF !important;
    }
    /* end select red */

    .renglonaje-director .filter--bloq{
        margin-left: 40px;
    }

    .renglonaje-director .filter--bloq:first-child{
        margin-left: 0;
    }

    /* search */
    .renglonaje-director .cont-search{        
        display: flex;
        justify-content: end;
    }

    .renglonaje-director .cont-search .v-input__control{
        min-height: auto !important;
    }
    
    .renglonaje-director .cont-search .v-input__control .v-label{
        font-size: 14px;
    }

    /* buttons */
    .renglonaje-director button.btn-red{
        background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        color: #FFF !important;
        text-transform: inherit !important;        
        height: 26px !important;
    }
    .renglonaje-director button.btn-white{
        background-color: #FFF !important;
        color: #000 !important;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
        padding: 20px !important;
    }
    /* end buttons */

    .renglonaje-director tfoot{
        background: #f2f2f2;
    }

    .renglonaje-director tfoot td{
        padding: 6px 0 !important;
        font-weight: bold;
    }

    .renglonaje-director .cont-shadow{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
    }
    .renglonaje-director .bloq-height{
      flex: 1;
      padding: 8px 15px;
    }

    .renglonaje-director a{
        color: #000 !important;
    }
</style>