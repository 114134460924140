<template>
  <div
    :class="[
      'w-full',
      red ? 'new-card-pink' : 'new-card',
      showTitle == false ? 'rounded-when-no-title' : '',
    ]"
  >
    <div
      class="d-flex justify-space-between align-center px-4 py-2 mb-4 new-card-title"
      v-if="showTitle"
    >
      <div>
        {{ title }}
      </div>
      <slot name="header-right"></slot>
    </div>
    <div
      :class="[
        'px-4',
        'py-2',
        showTitle == false ? 'rounded-when-no-title shadow-in' : '',
      ]"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ShoppingCard',
    props: {
      title: {
        type: String,
      },
      showTitle: {
        type: Boolean,
        default: true,
      },
      red: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style>
  .new-card {
    box-shadow: -1px 0px 8px 1px #bdbdbd;
    -webkit-box-shadow: -1px 0px 8px 1px #bdbdbd;
    -moz-box-shadow: -1px 0px 8px 1px #bdbdbd;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .new-card-pink {
    box-shadow: -1px 0px 8px 1px pink;
    -webkit-box-shadow: -1px 0px 8px 1px pink;
    -moz-box-shadow: -1px 0px 8px 1px pink;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .new-card-title {
    background-color: #fff;
    border-bottom: 0px solid #616161;
    color: #000;
    font-size: 0.9em;
    font-weight: bold;
    box-shadow: -1px 0px 15px 0px #bdbdbd inset;
    -webkit-box-shadow: -1px 0px 15px 0px #bdbdbd inset;
    -moz-box-shadow: -1px 0px 15px 0px #bdbdbd inset;
  }

  .rounded-when-no-title {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .shadow-in {
    box-shadow: inset 0px 2px 6px #00000029, 0px 2px 6px #00000029;
  }
</style>
