<template>
  <div
    class="d-flex justify-space-between align-center rounded shadow-in mb-3 p-2"
  >
    <div style="width: 70px">
      <v-progress-circular
        v-if="!imageURL"
        color="primary"
        indeterminate
      />
      <v-img
        v-else
        :src="imageURL"
        max-width="100"
        max-height="100"
      />
    </div>

    <div class="flex-grow-1 mx-2">
      <p class="text-left">{{ productName }}</p>
      <p class="text-left mb-0">SKU {{ productSKU }}</p>
    </div>

    <v-btn
      :class="{ 'btn-pr': isPRChannel, 'btn-dt': isDTChannel }"
      small
      @click="onAdd"
    >
      Agregar
    </v-btn>
  </div>
</template>

<script>
  import { getProductMainImage } from '@/repositories/ProductFiles';

  import { mapGetters } from 'vuex';

  export default {
    name: 'SearchProductResultItem',
    props: {
      product: { type: Object, required: true },
    },
    data: () => ({
      imageURL: undefined,
    }),
    computed: {
      ...mapGetters('auth', ['channel']),
      isPRChannel() {
        return this.channel === 'PR';
      },
      isDTChannel() {
        return this.channel === 'DT';
      },
      productSKU() {
        return this.product.MATNR.slice(-6);
      },
      productName() {
        return this.product.ARKTX;
      },
    },
    async mounted() {
      const { image } = await getProductMainImage(this.productSKU);
      this.imageURL = image.path;
    },
    methods: {
      onAdd() {
        this.$emit('select', {
          sku: this.productSKU,
          name: this.productName,
          imageURL: this.imageURL,
        });
      },
    },
  };
</script>

<style scoped>
  .shadow-in {
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  }
  .btn-pr {
    color: white !important;
    background-color: #005eb0 !important;
  }
  .btn-dt {
    color: white !important;
    background-color: #c82333 !important;
  }
</style>
