export default {
  methods: {
    formatNumber(number, prefix = '$', decimals = 2){ // 1000.212222 -> $1,000
      const validatedNumber = !isNaN(number) ? number : 0;
      
      const floatNumber = parseFloat(validatedNumber).toFixed(decimals); 
      const formatedNumber = new Intl.NumberFormat('es-MX', { currency: 'MXN', minimumFractionDigits: decimals }).format(floatNumber);

      return `${prefix}${formatedNumber}`;
    },

    formatNumberOptionalDecimals(number, prefix = '$', decimals = 2){ // 1000.212222 -> $1,000.21 || 1000.00 -> $1,000
      const validatedNumber = !isNaN(number) ? number : 0;
      
      if(validatedNumber % 1 == 0) // When number has 00 in de decimals
        return this.formatNumber(number, prefix, 0)
      else
        return this.formatNumber(number, prefix, decimals)
    },

    removeLeftZeros(numberString){ // 0000233392 -> 2333392
      if(numberString === null || numberString === undefined) return '';

      return !isNaN(numberString) ? (parseInt(numberString).toString()) : numberString; 
    },

    formatFloat(number, decimals = 2){
      if(number === null || number === undefined) return '';

      return !isNaN(number) ? (parseFloat(number).toFixed(decimals)) : number; 
    },
    
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }, 
    //Retorna un string rellenado un numero con un string a n digitos
        /**
         * @param {Number} number //numero base
         * @param {String} digits //caracteres
         * @param {String} fillString //string para llenar, defecto 0
         * @returns string
         */
    fillNumberToNDigits(number,digits,fillString = "0") {
      const numStr = number.toString();
      return numStr.padStart(digits,fillString).slice(-digits);
    }
  }
}