import {
  MIN_ORDER_AMOUNT,
  MIN_ORDER_AMOUNT_DT,
  NPO_MIN_CUSTOMER_TENURE_MONTHS,
  NPO_MIN_INVOICED_LINES,
  NPO_MIN_PRODUCTS_IN_CART,
} from '@/core/constants/orderConstants';
import { formatPrice } from '@/utilities/formatPrice';

export default {
  namespaced: true,
  state: {
    doctype: null,
    isBackOrder: null,
    invalidCartItems: [],
    alerts: [
      {
        id: 'MIN_ORDER_AMOUNT_PR',
        label: `El mónto mínimo de compra es de ${formatPrice(
          MIN_ORDER_AMOUNT
        )} MXP`,
        checked: true,
        doctype: ['PSIU'],
      },
      {
        id: 'MIN_ORDER_AMOUNT_DT',
        label: `El mónto mínimo de compra es de ${formatPrice(
          MIN_ORDER_AMOUNT_DT
        )} MXP`,
        checked: true,
        doctype: ['PSDT'],
      },
      {
        id: 'MIN_ORDER_AMOUNT_NPO',
        label: `El mónto mínimo de compra es de ${formatPrice(
          MIN_ORDER_AMOUNT
        )} MXP`,
        checked: true,
        doctype: ['PSNW'],
      },
      {
        id: 'CUSTOMER_CREDIT_LIMIT',
        label:
          'Ha superado el límite de crédito, el pedido será bloqueado por crédito',
        checked: true,
        doctype: ['PSIU', 'PSDT', 'PSPO', 'PSNW'],
      },
      {
        id: 'CUSTOMER_DOCVENC',
        label: 'El cliente tiene documentos vencidos',
        checked: true,
        doctype: ['PSIU', 'PSDT', 'PSPO', 'PSNW'],
      },
      {
        id: 'NPO_MIN_PRODUCTS_IN_CART',
        label: `Para generar el pedido deben ser mínimo ${NPO_MIN_PRODUCTS_IN_CART} renglones (partidas) dentro del carrito`,
        checked: true,
        doctype: ['PSNW'],
      },
      {
        id: 'NPO_MIN_CUSTOMER_TENURE_MONTHS',
        label: `Para generar el pedido el cliente debe de tener ${NPO_MIN_CUSTOMER_TENURE_MONTHS} meses de antiguedad`,
        checked: true,
        doctype: ['PSNW'],
      },
      {
        id: 'MIN_CUSTOMER_PPDP',
        label:
          'Para generar el pedido el cliente debe de tener un Promedio Ponderado de Dias de Pago de 36 días',
        checked: true,
        doctype: ['PSNW'],
      },
      {
        id: 'NPO_MIN_INVOICED_LINES',
        label: `El cliente debe de haber facturado al menos ${NPO_MIN_INVOICED_LINES} renglones en el último trimestre para poder realizar este tipo de pedido`,
        checked: true,
        doctype: ['PSNW'],
      },
      {
        id: 'CUSTOMER_AUFSD',
        label:
          'Estimado cliente usted tiene un bloqueo de partidas vencidas, será bloqueado por crédito',
        checked: true,
        doctype: ['PSIU', 'PSDT', 'PSPO', 'PSNW'],
      },
      {
        id: 'VALID_CART_ITEMS_PSPO',
        label: 'Estos materiales NO pertenecen a un Pedido de Artículos Promocionales (POP)',
        checked: true,
        doctype: ['PSPO']
      },
      {
        id: 'VALID_CART_ITEMS_PSNW',
        label: 'Estos materiales NO pertenecen a un Pedido de Productos Nuevos (PNC)',
        checked: true,
        doctype: ['PSNW']
      }
    ],
  },
  getters: {
    cartAlerts: (state) => {
      if (!state.doctype) return [];
      return state.alerts.filter(
        ({ doctype, checked }) =>
          doctype.includes(state.doctype) && checked === false
      );
    },
    doctype: (state) => state.doctype,
    isBackOrder: (state) => state.isBackOrder,
    invalidCartItems: (state) => state.invalidCartItems,
  },
  mutations: {
    SET_DOCTYPE(state, doctype) {
      state.doctype = doctype;
    },
    SET_IS_BACKORDER(state, isBackOrder) {
      state.isBackOrder = isBackOrder;
    },
    SET_INVALID_CART_ITEMS(state, items) {
      state.invalidCartItems = items;
    },
    CLEAR_ALERTS(state) {
      state.alerts = state.alerts.map((alert) => ({ ...alert, checked: true }));
    },
    SET_ALERT_CHECK(state, { id, isChecked }) {
      const index = state.alerts.findIndex((alert) => alert.id === id);
      if (index === -1) return;

      const alertAux = state.alerts[index];
      state.alerts.splice(index, 1, { ...alertAux, checked: isChecked });
    },
  },
  actions: {
    setShoppingCartAlertsDoctype({ commit }, doctype) {
      commit('SET_DOCTYPE', doctype);
    },
    setShoppingCartAlertsIsBackOrder({ commit }, isBackOrder) {
      commit('SET_IS_BACKORDER', isBackOrder);
    },
    setShoppingCartAlertsInvalidCartItems({ commit }, items) {
      commit('SET_INVALID_CART_ITEMS', items);
    },
    clearShoppingCartAlerts({ commit }) {
      commit('CLEAR_ALERTS');
    },
    setShoppingCartAlertChecked({ commit }, { id, isChecked }) {
      commit('SET_ALERT_CHECK', { id, isChecked });
    },
  },
};
