import fileRepositoryApi from '@/plugins/fileRepositoryApi';

export const getProductMainImage = async (productId) => {
  try {
    const { data } = await fileRepositoryApi.get('/listDropboxNew', {
      params: { code: productId, quant: 1, view: 0 },
    });

    return { error: '', image: getImageObject(data[0] || null) };
  } catch (error) {
    return { error: 'No se pudo obtener la imagen', image: getImageObject() };
  }
};

export const getProductImages = async (productId) => {
  try {
    const { data } = await fileRepositoryApi.get('/listDropboxNew', {
      params: { code: productId, quant: 0, view: 0 },
    });

    // Remove last item of the array, it is the metadata of the response
    const productImages = data.splice(0, data.length - 1);

    return {
      error: '',
      images: productImages.map((image) => getImageObject(image)),
    };
  } catch (error) {
    return { error: 'No se pudo obtener la imagen', images: [getImageObject()] };
  }
};

export const getAllProductFiles = async (productId) => {
  try {
    const { data } = await fileRepositoryApi.get('/allFileDropboxPdf', {
      params: { code: productId, type: 6 },
    });

    // Remove last item of the array, it is the metadata of the response
    const productFiles = data.splice(0, data.length - 1);

    return {
      error: '',
      files: productFiles.map((file) => getFileObject(file)),
    };
  } catch (error) {
    return { error: 'No se pudieron obtener los archivos', files: [] };
  }
};

const getImageObject = (singleImageResponse = null) => {
  return {
    id: singleImageResponse?.id || '',
    name: singleImageResponse?.name || 'default-product-img',
    path: singleImageResponse?.path || '/img/img-default.svg',
  };
};

const getFileObject = (singleFileResponse = null) => {
  return {
    id: singleFileResponse?.id || '',
    name: singleFileResponse?.name || '',
    path: singleFileResponse?.path || '',
    fileType: singleFileResponse?.type_file || '',
  };
};
