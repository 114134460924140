<template>
  <div>
    <v-simple-table>
                  <template v-slot:default>
                    <thead :class="`table-heading`"> 
                      <tr>
                        <th v-for="(header,index) in headers" class="text-center text-dark" :key="index"><b v-html="header.title"></b></th> 
                      </tr>
                    </thead>
                    <tbody :class="`${colorClass}`">
                      <tr v-if="!loading">
                        <td  ><b> {{ user.channels[chnl] | capitalizeFirstLetter }}</b></td>
                        <td>{{formatNumber(data.CSEMANT,"$",0)}}</td>
                        <td>{{formatFloat(data.PCSEMANT,2)}}%</td>
                        <td>{{formatNumber(data.PBSEM12,"$",0)}}</td>
                        <td>{{formatNumber(data.FBPNC,"$",0)}}</td> 
                        <td>{{formatNumber(data.PSEMPNC,"$",0)}}</td>
                        <td>{{formatFloat(data.PBSEM3,2 )}}%</td> 
                        <td> {{formatFloat(data.PCREC,2)}}% </td>
                      </tr>
                      <tr v-else>
                        <td colspan="8">
                            <v-skeleton-loader type="text" ></v-skeleton-loader>
                        </td>
                      </tr>
                    </tbody>
                  </template>
        </v-simple-table>
        <v-alert class="w-50" v-if="error" dense
      outlined
      type="error" elevation="2"> {{ error }} </v-alert>
  </div>


</template>

<script> 
import axios from '@/plugins/axios';
import numbersFormats from '@/mixins/numbersFormats';
import { mapGetters } from "vuex";

export default({
    name:'MonthEndTable', 
    props: {  
      chnl:{
        default:'PR',
        type: String,
      }, 
      regionalManagerId:{
        default:'',
        type: String,
      }
    },
    mixins:[numbersFormats],
    data: () => ({ 
      headers: [
           { 
            title:'Dirección'
            },
            {title:'Cierre semana <br/> anterior' },
            {title:'% de crecimiento <br /> semana anterior VS <br> su semana anterior' },
            {title:'Promedio facturación <br> utlimas 12 semanas' },
            {title:'Facturación de <br> PNC semana actual' },
            {title:'Facturación total <br/> semana actual' },
            {title:'% de facturación del <br>  mes VS ppto' },
            {title:'% de pedidos VS <br>  ppto del mes' },
        ],
        data:[],
        loading:true,
        error:'',
    }),
    mounted(){
        this.weeklyBillingSummary();
    },
    methods:{ 
        async weeklyBillingSummary(){ 
            try { 
            const params = { 
                channel: this.chnl
            }
            if (this.regionalManagerId) params.rgManager = this.regionalManagerId;
            const response = await axios.get("/daily-director/weekly-billing-summary-management", {
                    params:params
                }); 
                this.data = response.data;  
            } catch (error) { 
            this.error = `Error al obtener la consulta ${error}`;
            this.loading=false;
            }
            this.loading = false;
        },
    },
    computed:{
            colorClass(){
                return this.chnl != 'PR' ? 'dt-style' : 'pr-style';
            },   
            ...mapGetters({ user: "auth/user" }),
        },
    });
</script>
<style scoped>
.dt-style {
    border-bottom-color: #fff;
    border-width: 4px 0px 2px 0px;
    border-top-color: #af001f;
}
.pr-style {    
    border-bottom-color: #fff;
    border-width: 4px 0px 2px 0px;
    border-top-color: #124876;

}
</style>