var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'w-full',
    _vm.red ? 'new-card-pink' : 'new-card',
    _vm.showTitle == false ? 'rounded-when-no-title' : '',
  ]},[(_vm.showTitle)?_c('div',{staticClass:"d-flex justify-space-between align-center px-4 py-2 mb-4 new-card-title"},[_c('div',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._t("header-right")],2):_vm._e(),_c('div',{class:[
      'px-4',
      'py-2',
      _vm.showTitle == false ? 'rounded-when-no-title shadow-in' : '',
    ]},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }