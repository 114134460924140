<template>
  <div>
    <div>
      <v-badge
        color="primary"
        :content="itemsInCart"
        :value="itemsInCart"
        overlap
      >
        <v-icon
          color="black"
          large
          >mdi-cart-variant</v-icon
        >
      </v-badge>
      <p class="mb-0 mt-1">Renglones este pedido</p>
    </div>
    <hr />
    <div class="mb-4">
      <p class="mb-0">
        <span
          class="text-black text-decoration-underline cursor-pointer"
          @click="
            $router.push({
              name: 'Renglonaje',
              params: { trim: quarter, year, view: 'box-tied' },
            })
          "
          >{{ ordersInLastQuarter }}</span
        >
        Renglones acumulados
      </p>
      <div class="d-flex justify-center align-center">
        <p class="mb-0 mr-2">Trim {{ quarter }} / {{ year }}</p>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              ><v-icon color="red">mdi-information-outline</v-icon></span
            >
          </template>
          <span>Información al día anterior</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from '@/plugins/axios';

  import { mapGetters } from 'vuex';
  import moment from 'moment';

  export default {
    name: 'CustomerHistoryShopping',
    props: {
      customerCode: { type: Number },
    },
    data: () => {
      return {
        ordersInLastQuarter: undefined,
        year: moment().year(),
        quarter: moment().quarter(),
      };
    },
    computed: {
      ...mapGetters('ordercart', ['itemsInCart']),
    },
    watch: {
      customerCode: {
        async handler(customerCode) {
          if (!customerCode) return;

          try {
            const response = await axios.post('/history-shopping-lite', {
              customerCode,
            });
            this.ordersInLastQuarter = response.data.renglonaje;
          } catch (error) {
            this.$toast.error(
              'No se pudo obtener el renglonaje del último trimestre'
            );
          }
        },
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped></style>
