<template>
  <div>
    <div class="cart-prices secondary">
      <p class="mb-0">Subtotal</p>
      <p class="mb-0">{{ formatPrice(subtotal) }}</p>
    </div>
    <div class="cart-prices secondary">
      <p class="mb-0">I.V.A.</p>
      <p class="mb-0">{{ formatPrice(iva) }}</p>
    </div>
    <div
      class="cart-prices"
      :class="{ 'primary-dt': isDTChannel, 'primary-pr': isPRChannel }"
    >
      <p class="mb-0">Total con I.V.A.</p>
      <p class="mb-0">{{ formatPrice(total) }}</p>
    </div>
  </div>
</template>

<script>
  import { formatPrice } from '@/utilities/formatPrice';
  import { mapGetters } from 'vuex';

  export default {
    name: 'ShoppingCartPriceSummary',
    props: {
      subtotal: { type: Number },
      iva: { type: Number },
      total: { type: Number },
    },
    computed: {
      ...mapGetters('auth', ['channel']),
      isPRChannel() {
        return this.channel === 'PR';
      },
      isDTChannel() {
        return this.channel === 'DT';
      },
    },
    methods: {
      formatPrice(value) {
        return formatPrice(value);
      },
    },
  };
</script>

<style scoped>
  .cart-prices {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 40px;
    padding-right: 40px;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
    font-weight: 900;
    margin-bottom: 2px;
  }

  .cart-prices.primary-dt {
    color: white;
    background: transparent linear-gradient(180deg, #b00020 0%, #580010 100%) 0%
      0% no-repeat padding-box;
    border-bottom: 4px solid #707070 !important;
  }

  .cart-prices.primary-pr {
    color: white;
    background-color: #005eb0 !important;
    border-bottom: 4px solid #707070 !important;
  }

  .cart-prices.secondary {
    background-color: #fff6f6 !important;
    border-bottom: 4px solid #707070 !important;
  }
</style>
