<template>
    <thead>
        <tr> 
            <slot name="thead">  </slot>
            <th v-for="(item, index) in items" :key="index" class="text-center header pa-1" :colspan="colspan">
            <div class="text-black"> {{ item.title }}</div>
                <div v-if="!onlyMonths">
                    # de clientes <br> %
            </div>
            </th>
        </tr>
    </thead>
</template>
<script>
export default {
    name: "TableHead",
    props: {
        items: {
            default: () => [
                { title: 'Enero', value: '' },
                { title: 'Febrero', value: '' },
                { title: 'Marzo', value: '' },
                { title: 'Abril', value: '' },
                { title: 'Mayo', value: '' },
                { title: 'Junio', value: '' },
                { title: 'Julio', value: '' },
                { title: 'Agosto', value: '' },
                { title: 'Septiembre', value: '' },
                { title: 'Octubre', value: '' },
                { title: 'Noviembre', value: '' },
                { title: 'Diciembre', value: '' },
            ]
        },
        onlyMonths: {
            default: false
        },
        colspan: {
            default: 0,
            type: Number
        },
        columnSelectedLabel: {
            default: "Territorios",
            type: String,
        }
    },
    data: () => ({

    }),
    mounted() {
    },
    methods: {
    },
};
</script>
<style scoped>
.header {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    border-width: 1px 1px 3px 1px !important;
    border-color: #E1E1E1;
}
</style>