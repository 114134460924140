import store from "@/store";
//import {hasAnyRole} from "./index"
import CreditIndex from "../views/credit/CreditIndex.vue";
import CreditDetail from "../views/credit/CreditDetail.vue";
import PromissoryIndex from "../views/credit/PromissoryIndex.vue";
import CreditHistory from "../views/credit/CreditHistory.vue";
import PendingRejectOrders from "@/views/credit/OrdersPendingOrRejected.vue";
import CreditAccountStatement from "@/views/credit/CreditAccountStatement.vue";

export const hasAnyRole = (storeroles, roles) => {
  return storeroles.some(role => roles.includes(role.name));
};

export default [
  {
    path: "/credit/customers-request",
    name: "CreditIndex",
    component: CreditIndex,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/credit/history",
    name: "CreditHistory",
    component: CreditHistory,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/credit/customers-request/:customerId",
    name: "CreditDetail",
    component: CreditDetail,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/credit/promissory-notes",
    name: "PromissoryIndex",
    component: PromissoryIndex,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/credit/orders/pending-authorization",
    name: "PendingAuthorization",
    component: PendingRejectOrders,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ['Credit', 'CreditAssistant'])) {
        return next({
          name: "403",
        });
      }
      next();
    }
  },
  {
    path: "/credit/orders/rejected",
    name: "RejectedOrders",
    component: PendingRejectOrders,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ['Credit', 'CreditAssistant'])) {
        return next({
          name: "403",
        });
      }
      next();
    }
  },
  {
    path: "/credit/account-statement",
    name: "CreditAccountStatement",
    component: CreditAccountStatement,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ['Credit', 'CreditAssistant'])) {
        return next({
          name: "403",
        });
      }
      next();
    }
  },
];
