<template>
  <v-dialog
    v-model="isModalOpen"
    width="550"
    min-width="550"
    persistent
    scrollable
  >
    <v-card>
      <div
        class="w-full bg-black d-flex justify-content-between align-center px-4"
      >
        <span class="text-white d-flex align-center font-weight-bold"
          >Backorder</span
        >
        <v-btn
          icon
          small
          style="bg-gray"
          @click="isModalOpen = false"
        >
          <v-icon class="text-white font-size-8">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="w-9 bg-white d-flex flex-wrap">
        <h4
          class="w-full mt-4 font-weight-bold"
          style="color: grey"
        >
          CONFIRMAR BACKORDER
        </h4>
        <span class="solid_line"></span>
        <span class="text-title-bk font-weight-bold mt-3 mb-5"
          >Seleccione los backorders confirmados</span
        >
        <!-- Verifica si la lista de items está vacía -->
        <result-item-backorder
          class="mr-5 ml-5"
          v-for="(item, i) in cart"
          :key="i.codigo_material"
          :item="{
            materialCode: item.codigo_material.slice(-6),
            name: item.nombre_material,
            sku: item.codigo_material.slice(-6),
            reminder: item.reminder_check,
            recordatorios: item.recordatorios,
            precio_lista: item.precio_lista,
          }"
        >
          <div
            style="width: 60px"
            class="mr-1"
          >
            <img
              class="w-full"
              :src="item.image_url"
              alt=""
            />
          </div>
        </result-item-backorder>
      </div>
      <v-layout justify-center>
        <v-card-actions class="justify-center">
          <v-spacer></v-spacer>
          <v-btn
            class="button-backorder-text"
            @click="isModalOpen = false"
          >
            CANCELAR
          </v-btn>

          <v-btn
            class="button-backorder-text"
            :loading="loading"
            :disabled="disabled || userIsBlockedByBackorders"
            @click="$emit('orderGenerateCredit')"
          >
            <span
              style="font-size: 12px"
              v-if="disabled || userIsBlockedByBackorders"
              >Compra Bloqueada</span
            >
            <span v-else>CONFIRMAR PEDIDO</span>
            <span></span>
          </v-btn>
        </v-card-actions>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
  import ResultItemBackorder from './ResultItemBackorder.vue';
  import userBlockedByBackorders from '@/mixins/userBlockedByBackorders';
  import { mapGetters } from 'vuex';

  export default {
    name: 'ConfirmBackorderModal',
    components: {
      ResultItemBackorder,
    },
    mixins: [userBlockedByBackorders],
    props: {
      loading: {
        type: Boolean,
      },
      disabled: {
        type: Boolean,
      },
      value: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      ...mapGetters('ordercart', ['cart', 'subtotalCred']),
      isModalOpen: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
</script>

<style scoped src="@/../public/css/shoppingpr.css"></style>
