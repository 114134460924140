<template>
  <v-dialog
    v-model="isModalOpen"
    min-width="550"
    width="550"
    persistent
    scrollable
  >
    <v-card>
      <div
        class="w-full bg-black d-flex justify-content-between align-center px-4"
      >
        <span class="text-white d-flex align-center font-weight-bold"
          >Confirmación de pedido</span
        >
        <v-btn
          icon
          small
          style="bg-gray"
          @click="isModalOpen = false"
        >
          <v-icon class="text-white font-size-8">mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="w-9 bg-white d-flex flex-wrap">
        <h4
          class="w-full mt-4 font-weight-bold"
          style="color: grey"
        >
          LÍMITE DE CRÉDITO EXCEDIDO
        </h4>
        <span class="solid_line"></span>
        <span class="text-title-bk-black font-weight-bold mt-3 mb-5"
          >Crédito disponible: {{ formatPrice(availableCredit) }}
        </span>
        <span class="text-title-bk-black font-weight-bold mt-3 mb-5"
          >Total:
          <span
            v-bind:class="
              subtotalCred >= availableCredit
                ? 'text-title-bk'
                : 'text-title-bk-succes'
            "
            >{{ formatPrice(subtotalCred) }}</span
          ></span
        >
      </div>
      <v-card-text>
        <div class="w-9 bg-white d-flex flex-wrap">
          <result-item-val-credit
            class="mr-5 ml-5"
            v-for="(item, i) in cart"
            :key="i.codigo_material"
            @onClick="
              (event, item) => $emit('updateMaterialCredit', { event, item })
            "
            :item="{
              materialCode: item.codigo_material.slice(-6),
              name: item.nombre_material,
              sku: item.codigo_material.slice(-6),
              reminder: item.reminder_check,
              recordatorios: item.recordatorios,
              orderUnits: item.u_pedidas,
              totalCost: item.importe_producto,
              v_credit: item.v_credit,
              orderID: orderId,
            }"
          >
            <div
              style="width: 60px"
              class="mr-1"
            >
              <img
                class="w-full"
                :src="item.image_url"
              />
            </div>
          </result-item-val-credit>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-layout justify-center>
          <v-btn
            class="button-red-cancel text-white font-weight-bold font-size-8"
            @click="isModalOpen = false"
          >
            CANCELAR
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-bind:class="
              subtotalCred >= availableCredit || subtotalCred <= 0
                ? 'button-green-disabled font-weight-bold font-size-8'
                : 'button-green text-white font-weight-bold font-size-8'
            "
            :disabled="subtotalCred >= availableCredit || subtotalCred <= 0"
            @click="$emit('orderGenerate')"
          >
            <span
              class="text-white"
              style="font-size: 12px"
              >CONFIRMAR PEDIDO<v-icon
                >mdi-checkbox-multiple-marked-circle</v-icon
              ></span
            >
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import ResultItemValCredit from './ResultItemValCredit.vue';
  import { formatPrice } from '@/utilities/formatPrice';
  import { mapGetters } from 'vuex';

  export default {
    name: 'ConfirmCartCreditModal',
    components: {
      ResultItemValCredit,
    },
    props: {
      availableCredit: {
        type: Number,
      },
      value: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      ...mapGetters('ordercart', ['cart', 'orderId', 'subtotalCred']),
      isModalOpen: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
    methods: {
      formatPrice(value) {
        return formatPrice(value);
      },
    },
  };
</script>

<style scoped src="@/../public/css/shoppingpr.css"></style>
