<template>
  <div class="pa-3">
    <shopping-cart-type-header
      :doctype="doctype"
      :is-backorder="true"
      :backorder-type="type"
      @launchCartAlertsModal="launchCartAlertsModal(doctype)"
    />
    <v-row class="mt-3">
      <v-col
        cols="12"
        md="4"
      >
        <v-row>
          <v-col cols="12">
            <label class="w-100 font-weight-black mb-3 text-left">
              Documento Relacionado
            </label>
            <v-textarea
              v-model="relatedDocs"
              rows="1"
              hide-details
              filled
              solo
            />
          </v-col>
          <v-col cols="12">
            <destination-selector
              v-model="destination"
              :destinations="destinations"
              :loading="destinations.length === 0"
            />
          </v-col>
          <v-col cols="12">
            <product-search-card
              ref="searchForm"
              :disabled="isLoading"
              @submit="onProductSearchSubmit"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <label class="w-100 font-weight-black mb-3 text-left"
              >CFDI Relacionado</label
            >
            <v-autocomplete
              v-model="relatedCFDI"
              :items="RELATED_CFDI_OPTIONS"
              hide-details
              dense
              solo
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <label class="w-100 font-weight-black mb-3 text-left"
                >Orden de Compra</label
              >
              <v-text-field
                :value="order"
                dense
                solo
              />
            </div>
          </v-col>
          <v-col cols="12">
            <customer-credit-limit-bar
              :credit-limit="Number(customerInfo?.KLIMK)"
              :statement-balance="Number(customerInfo?.SKFOR)"
              :available-credit="Number(customerInfo?.CREDD)"
              :available-credit-current-order="availableCreditCurrentOrder"
            />
            <restricted-material-purchase-limit
              :doctype="doctype"
              :customer-code="Number(customerCode)"
            />
          </v-col>
          <v-col cols="12">
            <customer-history-shopping :customer-code="Number(customerCode)" />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <shopping-cart-action-buttons
          :doctype="doctype"
          :is-backorder="true"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <shopping-cart-table-p-r :customer-code="Number(customerCode)" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="5"
        offset-md="7"
      >
        <v-row>
          <v-col cols="12">
            <shopping-cart-price-summary
              :subtotal="subtotalCartPrice"
              :iva="ivaCartPrice"
              :total="totalCartPrice"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              class="confirm-order-btn"
              large
              block
              :disabled="isConfirmOrderButtonDisabled"
              @click="onConfirmOrder"
            >
              {{
                isConfirmOrderButtonDisabled
                  ? ' COMPRA EN PROCESO'
                  : 'CONFIRMAR PEDIDO'
              }}
              <v-icon class="ml-2">mdi-checkbox-multiple-marked-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-column flex-md-row action-btn-group"
          >
            <v-btn
              class="action-btn"
              @click="$router.push({ name: homeRouteName })"
            >
              Carátula Cliente
            </v-btn>
            <v-btn
              class="action-btn"
              @click="exportToCSV"
            >
              Generar Archivo CSV
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <legacy-footer />
    </v-row>

    <!-- MODALS, OVERLAYS AND TELEPORTS -->
    <generate-order-loading-modal v-model="isGeneratingOrder" />
    <confirm-backorder-modal
      v-model="isConfirmBackOrderModalOpen"
      :loading="isGeneratingOrder"
      :disabled="isConfirmOrderButtonDisabled"
      @orderGenerateCredit="runGenerateOrder"
    />
    <validate-customer-storage
      :order_id="idb"
      :channel_order="user.VTWEG"
    />
  </div>
</template>

<script>
  import CustomerCreditLimitBar from './components/CustomerCreditLimitBar.vue';
  import DestinationSelector from './components/DestinationSelector.vue';
  import ShoppingCartTypeHeader from './components/ShoppingCartTypeHeader.vue';
  import ProductSearchCard from './components/ProductSearchCard.vue';
  import ShoppingCartActionButtons from './components/ShoppingCartActionButtons.vue';
  import ShoppingCartTablePR from './components/ShoppingCartTable.vue';
  import LegacyFooter from '../seller/components/LegacyFooter.vue';
  import ConfirmBackorderModal from './components/ConfirmBackorderModal.vue';
  import GenerateOrderLoadingModal from './components/GenerateOrderLoadingModal.vue';
  import CustomerHistoryShopping from './components/CustomerHistoryShopping.vue';
  import ValidateCustomerStorage from '@/components/modals/ValidateCustomerStorage.vue';
  import ShoppingCartPriceSummary from '@/components/ShoppingCartPriceSummary.vue';
  import RestrictedMaterialPurchaseLimit from './components/RestrictedMaterialPurchaseLimit.vue';

  import { formatPrice as formatPriceUtil } from '@/utilities/formatPrice';
  import userBlockedByBackorders from '@/mixins/userBlockedByBackorders';
  import IUSALoader from '@/mixins/fullScreenLoader';
  import axios from '@/plugins/axios';

  import { mapGetters, mapActions, mapMutations } from 'vuex';
  import Papa from 'papaparse';
  import moment from 'moment';

  export default {
    name: 'NewShoppingCartBackPR',
    components: {
      CustomerCreditLimitBar,
      DestinationSelector,
      ShoppingCartTypeHeader,
      ProductSearchCard,
      ShoppingCartActionButtons,
      ShoppingCartTablePR,
      LegacyFooter,
      ConfirmBackorderModal,
      GenerateOrderLoadingModal,
      CustomerHistoryShopping,
      ValidateCustomerStorage,
      ShoppingCartPriceSummary,
      RestrictedMaterialPurchaseLimit,
    },
    mixins: [IUSALoader, userBlockedByBackorders],
    data: () => {
      return {
        isLoading: false,
        isOrderSubmitted: false,
        isGeneratingOrder: false,
        isLoadingCustomerInfo: true,
        isConfirmBackOrderModalOpen: false,
        RELATED_CFDI_OPTIONS: [
          { value: '00', text: '0 - SIN SELECCIÓN' },
          { value: '04', text: '04 - REFACTURACIÓN' },
        ],
        relatedCFDI: undefined,
        customerCode: undefined,
        customerInfo: undefined,
        destination: undefined,
        doctype: undefined,
        idb: undefined,
        orderName: undefined,
        homeRouteName: undefined,
        relatedDocs: undefined,
        sellerNumber: undefined,
        type: undefined,
      };
    },
    computed: {
      ...mapGetters('auth', ['user']),
      ...mapGetters('ordercart', [
        'cart',
        'order',
        'orderId',
        'destinations',
        'lowPrice',
        'itemsInCart',
        'subtotalCartPrice',
        'ivaCartPrice',
        'totalCartPrice',
      ]),
      ...mapGetters('shoppingCartAlerts', ['cartAlerts']),
      availableCredit() {
        return Number(this.customerInfo?.CREDD);
      },
      availableCreditCurrentOrder() {
        const availableCredit = Number(this.customerInfo?.CREDD);
        return availableCredit - this.totalCartPrice;
      },
      isConfirmOrderButtonDisabled() {
        if (this.doctype !== 'PSIU') return false;

        if (this.itemsInCart === 0 || this.isOrderSubmitted) {
          return true;
        }

        const typeBackOrderPR = this.$route.params.type == 'PR';
        const hasZeroBackOrders = this.cart.some(
          (item) => item.recordatorios == 0
        );
        if (hasZeroBackOrders && typeBackOrderPR) {
          return true;
        }

        return false;
      },
    },
    async mounted() {
      this.activeLoader();

      if (this.$hasAnyRole(['Customer', 'CustomerPR'])) {
        this.customerCode = this.user.custumer_code;
        this.sellerNumber = this.user.custumer_code;
        this.homeRouteName = 'Home';
        this.type = 'CL';
      } else {
        const currentCustomerInfo =
          JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];

        if (currentCustomerInfo.length) {
          this.$router.push({ name: 'Home' });
        }

        this.customerCode = parseInt(currentCustomerInfo?.KUNNR, 10);
        this.sellerNumber = this.user.employee_number;
        this.homeRouteName = 'CustomerCoverDetails';
        this.type = 'VE';
      }

      this.doctype = this.$route.params.doctype;
      this.idb = this.$route.params.idb;
      this.setShoppingCartAlertsDoctype(this.doctype);

      await this.getCustomerInfo();
      await this.loadCustomerDestinations({
        custumer_code: this.customerCode,
        VKORG: this.user.VKORG,
        VTWEG: this.user.VTWEG,
        VKBUR: this.user.VKBUR,
      });

      await this.loadOrderBack({
        customer: this.customerCode,
        order_id: this.idb,
        dd: 0,
      });

      await this.loadCartItems({
        custumer_code: this.customerCode,
        VKORG: this.user.VKORG,
        VTWEG: this.user.VTWEG,
        VKBUR: this.user.VKBUR,
        doctype: this.doctype,
      });

      this.runCartValidations();
      this.desactiveLoader();
      this.generateExcel();
      this.generatePDF();

      if (this.cartAlerts.length) {
        await this.launchCartAlertsModal(this.doctype);
      }
    },
    methods: {
      ...mapMutations('ordercart', ['SET_CUSTOMER_IVA']),
      ...mapActions('quotecart', ['turnOrderIntoQuoter']),
      ...mapActions('ordercart', [
        'getMaterialInfo',
        'loadOrderBack',
        'loadCartItems',
        'loadCustomerDestinations',
        'saveNewMaterialOrder',
        'updateMaterialQuantity',
        'generateOrderBack',
      ]),
      ...mapActions('modalStepsAfterLogin', [
        'updateModalState',
        'findStepByKey',
        'changeCurrentStepValue',
        'updateStepStatus',
        'loadShoppingCartAlertsStep',
      ]),
      ...mapActions('shoppingCartAlerts', [
        'setShoppingCartAlertsDoctype',
        'clearShoppingCartAlerts',
        'setShoppingCartAlertChecked',
      ]),
      ...mapActions('printer', ['sendToPDF', 'sendToExcel']),
      formatPrice(value) {
        return formatPriceUtil(value);
      },
      async launchCartAlertsModal(doctype) {
        await this.loadShoppingCartAlertsStep({
          doctype,
          isBackOrder: true,
          isConvert: false,
        });
        const cartAlertsStep = await this.findStepByKey('shoppingCartAlerts');

        if (cartAlertsStep) {
          cartAlertsStep.visited = true;
          this.updateStepStatus(cartAlertsStep);
          this.changeCurrentStepValue(cartAlertsStep.value);
          this.updateModalState(true);
        }
      },
      generatePDF() {
        this.sendToPDF({ active: true });
      },
      generateExcel() {
        const itemsCSV = this.cart.map((item) => ({
          Producto: item.codigo_material,
          Nombre: item.nombre_material,
          Genetica: item.material_type,
          Cantidad: item.u_confirm,
          Empaque: item.empaque,
          'Unidad de Medida.': item.unidad_medida,
          'Pedido Mensual.': item.PMCOM,
          'Dias solicitados.': item.DSINV,
          'Precio de lista': item.precio_lista,
          'Precio C/ descuento': item.precio_con_descuento,
          'Importe Total': item.importe_producto,
        }));

        const currentDate = moment().format('YYYY/MM/DD');
        const objectCSV = {
          name: `Pedidos-${currentDate}`,
          items: itemsCSV,
          showLabels: true,
        };

        this.sendToExcel(objectCSV);
      },
      exportToCSV() {
        const csvData = [
          ['Versión', 'Código de Productos', 'Orden de Compra'],
          ['1.3.0', 'IUSA', this.orderName || 'OC-0'],
          ['Cantidad', 'Código', 'Nombre'],
        ];

        const rows = this.cart.map((item) => [
          parseInt(item.u_confirm),
          item.codigo_material,
          item.nombre_material,
        ]);

        csvData.push(...rows);
        const csvFile = Papa.unparse(csvData);

        const blob = new Blob([csvFile], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = Object.assign(document.createElement('a'), {
          href: url,
          download: 'Pedidos.csv',
        });
        a.click();
      },
      runCartValidations() {
        this.clearShoppingCartAlerts();
        // VALIDATES CUSTOMER DOCUMENTS EXPIRED
        if (this.customerInfo.DOCVENC > 0) {
          this.setShoppingCartAlertChecked({
            id: 'CUSTOMER_DOCVENC',
            isChecked: false,
          });
        }

        // VALIDATES CUSTOMER BLOCKED BY AUSFD
        if (this.customerInfo.AUFSD == 1) {
          this.setShoppingCartAlertChecked({
            id: 'CUSTOMER_AUFSD',
            isChecked: false,
          });
        }
      },
      async getCustomerInfo() {
        this.isLoadingCustomerInfo = true;

        try {
          const response = await axios.post('infoCustomer', {
            customer: this.customerCode,
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR,
          });

          this.customerInfo = response.data;
          this.SET_CUSTOMER_IVA(this.customerInfo.TAXKD);

          return { ok: true, customerInfo: response.data };
        } catch (error) {
          this.$toast.error('No se pudo obtener la información del cliente');
          return { ok: false, customerInfo: undefined };
        } finally {
          this.isLoadingCustomerInfo = false;
        }
      },
      async onProductSearchSubmit({ sku, quantity }) {
        await this.addItemToCart(sku, quantity);
      },
      async addItemToCart(sku, quantity) {
        this.isLoading = true;

        try {
          const product = await this.getMaterialInfo({
            sku,
            quantity,
            custumer_code: this.customerCode,
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR,
            order_type: this.doctype,
          });

          await this.saveNewMaterialOrder({
            order_id: this.orderId,
            item: product,
          });

          if (quantity < product.empaque || product.u_confirm == 0) {
            await this.updateMaterialQuantity({
              codigo_material: product.codigo_material,
              quantity:
                product.u_confirm == 0
                  ? product.recordatorios
                  : product.u_confirm,
              custumer_code: this.customerCode,
              order_type: this.doctype,
            });
          }

          this.$refs.searchForm.clearForm();
          this.$toast.success('Producto agregado');
        } catch (error) {
          if (error.response?.data?.error_code) {
            this.$toast.warning(error.response.data.error_code);
          } else {
            this.$toast.error('No se pudo agregar al carrito');
          }
        } finally {
          this.isLoading = false;
        }
      },
      async onConfirmOrder() {
        if (!this.destination) {
          this.$toast.error('El destinatario de mercancía es requerido');
          return;
        }

        if (!this.cart || this.cart.length === 0) {
          this.$toast.error(
            'El carrito está vacío, no se puede generar la orden'
          );
          return;
        }

        const existBackOrder = this.cart.some((item) => item.recordatorios > 0);
        if (existBackOrder) {
          this.isConfirmBackOrderModalOpen = true;
        } else {
          this.runGenerateOrder();
        }
      },
      async runGenerateOrder() {
        this.isConfirmBackOrderModalOpen = false;
        this.isGeneratingOrder = true;
        let purchaseValidation;

        if (
          this.customerInfo.DOCVENC > 0 ||
          this.customerInfo.AUFSD == 1 ||
          this.availableCredit <= 1 ||
          this.totalCartPrice > this.availableCredit
        ) {
          purchaseValidation = 2;
        } else {
          purchaseValidation = 1;
        }

        try {
          const response = await this.generateOrderBack({
            custumer_code: this.customerCode,
            orden_compra: this.order,
            carrito: this.cart,
            orderType: this.doctype,
            destiny: parseInt(this.destination?.KUNNR),
            banderaProgramado: '',
            purchase_validation: purchaseValidation,
            numero_vendedor: this.sellerNumber,
            schedule_orders: this.scheduleOrder,
            reminder_date: this.scheduleOrderDate,
          });

          this.isGeneratingOrder = false;
          this.isOrderSubmitted = true;

          if (response.message == 'BACKORDER GENERADO') {
            this.$swal.fire(
              response.message,
              'Número de Pedido: S/N',
              'success'
            );
            this.$router.replace({
              name: 'HistoryOrders',
            });
          }

          const orderResponse = response.VBELN;
          const invoiceResponse = response.FACTURAS;
          const deliveryResponse = response.ENTREGAS;
          const purchaseResponse =
            response.PVALID == 1 ? '' : '* Bloqueado por Crédito';
          const messageResponse = response.MYERR == 0 ? '' : response.FNMSG;

          const message = `Pedido: ${orderResponse}.
                             Entrega: ${deliveryResponse}.
                             Factura: ${invoiceResponse}.
                             ${purchaseResponse}.
                             ${messageResponse}.`;

          this.$toast.success(
            'Pedido Generado',
            message + '* Bloqueado por crédito'
          );

          this.$router.replace({ name: 'HistoryOrders' });
        } catch (error) {
          this.isGeneratingOrder = false;

          if (error.response.status == 404) {
            if (error.response.data.message == 'BACKORDER GENERADO') {
              this.$swal.fire(
                error.response.data.message,
                'Número de Pedido: S/N',
                'success'
              );
              this.$router.replace({ name: 'HistoryOrders' });
            } else {
              this.$swal({
                title: error.response.data.error_code,
                icon: 'error',
                type: 'error',
              });
            }
          }
        }
      },
    },
    watch: {
      order: {
        handler(order) {
          this.orderName = order;
        },
        immediate: true,
      },
      totalCartPrice() {
        this.runCartValidations();
      },
    },
  };
</script>

<style scoped>
  .confirm-order-btn {
    background: transparent linear-gradient(180deg, #218838 0%, #11441c 100%) 0%
      0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
    color: white !important;
    font-weight: 900 !important;
  }

  .action-btn {
    box-shadow: inset 0px 2px 6px #00000029, 0px 2px 6px #00000029;
    font-weight: 900;
    text-transform: capitalize;
    flex-grow: 1;
  }

  .action-btn-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
  }

  @media (min-width: 960px) {
    .action-btn-group {
      flex-direction: row;
      gap: 16px;
    }
  }
</style>
