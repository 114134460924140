<template>
  <div class="pa-3">
    <shopping-cart-type-header
      :doctype="doctype"
      @launchCartAlertsModal="launchCartAlertsModal(doctype)"
    />
    <v-row
      v-if="showCustomerPaymentInfo"
      class="mt-3"
    >
      <v-col
        cols="12"
        md="9"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <label class="font-weight-black mb-3 text-left">
              Método de Pago
            </label>
            <v-autocomplete
              v-model="paymentMethod"
              :items="customerPaymentMethods"
              item-value="id"
              :item-text="(item) => `(${item.id}) ${item.des}`"
              dense
              hide-details
              solo
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <label class="font-weight-black mb-3 text-left">Vía de Pago</label>
            <v-autocomplete
              v-model="paymentChannel"
              :items="customerPaymentChannels"
              item-value="id"
              :item-text="(item) => `(${item.id}) ${item.des}`"
              dense
              hide-details
              solo
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <label class="font-weight-black mb-3 text-left">Uso de CFDI</label>
            <v-autocomplete
              v-model="cfdiApplication"
              :items="customerCfdiApplications"
              item-value="id"
              :item-text="(item) => `(${item.id}) ${item.des}`"
              dense
              hide-details
              solo
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col
        cols="12"
        md="4"
      >
        <v-row>
          <v-col cols="12">
            <label class="w-100 font-weight-black mb-3 text-left">
              Documento Relacionado
            </label>
            <v-textarea
              v-model="relatedDocs"
              rows="1"
              hide-details
              filled
              solo
            />
          </v-col>
          <v-col cols="12">
            <destination-selector
              v-model="destination"
              :destinations="destinations"
              :loading="destinations.length === 0"
            />
          </v-col>
          <v-col cols="12">
            <product-search-card
              ref="searchForm"
              :disabled="isLoading"
              @submit="onProductSearchSubmit"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <label class="w-100 font-weight-black mb-3 text-left"
              >CFDI Relacionado</label
            >
            <v-autocomplete
              v-model="relatedCFDI"
              :items="RELATED_CFDI_OPTIONS"
              hide-details
              dense
              solo
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-form @submit.prevent="saveOrderName">
              <label class="w-100 font-weight-black mb-3 text-left"
                >Orden de Compra</label
              >
              <v-text-field
                :value="order"
                dense
                solo
                @input="(v) => (orderName = v)"
              />
            </v-form>
          </v-col>
          <v-col cols="12">
            <customer-credit-limit-bar
              :credit-limit="Number(customerInfo?.KLIMK)"
              :statement-balance="Number(customerInfo?.SKFOR)"
              :available-credit="Number(customerInfo?.CREDD)"
              :available-credit-current-order="availableCreditCurrentOrder"
            />
          </v-col>
          <v-col cols="12">
            <customer-history-shopping :customer-code="Number(customerCode)" />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <shopping-cart-action-buttons
          :doctype="doctype"
          @scheduleOrder="
            ({ scheduleOrder, scheduleOrderDate }) => {
              this.scheduleOrder = scheduleOrder;
              this.scheduleOrderDate = scheduleOrderDate;
            }
          "
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <shopping-cart-table-p-r :customer-code="Number(customerCode)" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="7"
      >
        <div class="d-flex flex-column flex-md-row flex-wrap action-btn-group">
          <v-btn
            v-if="doctype !== 'PSIU'"
            class="action-btn"
            @click="convertToNormalOrder"
          >
            <span class="icoiusa-checkbox-marked mr-2"></span>
            Convertir a Pedido Normal
          </v-btn>
          <v-btn
            v-if="doctype !== 'PSNW'"
            class="action-btn"
            @click="convertToNewProductsOrder"
          >
            <span class="icoiusa-two-arrows mr-2"></span>
            Convertir a Pedido de Productos Nuevos (PNC)
          </v-btn>
          <v-btn
            v-if="doctype !== 'PSPO'"
            class="action-btn"
            @click="convertToPromotionalProductsOrder"
          >
            <span class="icoiusa-shrink2 mr-2"></span>
            Convertir a Pedido de Artículos Promocionales (POP)
          </v-btn>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <v-row>
          <v-col cols="12">
            <shopping-cart-price-summary
              :subtotal="subtotalCartPrice"
              :iva="ivaCartPrice"
              :total="totalCartPrice"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              class="confirm-order-btn"
              large
              block
              :disabled="isConfirmOrderButtonDisabled"
              @click="onConfirmOrder"
            >
              {{
                isConfirmOrderButtonDisabled
                  ? ' COMPRA EN PROCESO'
                  : 'CONFIRMAR PEDIDO'
              }}
              <v-icon class="ml-2">mdi-checkbox-multiple-marked-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-column flex-md-row action-btn-group"
          >
            <v-btn
              class="action-btn"
              @click="$router.push({ name: homeRouteName })"
            >
              Carátula Cliente
            </v-btn>
            <v-btn
              class="action-btn"
              @click="exportToCSV"
            >
              Generar Archivo CSV
            </v-btn>
            <v-btn
              class="action-btn"
              @click="convertOrderIntoQuote"
            >
              Convertir a Cotización
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <legacy-footer />
    </v-row>

    <!-- MODALS, OVERLAYS AND TELEPORTS -->
    <generate-order-loading-modal v-model="isGeneratingOrder" />
    <confirm-backorder-modal
      v-model="isConfirmBackOrderModalOpen"
      :loading="isGeneratingOrder"
      :disabled="isConfirmOrderButtonDisabled"
      @orderGenerateCredit="onConfirmBackOrder"
    />

    <confirm-cart-credit-modal
      v-model="isConfirmCartCreditModalOpen"
      :available-credit="availableCredit"
      @orderGenerate="runGenerateOrder"
      @updateMaterialCredit="
        (event, item) => onUpdateMaterialCredit(event, item)
      "
    />
  </div>
</template>

<script>
  import CustomerCreditLimitBar from './components/CustomerCreditLimitBar.vue';
  import DestinationSelector from './components/DestinationSelector.vue';
  import ShoppingCartTypeHeader from './components/ShoppingCartTypeHeader.vue';
  import ProductSearchCard from './components/ProductSearchCard.vue';
  import ShoppingCartActionButtons from './components/ShoppingCartActionButtons.vue';
  import ShoppingCartTablePR from './components/ShoppingCartTable.vue';
  import LegacyFooter from '../seller/components/LegacyFooter.vue';
  import ConfirmBackorderModal from './components/ConfirmBackorderModal.vue';
  import ConfirmCartCreditModal from './components/ConfirmCartCreditModal.vue';
  import GenerateOrderLoadingModal from './components/GenerateOrderLoadingModal.vue';
  import CustomerHistoryShopping from './components/CustomerHistoryShopping.vue';
  import ShoppingCartPriceSummary from '@/components/ShoppingCartPriceSummary.vue';

  import {
    MIN_ORDER_AMOUNT,
    NPO_MIN_CUSTOMER_TENURE_MONTHS,
    NPO_MIN_INVOICED_LINES,
    NPO_MIN_PRODUCTS_IN_CART,
  } from '@/core/constants/orderConstants';
  import { formatPrice as formatPriceUtil } from '@/utilities/formatPrice';
  import userBlockedByBackorders from '@/mixins/userBlockedByBackorders';
  import IUSALoader from '@/mixins/fullScreenLoader';
  import axios from '@/plugins/axios';

  import { mapGetters, mapActions, mapMutations } from 'vuex';
  import Papa from 'papaparse';
  import moment from 'moment';

  export default {
    name: 'NewShoppingCartPR',
    components: {
      CustomerCreditLimitBar,
      DestinationSelector,
      ShoppingCartTypeHeader,
      ProductSearchCard,
      ShoppingCartActionButtons,
      ShoppingCartTablePR,
      LegacyFooter,
      ConfirmBackorderModal,
      ConfirmCartCreditModal,
      GenerateOrderLoadingModal,
      CustomerHistoryShopping,
      ShoppingCartPriceSummary,
    },
    mixins: [IUSALoader, userBlockedByBackorders],
    data: () => {
      return {
        isLoading: false,
        isOrderSubmitted: false,
        isGeneratingOrder: false,
        isLoadingCustomerInfo: true,
        isConfirmBackOrderModalOpen: false,
        isConfirmCartCreditModalOpen: false,
        RELATED_CFDI_OPTIONS: [
          { value: '00', text: '0 - SIN SELECCIÓN' },
          { value: '04', text: '04 - REFACTURACIÓN' },
        ],
        relatedCFDI: undefined,
        cfdiApplication: undefined,
        customerCode: undefined,
        customerTenure: undefined,
        customerInfo: undefined,
        customerCfdiApplications: [],
        customerPaymentChannels: [],
        customerPaymentMethods: [],
        destination: undefined,
        doctype: undefined,
        idq: undefined,
        orderName: undefined,
        paymentChannel: undefined,
        paymentMethod: undefined,
        homeRouteName: undefined,
        relatedDocs: undefined,
        scheduleOrder: undefined,
        scheduleOrderDate: undefined,
        sellerNumber: undefined,
        type: undefined,
      };
    },
    computed: {
      ...mapGetters('auth', ['user']),
      ...mapGetters('ordercart', [
        'cart',
        'destinations',
        'orderId',
        'order',
        'lowPrice',
        'itemsInCart',
        'subtotalCartPrice',
        'ivaCartPrice',
        'totalCartPrice',
      ]),
      ...mapGetters('shoppingCartAlerts', ['cartAlerts']),
      availableCredit() {
        return Number(this.customerInfo?.CREDD);
      },
      availableCreditCurrentOrder() {
        const availableCredit = Number(this.customerInfo?.CREDD);
        return availableCredit - this.totalCartPrice;
      },
      showCustomerPaymentInfo() {
        if (this.customerInfo?.ZTERM != 'IU00') return false;
        return this.cart.some((item) => item.recordatorios == 0);
      },
      isConfirmOrderButtonDisabled() {
        const isCartEmpty = this.cart.length === 0;
        const isOrderSubmitted = this.isOrderSubmitted;
        const isBelowMinOrderAmount = this.totalCartPrice < MIN_ORDER_AMOUNT;
        const hasBackOrders = this.userIsBlockedByBackorders;

        if (this.scheduleOrder && !isCartEmpty) return false;

        if (this.doctype === 'PSIU') {
          if (
            isCartEmpty ||
            isOrderSubmitted ||
            isBelowMinOrderAmount ||
            hasBackOrders
          )
            return true;
          if (
            this.cart.some((item) => item.recordatorios === 0) &&
            isBelowMinOrderAmount
          )
            return true;
        }

        if (this.doctype === 'PSPO') {
          if (isCartEmpty || isOrderSubmitted) return true;
          if (
            this.cart.some((item) => item.recordatorios === 0) &&
            this.totalCartPrice < 0
          )
            return true;
        }

        if (this.doctype === 'PSNW') {
          const isCartSmall = this.cart.length < NPO_MIN_PRODUCTS_IN_CART;
          const isCustomerTooRecent =
            this.customerTenure < NPO_MIN_CUSTOMER_TENURE_MONTHS;
          const hasExceededPPDP = this.customerInfo?.PPDP > 36;
          const hasInsufficientInvoicedLines =
            this.customerInfo?.RTRIMA < NPO_MIN_INVOICED_LINES;

          if (
            isCartEmpty ||
            isOrderSubmitted ||
            isBelowMinOrderAmount ||
            isCartSmall ||
            isCustomerTooRecent ||
            hasInsufficientInvoicedLines ||
            hasExceededPPDP
          )
            return true;
        }

        return false;
      },
    },
    async mounted() {
      this.activeLoader();

      if (this.$hasAnyRole(['Customer', 'CustomerPR'])) {
        this.customerCode = this.user.custumer_code;
        this.sellerNumber = this.user.custumer_code;
        this.homeRouteName = 'Home';
        this.type = 'CL';
      } else {
        const currentCustomerInfo =
          JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];

        if (currentCustomerInfo.length) {
          this.$router.push({ name: 'Home' });
        }

        this.customerCode = parseInt(currentCustomerInfo?.KUNNR, 10);
        this.sellerNumber = this.user.employee_number;
        this.homeRouteName = 'CustomerCoverDetails';
        this.type = 'VE';
      }

      this.idq = this.$route.params.idq;
      this.doctype = this.$route.params.doctype;
      this.setShoppingCartAlertsDoctype(this.doctype);

      // LOADS PREVIOUS SELECTED CUSTOMER
      await this.loadCustomer();
      await this.validateUserBlockedByBackorders();

      await this.getCustomerInfo();
      await this.loadCustomerDestinations({
        custumer_code: this.customerCode,
        VKORG: this.user.VKORG,
        VTWEG: this.user.VTWEG,
        VKBUR: this.user.VKBUR,
      });

      await this.loadOrder({
        custumer_code: this.customerCode,
        user_id: this.user.id,
        type: this.type,
        channel: this.user.VTWEG,
        doctype: this.doctype,
        idq: this.idq,
      });

      await this.loadCartItems({
        custumer_code: this.customerCode,
        VKORG: this.user.VKORG,
        VTWEG: this.user.VTWEG,
        VKBUR: this.user.VKBUR,
        doctype: this.doctype,
      });

      this.runCartValidations();
      this.desactiveLoader();
      this.generateExcel();
      this.generatePDF();

      if (this.userIsBlockedByBackorders) {
        await this.launchBackOrdersModal();
        return;
      }

      if (this.cartAlerts.length) {
        await this.launchCartAlertsModal(this.doctype);
      }
    },
    methods: {
      ...mapMutations('ordercart', ['SET_CUSTOMER_IVA']),
      ...mapActions('quotecart', ['turnOrderIntoQuoter']),
      ...mapActions('ordercart', [
        'getMaterialInfo',
        'loadCartItems',
        'loadCustomerDestinations',
        'loadOrder',
        'saveNewMaterialOrder',
        'updateMaterialQuantity',
        'updateMaterialCredit',
        'updateOrderName',
        'generateOrder',
      ]),
      ...mapActions('modalStepsAfterLogin', [
        'updateModalState',
        'findStepByKey',
        'changeCurrentStepValue',
        'updateStepStatus',
        'loadRemindersStep',
        'loadShoppingCartAlertsStep',
      ]),
      ...mapActions('selectedUser', ['loadCustomer']),
      ...mapActions('shoppingCartAlerts', [
        'setShoppingCartAlertsDoctype',
        'clearShoppingCartAlerts',
        'setShoppingCartAlertChecked',
        'setShoppingCartAlertsInvalidCartItems',
      ]),
      ...mapActions('printer', ['sendToPDF', 'sendToExcel']),
      formatPrice(value) {
        return formatPriceUtil(value);
      },
      async launchBackOrdersModal() {
        await this.loadRemindersStep();
        const step = await this.findStepByKey('reminders');

        if (!step) return;

        step.visited = true;
        this.updateStepStatus(step);
        this.changeCurrentStepValue(step.value);
        this.updateModalState(true);
      },
      async launchCartAlertsModal(doctype, isConvert = false) {
        await this.loadShoppingCartAlertsStep({
          doctype,
          isBackOrder: false,
          isConvert,
        });
        const cartAlertsStep = await this.findStepByKey('shoppingCartAlerts');

        if (cartAlertsStep) {
          cartAlertsStep.visited = true;
          this.updateStepStatus(cartAlertsStep);
          this.changeCurrentStepValue(cartAlertsStep.value);
          this.updateModalState(true);
        }
      },
      generatePDF() {
        this.sendToPDF({ active: true });
      },
      generateExcel() {
        const itemsCSV = this.cart.map((item) => ({
          Producto: item.codigo_material,
          Nombre: item.nombre_material,
          Genetica: item.material_type,
          Cantidad: item.u_confirm,
          Empaque: item.empaque,
          'Unidad de Medida.': item.unidad_medida,
          'Pedido Mensual.': item.PMCOM,
          'Dias solicitados.': item.DSINV,
          'Precio de lista': item.precio_lista,
          'Precio C/ descuento': item.precio_con_descuento,
          'Importe Total': item.importe_producto,
        }));

        const currentDate = moment().format('YYYY/MM/DD');
        const objectCSV = {
          name: `Pedidos-${currentDate}`,
          items: itemsCSV,
          showLabels: true,
        };

        this.sendToExcel(objectCSV);
      },
      exportToCSV() {
        const csvData = [
          ['Versión', 'Código de Productos', 'Orden de Compra'],
          ['1.3.0', 'IUSA', this.orderName || 'OC-0'],
          ['Cantidad', 'Código', 'Nombre'],
        ];

        const rows = this.cart.map((item) => [
          parseInt(item.u_confirm),
          item.codigo_material,
          item.nombre_material,
        ]);

        csvData.push(...rows);
        const csvFile = Papa.unparse(csvData);

        const blob = new Blob([csvFile], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = Object.assign(document.createElement('a'), {
          href: url,
          download: 'Pedidos.csv',
        });
        a.click();
      },
      runCartValidations() {
        this.clearShoppingCartAlerts();
        // VALIDATES MIN PRICE IN CART FOR BOTH PSIU AND PSNW
        if (this.totalCartPrice < MIN_ORDER_AMOUNT) {
          this.setShoppingCartAlertChecked({
            id: 'MIN_ORDER_AMOUNT_PR',
            isChecked: false,
          });
          this.setShoppingCartAlertChecked({
            id: 'MIN_ORDER_AMOUNT_NPO',
            isChecked: false,
          });
        }

        // VALIDATES CUSTOMER AVAILABLE CREDIT
        if (this.totalCartPrice > this.availableCreditCurrentOrder) {
          this.setShoppingCartAlertChecked({
            id: 'CUSTOMER_CREDIT_LIMIT',
            isChecked: false,
          });
        }

        // VALIDATES CUSTOMER DOCUMENTS EXPIRED
        if (this.customerInfo.DOCVENC > 0) {
          this.setShoppingCartAlertChecked({
            id: 'CUSTOMER_DOCVENC',
            isChecked: false,
          });
        }

        // VALIDATES MIN AMOUNT IN ITEMS IN CART (PER ITEM NOT QUANTITY)
        if (this.itemsInCart < NPO_MIN_PRODUCTS_IN_CART) {
          this.setShoppingCartAlertChecked({
            id: 'NPO_MIN_PRODUCTS_IN_CART',
            isChecked: false,
          });
        }

        // VALIDATES MIN CUSTOMER TENURE IN MONTHS
        if (this.customerTenure < NPO_MIN_CUSTOMER_TENURE_MONTHS) {
          this.setShoppingCartAlertChecked({
            id: 'NPO_MIN_CUSTOMER_TENURE_MONTHS',
            isChecked: false,
          });
        }

        // VALIDATES MIN CUSTOMER PPDP
        if (this.customerInfo.PPDP > 36) {
          this.setShoppingCartAlertChecked({
            id: 'MIN_CUSTOMER_PPDP',
            isChecked: false,
          });
        }

        // VALIDATES MIN CUSTOMER INVOICED LINES IN THE LAST TRIMESTER
        if (this.customerInfo.RTRIMA < NPO_MIN_INVOICED_LINES) {
          this.setShoppingCartAlertChecked({
            id: 'NPO_MIN_INVOICED_LINES',
            isChecked: false,
          });
        }

        // VALIDATES CUSTOMER BLOCKED BY AUSFD
        if (this.customerInfo.AUFSD == 1) {
          this.setShoppingCartAlertChecked({
            id: 'CUSTOMER_AUFSD',
            isChecked: false,
          });
        }
      },
      async convertOrderType(type) {
        try {
          await axios.post('changeOrderType', {
            order_id: this.orderId,
            order_type: type,
          });

          this.$router
            .push({
              name: 'NewShoppingCartPR',
              params: { doctype: type, idq: this.idq },
            })
            .then(() => {
              this.$router.go(0);
              window.location.reload();
            });
        } catch (error) {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        }
      },
      async convertToNormalOrder() {
        await this.convertOrderType('PSIU');
      },
      async convertToNewProductsOrder() {
        if (this.itemsInCart === 0) {
          this.$toast.error(
            'El carrito está vacio, no se puede convertir a PNC'
          );
          return;
        }

        this.setShoppingCartAlertsDoctype('PSNW');
        this.runCartValidations();

        const nonNewProducts = this.cart.filter(
          (item) =>
            item.TIPOP === '1' ||
            item.PNC !== 0 ||
            item.sector === 30 ||
            item.sector === 90
        );

        // VALIDATE IF PRODUCTS ARE INVALID TO PSPO ORDER
        if (nonNewProducts.length) {
          this.setShoppingCartAlertsInvalidCartItems(nonNewProducts);
          this.setShoppingCartAlertChecked({
            id: 'VALID_CART_ITEMS_PSNW',
            isChecked: false,
          });
        }

        if (this.cartAlerts.length) {
          await this.launchCartAlertsModal('PSNW', true);
        } else {
          await this.convertOrderType('PSNW');
        }
      },
      async convertToPromotionalProductsOrder() {
        if (this.itemsInCart === 0) {
          this.$toast.error(
            'El carrito está vacio, no se puede convertir a POP'
          );
          return;
        }

        this.setShoppingCartAlertsDoctype('PSPO');
        this.runCartValidations();

        const nonPromotionalProducts = this.cart.filter(
          (item) =>
            item.MVGR4 !== '1ZA' &&
            item.MVGR4 !== '1ZB' &&
            item.MVGR4 !== '1ZC' &&
            item.MVGR4 !== '1YA'
        );

        // VALIDATE IF PRODUCTS ARE INVALID TO PSPO ORDER
        if (nonPromotionalProducts.length) {
          this.setShoppingCartAlertsInvalidCartItems(nonPromotionalProducts);
          this.setShoppingCartAlertChecked({
            id: 'VALID_CART_ITEMS_PSPO',
            isChecked: false,
          });
        }

        if (this.cartAlerts.length) {
          await this.launchCartAlertsModal('PSPO', true);
        } else {
          await this.convertOrderType('PSPO');
        }
      },
      async convertOrderIntoQuote() {
        try {
          await this.turnOrderIntoQuoter({
            custumer_code: this.customerCode,
            order_id: this.orderId,
            user_id: this.user.id,
            cart: this.cart,
            type: this.type,
            doctype: this.doctype,
            channel: this.user.VTWEG,
            total: this.totalCartPrice,
          });

          this.$router.push({ name: 'QuoterHistory' });
        } catch (error) {
          this.$toast.error('No se pudo convertir a cotización');
        }
      },
      async getCustomerInfo() {
        this.isLoadingCustomerInfo = true;
        const endpointUrl =
          this.user.VTWEG === 'PR' ? 'info-customer-pr' : 'infoCustomer';

        try {
          const response = await axios.post(endpointUrl, {
            customer: this.customerCode,
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR,
          });

          this.customerInfo = response.data.customer_data;
          this.customerTenure = moment().diff(this.customerInfo.ERDAT, 'month');
          this.customerCfdiApplications = response.data.uso_cfdi;
          this.customerPaymentChannels = response.data.via_de_pago;
          this.customerPaymentMethods = response.data.metodos_de_pago;
          this.SET_CUSTOMER_IVA(this.customerInfo.TAXKD);

          return { ok: true, customerInfo: response.data.customer_data };
        } catch (error) {
          this.$toast.error('No se pudo obtener la información del cliente');
          return { ok: false, customerInfo: undefined };
        } finally {
          this.isLoadingCustomerInfo = false;
        }
      },
      async saveOrderName() {
        try {
          if (this.order === this.orderName) {
            return;
          }

          await this.updateOrderName({
            orderId: this.orderId,
            orderName: this.orderName,
          });

          this.$toast.success('Se actualizó el nombre de la orden');
        } catch (error) {
          this.$toast.error('No se pudo actualizar el nombre de la orden');
        }
      },
      async onProductSearchSubmit({ sku, quantity }) {
        // SEARCH SKU IN CURRENT CART
        const index = this.cart.findIndex(
          (item) => item.codigo_material == sku
        );
        if (index !== -1) {
          this.$toast.warning('El código de material ya está en el carrito');
          return;
        }

        await this.addItemToCart(sku, quantity);
      },
      async addItemToCart(sku, quantity) {
        this.isLoading = true;

        try {
          const product = await this.getMaterialInfo({
            sku,
            quantity,
            custumer_code: this.customerCode,
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR,
            order_type: this.doctype,
          });

          await this.saveNewMaterialOrder({
            order_id: this.orderId,
            item: product,
          });

          if (quantity < product.empaque || product.u_confirm == 0) {
            await this.updateMaterialQuantity({
              codigo_material: product.codigo_material,
              quantity:
                product.u_confirm == 0
                  ? product.recordatorios
                  : product.u_confirm,
              custumer_code: this.customerCode,
              order_type: this.doctype,
            });
          }

          this.$refs.searchForm.clearForm();
          this.$toast.success('Producto agregado');
        } catch (error) {
          if (error.response?.data?.error_code) {
            this.$toast.warning(error.response.data.error_code);
          } else {
            this.$toast.error('No se pudo agregar al carrito');
          }
        } finally {
          this.isLoading = false;
        }
      },
      async onConfirmOrder() {
        if (!this.destination) {
          this.$toast.error('El destinatario de mercancía es requerido');
          return;
        }

        if (!this.cart || this.cart.length === 0) {
          this.$toast.error(
            'El carrito está vacío, no se puede generar la orden'
          );
          return;
        }

        const existBackOrder = this.cart.some((item) => item.recordatorios > 0);
        const exceedsCreditLimit =
          this.totalCartPrice >= this.availableCredit ? true : false;

        if (existBackOrder) {
          this.isConfirmBackOrderModalOpen = true;
        } else {
          if (exceedsCreditLimit) {
            if (
              this.customerInfo.DOCVENC > 0 ||
              this.customerInfo.AUFSD == 1 ||
              this.availableCredit <= 1 ||
              this.lowPrice > this.availableCredit
            ) {
              this.runGenerateOrder();
            } else {
              this.isConfirmCartCreditModalOpen = true;
            }
          } else {
            this.runGenerateOrder();
          }
        }
      },
      async onConfirmBackOrder() {
        const existBackOrder = this.cart.some((item) => item.recordatorios > 0);
        const exceedsCreditLimit =
          this.totalCartPrice >= this.availableCredit ? true : false;

        if (exceedsCreditLimit) {
          if (
            this.customerInfo.DOCVENC > 0 ||
            this.customerInfo.AUFSD == 1 ||
            this.availableCredit <= 1 ||
            this.lowPrice > this.availableCredit
          ) {
            this.runGenerateOrder();
          } else {
            if (existBackOrder) {
              this.runGenerateOrder();
            } else {
              this.isConfirmCartCreditModalOpen = true;
            }
          }
        } else {
          this.runGenerateOrder();
        }
      },
      async onUpdateMaterialCredit(event, item) {
        try {
          await this.updateMaterialCredit({
            orderId: item.orderID,
            sku: item.materialCode,
            credit: event,
            custumer: this.customerCode,
            quantity: item.orderUnits,
            doctype: this.doctype,
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR,
          });
        } catch (error) {
          this.$toast.error('No se pudo actualizar el crédito del material');
        }
      },
      async runGenerateOrder() {
        this.isConfirmBackOrderModalOpen = false;
        this.isConfirmCartCreditModalOpen = false;
        this.isGeneratingOrder = true;
        let purchaseValidation;

        if (
          this.customerInfo.DOCVENC > 0 ||
          this.customerInfo.AUFSD == 1 ||
          this.availableCredit <= 1 ||
          this.lowPrice > this.availableCredit
        ) {
          purchaseValidation = 2;
        } else {
          purchaseValidation = 1;
        }

        try {
          const response = await this.generateOrder({
            custumer_code: this.customerCode,
            orden_compra: this.orderName,
            carrito: this.cart,
            orderType: this.doctype,
            destiny: parseInt(this.destination?.KUNNR),
            banderaProgramado: '',
            purchase_validation: purchaseValidation,
            numero_vendedor: this.sellerNumber,
            documents: this.relatedDocs,
            v_cfdi: this.relatedCFDI,
            select_metodo: this.paymentMethod,
            select_v_pay: this.paymentChannel,
            select_use_cfdi: this.cfdiApplication,
            schedule_orders: this.scheduleOrder,
            schedule_date: this.scheduleOrderDate,
            quoter: this.idq,
          });

          this.isGeneratingOrder = false;
          this.isOrderSubmitted = true;

          if (response.message == 'BACKORDER GENERADO') {
            this.$swal.fire(
              response.message,
              'Número de Pedido: S/N',
              'success'
            );
            this.$router.replace({
              name: 'HistoryOrders',
            });
          }

          const orderResponse = response.VBELN;
          const orderSecondResponse =
            response.VBELN2 == ''
              ? ''
              : `Pedido enviado a crédito ${response.VBELN2}`;
          const invoiceResponse = response.FACTURAS;
          const deliveryResponse = response.ENTREGAS;
          const purchaseResponse = response.PVALID;
          const messageResponse = response.MYERR == 0 ? '' : response.FNMSG;

          const message = `Pedido: ${orderResponse}.
                         Entrega: ${deliveryResponse}.
                         Factura: ${invoiceResponse}.
                         ${orderSecondResponse}.
                         ${messageResponse}.`;

          if (purchaseResponse == 1) {
            this.$toast.success('Pedido Generado', message);
          } else {
            this.$toast.success(
              'Pedido Generado',
              message + '* Bloqueado por crédito'
            );
          }

          this.$router.replace({ name: 'HistoryOrders' });
        } catch (error) {
          this.isGeneratingOrder = false;

          if (error.response.status == 404) {
            if (error.response.data.message == 'BACKORDER GENERADO') {
              this.$swal.fire(
                error.response.data.message,
                'Número de Pedido: S/N',
                'success'
              );
              this.$router.replace({ name: 'HistoryOrders' });
            } else {
              this.$swal({
                title: error.response.data.error_code,
                icon: 'error',
                type: 'error',
              });
            }
          }
        }
      },
    },
    watch: {
      order: {
        handler(order) {
          this.orderName = order;
        },
        immediate: true,
      },
      totalCartPrice() {
        this.runCartValidations();
      },
    },
  };
</script>

<style scoped>
  .confirm-order-btn {
    background: transparent linear-gradient(180deg, #218838 0%, #11441c 100%) 0%
      0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
    color: white !important;
    font-weight: 900 !important;
  }

  .action-btn {
    box-shadow: inset 0px 2px 6px #00000029, 0px 2px 6px #00000029;
    font-weight: 900;
    text-transform: capitalize;
    flex-grow: 1;
  }

  .action-btn-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
  }

  @media (min-width: 960px) {
    .action-btn-group {
      flex-direction: row;
      gap: 16px;
    }
  }
</style>
