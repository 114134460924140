<template>
  <div>
    <span>{{ discount.codigo_material }}</span>
    <span v-if="validateGenetic">
      <special-genetics-icons :genetics="discount.material_type"/>
    </span>
    <span>
      <v-tooltip bottom v-if="discount.TIPOP == 1">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <img
              :src="require(`@/assets/img/icons_orders/POP.png`)"
              height="30px"
              width="30px"
              alt=""
              class="mr-2 ml-2 mt-2"
            />
          </span>
        </template>
        <span>Producto POP</span>
      </v-tooltip>
      <v-tooltip bottom v-if="validateNR">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <img
              :src="require(`@/assets/img/icons_orders/NR.png`)"
              height="30px"
              width="30px"
              alt=""
              class="mr-2 ml-2 mt-2"
            />
          </span>
        </template>
        <span>No cuenta para Renglonaje</span>
      </v-tooltip>
      <v-tooltip bottom v-if="discount.PNC == 1 && validateNR == false">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <img
              :src="require(`@/assets/img/discount_icons/PNC.png`)"
              height="30px"
              width="30px"
              alt=""
              class="mr-2 ml-2 mt-2"
            />
          </span>
        </template>
        <span>Producto nuevo</span>
      </v-tooltip>
    </span>
    <span v-for="(item, i) in validateDiscount" :key="i.id">
      <v-tooltip
        bottom
        v-if="
          discount[item.id] != 0 && item.id != 'MVGR5_POR' && item.id != 'ZK14'
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"
            ><img
              :src="require(`@/assets/img/discount_icons/${item.id}.svg`)"
              height="60px"
              width="60px"
              alt=""
              class="mt-2"
          /></span> </template
        ><span
          >{{ item.description }}
          <span v-if="item.id != 'PNC'"
            >{{ seePorcent(discount[item.id]) }}%</span
          ></span
        >
      </v-tooltip>

      <v-tooltip bottom v-if="discount[item.id] != 0 && item.id == 'MVGR5_POR'">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"
            ><img
              :src="
                require(`@/assets/img/discount_icons/${discount.MVGR5}.svg`)
              "
              style="width: 65px"
              alt=""
              class="mt-2"
            />
          </span> </template
        ><span>{{ item.description }} {{ discount[item.id] }} %</span>
      </v-tooltip>
      <v-tooltip bottom v-if="discount[item.id] != 0 && item.id == 'ZK14'">
        <template v-slot:activator="{ on, attrs }">
          <v-badge 
            :content="discountString(discount[item.id])"
            color="transparent"
            style="font-weight: bold"
            :offset-x="validateLenghtString(discount[item.id])"
            :offset-y="counterPercent(discount[item.id]) > 2 ? '35px' : '27px'"
          >
            <span v-bind="attrs" v-on="on"
              ><img
                :src="require(`@/assets/img/discount_icons/${item.id}.svg`)"
                :style="
                  counterPercent(discount[item.id]) > 2
                    ? 'width: 50px'
                    : 'width: 33px'
                "
                alt=""
              />
            </span> </v-badge></template
        ><span
          >{{ item.description }} {{ discountString(discount[item.id]) }}</span
        >
      </v-tooltip>
    </span>
  </div>
</template>
<script>
import SpecialGeneticsIcons from "./SpecialGeneticsIcons.vue";

export default {
  name: "SpecialDiscountIcon",
  props: {
    discount: {
      type: Object,
      required: true,
    },
  },
  components: {
  SpecialGeneticsIcons,
  },
  data: () => ({
    validateNR: null,
    validateGenetic: false,
    validateDiscount: [
      { id: "ZKVL", description: "Volumen" },
      { id: "ZKRF", description: "Familia" },
      { id: "ZKRG", description: "Renglonaje" },
      { id: "ZKCM", description: "Comercial" },
      { id: "ZK69", description: "Especial Familia" },
      { id: "KPRK", description: "Producto Combo" },
      { id: "ZKDE", description: "" },
      { id: "ZK25", description: "Especial Renglón" },
      { id: "ZK71", description: "Descuento" },
      { id: "ZKSP", description: "Descuento Especial" },
      { id: "MVGR5_POR", description: "Factor" },
      { id: "ZK14", description: "Promoción" },
    ],
  }),
  methods: {
    discountString(value) {
      // This is because it does not display 0 when is INT
      const valuePercent = parseFloat(value * 100);
      const result = valuePercent - Math.floor(valuePercent) !== 0;
      if (result) {
        const decimal = valuePercent.toFixed(2);
        return `${decimal}%`;
      } else {
        const decimal = valuePercent.toFixed(0);
        return `${decimal}%`;
      }
    },
    counterPercent(value) {
      const valuePercent = parseFloat(value * 100);
      const result = valuePercent - Math.floor(valuePercent) !== 0;

      if (result) {
        const decimal = valuePercent.toFixed(2);
        return decimal.length;
      } else {
        const decimal = valuePercent.toFixed(0);
        return decimal.length;
      }
    },
    seePorcent(value) {
      return parseFloat(value * 100).toFixed(2);
    },
    validateLenghtString(value){
      const valuePercent = this.counterPercent(value);
      
      if(valuePercent == 1){
        return "31px";
      }else if(valuePercent == 2){
        return "35px";
      }else if(valuePercent >= 3){
        return "52px";
      }
      
    }
  },
  watch: {
    "discount.MVGR4": {
      immediate: true,
      handler(item) {
        const arrayData = ["1ZA", "1XA", "1ZB", "1ZC", "1YA"];
        this.validateNR = arrayData.includes(item);
      },
    },
    "discount.material_type":{
      immediate: true,
      handler(genetic) {
         if(this.$hasAnyRole(['Customer','CustomerPR'])){
          if(genetic == 'D' || genetic == 'N'){
            this.validateGenetic = true;
          }else{
            this.validateGenetic = false;
          }
         }
      }
    }
  },
};
</script>
