export const cleanExpiredLocalStorage = (cachePrefix) => {
  const now = Date.now();

  Object.keys(localStorage).forEach((key) => {
    try {
      if (key.startsWith(cachePrefix)) {
        const item = JSON.parse(localStorage.getItem(key));

        if (item && item.ttl && item.createdAt) {
          const expiryTime = item.createdAt + item.ttl;

          // Remove if expired
          if (now > expiryTime) localStorage.removeItem(key);
        }
      }
    } catch {
      // Do nothing, just continue
    }
  });
};
