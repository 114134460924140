<template>
  <div class="d-flex align-items-center">
    <v-card
      class="mx-auto rounded-lg elevation-4 ma-3 w-100"
      :style="'border: 4px solid black; background-color:transparent;'"
      outlined
    >
      <div
        v-if="!isBackorder"
        class="text-general-module mb-0 d-flex justify-center align-center px-5 py-2"
      >
        <div class="d-flex align-center mr-2">
          <span
            v-if="doctype == 'PSIU' || doctype == 'PSDT'"
            class="icoiusa-checkbox-marked"
          ></span>
          <span
            v-if="doctype == 'PSPO'"
            class="icoiusa-shrink2"
          ></span>
          <span
            v-if="doctype == 'PSNW'"
            class="icoiusa-two-arrows"
          ></span>
        </div>

        <span v-if="doctype === 'PSIU' || doctype === 'PSDT'">
          NUEVO PEDIDO
        </span>
        <span
          v-if="doctype === 'PSPO'"
          class="ml-1"
        >
          PEDIDO DE ARTÍCULOS PROMOCIONALES (POP)</span
        >
        <span
          v-if="doctype == 'PSNW'"
          class="ml-1"
        >
          PEDIDO DE PRODUCTOS NUEVOS (PNC)</span
        >

        <v-icon
          color="orange"
          class="ml-2"
          @click="$emit('launchCartAlertsModal')"
        >
          mdi-alert-circle
        </v-icon>
      </div>

      <div
        v-else
        class="text-general-module mb-0 d-flex justify-center align-center px-5 py-2"
      >
        <div class="d-flex align-center mr-2">
          <span
            v-if="doctype == 'PSIU' || doctype == 'PSDT'"
            class="icoiusa-checkbox-marked"
          ></span>
        </div>

        <span>
          PEDIDO BACKORDER {{ backorderType }}
        </span>

        <v-icon
          color="orange"
          class="ml-2"
          @click="$emit('launchCartAlertsModal')"
        >
          mdi-alert-circle
        </v-icon>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'ShoppingCartTypeHeader',
    props: {
      doctype: { type: String },
      isBackorder: {type: Boolean},
      backorderType: {type: String}
    },
  };
</script>

<style lang="scss" scoped></style>
