<template>
    <div>
      <v-container fluid style="background-color: #fff"> 
        <div>
        <v-row class="mt-0 ml-3 mr-3" >
        <v-col cols="12" class="pb-0 pt-0">
            <search-bar/> 
        </v-col>
        <v-col cols="12 pb-0 pt-0 mt-1">  
              <net-sales  
              :chnl="chnl" 
              :regionalManager="regionalManager" />
        </v-col>
        <v-col  cols="12" class="pt-0 mb-0 "> 
              <orders 
              :chnl="chnl" 
              :regionalManager="regionalManager" />
        </v-col> 
        <v-col cols="12 pa-0 mt-1 mb-2">  
          <fill-rate 
            :userId="regionalManager" 
            :option="regionalManager ? 'GR':'D'" 
            :chnl="chnl" />
        </v-col>
        <v-col cols="12" md="12" class="mt-1 pa-0 ml-n1">
          <v-card elevation="0" flat> 
            <v-card-text class="d-flex flex-wrap pt-0 pr-1 pb-0">  
              <div class=" w-100 ma-0 " style="box-shadow: inset 0px 0px 8px #FE080829, 0px 0px 6px #F8090929;"> 
                <group-by-menu  
                @change="setFilters" 
                :chnl = "chnl"
               
                />  
              </div>
          <seller-list v-if="filter.groupBy == 3"
              :chnl="chnl" 
              :regionalManager="regionalManager"
              :period="[]" 
              :filter="filter"
              class="w-100"
              />
            <manager-list v-else
              :chnl="chnl"
              :filter="filter"
              :regionalManager="regionalManager"  
               class="w-100"
            />
        </v-card-text>
        </v-card>
        </v-col> 
        <v-col cols="12" md="12" class="mt-0 pa-0 pl-3 pr-2" v-if="filter.groupBy != 3">
          <month-end 
          :chnl="chnl"
          :regionalManager="regionalManager"  />
        </v-col>
      </v-row>
    </div> 
        <confidentiality-notice  />
      </v-container>
    </div>
  </template>
  <script>    

  import ConfidentialityNotice from "@/components/ConfidentialityNotice.vue";
  import NetSales from '@/views/diary/director/sections/NetSales.vue'
  import Orders from '@/views/diary/director/sections/Orders.vue'
  import ManagerList from '@/views/diary/director/sections/ManagerList.vue' 
  import MonthEnd from '@/views/diary/director/sections/MonthEnd.vue';
  import SearchBar from '@/views/diary/director/components/SearchBar.vue';
  import SellerList from "@/views/diary/director/sections/SellerList.vue";
  import GroupByMenu from "@/views/diary/director/components/GroupByMenu.vue";
  import FillRate from "@/views/fill_rate/FillRate.vue";

  import { mapGetters } from "vuex";

  export default {
    name: "DirectorHome",
    components: {
      ConfidentialityNotice,
      NetSales,
      Orders,
      ManagerList,   
      MonthEnd,
      SearchBar,
      SellerList,
      GroupByMenu,
      FillRate
    },
    data: () => ({    
      groupBy:'',
      period:'',
      chnl:"",
      regionalManager:0,
      filter:[],
    }), 
    methods: {  
      setFilters(data){ 
        this.filter = data;
      }, 
    },  
    created(){ 
        this.chnl =   this.user.VTWEG; 
        const rgManager = this.$getLocalStorageRegional();
        
        if (rgManager && this.$route.meta.role == 'Regional' && !this.$hasAnyRole(["RegionalManager", "IELRegManager"])) {
          this.regionalManager = `${rgManager}`;
        }  
        if (this.$hasAnyRole(["RegionalManager", "IELRegManager"])) {
          this.regionalManager = `${localStorage.getItem("username")}`; 
        }   
    },
    computed: {
    ...mapGetters({ 
      user: "auth/user",   
    }),
    
  }

  };
  </script>
  <style>
  .custom-red-button {
    background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
    opacity: 1; 
  }
  </style>