<template>
  <GmapMap :center="position" :zoom="16" style="width: 100%; height: 280px">
    <GmapMarker
      :position="position"
      :draggable="draggable"
      @dragend="(e) => $emit('dragend', e)"
    >
      <GmapInfoWindow :options="{ maxWidth: 200 }" :opened="openedInfoWindow">
        <p>Puede arrastrar el marcador a la posición correcta</p>
      </GmapInfoWindow>
    </GmapMarker>
  </GmapMap>
</template>

<script>
export default {
  name: 'SingleMapMarker',

  props: {
    lat: {
      type: Number,
      required: true,
    },

    lng: {
      type: Number,
      required: true,
    },

    draggable: Boolean,
    openedInfoWindow: Boolean,
  },

  computed: {
    position() {
      return { lat: this.lat, lng: this.lng };
    },
  },
};
</script>
