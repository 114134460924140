<template>
  <v-dialog
      v-model="showMap"
      persistent
      transition="dialog-bottom-transition"
      :max-width="900"> 

      <v-card>
        <!-- Header -->
        <v-toolbar :dark="true">
          <v-toolbar-title>Ubicación</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="right" @click="closeModal" icon>
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- End Header -->
        
        <v-card-text class="py-5" >
          <!-- Buttons Select -->
           <div v-if="!activeTaxAdress && !activeRecipient" class="d-flex gap-3">
            <div class="w-100">
              <v-btn class="text-capitalize custom-red-button py-6 font-size-x w-100"    
                dark            
                small
                rounded
                @click="openTaxAddress">Domicilio Fiscal
              </v-btn>
            </div>
            
            <div class="w-100">
              <v-btn class="text-capitalize custom-red-button py-6 font-size-x w-100" 
              dark
              small
              rounded
              @click="openRecipientMerchandise">Destinatario de Mercancia
            </v-btn>
            </div>
          </div>
          <!-- Buttons Select --> 

          <!-- Recipient Merchandise and TaxAddress -->
          <tax-address v-show="activeTaxAdress"></tax-address>
          <recipient-merchandise v-show="activeRecipient"></recipient-merchandise>
        </v-card-text>        
      </v-card>     
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import TaxAddress from '@/views/cordinate_records/TaxAddress.vue';
import RecipientMerchandise from '@/views/cordinate_records/RecipientMerchandise.vue';

export default {
  components: { TaxAddress, RecipientMerchandise },
  name: "LocationMaps",
  props: {
    taxAddress:{
      type: String,
      required: true
    },
    value: { 
      type: Boolean,
      default: false
    },
    customerCode:{
      type: String,
      required: true
    }
  },

  methods: {
    openTaxAddress(){
      this.SET_ADDRESS(this.taxAddress);
      this.actionActiveTaxAdress(); 
    },

    openRecipientMerchandise(){
      this.actionActiveRecipient();
    },

    closeModal(){
      this.showMap = false; 
      this.actionCloseModal();
    }, 

    ...mapMutations('coordinatesRecords', ['SET_ADDRESS', 'SET_SHOW_LOADER', 'SET_CUSTOMER_CODE']),
    ...mapActions('coordinatesRecords', ['actionActiveTaxAdress', 'actionActiveRecipient', 'actionCloseModal']),
  },

  computed:{
    showMap: { 
      set(value) { 
        this.$emit('input', value);
      },
      get(){
        return this.value;
      }
    },

    ...mapGetters('auth', ['user']), 
    ...mapGetters('coordinatesRecords', ['address', 'activeTaxAdress', 'activeRecipient']),   
  },

  mounted(){
    this.SET_CUSTOMER_CODE(this.customerCode);
  },
};
</script>