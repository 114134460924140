<template>
      <v-tooltip bottom >
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <img :src="orderType.icon"  width="25"/>
          </span>
        </template>
        <span>{{orderType.label}}</span>
      </v-tooltip>
</template>
<script>
export default {
  name: "SpecialOrderTypeIcon",
  props: {
    order: {
      type: String,
      required: true,
    },
  },
  computed: {
    orderType: function () {
      switch (this.order) {
        case "PSPO":
          return {
            icon: require(`@/assets/img/icons_orders/POP.png`),
            label: "Bono POP",
          };
        case "PSNW":
          return {
            icon: require(`@/assets/img/discount_icons/PNC.png`),
            label: "Productos Nuevos",
          };
        case "ZAIU":
          return {
            icon: require(`@/assets/img/icons_orders/A.png`),
            label: "Pago Anticipado",
          };
        default:
          return { icon: "", label: "" };
      }
    },
  },
};
</script>