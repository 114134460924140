var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"min-width":"550","width":"550","persistent":"","scrollable":""},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c('v-card',[_c('div',{staticClass:"w-full bg-black d-flex justify-content-between align-center px-4"},[_c('span',{staticClass:"text-white d-flex align-center font-weight-bold"},[_vm._v("Confirmación de pedido")]),_c('v-btn',{staticStyle:{},attrs:{"icon":"","small":""},on:{"click":function($event){_vm.isModalOpen = false}}},[_c('v-icon',{staticClass:"text-white font-size-8"},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"w-9 bg-white d-flex flex-wrap"},[_c('h4',{staticClass:"w-full mt-4 font-weight-bold",staticStyle:{"color":"grey"}},[_vm._v(" LÍMITE DE CRÉDITO EXCEDIDO ")]),_c('span',{staticClass:"solid_line"}),_c('span',{staticClass:"text-title-bk-black font-weight-bold mt-3 mb-5"},[_vm._v("Crédito disponible: "+_vm._s(_vm.formatPrice(_vm.availableCredit))+" ")]),_c('span',{staticClass:"text-title-bk-black font-weight-bold mt-3 mb-5"},[_vm._v("Total: "),_c('span',{class:_vm.subtotalCred >= _vm.availableCredit
              ? 'text-title-bk'
              : 'text-title-bk-succes'},[_vm._v(_vm._s(_vm.formatPrice(_vm.subtotalCred)))])])]),_c('v-card-text',[_c('div',{staticClass:"w-9 bg-white d-flex flex-wrap"},_vm._l((_vm.cart),function(item,i){return _c('result-item-val-credit',{key:i.codigo_material,staticClass:"mr-5 ml-5",attrs:{"item":{
            materialCode: item.codigo_material.slice(-6),
            name: item.nombre_material,
            sku: item.codigo_material.slice(-6),
            reminder: item.reminder_check,
            recordatorios: item.recordatorios,
            orderUnits: item.u_pedidas,
            totalCost: item.importe_producto,
            v_credit: item.v_credit,
            orderID: _vm.orderId,
          }},on:{"onClick":(event, item) => _vm.$emit('updateMaterialCredit', { event, item })}},[_c('div',{staticClass:"mr-1",staticStyle:{"width":"60px"}},[_c('img',{staticClass:"w-full",attrs:{"src":item.image_url}})])])}),1)]),_c('v-card-actions',[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-btn',{staticClass:"button-red-cancel text-white font-weight-bold font-size-8",on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v(" CANCELAR ")]),_c('v-spacer'),_c('v-btn',{class:_vm.subtotalCred >= _vm.availableCredit || _vm.subtotalCred <= 0
              ? 'button-green-disabled font-weight-bold font-size-8'
              : 'button-green text-white font-weight-bold font-size-8',attrs:{"disabled":_vm.subtotalCred >= _vm.availableCredit || _vm.subtotalCred <= 0},on:{"click":function($event){return _vm.$emit('orderGenerate')}}},[_c('span',{staticClass:"text-white",staticStyle:{"font-size":"12px"}},[_vm._v("CONFIRMAR PEDIDO"),_c('v-icon',[_vm._v("mdi-checkbox-multiple-marked-circle")])],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }