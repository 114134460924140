<template>
    <v-simple-table class="bordered-table" dense>
        <template v-slot:default>
            <table-head :colspan="2" only-months="true">
                <template v-slot:thead>
                    <th class="text-center header pa-1" colspan="2">
                        <div class="text-black"> {{ columnTitle }} </div>
                    </th>
                </template>
            </table-head>
            <tbody>
                <table-body-loader :colspan="25" v-if="loading" />
                <template v-else v-for="(item, index) in data">
                    <template v-if="index == 0">
                        <tr :key="`name-${index}`" class="first-row">
                            <td rowspan="3" class="pa-2 first-row w-0 item-name-border">
                                {{ item.name.replaceAll(" ", "&nbsp;") }}
                            </td>
                        </tr>
                        <tr :key="`iel-${index}`" class="first-row">
                            <td class="text-caption pa-1  w-0 item-iel-border">
                                IEL</td>
                            <template v-for="(iel, index) in item.IEL">
                                <td :key="`iel-amount-${index}`" class="text-caption pa-1 w-0 font-weight-bold">
                                    {{ formatNumber(iel.amount, "$", 0) }}
                                </td>
                                <td :key="`iel-percent-${index}`" class="text-caption pa-1 w-0  font-weight-bold">
                                    {{ formatFloat(iel.percent, 0) }}%</td>
                            </template>
                        </tr>
                        <tr :key="`sap-${index}`" class="first-row">
                            <td class="text-caption pa-1 w-0 item-sap-border">Otros
                            </td>
                            <template v-for="(sap, index) in item.SAP">
                                <td :key="`sap-amount-${index}`" class="text-caption pa-1 w-0  font-weight-bold">
                                    {{ formatNumber(sap.amount, "$", 0) }}</td>
                                <td :key="`sap-percent-${index}`" class="text-caption pa-1 w-0  font-weight-bold">
                                    {{ formatFloat(sap.percent, 0) }}%
                                </td>
                            </template>
                        </tr>
                    </template>
                    <template v-else>
                        <tr :key="`name-${index}`" class="light-rows">
                            <td rowspan="3" class="pa-2 w-0 item-name-border">
                                <a class="mr-8 text-black font-weight-bold" :class="{ 'text-decoration-none': manager }" @click="optionSelected(item)">{{
                                    item.name.replaceAll(" ", "&nbsp;") }}</a>
                            </td>
                        </tr>
                        <tr :key="`iel-${index}`" class="light-rows">
                            <td class="text-caption pa-1 w-0 item-iel-border">
                                IEL</td>
                            <template v-for="(iel, index) in item.IEL">
                                <td :key="`iel-amount-${index}`" class="text-caption pa-1 w-0 bg-white">{{
                                    formatNumber(iel.amount, "$", 0) }}
                                </td>
                                <td :key="`iel-percent-${index}`" class="text-caption pa-1 w-0">
                                    {{ formatFloat(iel.percent, 0) }}%
                                </td>
                            </template>
                        </tr>
                        <tr :key="`sap-${index}`" class="light-rows">
                            <td class="text-caption pa-1 w-0 item-sap-border">Otros</td>
                            <template v-for="(sap, index) in item.SAP">
                                <td :key="`sap-amount-${index}`" class="text-caption pa-1 w-0 bg-white">
                                    {{ formatNumber(sap.amount, "$", 0) }}</td>
                                <td :key="`sap-percent-${index}`" class="text-caption pa-1 w-0">
                                    {{ formatFloat(sap.percent, 0) }}%
                                </td>
                            </template>
                        </tr>
                    </template>

                </template>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import numbersFormats from '@/mixins/numbersFormats';
import Helpers from '@/mixins/helpers';

import TableHead from './TableHead.vue';
import TableBodyLoader from '@/components/tables/TableBodyLoader.vue';

export default {
    name: "BillingTable",
    components: { TableHead, TableBodyLoader },
    mixins: [numbersFormats, Helpers],
    props: {
        data: {
            default: () => [{}]
        },
        loading: {
            default: true,
        },
        columnTitle: {
            default: 'Zona',
        },
        manager: {
            type: String,
            default: "",
        },
    },
    methods: {
        optionSelected(value) {
            this.$emit('optionSelected', value)
        }
    },
};
</script>
<style scoped>
.first-row td {
    background: #E0E0E0 0% 0%;
    border-color: #A6A3A3 !important;
    border-top-color: #E1E1E1 !important;
}

.bordered-table td {
    border: 1px solid #A6A3A3;
}

.light-rows td {
    background: #F2F2F2 0% 0%;
    border-color: #A6A3A3 !important;
}

.item-name-border {
    border-width: 1px 0px 1px 1px;
}

.item-iel-border {
    border-width: 1px 1px 0px 0px;
}

.item-sap-border {
    border-width: 0px 1px 1px 0px;
}
</style>