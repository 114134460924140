import { mapActions } from 'vuex';

export default {
  created() {
    this.activeLoader();
  },

  methods: {
    ...mapActions('loader', { activeLoader : 'activeLoader', desactive: 'desactiveLoader' }),

    desactiveLoader(timeout = 200) {
      setTimeout(() => {
        this.desactive();
      }, timeout)
    },
  },
};
