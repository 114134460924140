<template>
  <v-row>
    <v-col cols="12">
      <v-card-subtitle>
        <p>
          LÍMITE DE CRÉDITO:
          <span class="font-weight-black">{{ formatPrice(creditLimit) }}</span>
        </p>

        <v-progress-linear
          :value="creditPercentage"
          height="25"
          dark
        >
          <strong>{{ creditPercentage }} %</strong>
        </v-progress-linear>
        <v-row
          no-gutters
          class="mt-1"
        >
          <v-col
            cols="12"
            md="6"
            class="d-flex justify-center justify-md-start pb-2 pb-md-0"
          >
            <span class="text-xs text-center text-md-left">
              SALDO AL CORTE:
              <span class="font-weight-black">
                {{ formatPrice(statementBalance) }}
              </span>
            </span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="d-flex flex-column"
          >
            <div class="text-center text-md-right">
              <span class="text-xs">
                CRÉDITO DISPONIBLE:
                <span class="font-weight-black">
                  {{ formatPrice(availableCredit) }}
                </span>
              </span>
            </div>
            <div class="text-center text-md-right">
              <span class="text-xs">
                CRÉDITO DISPONIBLE PEDIDO ACTUAL:
                <span class="font-weight-black">
                  {{ formatPrice(availableCreditCurrentOrder) }}
                </span>
              </span>
            </div>
          </v-col>
        </v-row>
      </v-card-subtitle>
    </v-col>
  </v-row>
</template>

<script>
  import { formatPrice } from '@/utilities/formatPrice';

  export default {
    name: 'CustomerCreditLimitBar',
    props: {
      creditLimit: { type: Number },
      statementBalance: { type: Number },
      availableCredit: { type: Number },
      availableCreditCurrentOrder: { type: Number },
    },
    computed: {
      creditPercentage() {
        return Math.floor(
          (parseInt(this.statementBalance) * 100) / parseInt(this.creditLimit)
        );
      },
    },
    methods: {
      formatPrice(value) {
        return formatPrice(value);
      },
    },
  };
</script>
