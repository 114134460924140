<template>
  <v-menu bottom left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark icon @click="launchStep" v-bind="attrs" v-on="on">
        <v-badge 
          color="transparent"
          :offset-x="availableRemindersCount < 1000 ? '25px' : '32px'"
          offset-y="23px"
          overlap
        >
          <template v-slot:badge>
            <v-progress-circular
              v-if="loadingReminders"
              indeterminate
              color="white"
              size="15"
              width="2"
              style="transform: translateY(-3px);"
            />
            <span v-else style="transform: translateY(-3px);">{{ remindersCountString }}</span>
          </template>

          <v-img 
            v-if="availableRemindersCount >= 1000" 
            src="@/assets/img/backorder_box_2.svg" 
            width="39px" 
          /> 
          <v-img 
            v-else 
            src="@/assets/img/backorder_box.svg" 
            width="39px" 
          /> 
        </v-badge>
      </v-btn>
    </template>

    <v-card v-if="loadingReminders || !hasAvailableReminders">
      <v-card-text>
        {{ loadingReminders ? 'Cargando recordatorios...' : 'No tienes backorders activos' }}
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ReminderStepLauncher',

  data() {
    return {
      intervalId: null,
      showEvery: 15 * 60 * 1000,
    };
  },

  mounted() {
    this.intervalId = setInterval(async () => {
      await this.loadRemindersStep();
      this.launchStep();
    }, this.showEvery); 
  },

  methods: {
    ...mapActions('modalStepsAfterLogin', [
      'updateModalState',
      'findStepByKey',
      'changeCurrentStepValue',
      'updateStepStatus',
      'loadRemindersStep',
    ]),

    async launchStep() {
      if (this.hasAvailableReminders) {
        await this.loadRemindersStep();

        const reminderStep = await this.findStepByKey('reminders');

        if (reminderStep) {
          reminderStep.visited = true;
          this.updateStepStatus(reminderStep);
          this.changeCurrentStepValue(reminderStep.value);
          this.updateModalState(true);
        }
      }
    }
  },

  computed: {
    ...mapGetters('reminders', [
      'hasAvailableReminders',
      'availableRemindersCount',
      'loadingReminders', 
    ]),

    remindersCountString() {
      return this.availableRemindersCount !== null && this.availableRemindersCount !== undefined 
        ? this.availableRemindersCount.toString() 
        : '0';
    }
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
    this.intervalId = null;
  },
};
</script>
