<template>
<div id="contenedor" ref="History">
  <v-container fluid>
    <template>
      <v-row>
      <v-col>
      <v-card cols="12" class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-7">
        <v-card-title>HISTORIAL PEDIDO</v-card-title>
        <v-row>
          <v-col cols="12" md="8">
          <date-range-selector class="mt-5" @onClick="getHistoryByDate" />
          </v-col>
          <v-col cols="12" md="4">
            <v-row class="mt-10" justify="end">
              <search-input v-model="search" class="search-input-width" />
            </v-row>
          </v-col>
        </v-row>
        <br>
        <order-history-table 
        :items="data"
        :search="search"
        height="auto"
        @onPDFClick="getPDFXMLInvoice"
        @onEmailPDFClick="senDEmail"
        @onEmailComClick="senDEmailComp"
        @onOrderDetail="detallePedido"
        @onDeliveryDetail="deliveryDetail"
        />
      </v-card>
    </v-col>
  </v-row>
      <br />
      <v-card id="backorders" cols="12" class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-15">
        <v-card-title>HISTORIAL BACKORDER</v-card-title>
        <v-row justify="space-between">
          <v-col cols="auto" align-self="start">
            <v-btn class="red-button mb-5" @click="loadReminders()">Actualizar</v-btn>
          </v-col>
          <v-col cols="12" md="6" >
            <v-row  justify="end">
            <search-input 
              v-model="searchReminder"
              label="Buscar"
              class="search-input-width"
            />
          </v-row>
          </v-col>
        </v-row>
        <reminders-table
         v-model="selectedRemindersList"
         :items="remindersValidatedFiltered"
         :search="searchReminder"
         :loading="loadingReminders || validatingMaterial"
         :hide-headers="hideTableHeaders()"
         height="auto"
         paginated
         @item-selected="validateItemSelected"
         @on-remove-item="setReminderToRemove"
         />
         <div class="d-sm-flex d-block mt-5 mt-md-10 gap-1 align-center">
          <material-status-helper />
           <v-spacer />
           <v-btn
             class="red-button w-xs-100"
             :disabled="validatingMaterial || selectedReminders <= 0"
             @click="goToCart"
             >
            Ir a carrito
          </v-btn>
        </div>
      </v-card>

      <!-- Request For quotation history table -->
      <v-card cols="12" class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-15">

        <v-card-title>COTIZAS BAJO PEDIDO</v-card-title>

        <div class="d-flex justify-space-between mx-4">
          <v-btn color="red" dark :to="{ name: 'QuotationTracking' }">
            Seguimiento Cotz.
            <v-icon>mdi-list-box-outline</v-icon>
          </v-btn>
          <search-input v-model="quotationSearch" class="search-input-width" />
        </div>

        <request-for-quotation-table 
          :items="requestForQuotationHistory"
          :search="quotationSearch"
          @onQuotationClick="getQuotationDetails"
        />
      </v-card>

      <products-order-modal 
        v-model="dialog"
        :products="pedidoInfo"
        :order-number="pedidoSelect"
        :total="pedidoImporte"      
      />
    
       <!-- Dialog -->
      <v-row justify="center">
      <v-dialog
        v-model="dialog_delivery"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="indigo">
            <v-btn icon dark @click="dialog_delivery = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Pedido:{{ pedidoSelect }}  Entrega: {{ deliverySelect }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <p></p>
          <template>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="text-left">CODIGO</th>
                  <th class="text-left">DESCRIPCION</th>
                  <th class="text-left">CANTIDAD</th>
                  <th class="text-left">CENTRO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in deliveryInfo" :key="item.MATNR">
                  <td class="text-left">{{ parseInt(item.MATNR)  }}</td>
                  <td class="text-left">{{ item.ARKTX }}</td>
                  <td class="text-left">{{ item.LFIMG }}</td>
                  <td class="text-left">{{ item.WERKS }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </template>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- /. Dialog -->
      <!-- Dialog loading -->
      <v-row justify="center">
        <v-dialog v-model="loading" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Cargando
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <v-dialog v-model="dialog_email" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Enviar por Correo
          </v-card-title>
          <v-card-text>
            <p></p>
            <h6 style="text-align: left; font-weight: 700">
              Factura: {{ invoice }}
            </h6>
            <p></p>
            <v-row>
              <v-col md="12">                
                <div class="form-group">
                  <label >Ingresar Email:</label>
                   <v-text-field
                   solo
                   v-model="emai_user"
                    >{{emai_user}}</v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error" text @click="close2()"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="sendEmailXMLPDF(invoice)">
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <delete-order-confirmation-modal
      v-model="showDeleteModal"
      title="Eliminar backorder"
      bar-title="Eliminar BackOrder"
      :key="orderToDelete.orderConcept || ''"
      :order-concept="orderToDelete.orderConcept || ''"
      :deleting="orderToDelete.loading || false"
      :deleted="orderToDelete.deleted || false"
      :error="orderToDelete.error || false"
      @delete-order="deleteReminder"
    />
    </template>
  </v-container>
</div>
</template>
<script>
import axios from "@/plugins/axios";
import {  mapGetters, mapActions, mapMutations  } from "vuex";
import RequestForQuotationTable from '@/components/tables/RequestForQuotationTable.vue';
import SearchInput from '@/components/SearchInput.vue';
import ProductsOrderModal from '@/components/modals/ProductsOrderModal.vue';
import RemindersTable from '@/components/tables/RemindersTable.vue';
import DeleteOrderConfirmationModal from '@/components/modals/DeleteOrderConfirmationModal';
import {
  saveToCartOrderReminderPR,
  saveToCartOrderReminderDT,
} from '@/repositories/Orders';
import OrderHistoryTable from '@/components/tables/OrderHistoryTable.vue';
import DateRangeSelector from "@/components/DateRangeSelector.vue";


import moment from 'moment'
export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    ...mapGetters('reminders', [
      'showRemindersModal',
      'remindersValidatedToSelect',
      'loadingReminders',
      'selectedReminders',
      'hasRemindersSelected',
      'firstReminderSelected',
    ]),
    ...mapGetters('selectedUser', ['loadCustomerError', 'noCustomerSelected']),

    selectedRemindersList: {
      set(newValue) {
        this.updateRemindersSelectionList(newValue);
      },

      get() {
        return this.selectedReminders;
      },
    },
    remindersValidatedFiltered() {
      return this.remindersValidatedToSelect.filter((r) => {
        if (this.customer_code == null) 
          return true;
        return parseInt(r.customer.code) === parseInt(this.customer_code);
      });
    }
  },
  components: {
    RequestForQuotationTable,
    SearchInput,
    ProductsOrderModal,
    RemindersTable,
    DeleteOrderConfirmationModal,
    OrderHistoryTable,
    DateRangeSelector,
  },

  data: () => ({
    id_order: null,
    comments: "A1 = Canc. Rec. Por Falt. Inventario",
    dialogSpecilaOrder :false,
    comments_0: null,
    purchase_order: null,
    invoice: null,
    dialog_email:false,
    selected: [],
    orderShop: [],
    order_array: [],
    disabledCount: 0,
    data: [],
    dialog: false,
    dialog_delivery:false,
    search: "",
    menu1: false,
    menu2: false,
    message_order:"",
    emai_user: null,
    itemCSV:[],

    loading3: false,
    loading: false,
    pedidoSelect: null,
    pedidoInfo: [],
    pedidoImporte: null,
    item: {},
    customer_code:null,
    sellerOrderAviable: true,
    today2: moment().format('YYYY/MM/DD'),
    deliverySelect:null,
    deliveryInfo: null,
    deliveryAmount: null,
    status: false,
    material_val:[],
    requestForQuotationHistory: [],
    quotationSearch: '',
    searchReminder:'',
    validatingMaterial: false,
    showDeleteModal: false,
    orderToDelete: {},
    startDate: moment().subtract( 7, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'), 
  }),
  watch: {
    data: {
            immediate: true,
            handler() {
                this.generarExcel()
            },
    },
  },
  mounted() {
    if (this.$hasAnyRole(['Customer','CustomerPR'])) {
    this.customer_code =  this.user.custumer_code;
   }else{
    const currentCustomerCode = JSON.parse(localStorage.getItem('currentCustomerCode')) || [];
    if (currentCustomerCode.length<1) {
      this.$router.push({name:'Home'})
    }   
    const currentCustomerInfo= JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
          if (currentCustomerInfo.length<1) {
          this.$router.push({name:'Home'})
          }    
          this.customerInfo = currentCustomerInfo;
    this.customer_code =  currentCustomerCode;
   } 
    this.getPedidoHistory(this.startDate, this.endDate);
    this.emai_user = this.user.email;
    this.loadReminders();
    this.getRequestForQuoatationHistory();
    this.generarPDF();
    
    // Slides to a specific id on the view, when comming from backorders blocking message it slides to backorders table
    if(this.$route.params.scrollTo) {  
      document.getElementById(this.$route.params.scrollTo).scrollIntoView();
    }
  },
  methods: {
    ...mapActions({
        sendToPDF: 'printer/sendToPDF',
        sendToExcel: 'printer/sendToExcel',
    }),
    ...mapActions('reminders', [
      'loadReminders',
      'changeRemindersModalState',
      'updateRemindersSelectionList',
      'validateItem',
      'removeReminder',
      'loadRemindersStep',
    ]),
    ...mapActions('selectedUser', ['loadCustomer', 'setSelectedCustomerCode']),

    ...mapMutations('reminders', ['SET_REMINDERS_SELECTED']),
    async validateItemSelected({ item, value }) {
      if (value) {
        this.validatingMaterial = true;
        const error = await this.validateItem(item);

        if (error !== null) this.error = error;

        this.validatingMaterial = false;
      }
    },
    setReminderToRemove(reminder) {
      this.showDeleteModal = true;
      this.orderToDelete = {
        ...reminder,
        loading: false,
        deleted: false,
        error: false,
      };
    },
    async deleteReminder(reasonsForm) {
      const deleteReminderData = {
        reminderData: this.orderToDelete,
        reasonsForm,
      };

      this.orderToDelete.loading = true;
      try {
        await this.removeReminder(deleteReminderData); // Removes reminder from store
        this.orderToDelete.deleted = true;

        setTimeout(() => {
          this.showDeleteModal = false;
        }, 3000);
      } catch (error) {
        this.orderToDelete.error = true;
      }

      this.orderToDelete.loading = false;
    },
    goToCart() {
      if (this.firstReminderSelected.isKit) {
        this.$router.push({
          name: 'CreateKitOrder',
          query: { backorders: true },
        });
      } else {
        if (this.channel === 'PR') {
          this.saveCartPR();
        } else {
          this.saveCartDT();
        }
      }
    },
    async saveCartDT() {
      try {
        const order = await saveToCartOrderReminderDT(
          this.firstReminderSelected.customer.code,
          this.user.id,
          this.firstReminderSelected.orderConcept,
          this.getSelectedReminders(),
          this.firstReminderSelected.type,
        );

        await this.loadReminderCustomer();
        this.SET_REMINDERS_SELECTED([]);

        this.$router.push({
          name: 'NewShoppingCartBack',
          params: { doctype: 'PSDT', idb: order.order_id, type: order.order_type},
        });
      } catch (error) {
        this.error = 'Sucedió un error, no es posible continuar al carrito';
      }
    },
    async saveCartPR() {
      this.creatingOrder = true;

      try {
        const order = await saveToCartOrderReminderPR(
          this.firstReminderSelected.customer.code,
          this.user.id,
          this.firstReminderSelected.orderConcept,
          this.getSelectedReminders(),
          this.firstReminderSelected.type,
        );

        await this.loadReminderCustomer();
        this.SET_REMINDERS_SELECTED([]);

        this.$router.push({
          name: 'NewShoppingCartBackPR',
          params: { doctype: 'PSIU', idb: order.order_id, type: order.order_type},
        });
      } catch (error) {
        this.error = 'Sucedió un error, no es posible continuar al carrito';
      }

      this.creatingOrder = false;
    },
    async loadReminderCustomer() {
      await this.setSelectedCustomerCode(this.firstReminderSelected.customer.code);
      await this.loadCustomer();

      if(this.noCustomerSelected || this.loadCustomerError) throw 'Error al cargar el cliente'
    },
    getSelectedReminders() {
      return this.selectedReminders.map((r) => {
        return {
          cantidad_pedida: r.requiredQty,
          codigo: r.materialId,
          id: r.id,
          medida: r.mesure,
          orden_compra: r.orderConcept,
          nombre: r.customer.name,
        };
      });
    },
    generarPDF(){
            this.sendToPDF({active: true});
    },
    generarExcel(){
      let newItems = []
      this.itemCSV = []

      for (let key in this.data) {                
                let item = this.data[key];
                newItems.push({
                        'Fecha': item.fecha,
                        'Consec': item.consec,
                        'Orden de compra': item.orden,
                        'Pedido': item.pedido,
                        'Entrega':item.entrega,
                        'Factura.': item.factura,
                        'Estatus': item.estatus,
                    });
              }
      this.itemCSV = newItems

      let objectCSV = {
                'name': `Hist. Pedidos-${this.today2}`,
                'items': this.itemCSV,
                'showLabels': true
            }

    this.sendToExcel(objectCSV)
    },
    getHistoryByDate({ startDate, endDate }) {
      this.startDate = startDate;
      this.endDate = endDate;
      
      this.getPedidoHistory(startDate, endDate);
    },
    async getPedidoHistory(startDate, endDate) {
      this.loading3= true;

      const params = {
          customer: this.customer_code,
          channel: this.user.VTWEG,
          date_start: moment(startDate).format("YYYY-MM-DD"),
          date_end: moment(endDate).format("YYYY-MM-DD"),
      };

      try {
      
        const response = await axios.post("/getPedidoHistory", params);
        this.loading3= false;
        this.data = response.data;

      } catch (error) {
        this.error = "No se pudo obtener el estado de cuenta";
      }
    },

    async getRequestForQuoatationHistory () {
      const history = await axios.get(`/request-for-quotation/customer-history/${this.customer_code}`);
      this.requestForQuotationHistory = history.data;
    },

    async getQuotationDetails (quotationId) {
      const quotation =  { pedido: quotationId };
      this.detallePedido(quotation);
    },

    detallePedido(item) {
      this.pedidoSelect = item.pedido;
      this.dialog = true;
      this.loading = true;
      axios
        .post("getInfoPedido", {
          id_pedido: item.pedido,
          //cliente: this.user.custumer_code,
          cliente:this.customer_code
        })
        .then((response) => {
          this.loading = false;
          this.pedidoInfo = response.data.prueba_Pedido;
          this.pedidoImporte = response.data.importe;
          this.pedidoInfo.pop();
        })
        .catch((error) => {
          this.loading = false;
          //alert(error);
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },

    deliveryDetail(item) {
    this.deliverySelect = item.entrega;
    this.pedidoSelect = item.pedido;
    this.dialog_delivery = true;
    this.loading = true;
    axios
      .post("getDeliveryDetail", {
        delivery: item.entrega,
      })
      .then((response) => {
        this.loading = false;
        this.deliveryInfo = response.data.delivery_detail;
        this.deliveryAmount = response.data.importe;
        this.deliveryInfo.pop();
      })
      .catch((error) => {
        this.loading = false;
        //alert(error);
        console.log(error);
      })
      .finally(() => (this.loading = false));
  },


    senDEmail(item) {
      console.log(item);
      this.invoice = item.factura;
      //this.id_order = item.id;
      this.dialog_email = true;
    },
    senDEmailComp(item) {
      console.log(item);
      this.invoice = item.pay_complement;
      //this.id_order = itto em.id;
      this.dialog_email = true;
    },
    close2() {
      this.dialog_email = false;
    },
    getPDFXMLInvoice(invoice,type){
      this.dialog_email = false;
      console.log(invoice);
      console.log(this.emai_user);
      axios
        .post("InvoicePDFXML", {
          invoice: invoice,
          type: type,
        },
        )
        .then((response) => {
          this.url = response.data;
          if(type == 1){
            const link = "https://docs.google.com/gview?embedded=true&url="+this.url;
            window.open(link, "_blank");
          }else{
            window.open(this.url, "_blank");
          }
          
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
    },
    sendEmailXMLPDF(invoice){
     console.log(invoice);
     this.dialog_email = false;
      console.log(invoice);
      console.log(this.emai_user);
      axios
        .post("InvoicePDFXMLEmail", {
          invoice: invoice,
          email: this.emai_user,
        })
        .then((response) => {
          this.message = response.data;
           
          this.$swal({
                    title:response.data.message,
                    icon: "success",
                    type: "succes"
                }).then(function () {
                  //this.getRecordatoriosHistory();
                  
                }
                );
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
    },
    hideTableHeaders(){

     if (this.$hasAnyRole(['Customer', 'CustomerPR'])) {
      return ['seller','manager','customer','regManager'];
    }
    if ( this.$hasAnyRole(['Seller','Manager','Submanager','RegionalManager','IELExpressSales', 'CreditAssistant'])) {
      return ['manager','seller','regManager'];
    }
    },
  },

  beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name === 'NewShoppingCartBackPR' || from.name === 'NewShoppingCartBack') {
                vm.loadReminders();
                vm.loadRemindersStep();
                
            }
        });
    },
};

</script>

<style scoped>
.search-input-width {
  width: 350px;
}
</style>