var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"min-height":"300px","outlined":""}},[_c('div',{staticClass:"px-5 pb-5",attrs:{"rounded":"0"}},[_c('div',{staticClass:"pt-4"},[_c('icon-title',{attrs:{"text":"Instrucciones","title-class":"text-h6 text-md-h6 ml-2"}}),_c('p',{staticClass:"text-left pt-2 mb-0"},[_vm._v(" Estimad@ "),_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.userName))])]),(_vm.alerts.length)?_c('p',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()],1),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._l((_vm.alerts),function(alert){return _c('div',{key:alert.id,staticClass:"d-flex align-center pb-2"},[_c('v-icon',{attrs:{"color":alert.checked ? 'green' : 'red darken-1'}},[_vm._v(" "+_vm._s(alert.checked ? 'mdi-check-circle' : 'mdi-close-circle')+" ")]),_c('p',{staticClass:"mb-0 ml-2"},[_vm._v(_vm._s(alert.label))])],1)}),(!_vm.alerts.length)?_c('div',{staticClass:"d-flex align-center pb-2"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]),_c('p',{staticClass:"mb-0 ml-2"},[_vm._v(" El pedido cumple con los requisitos necesarios ")])],1):_vm._e(),(
            _vm.alerts.find(
              (alert) =>
                alert.id === 'VALID_CART_ITEMS_PSPO' ||
                alert.id === 'VALID_CART_ITEMS_PSNW'
            )
          )?_c('ul',{staticClass:"pl-12"},_vm._l((_vm.invalidCartItems),function(invalidCartItem){return _c('li',{key:invalidCartItem.codigo_material},[_vm._v(" "+_vm._s(invalidCartItem.codigo_material)+" - "+_vm._s(invalidCartItem.nombre_material)+" ")])}),0):_vm._e()],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }